import React from 'react'
import RcInput from 'rc-input-number'

import './styles.scss'

export const NumberInput = ({
  controls,
  decimalSeparator,
  disabled,
  formatter,
  id,
  label,
  loading,
  max,
  min,
  onBlur,
  onInput,
  onChange,
  pattern,
  precision,
  required,
  value,
}) => {
  return (
    <div className="ui-kit-input">
      {label && id && <label htmlFor={id}>{label}</label>}
      <RcInput
        onInput={onInput}
        controls={controls}
        decimalSeparator={decimalSeparator}
        disabled={disabled}
        formatter={formatter}
        loading={loading}
        max={max}
        min={min}
        onBlur={onBlur}
        onChange={onChange}
        pattern={pattern}
        precision={precision}
        required={required}
        value={value}
      />
    </div>
  )
}
