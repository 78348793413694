import types from './types'

const { net } = types

export const actionSetRequestFailure = (errorText) => ({
  type: net.SET_REQUEST_FAILURE,
  payload: errorText,
})

export const actionClearRequestFailure = () => ({
  type: net.CLEAR_REQUEST_FAILURE,
})

export const actionSendRequest = (payload) => ({
  type: net.SEND_REQUEST,
  payload,
})

export const actionSetRequestSuccess = (payload) => ({
  type: net.SET_REQUEST_SUCCESS,
  payload,
})

export const actionSetUserProfile = (payload) => ({
  type: net.SET_USER_PROFILE,
  payload,
})

export const actionSetUserAccount = (payload) => ({
  type: net.SET_USER_ACCOUNT,
  payload,
})

export const actionClearAuthData = () => ({
  type: net.CLEAR_AUTH_DATA,
})
