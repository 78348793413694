import { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'
import { tx } from '@twind/core'

export type BreadcrumbItem = {
  title: string
  path?: string
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[]
}

const Comp: FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <div className={tx`breadcrumbs`}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        const isFirst = !index

        return (
          <div
            key={item.title}
            className={`breadcrumbs__item ${
              isLast && !isFirst ? 'breadcrumbs__item-last text-[#AF90FF]' : ''
            }
            ${!item.path ? 'breadcrumbs__item-disabled' : ''}`}
          >
            <Link to={item.path || ''} className="breadcrumbs__text">
              {item.title}
            </Link>
            {isLast || <div className="breadcrumbs__separator">|</div>}
          </div>
        )
      })}
    </div>
  )
}

export const Breadcrumbs = memo(Comp)
