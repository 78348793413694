import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
import closeIcon from '../../assets/svg/icon_close.svg'

import './styles.scss'
import { FC, useEffect, useRef, useState } from 'react'
import { VideoTypes } from '../../components/ExampleItem'

interface VideoProps {
  video: VideoTypes
  placeholderSrc?: string
  onClose?: () => void
  autoPlay?: boolean
  controls?: boolean
  loop?: boolean
  volume?: number
  bgOnly?: boolean
  muted?: boolean
  playsInline?: boolean
}

export enum Orientations {
  WEB = 'web',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

const MAX_WEB_WINDOW_WIDTH = 1200

function getOrientation(): Orientations {
  if (window.innerWidth > MAX_WEB_WINDOW_WIDTH) {
    return Orientations.WEB
  }

  if (window.matchMedia('(orientation: landscape)').matches) {
    return Orientations.LANDSCAPE
  }

  return Orientations.PORTRAIT
}

export const Video: FC<VideoProps> = ({
  video,
  autoPlay,
  controls,
  loop,
  placeholderSrc,
  bgOnly,
  muted,
  playsInline,
}) => {
  const [orientation, setOrientation] = useState<Orientations>(getOrientation())

  useEffect(() => {
    const onWindowSizeChange = () => {
      setOrientation(getOrientation())
    }

    window.addEventListener('resize', onWindowSizeChange)

    return () => {
      window.removeEventListener('resize', onWindowSizeChange)
    }
  }, [])

  return (
    <>
      {orientation === Orientations.WEB && (
        <video
          playsInline={playsInline}
          className="h-full w-full object-cover object-top"
          controls={controls}
          src={ bgOnly ? '' : video[Orientations.WEB]}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          poster={placeholderSrc}
        ></video>
      )}
      {orientation === Orientations.PORTRAIT && (
        <video
          playsInline={playsInline}
          className="h-full w-full object-cover object-top"
          controls={controls}
          src={ bgOnly ? '' : video[Orientations.PORTRAIT]}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          poster={placeholderSrc}
        ></video>
      )}
      {orientation === Orientations.LANDSCAPE && (
        <video
          className="h-full w-full object-cover object-top"
          controls={controls}
          playsInline={playsInline}
          src={ bgOnly ? '' : video[Orientations.LANDSCAPE]}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          poster={placeholderSrc}
        ></video>
      )}
    </>
  )
}
