/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'

import { CurrencySection, SubscriptionSection } from '../sections'
import './styles.scss'

import i1 from '../../../assets/img/currency/Laidimg.png'
import laidAva from '../../../assets/img/currency/laidAva.jpg'
import i2 from '../../../assets/img/mfc/2.jpg'
import i3 from '../../../assets/img/mfc/3.png'
import i4 from '../../../assets/img/mfc/4.png'
import i5 from '../../../assets/img/mfc/5.png'
import i6 from '../../../assets/img/mfc/6.png'
import avka from '../../../assets/svg/ava.svg'

// @ts-ignore
import videoWeb from '../../../assets/videos/Relagram_video_landingpage_Web.mp4'
import videoPortrait from '../../../assets/videos/Relagram_video_landingpage_Portrait.mp4'
import videoLandscape from '../../../assets/videos/Relagram_video_landingpage_Landscape.mp4'
import { routes } from '../../../constants/routes'
import ExampleItem from '../../../components/ExampleItem'
import { Button } from '../../../components/uiKit'

const items = [
  {
    link: '',
    avatar: avka,
    image: i3,
    video: {
      portrait: videoPortrait,
      landscape: videoLandscape,
      web: videoWeb
    },
    title: 'Internal MetaFek coins (MFC) currency',
    readyToShow: true,
  },
  {
    link: `https://google.com`,
    avatar: avka,
    image: i2,
    title: 'Internal MetaFek coins (MFC) currency',
  },
  {
    link: `https://google.com`,
    avatar: avka,
    image: i3,
    title: 'Internal MetaFek coins (MFC) currency',
  },
  {
    link: `https://google.com`,
    avatar: avka,
    image: i4,
    title: 'Internal MetaFek coins (MFC) currency',
  },
  {
    link: `https://google.com`,
    avatar: avka,
    image: i5,
    title: 'Internal MetaFek coins (MFC) currency',
  },
  {
    link: `https://google.com`,
    avatar: avka,
    image: i6,
    title: 'Internal MetaFek coins (MFC) currency',
  },
]

export const CurrencyPage = () => {
  return (
    <>
      <div className="subscription-section--goToExamplesBtnBlock">
        <Button
          onClick={() => location.replace('#videoExamples')}
          type="button"
          className="subscription-section--goToExamplesBtn"
        >
          Scroll down to watch other bloggers videos
        </Button>
      </div>
      <CurrencySection withoutSpaceBlock />
      <div id="videoExamples" className="subscription-section--videos">
        <div className="container">
          <div className="subscription-section--header">
            <h2>Watch example videos</h2>
            <a href="/">View All</a>
          </div>

          <ul className="subscription-section--list">
            {items.map((item, i) => (
              <ExampleItem
                key={i}
                readyToShow={item.readyToShow}
                title={item.title}
                video={item.video}
                avatar={item.avatar}
                link={item.link}
                image={item.image}
              />
            ))}
          </ul>
        </div>
      </div>
      {/* <SubscriptionSection withoutDevices /> */}
    </>
  )
}
