import { FC, useEffect } from 'react'

import './styles.scss'
import { useParams } from 'react-router-dom'
import bg from '../../assets/img/blog/bg.jpg'

const items = [
  {
    image: bg,
    title: 'Relagram re-launches Swipe Night in the US on November 12',
    date: '04 november 2020',
    text: `<p>Los Angeles, September 4, 2020 - On September 12th at 10 a.m., Tinder® will make its Swipe Night event available again in the United States to coincide with the event’s global launch. Swipe Night is a first-person, interactive event where Tinder members can swipe at key points in the experience to move the story forward and see where it takes them. Their choices dictate more than just the story; they also impact who they match with and what they might chat about once the epic journey ends.</p> 
    <p>Swipe Night follows a group of friends, with Tinder members playing the protagonist, during the final hours before an asteroid hits Earth. As the story unfolds, members will be challenged with moral dilemmas and practical choices that impact what happens next and who they match with once the event ends. But they’ll have to act fast: there are only seven seconds to make each decision. Each week, key choices are added to a member’s Tinder profile so there’s plenty of material for post-apocalyptic banter and icebreakers that don’t involve face masks.</p>`,
  },
]

const BlogItem: FC = () => {
  const { id } = useParams<{ id?: string }>()
  if (!id) return <div>No id in params</div>
  if (!items[+id]) return <div>Blog item is undefined</div>

  const { date, title, image, text } = items[+id]

  return (
    <div className="blogItem">
      <div className="container">
        <img className="blogItem--bg" src={image} alt="bg" />

        <div className="blogItem--textBlock">
          <h2 className="blogItem--title">{title}</h2>

          <div className="blogItem--date">{date}</div>

          <article
            ref={(el) => {
              if (!el) return

              el.innerHTML = text
            }}
            className="blogItem--text"
          />
        </div>
      </div>
    </div>
  )
}

export default BlogItem
