import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
import closeIcon from '../../assets/svg/icon_close.svg'

import './styles.scss'
import { FC, useEffect, useRef, useState } from 'react'
import { VideoTypes } from '../../components/ExampleItem'
import { Video } from '../Video'

interface VideoPresentationProps {
  video: VideoTypes
  onClose?: () => void
}

export enum Orientations {
  WEB = 'web',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

const MAX_WEB_WINDOW_WIDTH = 1200

function getOrientation(): Orientations {
  if (window.innerWidth > MAX_WEB_WINDOW_WIDTH) {
    return Orientations.WEB
  }

  if (window.matchMedia('(orientation: landscape)').matches) {
    return Orientations.LANDSCAPE
  }

  return Orientations.PORTRAIT
}

export const VideoPresentation: FC<VideoPresentationProps> = ({
  video,
  onClose,
}) => {
  return (
    <div
      className="video-presentation"
      onClick={(e) => {
        e.stopPropagation()

        if ((e.target as Element).localName === 'div') {
          onClose?.()
        }
      }}
    >
      <div className="video-presentation--video-popup">
        {onClose && (
          <span onClick={onClose}>
            <img src={closeIcon} alt="" />
          </span>
        )}

        <Video video={video} controls autoPlay />
      </div>
    </div>
  )
}
