/* eslint-disable max-lines */
import './styles.scss'

export const AboutUs = () => {
  return (
    <div className="container">
      <div className="container">
        <div className="documents">
          <p>
            <strong>About Us</strong>
          </p>
          <h2>What is Relagram?</h2>
          <p>
            Is it a messenger? Is it a marketplace? Is it a digital dating space? Is
            it a job portal?&nbsp;
          </p>
          <p>It&rsquo;s all these and even more!</p>
          <p>
            Relagram is a revolutionary new platform that combines a
            messenger&apos;s convenience with a social network&apos;s connectivity.
            The app is designed to connect people and facilitate communication for
            personal or professional purposes.
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Connect With Friends</h2>
          <p>
            Relagram you like to keep in touch with the world around you. To make
            this possible, one of the app&apos;s major features is the instant
            messenger. This feature allows you to skip the FOMO and always be in
            touch with friends, family, and colleagues, no matter where you are.
          </p>
          <p>
            &nbsp;Get real-time updates, share photos, and swap stories with one
            easy-to-use app.
          </p>
          <p>
            For those who can&rsquo;t live without their fur babies, the app also
            includes a feature called Pets, which allows you to create and
            maintain a profile for your pet. This is a great way to connect with
            other pet owners and share experiences and tips that will make you an
            even better pet parent.
          </p>
          <p>
            Want to add more people to a conversation? The app&rsquo;s group chat
            feature makes it easy to find your tribe and connect with them. It is
            perfect for organizing events, team projects, or your next weekend
            party.
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Find Your Forever After</h2>
          <p>
            Want to turn an online connection into your very own IRL love story?
            Relagram could be the setting for your own digital romance. The
            platform&rsquo;s dating feature makes it easy to meet new people, with
            advanced search filters that help you zero in on potential matches based
            on your location and preferences.
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Discover a New World</h2>
          <p>
            Places is a feature that allows travelers to share recommendations
            for unusual and offbeat places they&rsquo;ve visited. This is an
            excellent resource for discovering new and exciting destinations.
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Build Your Audience</h2>
          <p>
            Do you have some significant ideas to tell the world? Find followers and
            start your text-based thematic channel on the Map app. Go on, share
            your thoughts and ideas with and build a community based on your
            interests on the app.
          </p>
          <p>
            Relagram&rsquo;s events tab lets you create and promote events, such
            as parties, concerts, and other gatherings. Why worry about finding the
            right promoter for your event, ever.? Relagram is all you need.&nbsp;
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Publicize Your Online Presence</h2>
          <p>
            Relagram values the contributions of bloggers and influencers and
            allows you to link to your existing channels on other social networks.
            This is our way of thanking you for helping us advertise and promote our
            product.
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Buy, Sell &amp; Promote Your Brand</h2>
          <p>
            To empower business owners, the app also offers a host of features that
            can help them leverage the power of social commerce. For starters, users
            who own a business there is a possibility to create and promote a
            business profile on the platform.&nbsp;
          </p>
          <p>
            Whether you&rsquo;re looking for potential customers or leads to promote
            your product or service, they&rsquo;re all on the Map app. In
            addition, there&rsquo;s also the Market, an in-app marketplace where
            you can buy and sell goods. Whether it&apos;s something you no longer
            need or if you want to sell the product you have innovated, it is easy
            to find an audience of receptive buyers on the Map app.&nbsp;
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>Find Your Dream Job or the Perfect Employee</h2>
          <p>
            Are you looking for your dream job? Or are you scouting for the right
            talent to fill an existing position?&nbsp;
          </p>
          <p>
            For job seekers and employers - there&rsquo;s Jobs, a job portal
            that&rsquo;s part of the Map ecosystem. Finding and filling
            opportunities near you has never been easier!
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <h2>What makes the Map app unique?</h2>
          <h3>You Won&rsquo;t Spend a Dime</h3>
          <p>
            One of the unique features of the app is the use of its internal
            currency, MetaFek Coin. This currency can be earned within the app by
            undertaking micro-actions, earning referral coins, or via product
            advertising awards within the ecosystem. MetaFek can be used to purchase
            advanced features and subscription plans<s>&nbsp;</s>without spending
            any real money.
          </p>
          <h3>Real-Time Navigation</h3>
          <p>
            &nbsp;A geolocation map also helps you navigate events, businesses,
            goods, people, and pets in your area in real-time. You can also display
            your exact or approximate location on the map or just turn it off.
          </p>
          <h3>Compatible Across Devices</h3>
          <p>
            Phone or tablet, iOS or Android, the app is compatible across devices
            and operating systems. Whether you upgrade or crossover, the app, and
            all your data, are always there waiting for you.
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <p>
            Overall, Relagram is a comprehensive platform that offers a wide range
            of features for communication, connection, and commerce.&nbsp;
          </p>
          <p>
            Break through the clutter of using multiple apps for specific purposes
            and get this all-in-one app with the best of everything the digital
            world offers. Connections or romance, DMs or group chats, Android or
            iOS, business or leisure, work or play, whatever you seek, there&rsquo;s
            one app for it all.&nbsp;
          </p>
          <p>
            <br />
            &nbsp;
          </p>
        </div>
        <div className="documents">
          <p>
            <strong>关于我们</strong>
          </p>
          <h2>Relagram是什么？</h2>
          <p>它是一个即时通讯工具吗？是一个市场吗？是一个数字约会空间吗？是一个职业门户吗？</p>
          <p>它不仅仅是这些！</p>
          <p>Relagram是一个革命性的新平台，将即时通讯工具的便利性与社交网络的连接性相结合。该应用程序旨在连接人们，促进个人或专业目的的沟通。</p>
          <p>&nbsp;</p>
          <h2>与朋友建立联系</h2>
          <p>Relagram让您保持与周围世界的联系。为了实现这一目标，该应用程序的主要功能之一是即时通讯工具。该功能使您可以跳过错过的机会，无论身在何处，都能与朋友、家人和同事保持联系。</p>
          <p>获得实时更新，分享照片，并通过一个易于使用的应用程序交换故事。</p>
          <p>对于那些无法离开宠物的人，该应用程序还包括一个名为 Pets的功能，允许您为您的宠物创建和维护个人资料。这是与其他宠物主人联系并分享经验和技巧的绝佳方式，使您成为一个更好的宠物父母。</p>
          <p>想要在对话中添加更多人吗？该应用程序的群聊功能使您可以轻松找到您的群体并与他们联系。它非常适用于组织活动、团队项目或下一个周末聚会。</p>
          <p>&nbsp;</p>
          <h2>找到您的永久之后</h2>
          <p>想要将在线联系转化为您自己的现实生活爱情故事吗？ Relagram可能是您自己数字浪漫的背景。该平台的约会功能使您能够轻松地认识新朋友，通过高级搜索过滤器根据您的位置和偏好找到潜在匹配项。</p>
          <p>&nbsp;</p>
          <h2>发现新世界</h2>
          <p>Places是一个功能，允许旅行者分享他们去过的不寻常和新奇的地方的推荐。这是发现新奇和令人兴奋目的地的极好资源。</p>
          <p>&nbsp;</p>
          <h2>建立您的听众</h2>
          <p>您是否有一些重要的想法要告诉世界？在Map应用程序上找到追随者并启动您的基于文本的主题频道。继续，与您的兴趣建立社区并分享您的思想和想法。</p>
          <p>Relagram的活动选项卡让您创建和促进活动，例如聚会、音乐会和其他聚会。为什么要担心为您的活动找到合适的推广者呢？Relagram是您需要的一切。</p>
          <p>&nbsp;</p>
          <h2>宣传您的在线存在</h2>
          <p>Relagram重视博客作者和影响者的贡献，并允许您链接到其他社交网络上的现有频道。这是我们感谢您帮助我们广告和推广我们的产品的方式。</p>
          <p>&nbsp;</p>
          <h2>购买、销售和推广您的品牌</h2>
          <p>为了赋予业务所有者力量，该应用程序还提供了一系列功能，可以帮助他们利用社交商务的力量。首先，拥有业务的用户有可能在平台上创建和推广业务资料。</p>
          <p>无论您是寻找潜在客户还是引导推广您的产品或服务，他们都在Map应用程序上。此外，还有Market，一个应用内市场，您可以在其中购买和销售商品。无论是您不再需要的东西，还是您想销售的产品，您都可以在Map应用程序上找到一个受欢迎买家的观众。</p>
          <p>&nbsp;</p>
          <h2>找到您梦寐以求的工作或完美的员工</h2>
          <p>您正在寻找梦寐以求的工作吗？还是在寻找合适的人才填补现有职位？</p>
          <p>对于求职者和雇主来说 - 还有Jobs，作为Map生态系统的一部分的职业门户。在您附近找到机会并填补职位从未如此容易！</p>
          <p>&nbsp;</p>
          <h2>什么使Map应用程序独特？</h2>
          <h3>您不会花一分钱</h3>
          <p>该应用程序的独特功能之一是其内部货币MetaFek Coin的使用。该货币可以在应用程序内部通过进行微操作、获得推荐货币或通过生态系统内的产品广告奖励而赚取。MetaFek可用于购买高级功能和订阅计划，而无需花费任何真钱。</p>
          <h3>实时导航</h3>
          <p>地理位置地图还可以帮助您实时导航您所在地区的活动、企业、商品、人员和宠物。您还可以在地图上显示您的确切或近似位置，或者只是关闭它。</p>
          <h3>跨设备兼容</h3>
          <p>无论是手机还是平板电脑，iOS还是Android，该应用程序都与设备和操作系统兼容。无论您升级还是转换，该应用程序和所有数据始终在那里等待着您。</p>
          <p>&nbsp;</p>
          <p>总的来说，Relagram是一个全面的平台，为通信、连接和商务提供了广泛的功能。</p>
          <p>摆脱使用多个应用程序进行特定目的的困扰，使用这个一体化应用程序，获得数字世界提供的最好一切。连接或浪漫，DMs或群聊，Android或iOS，商业或休闲，工作或玩耍，无论您寻找什么，都有一个应用程序可供选择。</p>
          <p>您还在等什么？是时候开始使用Map了！</p>
          <p>&nbsp;</p>
        </div>
      </div>


    </div>
  )
}
