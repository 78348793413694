import { routes } from './routes'

export const navigationItems = [
  { text: 'Product', route: routes.PRODUCT },
  { text: 'Features', route: routes.FUNCTIONS },
  { text: 'MetaFek', route: routes.CURRENCY },
  // { text: 'Blog', route: routes.BLOG },
  // { text: 'Donate', route: routes.DONATE },
  // { text: 'Secure', route: routes.SECURE },
]
