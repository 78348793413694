import { FC, useEffect, useState } from 'react'

interface CheckboxProps {
  content: JSX.Element | string
  name: string
  checked?: boolean
  className?: string
  onCheck?: (value: unknown) => void
  color?: string
}

const Checkbox: FC<CheckboxProps> = ({
  content,
  name,
  className,
  onCheck,
  checked = false,
  color = '#9B9B9B',
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const htmlFor = Math.random().toString()

  const handleChange = () => {
    setIsChecked(!isChecked)
    onCheck && onCheck({name, checked: !isChecked})
  }

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <div className={`ui-checkbox ${className}`}>
      <label className="ui-checkbox--label shrink-0 cursor-pointer" htmlFor={htmlFor}>
        <div className="ui-checkbox--box">
          {isChecked && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill={color}
                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
              />
            </svg>
          )}
        </div>
        {content}
        <input
          onClick={handleChange}
          onChange={() => {}}
          name={name}
          type="checkbox"
          id={htmlFor}
          hidden
          checked={isChecked}
        />
      </label>
    </div>
  )
}

export default Checkbox
