import './styles.scss'

export const AdvertisingGuidelines = () => {
  return (
    <div className="documents">
      <div className="container">
        <div className="mt-4">
          <h1>Advertising Guidelines for US and EU</h1>
          <h1>General Information</h1>
          <p>
            Relagram is meetings, dating, talking to like-minded people, organizing
            events, advertising your business Mobile application. The application is
            designed to help single people find a partner, friends, like-minded
            people, whom you can have a pleasant time with not only online, but in
            real life as well, creating different kind of events as well directly from
            the mobile phone. We aim to foster a welcoming community and our
            Advertising Guidelines aim to ensure we can achieve those goals.
          </p>
          <p>
            Advertisers must agree with Relagram Terms of Service and Privacy Policy,
            as well as these Advertising Guidelines. These terms, guidelines, and
            policies may be updated from time to time, so please review them
            regularly.
          </p>
          <p>
            Advertisers are responsible for ensuring that the advertisements they
            place on Relagram are suitable for users aged 18 and over.
          </p>
          <h1>Ad Review Process</h1>
          <p>
            Relagram ad review team uses these Advertising Guidelines to evaluate
            each ad campaign presented for review. Relagram reserves the right to
            reject ad campaigns from running or remove any ads at Relagram sole
            discretion, including, without limitation, if they violate the Terms of
            Service, the Privacy Policy, these Advertising Guidelines or any other
            Relagram policies. Relagram also reserves the right to remove a business
            account if it violates any of the above. You will not be refunded.
          </p>
          <h1>Compliance with local requirements</h1>
          <p>
            Advertisers are responsible for ensuring that their advertisements comply
            with all applicable laws, local language requirements, rules and
            regulations, and self-regulatory guidelines, rules, and codes.
          </p>
          <h1>Disclosures</h1>
          <p>
            All advertisements must include any mandatory disclosures, and all
            disclosures must be clear and prominent. Any advertisements without
            mandatory disclosures will be suspended.
          </p>
          <h1>Prohibited Content</h1>
          <p>
            The following list includes products and services that cannot be
            advertised on Relagram:
          </p>
          <p>
            Drugs - Ads that depict or encourage the use of illegal drugs, drug
            paraphernalia, and recreational drugs.
          </p>
          <p>
            Alcohol - Ads promoting alcoholic beverages, (wine, beer, spirits etc)
            alcohol clubs/subscription services and alcohol making kits are not
            permitted.
          </p>
          <p>
            Cigarettes and tobacco products - Ads promoting cigarettes (including
            e-cigarettes and vaping products), cigars, tobacco, or any related
            products.
          </p>
          <p>
            Sexual content and products- Ads that depict nudity (including blurred or
            pixilated nudity) or sexual content, including sexually suggestive acts or
            other sexually provocative content and language. Adult entertainment and
            paraphernalia including pornography, sex toys, lubricants, fetish costumes
            are also not permitted.
          </p>
          <p>
            Illegal Products, Services or Activities - Ads that promote illegal
            products/services or promote illegal or inappropriate activities.
          </p>
          <p>
            Gambling and Lotteries - Ads that promote online gambling, lotteries,
            casinos, sports bookings, fantasy sports, bingo, poker, or simulated
            gambling.
          </p>
          <p>
            Infringing Content - Ads that include content that infringes or violates
            third party rights, including copyright, trademark, privacy, publicity, or
            other personal or proprietary rights. Services that enable infringement
            such as software to circumvent usage restrictions are also prohibited. All
            aspects of the ad including talent, music, and logos must either be owned
            by the advertiser or properly licensed. We do not allow user generated
            content in ads unless the advertiser has explicit permissions to do so.
          </p>
          <p>
            Shocking, Graphic or Sensational Content - Ads that contain real or
            dramatized graphic violence, gore, or gruesome imagery; ads that intend to
            shock or evoke distress; ads that contain profanity, shocking,
            sensational, vulgar, or lewd references.
          </p>
          <p>
            Hate Speech or Violence - Ads that promote or encourage violence or hate
            speech.
          </p>
          <p>
            Discrimination - Ads that discriminate or encourage discrimination against
            people based on personal attributes such as race, ethnicity, national
            origin, religion, age, sex, sexual orientation, gender identity, family
            status, disability, medical or genetic condition.
          </p>
          <p>
            Weapons - Ads that promote or encourage the use of dangerous weapons,
            including guns, explosives, knives, swords and other objects that are
            designed to cause harm to individuals.
          </p>
          <p>Counterfeit Goods - Ads that promote the sale of counterfeit goods.</p>
          <p>
            Spyware and Malware - Ads must not contain, or link to sites containing
            any spyware, malware, or other harmful codes or sites.
          </p>
          <p>
            Political ads - Ads that promote or oppose a candidate for public office,
            current or former political leader, political party or political group, or
            contain content regarding a local, state, or federal issue of public
            importance.
          </p>
          <p>
            Children&apos;s Products or Services - Ads for products or services that
            are specifically intended for or appeal to children including toys, games,
            apps, clothing, etc. Advertisements marketed specifically toward children,
            even if the product may be for a general audience, are also prohibited.
          </p>
          <h1>Restricted Content</h1>
          <p>
            The following list includes content that Relagram may allow at its
            discretion if the content complies with all applicable laws, rules and
            regulations:
          </p>
          <p>
            Financial Services - Ads related to the exchange, management or investment
            of funds (fiat or virtual) must comply with applicable laws, regulations,
            licensing obligations, and industry requirements. For all ads within this
            category, we may ask for disclosures and evidence of appropriate regulator
            and/or third-party accreditations or certifications.
          </p>
          <p>
            The following types of financial services/products are not allowed: single
            securities or other tradable financial assets, bail bonds, debt assistance
            programs, get rich quick schemes, pyramid schemes (including non-financial
            pyramid schemes) and multi-level marketing, penny auctions, binary
            options, cryptocurrency wallets, unaccredited digital banks that perform
            any traditional bank-like function, cryptocurrency credit or debit cards,
            initial coin offerings, token sales, or other means of promotion or
            advertisement of individual digital currencies or tokens.
          </p>
          <p>Ads may only target 18+ and must include proper disclaimers.</p>
          <p>
            Foods and drinks - Ads promoting foods or drinks high in fat, salt or
            sugar as identified using nutrient profiling, may be subject to
            restrictions.
          </p>
          <p>
            Pharmaceuticals, Healthcare, Medicines - Ads for pharmaceuticals,
            healthcare, and medicines must target 18+ users and must comply with local
            laws of the target country. All pharmaceuticals including prescription and
            over-the-counter drugs must be approved by the regional regulating
            authority and may not contain misleading or inaccurate claims. Please note
            that ads for prescription drugs in the EU are prohibited.
          </p>
          <p>
            We do allow advertisements for brick and mortar pharmacies and online
            pharmacies that are certified by a third-party licensing organization such
            as the NABP or LegitScript or regional equivalent. Proof of licensing may
            be necessary for approval.
          </p>
          <p>
            Dating Applications or Services - Ads that promote dating applications or
            services must target 18+. Such ads must not be overtly sexual or reference
            transactional companionship or cheating, etc. Dating ads should not be
            sexually gratifying in nature (e.g., focusing on bodies, sexual language
            or innuendo, etc.)
          </p>
          <p>
            Media and Entertainment - Ads that promote films, TV-shows, or games must
            not contain profanity and crude language; sexually gratifying language or
            images; graphic violence or shocking content or drug use.
          </p>
          <h1>Prohibited Practices</h1>
          <p>
            Misleading Claims - Ads must not promise or exaggerate results or make
            claims that cannot be substantiated. Superlatives such as &quot;#1,&quot;
            &quot;Best Selling,&quot; &quot;Most Popular&quot; cannot be used unless
            the claim can be verified by a trusted third&nbsp;party. Advertisers may
            need to provide documentation to support the claim or link to the
            verifying source on the landing page.
          </p>
          <p>
            Misleading functionality- Ads may not include elements that mislead or
            trick the user into interacting with the ad. This includes non-functioning
            buttons as well as calls to action that do not allow the user to take said
            action such as &quot;swipe up.&quot;
          </p>
          <p>
            Mute or still ads - Ads must contain high quality audio and video. Ads
            without sound or ads that contain static images will be disapproved.
          </p>
          <p>
            Data collection - If you have a questionnaire or request for user data, it
            should appear only on out-of-app links. Links to such landing pages are
            allowed but it should be made clear to the user that they are leaving the
            in-app experience. Your privacy policy should be accessible to ay
            individual whose data you are processing. Any data collected, received, or
            derived from a Relagram ad may only be shared with the advertiser&apos;s
            service providers. You are responsible for ensuring that any personal
            information you collect is collected and processed securely and in
            accordance with local laws.
          </p>
          <h1>Use of Relagram Brand Assets</h1>
          <p>
            Ads should not imply a Relagram endorsement, partnership, or association
            of any kind, or the same for any other Relagram affiliated company.
          </p>
          <h1>Promotions</h1>
          <p>
            Any promotions, sweepstakes, or contests (collectively,
            &quot;Promotions&quot;) you run on Relagram must comply with all legal
            requirements for the territories in which you plan to run the Promotions.
            This includes, posting official rules, disclaimers, and other terms, and
            complying with marketing regulations.
          </p>
          <p>
            Unless we agree otherwise, your Promotion must not imply a Relagram
            endorsement, partnership, or association of any kind, or the same for any
            other Relagram affiliated company.
          </p>
          <h1>Relevance and Appropriateness</h1>
          <p>
            All elements of ads, including any text, images, or other media, must be
            relevant and appropriate to the product or service being offered and the
            audience viewing the ad.
          </p>
          <p>Ads should not be composed of text only.</p>
          <h1>Other Ad Specifications for Ad Review</h1>
          <p>
            We may provide you with a rate card that outlines certain additional ad
            specifications, such as:
          </p>
          <p>Limitation on text in certain ad formats</p>
          <p>Specific disclosure requirements</p>
          <p>Limitations on use of external links</p>
          <p>Specific limitations and requirements of video length</p>
          <p>
            We reserve the right to reject, approve, or remove any ad for any reason,
            in our sole discretion, including ads that negatively affect our
            relationship with our users or that promote content, services, or
            activities, contrary to our competitive position, interests, or
            advertising philosophy.
          </p>
          <h1>These guidelines are subject to change at any time without notice.</h1>
        </div>
        <div className="mt-4">
          <h1>美国和欧盟的广告指南</h1>
          <h1>一般信息</h1>
          <p>
            Relagram是一个移动应用程序，用于会议、约会、与志同道合的人交谈、组织活动和广告您的业务。该应用程序旨在帮助单身人士找到伴侣、朋友、志同道合的人，您可以与其在线和现实生活中愉快相处，并直接从手机上创建不同类型的活动。我们旨在培养一个友好的社区，我们的广告指南旨在确保我们能够实现这些目标。
          </p>
          <p>
            广告商必须同意Relagram的服务条款和隐私政策，以及这些广告指南。这些条款、指南和政策可能会不时更新，因此请定期查看。
          </p>
          <p>
            广告商有责任确保他们在Relagram上投放的广告适合18岁及以上的用户。
          </p>
          <h1>广告审核流程</h1>
          <p>
            Relagram广告审核团队使用这些广告指南来评估每个提交审核的广告活动。Relagram保留拒绝运行广告活动或自行删除任何广告的权利，包括但不限于，如果它们违反了服务条款、隐私政策、这些广告指南或任何其他Relagram政策。Relagram还保留删除违反以上任何条款的企业账户的权利。您将不会获得退款。
          </p>
          <h1>遵守当地要求</h1>
          <p>
            广告商有责任确保他们的广告符合所有适用的法律、当地语言要求、规则和法规，以及自律指南、规则和代码。
          </p>
          <h1>披露</h1>
          <p>
            所有广告必须包含任何强制性披露，并且所有披露必须清晰显著。未包含强制性披露的广告将被暂停。
          </p>
          <h1>禁止内容</h1>
          <p>以下是不得在Relagram上进行广告宣传的产品和服务：</p>
          <p>毒品 - 描绘或鼓励使用非法药物、药物器具和娱乐药物的广告。</p>
          <p>酒精 - 不允许宣传酒精饮料（葡萄酒、啤酒、烈酒等）、酒精俱乐部/订阅服务和酿酒工具。</p>
          <p>香烟和烟草制品 - 不允许宣传香烟（包括电子烟和吸烟产品）、雪茄、烟草或任何相关产品。</p>
          <p>性内容和产品 - 描绘裸露（包括模糊或像素化的裸露）或性内容的广告，包括性暗示行为或其他性挑逗内容和语言。成人娱乐和相关器具包括色情、性玩具、润滑剂、恶俗装束也不允许。</p>
          <p>非法产品、服务或活动 - 宣传非法产品/服务或宣传非法或不适当的活动的广告。</p>
          <p>赌博和彩票 - 宣传在线赌博、彩票、赌场、体育博彩、幻想体育、宾果、扑克或模拟赌博的广告。</p>
          <p>侵权内容 - 包含侵犯或违反第三方权利（包括版权、商标、隐私、公开权或其他个人或专有权利）的内容的广告。促进侵权的服务，如绕过使用限制的软件也被禁止。广告的所有方面，包括人才、音乐和标志，都必须由广告商拥有或适当授权。我们不允许广告中的用户生成的内容，除非广告商有明确的权限。</p>
          <p>令人震惊、图形或耸人听闻的内容 - 包含真实或戏剧化的图形暴力、血腥或令人不快的形象的广告；旨在震惊或引起痛苦的广告；包含粗言秽语、震惊、耸人听闻、粗俗或淫秽引用的广告。</p>
          <p>仇恨言论或暴力 - 宣传或鼓励暴力或仇恨言论的广告。</p>
          <p>歧视 - 宣传或鼓励基于个人属性（如种族、民族、国籍、宗教、年龄、性别、性取向、性别认同、家庭状况、残疾、医疗或遗传条件）歧视的广告。</p>
          <p>武器 - 宣传或鼓励使用危险武器的广告，包括枪支、爆炸物、刀具、剑等用于伤害个人的物品。</p>
          <p>假冒商品 - 宣传销售假冒商品的广告。</p>
          <p>间谍软件和恶意软件 - 广告不得包含或链接到包含任何间谍软件、恶意软件或其他有害代码或网站。</p>
          <p>政治广告 - 宣传或反对公职候选人、现任或前任政治领导人、政党或政治团体的广告，或包含关于当地、州或联邦重要公共问题的内容。</p>
          <p>儿童产品或服务 - 旨在或吸引儿童的产品或服务的广告，包括玩具、游戏、应用、服装等。即使产品可能面向广泛的观众，专门面向儿童营销的广告也是被禁止的。</p>
          <h1>受限制的内容</h1>
          <p>
            以下是Relagram可能酌情允许的内容列表，如果内容符合所有适用的法律、规则和法规：
          </p>
          <p>金融服务 - 与资金（法定货币或虚拟货币）的交换、管理或投资相关的广告必须遵守适用的法律、法规、许可义务和行业要求。对于此类广告，我们可能会要求披露和提供适当的监管机构和/或第三方认证或认证的证明。</p>
          <p>不允许以下类型的金融服务/产品：单一证券或其他可交易金融资产、保释金债券、债务援助计划、快速致富计划、金字塔计划（包括非金融金字塔计划）和多层次营销、一分钱拍卖、二元期权、加密货币钱包、未经认证的执行任何传统银行功能的数字银行、加密货币信用卡或借记卡、首次代币发行、代币销售或其他促销或宣传个人数字货币或代币的方式。</p>
          <p>广告只能针对18岁及以上的用户，并必须包含适当的免责声明。</p>
          <p>
            食品和饮料 - 宣传高脂肪、高盐或高糖食品或饮料的广告，根据营养素分析可能会受到限制。
          </p>
          <p>
            药品、医疗保健、药品 - 药品、医疗保健和药品的广告必须针对18岁及以上的用户，并且必须符合目标国家/地区的当地法律。所有药品，包括处方药和非处方药，必须获得地区监管机构的批准，不得含有误导性或不准确的声明。请注意，欧盟的处方药广告是被禁止的。
          </p>
          <p>
            我们允许广告宣传实体药店和通过第三方许可机构（如NABP或LegitScript或区域等同机构）认证的在线药店。可能需要证明许可才能获得批准。
          </p>
          <p>
            约会应用或服务 - 宣传约会应用或服务的广告必须针对18岁及以上的用户。此类广告不得过于性化，也不得提及交易伴侣或作弊等行为。约会广告不应该以性满足为特征（例如，聚焦于身体、性语言或暗示等）。
          </p>
          <p>
            媒体和娱乐 - 宣传电影、电视节目或游戏的广告不得包含粗俗语言和粗鄙语言；不得包含性满足的语言或图像；不得包含真实暴力或震惊内容或药物使用。
          </p>
          <h1>禁止做法</h1>
          <p>
            误导性声明 - 广告不得承诺或夸大结果，或提出不能证实的声明。超级拉弓词如“＃1”、“畅销”、“最受欢迎”等词语，除非可以由可信赖的第三方证实，否则不能使用。广告商可能需要提供支持声明的文件或在落地页上链接到验证来源。
          </p>
          <p>
            误导功能- 广告不得包含误导或欺骗用户与广告进行交互的元素。这包括非功能性按钮以及不允许用户执行的行动，例如“向上滑动”等。
          </p>
          <p>
            无声或静态广告 - 广告必须包含高质量的音频和视频。没有声音的广告或包含静态图像的广告将被驳回。
          </p>
          <p>
            数据收集 - 如果您有问卷调查或对用户数据的请求，它应该仅出现在应用外链接中。允许链接到此类落地页，但应向用户明确表示他们正在离开应用体验。您的隐私政策应对正在处理其数据的任何个人都可访问。从Relagram广告收集、接收或衍生的任何数据只能与广告商的服务提供商共享。您有责任确保您收集的任何个人信息是安全地按照当地法律收集和处理的。
          </p>
          <h1>使用Relagram品牌资产</h1>
          <p>
            广告不得暗示Relagram对其任何形式的认可、合作伙伴关系或关联，或Relagram附属公司的认可、合作伙伴关系或关联。
          </p>
          <h1>促销活动</h1>
          <p>
            您在Relagram上进行的任何促销、抽奖或竞赛（统称为“促销活动”）必须符合您计划进行促销活动的领土的所有法律要求。这包括张贴官方规则、免责声明和其他条款，并遵守市场营销法规定。
          </p>
          <p>
            除非另有约定，您的促销活动不得暗示Relagram对其任何形式的认可、合作伙伴关系或关联，或Relagram附属公司的认可、合作伙伴关系或关联。
          </p>
          <h1>相关性和适切性</h1>
          <p>
            所有广告元素，包括任何文本、图像或其他媒体，都必须与所提供的产品或服务以及观看广告的受众相关且适当。
          </p>
          <p>广告不应仅由文本组成。</p>
          <h1>广告审核的其他广告规格</h1>
          <p>
            我们可能会向您提供一份费率卡，其中概述了某些额外的广告规格，例如：
          </p>
          <p>某些广告格式中文本的限制</p>
          <p>特定披露要求</p>
          <p>外部链接使用的限制</p>
          <p>视频长度的具体限制和要求</p>
          <p>
            我们保留出于我们的唯一判断拒绝、批准或删除任何广告的权利，包括对负面影响我们与用户的关系或促进与我们的竞争地位、利益或广告理念相抵触的内容、服务或活动进行广告。
          </p>
          <h1>这些指南随时可能会更改，恕不另行通知。</h1>
        </div>

      </div>
    </div>
  )
}
