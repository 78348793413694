import { FC } from 'react'

import { StickerFile } from '../../pages/AddAnimatedStickers'
import closeIcon from '../../assets/svg/icon_close_cross.svg'

import './styles.scss'

interface PreparedStickerProps extends Omit<StickerFile, 'file'> {
  handleRemoveSticker: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
}

export const PreparedSticker: FC<PreparedStickerProps> = ({
  emoji,
  handleRemoveSticker,
  id,
  sticker,
  isAnimated,
}) => {
  console.log('🚀 ~ isAnimated:', isAnimated)
  return (
    <div
      key={id}
      className="prepared-sticker adding-stickers-form__add-btn adding-stickers-form--sticker"
    >
      <div className="adding-stickers-form--emoji">{emoji.emoji}</div>
      {isAnimated ? (
        <tgs-player
          style={{ height: '100px', width: '100px' }}
          autoplay
          loop
          mode="normal"
          src={sticker}
        ></tgs-player>
      ) : (
        <img src={sticker} alt="" />
      )}
      <button
        type="button"
        data-sticker-id={id}
        onClick={handleRemoveSticker}
        className="adding-stickers-form--sticker-cape"
      >
        <img src={closeIcon} alt="" />
      </button>
    </div>
  )
}
