import React from 'react'

export const Ru = () => (
  <>
    <p>
      <span>
        <strong>
          Информация, отображаемая на странице профиля пользователя:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>На странице пользователя видно только количество </span>Followers
      <span> и </span>Following<span>. </span>
    </p>
    <p>
      <span>
        Согласно нашей политике конфиденциальности, вы не сможете перейти в
        профили{' '}
      </span>
      Followers<span> и </span>Following
      <span>
        {' '}
        пользователя, к которому зашли на страницу. Эти параметры указаны для
        того, чтобы можно было понять насколько активен пользователь в
        приложении.
      </span>
    </p>
    <p>
      <span>
        Чтобы в следующий раз быстро найти этого пользователя, нужно его
        отметить, нажав{' '}
      </span>
      <strong>Follow</strong>
      <span>, после чего он будет отображаться у вас в </span>
      <strong>Following</strong>
      <span> в рабочем кабинете. </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>Пользователю с </span>
      <span>
        <em>
          <strong>закрытым</strong>
        </em>
      </span>
      <span> (</span>Private
      <span>
        ) профилем можно послать запрос на предоставление доступа к его профилю.
        Если пользователь решит принять ваш запрос, он его одобрит, и появится
        возможность написать ему сообщение.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>В меню анкеты пользователя с </span>
      <span>
        <em>
          <strong>открытым</strong>
        </em>
      </span>
      <span> профилем можно:</span>
    </p>
    <p>
      <span>- </span>
      <strong>Invite</strong> <strong>to</strong>
      <span>
        <strong>…</strong>
      </span>
      <span> - пригласить его в вашу группу, на мероприятие.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Send</strong> <strong>message</strong>
      <span>
        {' '}
        - послать личное сообщение, а дальнейшую переписку увидеть, зайдя на
        главную страницу поиска в раздел{' '}
      </span>
      Chats<span>, затем в подраздел </span>People<span>.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Block</strong>
      <span>
        {' '}
        - заблокировать человека, после чего он не будет видеть вас в
        приложении.{' '}
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>Report</strong>
      <span>
        {' '}
        - послать жалобу администрации, если человек совершил какие-то
        нарушения.
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>Copy</strong> <strong>URL</strong>
      <span> - скопировать </span>URL
      <span>
        {' '}
        адрес профиля человека и переслать его любым способом, кому пожелаете.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>Особенности работы системы знакомств (</strong>
      </span>
      <strong>Dating</strong>
      <span>
        <strong>) в приложении:</strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>Система знакомства очень проста. </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        - Любой зарегистрированный пользователь может зайти в фильтры на главном
        экране поиска и выставить переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>me</strong> <strong>on</strong> <strong>Dating</strong>
      <span> в позицию </span>
      <strong>On</strong>
      <span>. Теперь вы в поиске сервиса знакомств. </span>
    </p>
    <p>
      <span>- На странице поиска активируйте кнопку с сердечком </span>
      <strong>Dating</strong>
      <span>. Теперь вы видите все сервисы раздела</span>
      <strong>Dating</strong>
    </p>
    <p>
      <span>
        - По умолчанию (бесплатно) в списке видно всех людей, кто хочет
        познакомиться в данный момент (с включенным переключателем знакомств) в
        радиусе 10 миль (16 километров) от вас. Всегда есть возможность
        составить удобный для вас план подписки, увеличив радиус обзора на карте
        и, указав, на какое количество дней вы хотите выбрать подписку*. На
        карте отображаются только те, кто пожелал указать свою локацию.{' '}
      </span>
    </p>
    <p>
      <span>
        - Чтобы разрешить другим участникам знакомств видеть вашу локацию на
        карте, нужно активировать переключатель{' '}
      </span>
      <strong>Display</strong>
      <strong>me</strong> <strong>on</strong> <strong>a</strong>{' '}
      <strong>dating</strong> <strong>map</strong>
      <span> в фильтрах (он также продублирован в разделе </span>
      <strong>Settings</strong>
      <span>
        {' '}
        личного кабинета). С целью соблюдения конфиденциальности, ваша локация
        отображается с погрешностью до 2 миль (3 километров) от вашего реального
        местонахождения.{' '}
      </span>
    </p>
    <p>
      <span>
        - Чтобы указать ваше настоящее местоположение, нужно активировать
        переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>my</strong> <strong>exact</strong> <strong>location</strong>
      <span>. </span>
    </p>
    <p>
      <span>
        - В разделе фильтров можно выбрать пол, сексуальную ориентацию и возраст
        людей, которых хотите найти. Мы намеренно не усложняли процесс
        знакомства добавлением дополнительных фильтров таких, как: хобби, цели
        знакомства и другое, поскольку считаем, что такие фильтры необъективны и
        люди должны узнавать друг друга в процессе общения.{' '}
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>*</span>
      <span>
        <em>План подписки приобретается только за внутренние монеты – </em>
      </span>
      <em>MetaFek</em>
      <em>Coins</em>
      <span>
        <em> (смотрите раздел «Что такое </em>
      </span>
      <em>MetaFek</em> <em>Coins</em>
      <span>
        <em>
          {' '}
          и где их взять?»). Монеты можно докупить за деньги или заработать.{' '}
        </em>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Особенности работы бегущей строки с аватарками в разделе знакомств (
        </strong>
      </span>
      <strong>Dating</strong>
      <span>
        <strong>) :</strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- Для того, чтобы отображаться в ленте</span>{' '}
      <strong>Dating</strong>
      <span>, выставьте</span>
      <span>переключатель </span>
      <strong>Show</strong> <strong>me</strong> <strong>on</strong>
      <strong>Dating</strong>
      <span> в позицию </span>
      <strong>On</strong>{' '}
      <span>
        {' '}
        в фильтрах расположенных возле поисковой строки на главном экране
        поиска. Далее вернитесь на сам экран поиска и активируйте кнопку{' '}
      </span>
      <strong>Dating</strong>
      <span>для отображения сервиса знакомств на карте.</span>
    </p>
    <p>
      <span>
        - Нужно нажать на + и выбрать радиус, в котором хотите быть видимым всем
        пользователям, кто зарегистрирован в разделе знакомств. Там же
        указывается количество раундов (сколько раз вы хотите выходить в ленту).
        Также можно использовать временной интервал выхода (количество минут
        между раундами ваших аватарок), если вы приобретаете 2 или более
        раунда*.{' '}
      </span>
    </p>
    <p>
      <span>
        - Можно добавить текст до 150 символов, который будет виден всем в
        указанном вами радиусе.{' '}
      </span>
    </p>
    <p>
      <span>
        Смещение позиции в ленте знакомств происходит за счет вытеснения
        предыдущей аватарки появлением новой. При этом происходит сдвиг ленты
        влево на одну позицию.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>*</span>
      <span>
        <em>План подписки приобретается только за внутренние монеты – </em>
      </span>
      <em>
        <strong>MetaFek</strong>
      </em>
      <em>
        <strong>Coins</strong>
      </em>
      <span>
        <em> (смотрите раздел «Что такое </em>
      </span>
      <em>MetaFek</em>
      <em>Coins</em>
      <span>
        <em>
          {' '}
          и где их взять?»). Монеты можно докупить за деньги или заработать.
        </em>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Особенности работы бегущей строки с аватарками в разделе общего
          поиска:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- При ОТКЛЮЧЕННОМ функционале знакомств</span>{' '}
      <strong>Dating</strong>
      <span>, нужно нажать на </span>
      <span>
        <strong>+</strong>
      </span>
      <span>
        {' '}
        и выбирать радиус, в котором хотите быть видны всем пользователям
        приложения на экране общего поиска.
      </span>
    </p>
    <p>
      <span>
        - Можно выбрать, что именно вы хотите показать в ленте событий: свой
        личный профиль, группу, мероприятие, бизнес или{' '}
      </span>
    </p>
    <p>
      <span>
        - Там же можно указать количество раундов (сколько раз вы хотите
        выходить в ленту).
      </span>
    </p>
    <p>
      <span>
        - Можно использовать временной интервал выхода (количество минут между
        раундами ваших аватарок), если вы приобретаете 2 или более раунда*.{' '}
      </span>
    </p>
    <p>
      <span>
        - Можно добавить текст до 150 символов, который будет виден всем в
        указанном вами радиусе.{' '}
      </span>
    </p>
    <p>
      <span>
        - Смещение позиции в ленте знакомств происходит за счет вытеснения
        предыдущей аватарки появлением новой. При этом лента сдвигается влево на
        одну позицию.
      </span>
    </p>
    <p>
      {' '}
      <span>
        - Можно использовать кнопки-переключатели, чтобы регулировать внешний
        вид бегущей строки, а также выбирать: видеть в ней только личные профили
        или добавить группы, мероприятия, бизнесы, активируя соответствующие
        кнопки на главном экране поиска.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>*</span>
      <span>
        <em>План подписки приобретается только за внутренние монеты – </em>
      </span>
      <em>
        <strong>MetaFek</strong>
      </em>
      <em>
        <strong>Coins</strong>
      </em>
      <span>
        <em> (смотрите раздел «Что такое </em>
      </span>
      <em>MetaFek</em>
      <em>Coins</em>
      <span>
        <em>
          {' '}
          и где их взять?»). Монеты можно докупить за деньги или заработать.
        </em>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Информация, отображаемая на странице мероприятия другого пользователя:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- Название мероприятия.</span>
    </p>
    <p>
      <span>- Описание мероприятия.</span>
    </p>
    <p>
      <span>- Дата и время начала мероприятия.</span>
    </p>
    <p>
      <span>- Дата и время окончания мероприятия.</span>
    </p>
    <p>
      <span>- Кто является администратором мероприятия.</span>
    </p>
    <p>
      <span>- Список участников мероприятия. </span>
    </p>
    <p>
      <span>- Карта с локацией мероприятия. </span>
    </p>
    <p>
      <span>- Фотографии мероприятия.</span>
    </p>
    <p>
      <span>- Лимит количества участников, если таковой есть.</span>
    </p>
    <p>
      <span>
        - Возможность закрепить чат мероприятия, и он будет отображаться первым
        в категории чатов{' '}
      </span>
      <strong>Events</strong>
      <span> (мероприятий).</span>
    </p>
    <p>
      <span>
        - Возможность включать или отключать оповещения о новых сообщениях.
      </span>
    </p>
    <p>
      <span>
        - Чтобы разрешить другим участникам этого мероприятия видеть вашу
        локацию на карте, нужно активировать переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>me</strong> <strong>to</strong> <strong>this</strong>{' '}
      <strong>community</strong>
      <span>
        . С целью соблюдения конфиденциальности, ваша локация отображается с
        погрешностью 3 километра от вашего реального местонахождения.
      </span>
    </p>
    <p>
      <span>
        - Чтобы указать ваше настоящее местоположение, нужно активировать
        переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>my</strong> <strong>exact</strong> <strong>location</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы поделиться информацией о данном мероприятии с другими людьми
        через другие мессенджеры, смс или е-мейл, нужно активировать
        переключатель{' '}
      </span>
      <strong>Share</strong>
      <strong>with</strong> <strong>friends</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы увидеть других участников мероприятия, которые дали согласие
        показывать свою геолокацию в рамках данного мероприятия, нужно нажать на
        значок{' '}
      </span>
      <span>
        <strong>Карты</strong>
      </span>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы присоединиться к мероприятию, нужно зайти в правом верхнем углу
        в меню (три точки) и найти функцию{' '}
      </span>
      <strong>Join</strong>
      <span>
        {' '}
        (послать запрос администратору на присоединение к мероприятию). После
        одобрения заявки администратором, вы сможете общаться в чате
        мероприятия, а само мероприятие появится у вас в личном кабинете в
        разделе{' '}
      </span>
      <strong>My</strong>
      <strong>Events</strong>
      <span>
        . После окончания мероприятия чат исчезнет из приложения автоматически.
      </span>
    </p>
    <p>
      <span>- Чтобы покинуть мероприятие нужно нажать на кнопку </span>
      <strong>Leave</strong>
      <span>.</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Информация, отображаемая на странице группы другого пользователя:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- Название группы.</span>
    </p>
    <p>
      <span>- Описание группы.</span>
    </p>
    <p>
      <span>- Кто является администратором группы.</span>
    </p>
    <p>
      <span>- Список участников группы.</span>
    </p>
    <p>
      <span>- Фотографии группы.</span>
    </p>
    <p>
      <span>- Лимит количества участников, если таковой есть.</span>
    </p>
    <p>
      <span>
        - Возможность включать или отключать оповещения о новых сообщениях.
      </span>
    </p>
    <p>
      <span>
        - Возможность закрепить чат группы, и он будет отображаться первым в
        категории чатов{' '}
      </span>
      <strong>Groups</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Возможность узнать является ли группа публичной, и вы вступили в нее,
        найдя в поиске, или закрытой, в которую вас пригласили по ссылке.{' '}
      </span>
      Private<span> (закрытые) группы в общем поиске не отображаются.</span>
    </p>
    <p>
      <span>
        - Чтобы разрешить другим участникам этой группы видеть вашу локацию на
        карте, нужно активировать переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>me</strong> <strong>to</strong> <strong>this</strong>{' '}
      <strong>community</strong>
      <span>
        . С целью соблюдения конфиденциальности, ваша локация отображается с
        погрешностью до 2 миль (3 километров) от вашего реального
        местонахождения.
      </span>
    </p>
    <p>
      <span>
        - Чтобы указать ваше настоящее местоположение, нужно активировать
        переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>my</strong> <strong>exact</strong> <strong>location</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы поделиться информацией о данной группе с другими людьми через
        другие мессенджеры, смс или е-мейл, нужно активировать переключатель{' '}
      </span>
      <strong>Share</strong>
      <strong>with</strong> <strong>friends</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы увидеть других участников группы, которые дали согласие
        показывать свою геолокацию в рамках данной группы, нужно нажать на
        значок{' '}
      </span>
      <span>
        <strong>Карты</strong>
      </span>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы присоединиться к группе, нужно зайти в правом верхнем углу в
        меню (три точки) и найти функцию{' '}
      </span>
      <strong>Join</strong>
      <span>
        {' '}
        (послать запрос администратору на присоединение к группе). После
        одобрения заявки администратором, вы сможете общаться в чате группы, а
        сама группа появится у вас в личном кабинете в разделе{' '}
      </span>
      <strong>My</strong>
      <strong>Groups</strong>
      <span>. </span>
    </p>
    <p>
      <span>- Чтобы покинуть группу, нужно нажать на кнопку </span>
      <strong>Leave</strong>
      <span>.</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Возможности настроек при создании и редактировании своего МЕРОПРИЯТИЯ:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- Добавить и редактировать аватарку мероприятия.</span>
    </p>
    <p>
      <span>- Добавить и редактировать название мероприятия.</span>
    </p>
    <p>
      <span>- Добавить и изменить тип мероприятия.</span>
    </p>
    <p>
      <span>- Добавить и редактировать описание мероприятия.</span>
    </p>
    <p>
      <span>- Добавить и удалить фотографии мероприятия.</span>
    </p>
    <p>
      <span>- Назначить и изменить дату и время начала мероприятия.</span>
    </p>
    <p>
      <span>- Назначить и изменить дату и время окончания мероприятия.</span>
    </p>
    <p>
      <span>
        - Указать место мероприятия на карте или написать адрес, где будет
        проходить мероприятие.
      </span>
    </p>
    <p>
      <span>- Видеть, кто является администратором мероприятия.</span>
    </p>
    <p>
      <span>- Видеть список участников мероприятия.</span>
    </p>
    <p>
      <span>- Включать и отключать оповещения о новых сообщениях.</span>
    </p>
    <p>
      <span>- Управлять лимитом количества участников, если таковой есть.</span>
    </p>
    <p>
      <span>
        - Закрепить чат мероприятия, и он будет отображаться первым в категории
        чатов{' '}
      </span>
      <strong>Events</strong>
      <span> (только для вас).</span>
    </p>
    <p>
      <span>
        - Узнать является ли мероприятие публичным, и вы вступили в него, найдя
        в поиске, или закрытым, в который вас пригласили по ссылке.{' '}
      </span>
      <strong>Private</strong>
      <strong>events</strong>
      <span>
        {' '}
        (закрытые мероприятия) в общем поиске не отображаются. После создания
        мероприятия изменить статус его приватности не возможно.
      </span>
    </p>
    <p>
      <span>
        - Чтобы сделать мероприятие публичным, доступным для поиска другим
        пользователям или приватным, в который можно приглашать участников
        исключительно по ссылке, нужно воспользоваться переключателем{' '}
      </span>
      <strong>Private</strong>
      <span>. </span>Private
      <span>
        {' '}
        (закрытые) мероприятия в общем поиске не отображаются. После создания
        мероприятия, в разделе{' '}
      </span>
      <strong>Edit</strong>
      <span> статус приватности изменить нельзя. </span>
    </p>
    <p>
      <span>
        - Чтобы разрешить другим участникам этого мероприятия видеть вашу
        локацию на карте, нужно активировать переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>me</strong> <strong>to</strong> <strong>this</strong>{' '}
      <strong>community</strong>
      <span>
        . С целью соблюдения конфиденциальности, ваша локация отображается с
        погрешностью до 2 миль (3 километров) от вашего реального
        местонахождения.
      </span>
    </p>
    <p>
      <span>
        - Чтобы указать ваше настоящее местоположение, нужно активировать
        переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>my</strong> <strong>exact</strong> <strong>location</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы поделиться информацией о данном мероприятии с другими людьми
        через другие мессенджеры, смс или е-мейл, нужно активировать
        переключатель{' '}
      </span>
      <strong>Invite</strong>
      <strong>people</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы увидеть других участников мероприятия, которые дали согласие
        показывать свою геолокацию в рамках данного мероприятия, нужно нажать на
        значок{' '}
      </span>
      <span>
        <strong>Карты</strong>
      </span>
      <span>.</span>
    </p>
    <p>
      <span>- Чтобы полностью очистить чат, нужно нажать на </span>
      <strong>Clear</strong> <strong>chat</strong>
      <strong>history</strong>
      <span>. </span>
    </p>
    <p>
      <span>
        <u>Будьте внимательны! Данные будут утеряны безвозвратно!</u>
      </span>
    </p>
    <p>
      <span>
        - Чтобы полностью очистить чат и удалить мероприятие, нужно нажать{' '}
      </span>
      <strong>Delete</strong>
      <strong>and</strong> <strong>Leave</strong>
      <span>. </span>
      <span>
        <u>Данные будут утеряны безвозвратно!</u>
      </span>
    </p>
    <p>
      <span>
        -После окончания мероприятия, данное сообщество исчезает из приложения
        автоматически.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Возможности настроек при создании и редактировании своей ГРУППЫ:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- Добавить и редактировать аватарку группы. </span>
    </p>
    <p>
      <span>- Добавить и редактировать название группы.</span>
    </p>
    <p>
      <span>- Добавить и редактировать описание группы.</span>
    </p>
    <p>
      <span>- Добавить и удалить фотографии группы. </span>
    </p>
    <p>
      <span>- Видеть, кто является администратором группы.</span>
    </p>
    <p>
      <span>- Видеть список участников группы.</span>
    </p>
    <p>
      <span>- Включать или отключать оповещения о новых сообщениях.</span>
    </p>
    <p>
      <span>- Управлять лимитом количества участников, если таковой есть</span>
    </p>
    <p>
      <span>
        - Закрепить чат группы, и он будет отображаться первым в категории чатов{' '}
      </span>
      <strong>Groups</strong>
      <span> (только для вас).</span>
    </p>
    <p>
      <span>
        - Чтобы сделать группу публичной, доступной для поиска другим
        пользователям или приватной, в которую можно приглашать участников
        исключительно по ссылке, нужно воспользоваться переключателем{' '}
      </span>
      <strong>Private</strong>
      <span>. </span>Private
      <span>
        {' '}
        (закрытые) группы в общем поиске не отображаются. После создания группы
        статус ее приватности изменить нельзя.
      </span>
    </p>
    <p>
      <span>
        - Чтобы разрешить другим участникам этой группы видеть вашу локацию на
        карте, нужно активировать переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>me</strong> <strong>to</strong> <strong>this</strong>{' '}
      <strong>community</strong>
      <span>
        . С целью соблюдения конфиденциальности, ваша локация отображается с
        погрешностью до 3 километров от вашего реального местонахождения.
      </span>
    </p>
    <p>
      <span>
        - Чтобы указать ваше настоящее местоположение, нужно активировать
        переключатель{' '}
      </span>
      <strong>Show</strong>
      <strong>my</strong> <strong>exact</strong> <strong>location</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы поделиться информацией о данной группе с другими людьми через
        другие мессенджеры, смс или е-мейл, нужно нажать кнопку{' '}
      </span>
      <strong>Invite</strong>
      <strong>people</strong>
      <span>.</span>
    </p>
    <p>
      <span>
        - Чтобы увидеть других участников группы, которые дали согласие
        показывать свою геолокацию в рамках данной группы, нужно нажать на
        значок{' '}
      </span>
      <span>
        <strong>Карты</strong>
      </span>
      <span>.</span>
    </p>
    <p>
      <span>- Чтобы полностью очистить чат, нужно нажать </span>
      <strong>Clear</strong> <strong>chat</strong>
      <strong>history</strong>
      <span>. </span>
    </p>
    <p>
      <span>
        <u>Будьте внимательны! Данные будут утеряны безвозвратно!</u>
      </span>
    </p>
    <p>
      <span>
        - Чтобы полностью очистить чат и удалить группу, нужно нажать{' '}
      </span>
      <strong>Delete</strong>
      <strong>and</strong> <strong>Leave</strong>
      <span>
        <strong>.</strong>
      </span>{' '}
      <span>
        <u>Данные будут утеряны безвозвратно!</u>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>
          Возможности настроек рекламы своего БИЗНЕСА в приложении:
        </strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- В личном кабинете нужно зайти в раздел </span>
      <strong>My</strong> <strong>Business</strong>
      <span> и нажать + «Создать новый бизнес».</span>
    </p>
    <p>
      <span>- Добавить аватарку бизнеса.</span>
    </p>
    <p>
      <span>- Указать название бизнеса.</span>
    </p>
    <p>
      <span>- Выбрать категорию бизнеса.</span>
    </p>
    <p>
      <span>- Создать краткое описание бизнеса.</span>
    </p>
    <p>
      <span>- Добавить фотографии.</span>
    </p>
    <p>
      <span>- Указать адрес, телефон и вебсайт. </span>
    </p>
    <p>
      <span>
        - Всем, кто находится в радиусе 48 километров, ваш бизнес будет виден
        бесплатно.{' '}
      </span>
    </p>
    <p>
      <span>
        - Чтобы докупить дополнительную зону покрытия видимости вашего бизнеса и
        выбрать количество дней, на которое вы хотите приобрести подписку, нужно
        зайти в раздел{' '}
      </span>
      <strong>Advertisement</strong>
      <strong>plan</strong>
      <span>
        <strong>.</strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <em>
          Внимание! Создание второго и каждого последующего бизнеса стоит 50{' '}
        </em>
      </span>
      <em>MetaFek</em>
      <em>Coins</em>
      <span>
        <em> в год. </em>
      </span>
    </p>
    <p>
      <span>
        <em>
          Монеты можно докупить за деньги или заработать их. Смотрите раздел
          «Что такое{' '}
        </em>
      </span>
      <em>MetaFek</em>
      <em>Coins</em>
      <span>
        <em> и где их взять?»</em>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>Что такое </strong>
      </span>
      <strong>MetaFek</strong> <strong>Coins</strong>
      <span>
        <strong> и где их взять?</strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        Для оплаты различных внутренних сервисов в приложении введен коин
        (валюта данного приложения). В личном кабинете в правом углу находится
        ваш текущий баланс, нажав на который вы переходите в раздел, где можно
        купить монеты, а также узнать, как их заработать или получить бесплатно.
      </span>
    </p>
    <p>
      <span>
        Бесплатные монеты начисляются как реферальный бонус за приглашенного
        участника, а также за рекламу приложения{' '}
      </span>
      Relagram
      <span>
        {' '}
        в соцсетях. Правила получения монет описаны в разделе баланса.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>Личный кабинет</strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>В личном кабинете указаны:</span>
    </p>
    <p>
      <span>- Ваше имя и фамилия, которые использовались при регистрации.</span>
    </p>
    <p>
      <span>- Аватарка.</span>
    </p>
    <p>
      <span>- Возраст (актуален для функции знакомств).</span>
    </p>
    <p>
      <span>- Раздел о Вас (краткое описание себя).</span>
    </p>
    <p>
      <span>- Баланс </span>Metafek Coin
      <span> с переходом в раздел пополнения баланса.</span>
    </p>
    <p>
      <span>- Имеется функция </span>Edit<span> вашего профиля.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Share</strong>{' '}
      <span>
        – поделиться ссылкой на свой профиль с помощью других мессенджеров, смс
        или е-мэйла.
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>Search</strong> <strong>Friends</strong>
      <span> – найти людей в приложении через главного поиск.</span>
    </p>
    <p>
      <span>- </span>
      <strong>My</strong> <strong>Groups</strong>
      <span> – группы, созданные вами и группы, в которых вы участвуете.</span>
    </p>
    <p>
      <span>- </span>
      <strong>My</strong> <strong>Events</strong>
      <span>
        {' '}
        – мероприятия или события, созданные вами и, в которых вы участвуете.
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>My</strong> <strong>Business</strong>
      <span>
        {' '}
        – ваш бизнес профиль, который вы создали для рекламы на карте поиска и в
        самом поиске.
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>Dating</strong>
      <span> – переход на покупку или проверку текущего плана знакомств.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Purchase</strong> <strong>History</strong>
      <span>
        {' '}
        – отражение всех транзакций, связанных с приобретением или тратой монет{' '}
      </span>
      MetaFek Coins<span>.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Pending</strong> <strong>Requests</strong>{' '}
      <span>
        - поступление запросов от участников о вступлении в созданную вами
        группу или мероприятие. Здесь же находятся ваши запросы о вступлении в
        чужие группы и мероприятия. В этот же раздел приходят запросы с просьбой
        предоставить доступ к вашему профилю с возможностью написать вам
        сообщение. Проверяйте этот раздел регулярно.{' '}
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>Settings</strong>{' '}
      <span>
        – общие настройки (оповещения, отображения вашей аватарки на картах
        общего поиска и знакомств). Здесь же можно сделать СБРОС всех настроек в
        первоначальное положение или же удались свой аккаунт в{' '}
      </span>
      Relagram<span>. Данные будут утеряны безвозвратно.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Help</strong>
      <span>
        {' '}
        - юридические документы и соглашения, которые были приняты вами при
        регистрации.
      </span>
    </p>
    <p>
      <span>- </span>
      <strong>Report</strong> <strong>a</strong> <strong>Bug</strong>
      <span> – сообщение службе поддержки об ошибке.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Rate</strong> <strong>Us</strong>
      <span> – оценить приложение.</span>
    </p>
    <p>
      <span>- </span>
      <strong>Log</strong> <strong>out</strong>
      <span> – выход из приложения.</span>
    </p>
    <p>
      <span>
        <strong>- </strong>
      </span>
      <strong>Delete</strong> <strong>Account</strong>{' '}
      <span>
        – полное удаление всех личных данных и закрытие прифиля в приложении{' '}
      </span>
      Map<span>.</span>App
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        <strong>Особенности работы экрана поиска:</strong>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>- На экране поиска в верхней строке находятся кнопки-фильтры </span>
      <strong>Dating</strong>
      <span>
        <strong>, </strong>
      </span>
      <strong>People</strong>
      <span>
        <strong>, </strong>
      </span>
      <strong>Event</strong>
      <span>
        <strong>, </strong>
      </span>
      <strong>Local</strong>
      <strong>Deals</strong>
      <span>
        , которые отвечают за отображение объектов на карте. Когда вы пишите
        текст в строке поиска, наши алгоритмы показывают вам совпадения в
        поиске, которые можно отфильтровать этими кнопками-фильтрами -{' '}
      </span>
      <strong>Dating</strong>
      <span>
        <strong>, </strong>
      </span>
      <strong>People</strong>
      <span>
        <strong>, </strong>
      </span>
      <strong>Event</strong>
      <span>
        <strong>, </strong>
      </span>
      <strong>Local</strong>
      <strong>Deals</strong>
      <span>, а также кнопкой </span>
      <strong>Groups</strong>
      <span>
        {' '}
        , если речь идет об объектах, которые на карте указать невозможно из-за
        их особенностей.
      </span>
    </p>
    <p>
      <span>
        - Чтобы более детально поработать с настройками отображения информации
        на вашей карте, нужно зайти в раздел{' '}
      </span>
      <strong>advanced</strong>
      <strong>filters</strong>
      <span>
        <strong>, </strong>
      </span>
      <span>который находится правее строки ввода текста.</span>
    </p>
    <p>
      <span>- Все ваше общение происходит в разделе </span>
      <strong>Chat</strong>
      <span>
        . Для удобства мы разделили чаты на персональные и чаты в группах и
        мероприятиях, в которых вы участвуете.
      </span>
    </p>
    <p>
      <span>
        - Чтобы перейти в ваш личный кабинет, нужно в правом нижнем углу нажать
        кнопку{' '}
      </span>
      <strong>Profile</strong>
      <span>. </span>
    </p>
  </>
)
