import FunctionItem from '../../../components/FunctionItem'
import { QrSection } from '../../../components/QrSection'
import { additionFuntions, functions } from './items'

import './styles.scss'

export const FunctionsPage = () => {
  return (
    <section className="functionsSection">
      <div className="container">
        <div className="funcsList">
          {functions.map(({ icon, isHidden, title, subtitle, link }) => {
            if (isHidden) return null
            return (
              <FunctionItem
                key={title}
                title={title}
                subtitle={subtitle}
                linkTo={link}
                icon={icon}
              />
            )
          })}
        </div>

        <h2 className="functionsSection--title">
          Convenience tools used in the app
        </h2>

        <div className="funcsList">
          {additionFuntions.map(({ icon, isHidden, title, subtitle, link }) => {
            if (isHidden) return null
            return (
              <FunctionItem
                key={title}
                title={title}
                subtitle={subtitle}
                linkTo={link}
                icon={icon}
              />
            )
          })}
        </div>
      </div>
      <div
        id="qr-section"
        className="container"
        style={{ paddingBottom: '40px' }}
      >
        <QrSection />
      </div>
    </section>
  )
}
