/* eslint-disable max-lines */
import './styles.scss'
import { contactMailLink } from '../../../constants/links'

export const VirtualItemsPolicy = () => {
  return (
    <div className="documents ">
      <div className="container">
        <div className="mt-4">
          <h2>Virtual Items Policy</h2>
          <p>&nbsp;</p>
          <p>
            The Relagram Program (the “Program”) is open to all users of the
            Services and is subject to the following terms and conditions of this
            Virtual Items Policy. We may make the following products and incentives
            available to you on our Platform from time to time.
          </p>
          <h2>METAFEK COINS (COINS, MFC)</h2>
          <p>
            Any user of our Services may purchase virtual “MetaFek Coins” (“MetaFek
            Coins”) from us using authorized payment methods provided by Apple or
            Google. All charges and payments for Relagram services will be made in
            the currency specified at the point of purchase through the relevant
            payment mechanism. Currency exchange settlements, foreign transaction
            fees and payment channel fees, if any, are based on your agreement with
            the applicable payment provider. The price of the MetaFek Coins will be
            displayed at the point of purchase. You will be responsible for the
            payment of any MetaFek Coins purchased by you.
          </p>
          <p>
            Once your purchase has been completed, your user account will be
            credited with MetaFek Coins. Our acceptance of your order will take
            place when we email you to accept it, at which point a contract will
            come into existence between you and us. If you wish to make changes to
            your purchase, please contact us. We will let you know if this change is
            possible. Please note that changes may impact price as well as other
            aspects of the product. If you live in the European Union, you have
            certain rights to withdraw from a purchase under the Consumer Rights
            Directive and its implementing legislation. However, if you purchase
            MetaFek Coins, you acknowledge and agree that we start supplying the
            MetaFek Coins to you promptly once the purchase is complete and
            therefore, your right to cancel or withdraw from the agreement to
            purchase is lost at this point.]
          </p>
          <p>
            MetaFek Coins cannot be exchanged for cash, or legal tender, or currency
            of any state, region, or any political entity, or any other form of
            credit. MetaFek Coins can only be used on our Platform and as part of
            our Services, and cannot be combined or used in conjunction with other
            promotions, coupons, discounts or special offers, except those
            designated by us.
          </p>
          <p>
            No MetaFek Coins may be assigned or transferred to any other user of the
            Services or third party except as expressly permitted by Relagram in
            writing. The sale, barter, assignment or other disposal of any MetaFek
            Coins, other than by Relagram, is expressly prohibited. Accrued MetaFek
            Coins do not constitute property and are not transferable: (a) upon
            death; (b) as part of a domestic relations matter; or (c) otherwise by
            operation of law. Any MetaFek Coins assigned, sold, or otherwise
            transferred without the express written consent of Relagram are void.
            Any user of the Services who violates the foregoing restriction is
            subject to the termination of account with Relagram, forfeiture of
            MetaFek Coins from his or her account, and/or liability for damages and
            litigation and transaction costs.
          </p>
          <p>
            All MetaFek Coins of a user will expire automatically upon termination
            of such user’s account for any reason.
          </p>
          <p>
            You agree that we have the absolute right to manage, regulate, control,
            modify and/or eliminate such MetaFek Coins, where we have a valid reason
            to do so, in any general or specific case, and that we will have no
            liability to you based on its exercise of such right.
          </p>
          <p>
            If you have any problems with the purchase of MetaFek Coins, please
            contact us at{' '}
            <u>
              <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
            </u>
            .
          </p>
          <p>&nbsp;</p>
          <h2>GIFTS, STICKERS, BACKGROUNDS</h2>
          <p>
            Virtual gifts and Stickers of the Program (“Gifts”) constitute a limited
            license right for you to certain feature of digital products and
            Services of the Services. You may exchange your MetaFek Coins for
            various Gifts, Stickers or Backgrounds and other virtual items. The
            exchange rate between each Coin and Gift will be displayed on our
            Platform at the point of exchange. Published prices do not include taxes
            (and, to the extent required by applicable laws in your jurisdiction,
            shall include taxes). If any Gifts are subject to sales tax in any
            jurisdiction and you have not paid the applicable sales tax to us, then
            you will be responsible for the payment of such sales tax and any
            related penalties or interest to the relevant tax authority. You agree
            that we have the absolute right to manage, regulate, control, modify
            and/or eliminate such exchange rate as we see fit in our sole
            discretion, in any general or specific case, and that we will have no
            liability to you based on our exercise of such right.
          </p>
          <p>
            [Our acceptance of your order will take place when we email you to
            accept it, at which point a contract will come into existence between
            you and us. If you wish to make changes to your purchase, please contact
            us. We will let you know if this change is possible. Please note that
            changes may impact price as well as other aspects of the product.]
          </p>
          <p>
            All sales of Gifts are final and we do not offer refunds for any
            purchased Gifts.
          </p>
          <p>
            When you have exchanged your MetaFek Coins for Gifts, such MetaFek Coins
            will have been used up in your user account. Instead, your user account
            will be credited with Gifts. Gifts cannot be converted into or exchanged
            for MetaFek Coins or cash, or be refunded or reimbursed by us for any
            reason.
          </p>
          <p>
            Gifts exchanged or received by any User do not constitute property and
            are not transferable: (a) upon death; (b) as part of a domestic
            relations matter; or (c) otherwise by operation of law.
          </p>
          <p>
            Relagram may replace previously exchanged copies of Gifts, if we
            determine in our sole discretion that the Gifts exchanged or received by
            a user is corrupted or otherwise damaged. We will not charge additional
            fee for reissuing a corrupted or otherwise damaged Gift. If you receive
            a corrupted or otherwise damaged Gift, then please conduct us at{' '}
            <u>
              <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
            </u>
            . Relagram reserves the right to terminate, in its sole and absolute
            discretion, the account of any User that is deemed to be abusing this
            provision.
          </p>
          <p>
            In relation to a live stream product, you may contribute your Gifts to
            rate or show your appreciation for an item of User Content that is
            uploaded or streamed by another user (“Content Provider”). When this
            functionality is available on the Services, you can contribute Gifts to
            User Content by clicking the “Give Gift” button below the relevant User
            Content. When you have contributed a Gift to an item of User Content,
            this Gift will have been used up in your account, and converted into
            Diamond (as defined and further explained below) in the Content
            Provider’s account.
          </p>
          <p>
            Please note that when you give a Gift to another user you do so
            publically so other users on the Services (including the recipient of
            the Gift) can see your name, user ID and the details of the Gift that
            you gave. [By giving a Gift, you consent to this information being made
            available in this manner.
          </p>
          <p>
            All gifts and Stickers, Backgrounds, Gifts and other virtual items
            published on Relagram are created by our developers. All rights
            reserved. Nobody can use, copy, modify, distribute our images for any
            purposes.
          </p>
          <p>
            Some of backgrounds published on Relagram are licensed under CC0 1.0{' '}
            <u>
              <a href="https://creativecommons.org/publicdomain/zero/1.0/">
                https://creativecommons.org/publicdomain/zero/1.0/
              </a>
            </u>
          </p>
          <p>
            If you have any problems with Gifts, please contact us at{' '}
            <u>
              <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
            </u>
          </p>
          <p>&nbsp;</p>
          <h2>SUBSCRIPTION PLANS AND PAID SERVICES</h2>
          <p>
            - You have to use MetaFek Coins to create and purchase any subscription
            plans to advertise your business page, or obtain advanced dating
            subscription plan or services such as Face Carousel in Dating section.
          </p>
        </div>
        <div className="mt-4">
          <h2>虚拟物品政策</h2>
          <p>&nbsp;</p>
          <p>Relagram计划（“计划”）向所有服务的用户开放，并受本虚拟物品政策的以下条款和条件的约束。我们可能会不时地在我们的平台上提供以下产品和激励。</p>
          <h2>MetaFek硬币（硬币，MFC）</h2>
          <p>我们服务的任何用户都可以使用Apple或Google提供的授权付款方式从我们这里购买虚拟的“MetaFek硬币”（“MetaFek硬币”）。所有Relagram服务的费用和付款将以在购买时点指定的货币通过相关付款机制进行支付。货币兑换结算、外汇交易费以及支付渠道费（如果有的话）都基于您与适用支付提供商的协议。MetaFek硬币的价格将显示在购买时点。您将负责支付您购买的任何MetaFek硬币。</p>
          <p>一旦您的购买完成，您的用户帐户将被记上MetaFek硬币。我们接受您的订单将于我们向您发送接受邮件时进行，此时您和我们之间将形成一项合同。如果您希望对您的购买进行更改，请与我们联系。我们会告诉您是否可能进行此更改。请注意，更改可能会影响价格以及产品的其他方面。如果您居住在欧盟，您有权根据消费者权利指令及其实施法律撤销购买。但是，如果您购买MetaFek硬币，则您承认并同意我们在购买完成后立即开始向您提供MetaFek硬币，因此，在此时取消或撤销购买协议的权利已丧失。</p>
          <p>MetaFek硬币不能兑换成现金、法定货币或任何国家、地区或任何政治实体的货币，也不能兑换成其他形式的信用。MetaFek硬币只能在我们的平台上和作为我们服务的一部分使用，不能与其他促销、优惠券、折扣或特别优惠组合使用或同时使用，除非由我们指定。</p>
          <p>任何MetaFek硬币均不得转让或转让给服务的任何其他用户或第三方，除非Relagram明确书面许可。除了由Relagram以外的任何出售、交换、转让或其他处置任何MetaFek硬币的行为都是明确禁止的。未经Relagram明确书面同意转让、出售或以其他方式转让的任何MetaFek硬币均无效。任何服务用户违反上述限制的行为都可能导致Relagram终止其帐户、没收其帐户中的MetaFek硬币，并/或承担损害赔偿责任和诉讼及交易成本。</p>
          <p>用户的所有MetaFek硬币将在任何原因终止该用户帐户时自动过期。</p>
          <p>您同意我们有绝对权利管理、监管、控制、修改和/或消除此类MetaFek硬币，我们有正当理由这样做，在任何一般或特定情况下，我们对其行使此类权利不承担责任。</p>
          <p>如果您在购买MetaFek硬币时遇到任何问题，请通过<u><a href={`mailto:${contactMailLink}`}>{contactMailLink}</a></u>与我们联系。</p>
          <p>&nbsp;</p>
          <h2>礼物、贴纸、背景</h2>
          <p>程序的虚拟礼物和贴纸（“礼物”）构成您对服务的数字产品和服务的某些功能的有限许可权。您可以用您的MetaFek硬币兑换各种礼物、贴纸或背景和其他虚拟物品。每个硬币和礼物之间的兑换率将显示在我们的平台上兑换点。发布的价格不包括税款（并且根据您司法管辖区的适用法律要求，应包括税款）。如果任何礼物在任何司法管辖区都要缴纳销售税，并且您未向我们支付适用的销售税，则您将对向相关税务机关支付此类销售税及相关罚款或利息负责。您同意我们有绝对权利根据我们自己的判断，在任何一般或特定情况下管理、监管、控制、修改和/或消除这样的兑换率，我们对其行使此类权利不承担责任。</p>
          <p>我们接受您的订单将于我们向您发送接受邮件时进行，此时您和我们之间将形成一项合同。如果您希望对您的购买进行更改，请与我们联系。我们会告诉您是否可能进行此更改。请注意，更改可能会影响价格以及产品的其他方面。</p>
          <p>所有礼物的销售都是最终的，我们不对任何购买的礼物提供退款。</p>
          <p>当您用MetaFek硬币兑换礼物时，这些MetaFek硬币将在您的用户帐户中用完。相反，您的用户帐户将被记上礼物。礼物不能转换为或兑换为MetaFek硬币或现金，也不能由我们进行退款或偿还。</p>
          <p>任何用户交换或收到的礼物不构成财产，也不可转让：（a）在死亡时；（b）作为家庭关系事务的一部分；或（c）根据法律的规定。如果我们确定在用户交换或收到的礼物在我们唯一的自由裁量权的情况下受损或其他损坏，Relagram可以替换以前交换的礼物。我们不会对重新发行受损或其他损坏的礼物收取额外费用。如果您收到受损或其他损坏的礼物，请联系我们。Relagram保留终止任何被视为滥用此规定的用户帐户的权利。</p>
          <p>关于直播产品，您可以将您的礼物贡献给另一个用户上传或流媒体的用户内容项（“内容提供者”）以评级或表示您对该用户内容的欣赏。当此功能在服务上可用时，您可以通过点击相关用户内容下方的“赠送礼物”按钮向用户内容贡献礼物。当您向某项用户内容贡献礼物时，此礼物将在您的帐户中用完，并转换为内容提供者帐户中的钻石（如下所定义和进一步解释）。</p>
          <p>请注意，当您向另一个用户赠送礼物时，您是公开的，因此服务上的其他用户（包括礼物的收件人）可以看到您的姓名、用户ID以及您给出的礼物的详细信息。[通过给出礼物，您同意以这种方式公开这些信息。</p>
          <p>在Relagram上发布的所有礼物和贴纸、背景、礼物和其他虚拟物品均由我们的开发人员创建。保留所有权利。任何人都不能将我们的图像用于任何目的。</p>
          <p>Relagram上发布的一些背景受CC0 1.0<u><a href="https://creativecommons.org/publicdomain/zero/1.0/">https://creativecommons.org/publicdomain/zero/1.0/</a></u>许可。</p>
          <p>如果您对礼物有任何问题，请通过<u><a href={`mailto:${contactMailLink}`}>{contactMailLink}</a></u>与我们联系。</p>
          <p>&nbsp;</p>
          <h2>订阅计划和付费服务</h2>
          <p>- 您必须使用MetaFek硬币创建和购买任何订阅计划，以广告您的商业页面，或获得高级约会订阅计划或服务，例如在约会部分的面部走廊。</p>
        </div>
      </div>
    </div>

  )
}
