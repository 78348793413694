import { FC, useEffect } from 'react'

const GetApp: FC = () => {
  useEffect(() => {
    const ug = navigator.userAgent.toLocaleLowerCase()

    if (!ug) {
      location.replace('https://relagram.com/features#qr-section')

      return
    }

    if (
      (ug.includes('apple') ||
        ug.includes('iphone') ||
        ug.includes('safari')) &&
      !ug.includes('android')
    ) {
      location.replace('https://apps.apple.com/app/yapmapapp/id1462299890')
    } else {
      location.replace(
        'https://play.google.com/store/apps/details?id=com.yapmap.yapmap',
      )
    }
  }, [])
  return null
}

export default GetApp
