import { FC, useState } from 'react'
import { Orientations, VideoPresentation } from '../../pages/VideoPresentation'

export type VideoTypes = {
  portrait: Orientations.PORTRAIT
  landscape: Orientations.LANDSCAPE
  web: Orientations.WEB
}
interface ExampleItemProps {
  video: VideoTypes
  avatar: string
  title: string
  link: string
  image: string
  readyToShow: boolean | undefined
}

const ExampleItem: FC<ExampleItemProps> = ({
  avatar,
  title,
  video,
  link,
  image,
  readyToShow,
}) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false)

  const handleVideoClose = () => {
    setIsVideoOpen(false)
  }
  const handleVideoOpen = () => {
    setIsVideoOpen(true)
  }

  return (
    <li style={{ position: 'relative' }}>
      {!readyToShow && (
        <span className="subscription-section--hint">Coming soon...</span>
      )}
      <img
        className="subscription-section--main-img"
        src={image}
        alt="item"
        onClick={handleVideoOpen}
      />
      {isVideoOpen && readyToShow && (
        <VideoPresentation video={video} onClose={handleVideoClose} />
      )}
      <a
        href={link}
        target="_blank"
        className="subscription-section--videos-block"
      >
        <img
          className="subscription-section--videos-avatar"
          src={avatar}
          alt="avatar"
        />
        <div>{title}</div>
      </a>
    </li>
  )
}

export default ExampleItem
