import { FC, useState } from 'react'
import { useModalContext } from '../contexts/ModalContext'
import { deleteAccount } from '../services/api/accountService'
import { useHistory } from 'react-router-dom'
import { routes } from '../constants/routes'
import { authTokenStoreService } from '../services/authTokenStoreService'

const DeleteAccount: FC = () => {
  const { openModal, openPopup } = useModalContext()
  const { push } = useHistory()
  const [deleting, setDeleting] = useState(false)

  const handleDeleteAccount = () => {
    openModal({
      type: 'warning',
      message: (
        <div>
          This action will delete your account. <br />
          Are you sure?'
        </div>
      ),
      async onOk() {
        setDeleting(true)
        try {
          return deleteAccount().then(() => {
            authTokenStoreService.remove()
            setTimeout(() => {
              openPopup({
                type: 'ok',
                message: 'Your account has been successfully deleted',
                onClose() {
                  push(routes.HOME)
                },
              })
            }, 100)
          })
        } catch (error) {
          setTimeout(() => {
            openPopup({
              type: 'error',
              message: 'Something went wrong when deleting your account',
            })
          }, 100)
          return error
        } finally {
          setDeleting(false)
        }
      },
      onOkText: 'Delete',
    })
  }

  return (
    <div className="h-[600px] mb-auto w-full flex container py-[100px]">
      <div className="ml-auto mr-auto">
        <div className="mb-[34px]">
          Would you want to delete this your account?
        </div>
        <div className="self-start flex justify-center">
          {deleting ? (
            'Deleting...'
          ) : (
            <button
              onClick={handleDeleteAccount}
              disabled={deleting}
              className={`bg-[#AF90FF] border-[1px] border-transparent rounded-[4px]
            text-white text-[13px] leading-[19.5px] font-[Poppins] px-[14px] py-[9px]
            hover:bg-white
            hover:text-[#AF90FF]
            hover:border-[#AF90FF]`}
              type="button"
            >
              Delete Account
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount
