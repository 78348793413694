import { FileUploader } from 'react-drag-drop-files'
import ddImage from '../../assets/img/ad/load_image.svg'
import { FC } from 'react'
import { tw } from '@twind/core'

interface DragDropProps {
  onChange: (e: File) => void
}

const DragDrop: FC<DragDropProps> = ({
  onChange
}) => {

  return (
    <FileUploader handleChange={onChange} name="file">
      <div
        className={tw(`
          cursor-pointer
          flex flex-col items-center justify-center py-[30px] border-[1px] border-dashed border-[#DEDEDE] 
          rounded-3xl overflow-hidden w-[574px] max-md:w-auto`)}
      >
        <img src={ddImage} alt="ddImage" />
        <span className={tw('text-[#9B9B9B] mt-10 mb-[30px]')}>
          Drag and drop to upload, or
        </span>
        <div
          className={tw(
            'text-[17px] uppercase px-[30px] py-[17px] text-white bg-[#AF90FF] rounded-[8px]',
          )}
        >
          browse
        </div>
      </div>
    </FileUploader>
  )
}

export default DragDrop