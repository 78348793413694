export default (agent) => {
  const isAndroid = agent.indexOf('android') > -1
  const isIOS =
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

  return {
    isIOS,
    isAndroid,
    isPc: !isIOS && !isAndroid,
  }
}
