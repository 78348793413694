import { useEffect, useState } from 'react'
import './styles.scss'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import cross from '../../assets/svg/icon_close_cross.svg'
import { routes } from '../../constants/routes'
import { authTokenStoreService } from '../../services/authTokenStoreService'
import { actionSetRedirect } from '../../store/actions/commonUiActions'

export const Menu = ({ children }) => {
  const dispatch = useDispatch()
  const [shown, setShown] = useState(false)

  const location = useLocation()

  useEffect(() => {
    setShown(false)
  }, [location])

  const handleShowMenu = () => setShown((prev) => !prev)

  const handleLogout = () => {
    authTokenStoreService.remove()
    handleShowMenu()
    dispatch(actionSetRedirect(routes.LOGIN))
  }

  return (
    <div className="login-menu">
      <button
        type="button"
        className="login-menu__button"
        onClick={handleShowMenu}
      >
        {children}
      </button>
      {shown && (
        <ul className="login-menu__list relative">
          <li className="login-menu__item account-item">
            <NavLink
              to={routes.PROFILE.ACCOUNT}
              activeClassName="active-link"
              onClick={handleShowMenu}
              className="login-menu__item__link"
            >
              Account
            </NavLink>
          </li>
          <li className="login-menu__item logout-item ">
            <button type="button" onClick={handleShowMenu}><img src={cross} className="absolute top-3 right-3" alt="close cross" /></button>
            <button
              className="login-menu__item__logout-button"
              type="button"
              onClick={handleLogout}
            >
              Logout
            </button>
          </li>
        </ul>
      )}
    </div>
  )
}
