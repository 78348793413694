export default {
  net: {
    CLEAR_AUTH_DATA: 'CLEAR_AUTH_DATA',
    SEND_REQUEST: 'SEND_REQUEST',
    SET_REQUEST_SUCCESS: 'SET_REQUEST_SUCCESS',
    SET_REQUEST_FAILURE: 'SET_REQUEST_FAILURE',
    CLEAR_REQUEST_FAILURE: 'CLEAR_REQUEST_FAILURE',
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    SET_USER_ACCOUNT: 'SET_USER_ACCOUNT',
  },
  ui: {
    SET_REDIRECT_URL: 'SET_REDIRECT_URL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    OPEN_MODAL: 'OPEN_MODAL',
    OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
    CLOSE_CONFIRM_MODAL: 'CLOSE_CONFIRM_MODAL',
  },
}
