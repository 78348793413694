import { FC } from 'react'

import { Button as btn, TextInput as inp } from '../uiKit'
import { StickerFile } from '../../pages/AddAnimatedStickers'
import mfcIcon from '../../assets/svg/mfcIcon.svg'
import { WarningAboutStickerSize } from '../../constants'

const TextInput = inp as any
const Button = btn as any

interface StickerAddingFormProps {
  stickerFiles: StickerFile[]
  isDataSending: boolean
}

export const StickerAddingForm: FC<StickerAddingFormProps> = ({
  stickerFiles,
  isDataSending,
}) => {
  return (
    <>
      <div className="adding-stickers-form__fieldset flex justify-between">
        <div>
          <div className="font-[KohinoorBangla] text-[12px] leading-[18px] text-[#8D9298]">
            Name pack
          </div>
          <TextInput
            bordered
            className="w-[258px] mr-[24px]"
            required
            name="packName"
            inputClassName="py-[8px]"
            onChange={() => {}}
          />
        </div>
        <div>
          <div className="font-[KohinoorBangla] text-[12px] leading-[18px] text-[#8D9298]">
            Amount (MFC)
          </div>
          <div className="relative flex items-center">
            <img
              src={mfcIcon}
              className="absolute z-10 shrink-0"
              alt={'mfcIcon'}
            />
            <TextInput
              bordered
              className="w-[258px]"
              onChange={() => {}}
              type="number"
              min={0}
              placeholder="0"
              name="packPrice"
              inputClassName="pl-[20px] py-[8px]"
            />
          </div>
        </div>
      </div>
      <div className="text-[10px] leading-[15px] font-[KohinoorBangla] text-[#9B9B9B] mt-[0px] mb-[50px]">
        Attention! 30% of the sales amount will be withheld as a Relagram sales
        commission.
      </div>
      <div className="adding-stickers-form__fieldset">
        <div className="w-full flex justify-between items-center">
          <div className="w-[98px] h-[98px]">
            {stickerFiles[0] &&
              (stickerFiles[0]?.isAnimated ? (
                <tgs-player
                  style={{ height: '100px', width: '100px' }}
                  autoplay
                  loop
                  mode="normal"
                  src={URL.createObjectURL(stickerFiles[0].file)}
                ></tgs-player>
              ) : (
                <img
                  src={URL.createObjectURL(stickerFiles[0].file)}
                  alt="stickerpack avatar"
                />
              ))}
          </div>

          <button
            className="bg-[#AF90FF] rounded-[4px] text-white text-[13px] leading-[19.5px] font-[Poppins] px-[14px] py-[9px]"
            disabled={isDataSending || !stickerFiles.length}
            type="submit"
          >
            Send
          </button>
        </div>
        <div className="mt-[16px]">{WarningAboutStickerSize}</div>
      </div>
    </>
  )
}
