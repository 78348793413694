import React from 'react'

import { SecuritySection } from '../sections'
import secure from '../../../assets/svg/secure.svg'
import './styles.scss'

export const SecurePage = () => {
  return (
    <div className="securePage">
      <div className="container">
        <img src={secure} alt="secure" />
      </div>

      <div className="container">
        <div className="securePage--info">
          <h2>Privacy Policy</h2>

          <p>
            {' '}
            Welcome to Tinder’s Privacy Policy. Thank you for taking the time to
            read it.
          </p>

          <p>
            We appreciate that you trust us with your information and we intend
            to always keep that trust. This starts with making sure you
            understand the information we collect, why we collect it, how it is
            used and your choices regarding your information. This Policy
            describes our privacy practices in plain language, keeping legal and
            technical jargon to a minimum.
          </p>

          {/* <p>
            This Privacy Policy applies beginning May 25, 2018. The previous
            version of this Privacy Policy, available <a href="/">here</a>, will
            apply until then.
          </p> */}

          <p>EFFECTIVE DATE: May 25, 2018</p>

          {/* <ul>
            <li>
              <a href="/">1. Who We Are</a>
            </li>
            <li>
              <a href="/">2. Where This Privacy Policy Applies</a>
            </li>
            <li>
              <a href="/">3. Information We Collect</a>
            </li>
            <li>
              <a href="/">
                4. Cookies And Other Similar Data Collection Technologies
              </a>
            </li>
            <li>
              <a href="/">5. How We Use Information</a>
            </li>
          </ul> */}

          <h3>Information we receive from others</h3>

          <p>
            {' '}
            In addition to the information you provide us directly, we receive
            information about you from others, including:
          </p>

          <p>
            Other Users Other users may provide information about you as they
            use our services. For instance, we may collect information about you
            from other users if they contact us about you.
            <br />
            <b>• Social Media</b> You may be able to use your social media login
            (such as Facebook Login) to create and log into your Tinder account.
            This saves you from having to remember yet another user name and
            password and allows you to share some information from your social
            media account with us.
            <br />
            <b>• Other Partners</b> We may receive info about you from our
            partners, for instance where Tinder ads are published on a partner’s
            websites and platforms (in which case they may pass along details on
            a campaign’s success).
          </p>
        </div>
      </div>
    </div>
  )
}
