import types from '../actions/types'

const { net } = types

const initialAutData = {
  profile: null,
  account: null,
}

const initialState = {
  error: null,
  pending: false,
  ...initialAutData,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case net.SEND_REQUEST: {
      return {
        ...state,
        pending: true,
      }
    }
    case net.SET_REQUEST_FAILURE: {
      return {
        ...state,
        pending: false,
        error: payload,
      }
    }
    case net.CLEAR_REQUEST_FAILURE: {
      return {
        ...state,
        pending: false,
        error: null,
      }
    }
    case net.SET_REQUEST_SUCCESS: {
      return {
        ...state,
        pending: false,
      }
    }
    case net.SET_USER_PROFILE: {
      return {
        ...state,
        profile: payload,
      }
    }
    case net.SET_USER_ACCOUNT: {
      return {
        ...state,
        account: payload,
      }
    }
    case net.CLEAR_AUTH_DATA: {
      return {
        ...state,
        ...initialAutData,
      }
    }
    default:
      return state
  }
}
