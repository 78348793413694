import { tw } from '@twind/core'
import { FC, useEffect, useMemo, useState } from 'react'
import { statuses } from '.'
import { useModalContext } from '../../contexts/ModalContext'
import { deleteStickerPack } from '../../services/api/stickerManagingService'
import axios from 'axios'

interface StickerpackItemProps {
  id: string
  icon: string
  name: string
  status: string
  handleRemoveFromState: (id: string) => void
}

const StickerpackItem: FC<StickerpackItemProps> = ({
  icon,
  id,
  name,
  status,
  handleRemoveFromState,
}) => {
  const { openModal, openPopup } = useModalContext()
  const [isStickerCorsError, setIsStickerCorsError] = useState<
    undefined | boolean
  >()
  const statusTitles = useMemo(() => {
    return [
      <div className={tw('text-[#9B9B9B]')}>Moderation in process</div>,
      <div className={tw('text-[#7ED321]')}>Approved</div>,
      <div className={tw('text-[#FB6F80]')}>Rejected</div>,
    ]
  }, [])

  const handleRemove = () => {
    openModal({
      message: `Do you want to remove the ${name} stickerpack`,
      type: 'warning',
      onOk() {
        deleteStickerPack(id)
          .then(() => {
            openPopup({
              message: `The ${name} stickerpack successfully removed`,
              type: 'ok',
            })

            handleRemoveFromState(id)
          })
          .catch(() => {
            openPopup({
              message: `Something went wrong while deleting the ${name} stickerpack`,
              type: 'error',
            })
          })
          .finally(() => {})
      },
      onOkText: 'Remove',
    })
  }

  useEffect(() => {
    axios.get(icon).catch((r) => {
      setIsStickerCorsError(true)
    })
  }, [])

  return isStickerCorsError === undefined ? (
    null
  ) : (
    <li
      key={id}
      className={tw(
        'flex shrink-0 flex-col items-center w-[150px] mr-[30px] mb-[30px]',
      )}
    >
      <div
        className={tw(
          `h-[98px] w-[98px] flex items-center justify-center rounded-[12px] mb-[20px] overflow-hidden`,
        )}
      >
        {icon.endsWith('.tgs') ? (
          isStickerCorsError ? (
            <tgs-player
              style={{ height: '100px', width: '100px' }}
              autoplay
              loop
              mode="normal"
              src={`https://cors-anywhere.herokuapp.com/${icon}`}
            ></tgs-player>
          ) : (
            <tgs-player
              style={{ height: '100px', width: '100px' }}
              autoplay
              loop
              mode="normal"
              src={icon}
            ></tgs-player>
          )
        ) : (
          <img src={icon} className="object-contain" alt="image" />
        )}
      </div>
      <div
        className={tw(
          `text-[16px] leading-[24px] font-[500] self-start ${
            statuses[status] === 2 ? 'text-[#9B9B9B]' : 'text-[#4A4A4A]'
          } mb-[4px]`,
        )}
      >
        {name}
      </div>
      <div className={tw('text-[12px] leading-[18px] self-start')}>
        {statusTitles[statuses[status]]}
      </div>

      {statuses[status] === 2 && (
        <div className="text-KohinoorBangla leading-[10px] text-[10px] text-[#4A4A4A] self-start mt-[20px]">
          Unauthorized content
        </div>
      )}
      {statuses[status] === 1 && (
        <button
          type="button"
          onClick={handleRemove}
          className={tw(
            `text-[15px] leading-[21px] px-[16px] py-[7.5px] text-white bg-[#DFE3E8] rounded-[4px]
           hover:bg-[#AF90FF] hover:text-white hover:border-[#AF90FF] border-[1px] border-[#DFE3E8] mt-[20px]`,
          )}
        >
          Remove
        </button>
      )}
    </li>
  )
}

export default StickerpackItem
