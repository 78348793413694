import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import './styles.scss'

const Component = () => {
  const { profile, account } = useSelector((state) => state.commonNetReducer)

  return (
    <div className="user-profile">
      <h4 className="user-profile__name">
        {profile ? `${profile.lastName} ${profile.firstName}` : <Skeleton />}
      </h4>
      <p className="user-profile__balance">
        {account ? `Balance: ${account.balance} MFC` : <Skeleton />}
      </p>
    </div>
  )
}

export const UserProfile = memo(Component)
