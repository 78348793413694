import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button, NumberInput } from '../../../components/uiKit'
import { thunkCreateCryptoPayment } from '../../../store/thunks/thunkCreateCryptoPayment'
import './styles.scss'
import { separatedSum } from '../../../utils/stringProcessors'

const getDescription = (user, amount) =>
  `${amount} MFC (${amount} $) by ${user}`

export const PaymentPage = () => {
  const [state, setState] = useState({
    mfc: 0,
    usd: 0,
  })

  const dispatch = useDispatch()

  const { profile } = useSelector((state) => state.commonNetReducer)

  const mfcFormatter = useCallback((value) => separatedSum(value, ','), [])

  const usdFormatter = useCallback(
    (value) => `$ ${separatedSum(value, ',')}`,
    [],
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    const paymentData = {
      name: 'Relagram balance replenishment',
      description: getDescription(
        `${profile.lastName} ${profile.firstName}`,
        state.usd,
      ),
      local_price: {
        amount: Number(state.mfc),
        currency: 'USD',
      },
      pricing_type: 'fixed_price',
      redirect_url: window.location.href,
      cancel_url: window.location.href,
    }
    dispatch(thunkCreateCryptoPayment(paymentData))
  }

  const isPaymentValid = state.mfc > 0

  const handleChangeAppCurrencySum = (value) => {
    const newValue = value > 0 ? value : 0
    setState((prev) => ({
      ...prev,
      mfc: newValue,
      usd: newValue,
    }))
  }

  return (
    <div className="payment-page">
      <div className="payment-page__form-container">
        <form onSubmit={handleSubmit}>
          <div className="payment-page__item">Replenish account</div>
          <div className="payment-page__item">
            <NumberInput
              value={state.mfc}
              label="MFC amount"
              onChange={handleChangeAppCurrencySum}
              formatter={mfcFormatter}
              min={0}
              id="MFC"
            />
          </div>
          <div className="payment-page__item">
            <NumberInput
              value={state.usd}
              label="Total"
              id="USDT"
              min={0}
              formatter={usdFormatter}
              disabled
            />
          </div>
          <div className="payment-page__item">
            <Button
              style={{ width: '100%' }}
              type="submit"
              disabled={!isPaymentValid}
            >
              PAYMENT
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
