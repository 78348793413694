import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AppRouter } from './routers/AppRouter'
import { ScrollToTop } from './components/ScrollToTop'
import configureStore from './store/configureStore'
import AskForCookie from './components/AskForCookie'
import { ModalContext } from './contexts/ModalContext'

const store = configureStore()

export const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <AskForCookie />
        <ScrollToTop />
        <ModalContext>
          <AppRouter />
        </ModalContext>
      </QueryParamProvider>
    </BrowserRouter>
    <ToastContainer />
  </Provider>
)
