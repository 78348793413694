import React from 'react'
import RcInput from 'rc-input'

import './styles.scss'
import ErrorMessage from './ErrorMessage'

export const TextInput = React.forwardRef(
  (
    {
      autoComplete,
      bordered,
      disabled,
      id,
      label,
      loading,
      onBlur,
      onChange,
      type,
      value,
      name,
      placeholder,
      suffix,
      prefix,
      addonBefore,
      addonAfter,
      errorType,
      errorMessage,
      inputClassName,
      className,
      inlPrefix,
      ...rest
    },
    ref,
  ) => {
    const handleChange = (e) => {
      e.persist()

      // e.target.value
      console.log('🚀 ~ e.target.value:', e.target.value)
      const value = e.target.value

      onChange(e, name)
    }
    return (
      <div className={`ui-kit-input ${className}`}>
        {label && id && <label htmlFor={id}>{label}</label>}
        <div className="flex">
          {inlPrefix}
          <RcInput
            {...rest}
            autoComplete={autoComplete}
            bordered={typeof bordered === 'boolean' && bordered.toString()}
            disabled={disabled}
            loading={loading}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            type={type}
            value={value}
            placeholder={placeholder}
            suffix={suffix}
            prefix={prefix}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            className={inputClassName}
            ref={ref}
          />
        </div>

        {errorMessage && (
          <ErrorMessage errorType={errorType} message={errorMessage} />
        )}
      </div>
    )
  },
)

TextInput.displayName = 'TextInput'
