import types from './types'

const { ui } = types

export const actionSetRedirect = (url, force, blank) => ({
  type: ui.SET_REDIRECT_URL,
  payload: { url, blank, force },
})

export const actionOpenModal = (componentType) => ({
  type: ui.OPEN_MODAL,
  payload: componentType,
})

export const actionCloseModal = () => ({
  type: ui.CLOSE_MODAL,
})

export const actionOpenConfirmModal = () => ({
  type: ui.OPEN_CONFIRM_MODAL,
})

export const actionCloseConfirmModal = () => ({
  type: ui.CLOSE_CONFIRM_MODAL,
})
