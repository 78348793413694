import { useCallback, useMemo, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import { StickerEditor } from '../../components/StickerEditor'
import { routes } from '../../constants/routes'
import isRightImageType from '../../utils/isRightImageType'
import { PreparedSticker } from '../../components/PreparedSticker'
import { StickerAddingForm } from '../../components/StickerAddingForm'
import { AddStickerInput } from '../../components/AddStickerInput'
import { EmojiData, StickerFile } from '../AddAnimatedStickers'
import { thunkUploadStickers } from '../../store/thunks/thunkUploadStickers'

import './style.scss'
import { useModalContext } from '../../contexts/ModalContext'
import {
  FreeStickerPackInfo,
  MAX_STICKERS_NUMBER,
  approveText,
  loadStickersMessage,
} from '../../constants'

export const statuses: Record<string, number> = {
  CREATED: 0,
  PENDING: 0,
  IN_PROGRESS: 0,
  APPROVED: 1,
  REJECTED: 2,
}

export const AddStickers = () => {
  const { push } = useHistory()
  const [stickerFiles, setStickerFiles] = useState<StickerFile[]>([])
  const [editableSticker, setEditableSticker] = useState<File | null>(null)
  const [editor, setEditor] = useState<AvatarEditor | null>(null)
  const [isStickersBeingLoaded, setIsStickersBeingLoaded] = useState(false)
  const dispatch = useDispatch()
  const { openModal } = useModalContext()

  const bc = useMemo<BreadcrumbItem[]>(
    () => [
      { title: 'StickerFiles', path: routes.PROFILE.STICKERS },
      { title: 'Add Graphic Stickers' },
    ],
    [],
  )

  const onSuccess = () => {
    setIsStickersBeingLoaded(false)
    toast('Your Stickerpack has been loaded!')
    push(routes.PROFILE.STICKERS)
  }

  const onError = () => {
    setIsStickersBeingLoaded(false)
    toast('Something went wrong during the stickerpack uploading', {
      className: 'notification--error',
    })
  }

  const onLoading = () => {
    setIsStickersBeingLoaded(true)
  }

  const sendStickers = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget
    const { packName, packPrice } = form

    e.preventDefault()
    openModal({
      type: 'ok',
      message: loadStickersMessage,
      onOkText: approveText,
      onOk() {
        dispatch(
          thunkUploadStickers(
            stickerFiles,
            packName.value,
            +packPrice.value,
            onSuccess,
            onLoading,
            onError,
            'GRAPHIC',
          ),
        )
      },
    })
  }

  const handleEditingDone = (emojiData: EmojiData) => {
    if (!editableSticker) return

    setStickerFiles((state) => [
      ...state,
      {
        id: new Date().getTime(),
        file: editableSticker,
        sticker: URL.createObjectURL(editableSticker),
        emoji: emojiData,
        isAnimated: !(
          editableSticker.type === 'image/webp' ||
          editableSticker.type === 'image/png'
        ),
      },
    ])

    setEditableSticker(null)
  }

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()

    if (!e.target.files) return

    const isSupported = await isRightImageType(e.target.files[0])
    if (!isSupported) {
      toast('File format is not supported. Only .png or .webp and 512 x 512 px')
      e.target.value = ''

      return
    }

    setEditableSticker(e.target.files[0])

    e.target.value = ''
  }

  const handleEditorClose = useCallback(() => {
    setEditableSticker(null)
  }, [])

  const handleRemoveSticker = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const sId = e.currentTarget.dataset.stickerId

    setStickerFiles((files) => {
      return files.filter(({ id }) => id.toString() !== sId)
    })
  }

  return (
    <div className="adding-stickers">
      <div className="mb-[25px]">
        <Breadcrumbs items={bc} />
      </div>
      <div className="relative">
        <div className="text-violet-400">{FreeStickerPackInfo}</div>

        <form className="adding-stickers-form" onSubmit={sendStickers}>
          <StickerAddingForm
            stickerFiles={stickerFiles}
            isDataSending={isStickersBeingLoaded}
          />

          <div className="sticker-monitor ml-[-4px]">
            {stickerFiles.map(({ id, sticker, emoji }) => (
              <PreparedSticker
                handleRemoveSticker={handleRemoveSticker}
                key={id}
                id={id}
                sticker={sticker}
                emoji={emoji}
              />
            ))}
            {stickerFiles.length < MAX_STICKERS_NUMBER && (
              <AddStickerInput handleFileSelect={handleFileSelect} />
            )}
          </div>

          {editableSticker && (
            <div className="absolute inset-0 bg-white z-10">
              <StickerEditor
                onDone={handleEditingDone}
                ref={setEditor}
                onClose={handleEditorClose}
                editableSticker={editableSticker}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
