import axios from 'axios'

import { API_DOMAIN, API_DOMAIN_PROD } from '../config'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ?  API_DOMAIN : API_DOMAIN_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.response.use(
  (response) => response.data,
  (err) => Promise.reject(err.response),
)

export const get = async (url, config) => instance.get(url, config)

export const post = async (url, data, config) =>
  instance.post(url, data, config)

export const put = async (url, data, config) => instance.put(url, data, config)
