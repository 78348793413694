import React from 'react'
import { NavLink } from 'react-router-dom'

import { navigationItems } from '../../constants/navigationRoutes'

export const NavigationBar = () => {
  return (
    <>
      {navigationItems.map((route) => (
        <li key={route.text} className="header__navigation__item">
          <NavLink
            to={route.route}
            activeClassName="active-link"
            className="header__navigation__link"
          >
            {route.text}
          </NavLink>
        </li>
      ))}
    </>
  )
}
