import { FC, useEffect, useMemo, useState } from 'react'
import { tw } from '@twind/core'
import { Stickerpack } from '../Ads/AdsContexts'
import Progress from '../Ads/Progress'
import { getStickerPack } from '../../services/api/stickerManagingService'
import { statuses } from '.'
import StickerpackItem from './StickerpackItem'
import { useModalContext } from '../../contexts/ModalContext'

const Stickerpacks: FC = () => {
  const { openPopup } = useModalContext()
  const [stickerpacks, setStickerpacks] = useState<Stickerpack[]>([])

  const handleError = () => {
    openPopup({
      message: 'Something went wrong while stickerpacks loading',
      type: 'error',
      onClose() {},
    })
  }

  const handleRemoveFromState = (id: string) => {
    setStickerpacks(stickerpacks.filter((sp) => sp.id !== id))
  }

  useEffect(() => {
    getStickerPack()
      .then((stickerpacks: any) => {
        setStickerpacks(stickerpacks || [])
      })
      .catch(handleError)
  }, [])

  return (
    <div>
      <ul className="flex flex-wrap">
        {stickerpacks.map((pack) => {
          return (
            <StickerpackItem
              key={pack.id}
              id={pack.id}
              icon={pack.icon}
              name={pack.name}
              status={pack.status}
              handleRemoveFromState={handleRemoveFromState}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default Stickerpacks

// const VideoRow: FC<{ allAds: AllAds[] }> = ({ allAds }) => {
//   return (
//     <>
//       {allAds.map((add) => {
//         return (
//           <div
//             key={add.id}
//             className={tw(
//               'flex shrink-0 flex-col w-[150px] mr-[30px] mb-[30px]',
//             )}
//           >
//             <div
//               className={tw(
//                 `h-[86px] w-full flex items-center justify-center rounded-[12px] mb-[20px] overflow-hidden`,
//               )}
//             >
//               <video
//                 onLoadedData={(e) => {
//                   ;(e.target as any).style.display = 'block'
//                 }}
//                 className={tw(
//                   'relative z-[99] w-full h-full hidden object-cover',
//                 )}
//                 src={add.url}
//                 controls={false}
//               ></video>

//               <div className={tw('absolute')}>
//                 <Progress isCompleted={false} />
//               </div>
//             </div>

//           </div>
//         )
//       })}
//     </>
//   )
// }
