import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import cross from '../../../assets/svg/icon_close_cross.svg'
import menu from '../../../assets/svg/icon_menu.svg'
import './styles.scss'

export const BurgerMenu = ({ opened, setOpened }) => {
  const location = useLocation()

  useEffect(() => {
    setOpened(false)
  }, [location])

  const handleOpenMenu = () => setOpened((prev) => !prev)
  return (
    <div
      className="burger-menu"
      role="button"
      type="button"
      tabIndex={0}
      onClick={handleOpenMenu}
    >
      {opened ? (
        <img src={cross} className="burger-menu--cross" alt="close cross" />
      ) : (
        <img src={menu} alt="burger menu" />
      )}
    </div>
  )
}
