import { FC } from 'react'
import { useQr } from '../../hooks/useQr'
import AppStoreLogo from '../../assets/svg/logo_app_store.svg'
import googlePlayLogo from '../../assets/svg/logo_google_play.svg'
import { appStoreLink, googlePlayLink } from '../../constants/links'
import getAppLink from '../../utils/getAppLink'
import { endpoints } from '../../constants/endpoints'
import { API_DOMAIN, API_DOMAIN_PROD } from '../../config'
import { tx } from '@twind/core'
import { routes } from '../../constants/routes'

const api = process.env.NODE_ENV === 'development' ? API_DOMAIN : API_DOMAIN_PROD
// const api = API_DOMAIN_PROD
const qrlink = `${api}${endpoints.GET_APP}`

export const QrSection: FC = () => {
const link = `${location.origin}${routes.GET_APP}`
  const appStoreQR = useQr(link)

  return (
    <div className={tx`max-sm:flex flex-col items-center`}>
      <div className="mainScreenSection--subtitle">
        Scan the QR code and get the Relagram app
      </div>

      <div className="mainScreenSection--qrs">
        <div className="mainScreenSection--qr">
          {appStoreQR}
          <div>
            <a href={appStoreLink}>
              <img src={AppStoreLogo} alt="app store logo" />
            </a>
            <a href={googlePlayLink}>
              <img src={googlePlayLogo} alt="google store logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
