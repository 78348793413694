import { Link, useLocation } from 'react-router-dom'

import { appStoreLink, googlePlayLink } from '../../constants/links'
import { navigationItems } from '../../constants/navigationRoutes'
import { documentsRoutes } from '../../constants/documentsRoutes'
import planetUn from '../../assets/svg/icon_planet_un.svg'
import { LogoLink } from '../ui'
import mars from '../../assets/svg/icon_planet_mars.svg'
import { routes } from '../../constants/routes'

import './styles.scss'

export const Footer = () => {
  const { pathname } = useLocation()

  const isWhiteBg =
    pathname.includes(routes.PRODUCT) || pathname.includes(routes.CURRENCY)

  return (
    <footer className={`footer mt-auto ${!isWhiteBg ? 'notProductRoute' : ''}`}>
      <div className="container">
        <div className="footer__navigation-item">
          <div className="footer--logo">
            <LogoLink isWhiteLogo={!isWhiteBg} />
          </div>
          <ul>
            {navigationItems.map((route, i) =>
              i !== 0 ? (
                <li key={`footer${route.route}`}>
                  <Link to={route.route}>{route.text}</Link>
                </li>
              ) : null,
            )}
          </ul>
        </div>
        <div className="footer__navigation-item">
          <span className="footer__navigation-title">Company</span>
          <ul>
            {documentsRoutes.map((route) => (
              <li key={`footer${route.route}`}>
                <Link to={route.route}>{route.text}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer__navigation-item">
          <span className="footer__navigation-title">Downloads</span>
          <ul>
            <li>
              <a href={appStoreLink}>iOS</a>
            </li>
            <li>
              <a href={googlePlayLink}>Android</a>
            </li>
          </ul>
        </div>
        <div className="footer__navigation-item">
          <div className="footer__select">
            <select>
              <option value="0">English</option>
              {/* <option value="1">Русский</option> */}
            </select>
          </div>
        </div>
      </div>
      <div className="footer--sub">
        © 2022 Relagram. Made On Planet Earth.
        <img src={planetUn} alt="planet UN icon" />
        ... Approved for use on any Territories
        <img src={mars} alt="planet UN icon" />
      </div>
      <div className="footer--sub mob">
        © 2022 Relagram. Made On Planet Earth.
        <img src={planetUn} alt="planet UN icon" /> <br />
        Approved for use on any Territories
        <img src={mars} alt="planet UN icon" />
      </div>
    </footer>
  )
}
