import { memo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

import { routes } from '../../constants/routes'
import './styles.scss'
import { NavigationBar } from '../NavigationBar'
import { authTokenStoreService } from '../../services/authTokenStoreService'
import { getProfile } from '../../store/selectors'
import { minifyName } from '../../utils/stringProcessors'
import { Menu } from '../Menu'
import { BurgerMenu } from '../uiKit/BurgerMenu/BurgerMenu'
import { LogoLink } from '../ui'

export const HeaderComponent = () => {
  const state = useSelector((state) => state)
  const profile = getProfile(state)
  const [opened, setOpened] = useState(false)

  return (
    <header className="header">
      <div className="container">
        <div className="header--block">
          {opened || <LogoLink />}

          <ul className={`header__navigation ${opened ? 'mobile' : ''}`}>
            <NavigationBar />

            {authTokenStoreService.isLogged() ? (
              <li className="header__navigation__item">
                <Menu>
                  {profile ? (
                    <span
                      // to={routes.PROFILE.PROFILE}
                      className="header__navigation__link"
                    >{`${profile?.lastName} ${minifyName(
                      profile?.firstName,
                    )}`}</span>
                  ) : (
                    <Skeleton />
                  )}
                </Menu>
              </li>
            ) : (
              <li className="header__navigation__item">
                <NavLink
                  to={routes.LOGIN}
                  activeClassName="active-link"
                  className="header__navigation__link header--login-btn"
                >
                  Login
                </NavLink>
              </li>
            )}
          </ul>
          <BurgerMenu opened={opened} setOpened={setOpened} />
        </div>
      </div>
    </header>
  )
}

export const Header = memo(HeaderComponent)
