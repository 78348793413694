const payWithOptions = [
  {
    id: 0,
    name: 'MFC Balance',
    types: ['MFC'],
  },
  {
    id: 1,
    name: 'Applepay',
    types: ['APPLE_PAY'],
  },
  {
    id: 2,
    name: 'Coinbase',
    types: ['COINBASE'],
  },
]

export default payWithOptions
