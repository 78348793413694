import React from 'react'

import { Button, TextInput } from '../../components/uiKit'

import ErrorMessage from './ErrorMessage'

const FormCode = ({ state, handleChangeValues }) => {
  const { email, loading, error } = state
  return (
    <>
      <div className="reset-form__item my-7">
        <TextInput
          label="email"
          id="email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={handleChangeValues}
          placeholder="email for sending verification code"
        />
      </div>
      {error && <ErrorMessage error={error} />}
      <div className="reset-form__item">
        <Button style={{ width: '100%' }} type="submit" loading={loading}>
          SEND CODE TO EMAIL
        </Button>
      </div>
    </>
  )
}

export default FormCode
