import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import './styles.scss'
import { thunkSendCodeForResetPasswordByEmail } from '../../store/thunks/thunkSendCodeForResetPasswordByEmail'
import { thunkConfirmResetPasswordByEmail } from '../../store/thunks/thunkConfirmResetPasswordByEmail'
import { actionSetRedirect } from '../../store/actions/commonUiActions'
import { routes } from '../../constants/routes'
import { Button } from '../../components/uiKit'

import FormCode from './FormCode'
import FormPassword from './FormPassword'
import NotificationCode from './NotificationCode'

export const ResetPasswordPage = () => {
  const [state, setState] = useState({
    email: new URLSearchParams(location.search).get('email') || undefined,
    password: undefined,
    repeatedPassword: undefined,
    code: undefined,
    error: undefined,
    loading: false,
    isCodeSent: false,
    isPasswordChanged: false,
  })
  const dispatch = useDispatch()

  const setError = (message) =>
    setState((prev) => ({ ...prev, error: message }))

  const setIsCodeSent = (status) =>
    setState((prev) => ({ ...prev, isCodeSent: status }))

  const setIsPasswordChanged = (status) =>
    setState((prev) => ({ ...prev, isPasswordChanged: status }))

  const setLoading = (loading) => setState((prev) => ({ ...prev, loading }))

  const onError = (message) => setError(message)

  const confirmResetPasswordByEmail = () => {
    const data = {
      email: state.email,
      password: state.password,
      emailCode: state.code,
    }
    const onSuccess = () => {
      setIsPasswordChanged(true)
    }

    dispatch(
      thunkConfirmResetPasswordByEmail(data, setLoading, onSuccess, onError),
    )
  }

  const sendCodeForResetPasswordByEmail = () => {
    const data = {
      email: state.email,
    }
    const onSuccess = () => {
      setIsCodeSent(true)
    }
    dispatch(
      thunkSendCodeForResetPasswordByEmail(
        data,
        setLoading,
        onSuccess,
        onError,
      ),
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setError(undefined)

    if (state.isCodeSent) {
      confirmResetPasswordByEmail()
    } else {
      sendCodeForResetPasswordByEmail()
    }
  }

  const handleChangeValues = React.useCallback((e, name) => {
    setState((prev) => ({
      ...prev,
      [name]: e.target.value,
    }))
  }, [])

  const goToLoginPage = () => dispatch(actionSetRedirect(routes.LOGIN))

  return (
    <div className="reset-password-page py-7">
      {state.isPasswordChanged ? (
        <div className="reset-password-page__redirect">
          <div>Password has been changed successfully</div>
          <Button onClick={goToLoginPage}>Go to login page</Button>
        </div>
      ) : (
        <div className="reset-password-page__form-container">
          <form
            onSubmit={handleSubmit}
            autoComplete="on"
            className="reset-form"
          >
            {state.isCodeSent ? (
              <>
                <FormPassword
                  state={state}
                  handleChangeValues={handleChangeValues}
                  onResendCode={confirmResetPasswordByEmail}
                />
                <NotificationCode
                  email={state.email}
                  onResendCode={sendCodeForResetPasswordByEmail}
                />
              </>
            ) : (
              <FormCode state={state} handleChangeValues={handleChangeValues} />
            )}
          </form>
        </div>
      )}
    </div>
  )
}
