import React from 'react'
import RCTooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import './styles.scss'

const Tooltip = ({ visible, placement, trigger, children, overlay }) => {
  return (
    <RCTooltip
      placement={placement}
      trigger={trigger}
      className="ui-kit-tooltip"
      overlay={overlay}
    >
      {children}
    </RCTooltip>
  )
}

export default Tooltip
