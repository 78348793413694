export const loadStickersMessage = `By uploading your sticker pack, you're agreeing to make it available for use by other users of the application. Plus, you'll earn a fantastic 70% share of the profits whenever someone purchases it, paid out to your balance within the app in MFC (coins of the app)! Please note that once the stickers are uploaded, you won't be able to edit them. If you wish to delete your sticker pack, please contact support.`
export const approveText = 'Approve'
export const FreeStickerPackInfo = (
  <div className="text-[#9B9B9B] text-[10px] leading-[15px] mb-[24px]">
    {'Enter 0 MFC if it’s free sticker pack'}
  </div>
)
export const WarningAboutStickerSize = (
  <div className="text-[#9B9B9B] text-[10px] leading-[15px] mb-[24px]">
    The icon could be added. The icon size could be 100х100 pixels with looped
    animation <br />
    no more than 3 seconds.
  </div>
)

export const MAX_STICKERS_NUMBER = 30
