import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import cross from '../../assets/svg/icon_close_cross.svg'

type Item = { id: number; name: string }
interface MultiSelectProps {
  items: Item[]
  name: string
  value?: MultiSelectProps['items'][number][]
  onChange: (e: {
    name: string
    value: MultiSelectProps['items'][number][]
  }) => void
  disabled?: boolean
  excludedItem?: Item
  multi?: boolean
}

const MultiSelect: FC<MultiSelectProps> = ({
  items,
  onChange,
  name,
  value,
  disabled,
  excludedItem,
}) => {
  const [selectedItems, setSelectedItems] = useState<Item[]>(value || [])

  const [query, setQuery] = useState('')
  const ref = useRef(true)

  useEffect(() => {
    if (ref.current) {
      ref.current = false

      return
    }

    onChange({ name, value: selectedItems })
  }, [selectedItems])

  const unselectItem = (id: number) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== id))
  }

  const selectItem = (items: Item[]) => {
    const isFirstExcluding = items[0]?.id === excludedItem?.id
    const isLastExcluding = items[items.length - 1]?.id === excludedItem?.id

    if (isFirstExcluding) {
      setSelectedItems(items.filter((item) => item.id !== excludedItem?.id))
    } else if (isLastExcluding) {
      setSelectedItems(excludedItem ? [excludedItem] : [])
    } else {
      setSelectedItems(items)
    }
  }

  const filteredList =
    query === ''
      ? items
      : items.filter((it) =>
          it.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  return (
    <div
      className={`w-full ${
        disabled ? 'pointer-events-none opacity-30' : ''
      } max-w-[500px]`}
    >
      <Combobox multiple value={selectedItems} onChange={selectItem}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden border-b-[1px] bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder="Start typing..."
              className="w-full outline-none border-none py-[14px] text-[14px] pr-10 leading-[27px] text-gray-900 focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-[999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredList.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredList.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
        <ul className="mt-3">
          {selectedItems.map((item: Item) => {
            return (
              <li
                key={item.id}
                className="relative px-1 inline-flex items-center justify-center overflow-hidden bg-[rgb(175,144,255)] text-white rounded-sm m-[2px] group"
              >
                <div className="absolute flex items-center justify-center opacity-0 h-full w-full group-hover:opacity-100 bg-white/50 pointer-events-none">
                  <img src={cross} alt="x" />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    unselectItem(item.id)
                  }}
                >
                  {item.name}
                </button>
              </li>
            )
          })}
        </ul>
      </Combobox>
    </div>
  )
}

export default MultiSelect
