import { useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../../components/Breadcrumbs'
import { routes } from '../../../constants/routes'
import { deatiledFuncData } from './items'

import './styles.scss'

export const FunctionDetailedPage = () => {
  const { func } = useParams<{ func: keyof typeof deatiledFuncData }>()

  const arr = func.split('')
  arr[0] = arr[0].toUpperCase()
  const breadCrumbTitle = arr.join('')

  const { title, bgImage, phoneImage, description, icon } =
    deatiledFuncData[func]

  return (
    <section className="functionsDetailedSection">
      <div className="container">
        <div className="functionsDetailedSection--breadcrumbs">
          <Breadcrumbs
            items={[
              { title: 'Features', path: routes.FUNCTIONS },
              { title: breadCrumbTitle, path: '' },
            ]}
          />
        </div>

        <div className="functionsDetailedSection--imageBlock">
          <img
            className="functionsDetailedSection--mainImg"
            src={bgImage}
            alt="bgImage"
          />
          <img
            className="functionsDetailedSection--phoneImg"
            src={phoneImage as unknown as string}
            alt="phoneImage"
          />
        </div>

        <div className="functionsDetailedSection--descriptinBlock">
          <img src={icon} alt="icon" />

          <h2>{title}</h2>

          <div>
            {typeof description === 'function' ? description() : description}
          </div>
        </div>

        <img
          className="functionsDetailedSection--phoneImg functionsDetailedSection--phoneImg-mob"
          src={phoneImage as unknown as string}
          alt="phoneImage"
        />
      </div>
    </section>
  )
}
