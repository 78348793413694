import { Link } from 'react-router-dom'
import { routes } from '../../../constants/routes'
import './styles.scss'
import { contactMailLink } from '../../../constants/links'

export const PrivacyPolicy = () => {
  return (
    <div className="documents">
      <div className="container">
        <div className="mt-4">
          <div>
            <h2>Relagram Privacy Policy</h2>
            <p>
              At Relagram one of our main priorities is the privacy of our visitors.
              This Privacy Policy contains the types of information that is collected,
              processed and stored by Relagram and how we use it.
            </p>
            <p>
              This Privacy Policy applies only to our online activities and is valid for
              visitors to our website/platform with regards to the information that they
              share and/or collect in Relagram This policy is not applicable to any
              information collected via channels other than this website/platform.
            </p>
            <p>
              This Privacy Policy tells you how we process your personal data and
              informs you about your rights. We are aware of the significance of the
              processing of personal data for you as a data subject and so we comply
              with all relevant legal requirements. The protection of your privacy is of
              the utmost importance to us. Processing of your personal data by us is
              carried out in accordance with the GDPR and other national laws and state
              laws outside of the European Union.
            </p>
            <h2>Interpretation</h2>
            <p>
              This privacy policy uses the definitions in the General Data Protection
              Regulation (GDPR) of the European Union and its Member States. References
              to personal data within the meaning of the GDPR shall be construed as
              encompassing references to personal information and personal data within
              the meaning of the California Consumer Privacy Act (CCPA) and the
              treatment of such data, and rights and liabilities arising under the GDPR
              shall be understood to encompass the treatment, rights and liabilities
              arising under CCPA.
            </p>
            <p>
              “Personal data” means all information which relates to an identified or
              identifiable individual (“data subject”). By identifiable we mean an
              individual can be identified, in particular by reference to an identifier
              such as a name, an identification number, location data, an online
              identifier or one or more factors specific to the physical, physiological,
              genetic, mental, economic, cultural or social identity of that individual.
            </p>
            <p>
              &quot;Processing&quot; means any operation or set of operations which is
              performed upon personal data, whether or not by automatic means, such as
              collection, recording, organisation, filing, storage, adaptation or
              alteration, retrieval, consultation, use, disclosure by transmission,
              dissemination or otherwise making available, alignment or combination,
              restriction, erasure or destruction.
            </p>
            <p>
              &quot;Restriction on processing&quot; means the marking of stored personal
              data with a view to restricting their processing in the future.
            </p>
            <p>
              &quot;Profiling&quot; means any automated processing of personal data
              consisting of the use of personal data to evaluate certain personal
              aspects relating to an individual, in particular with a view to analysing
              or predicting aspects relating to the work performance, economic
              situation, health, personal preferences, interests, reliability, conduct,
              location or movement of that individual.
            </p>
            <p>
              &quot;Filing system&quot; means any structured collection of personal data
              accessible according to specific criteria, whether centralised,
              decentralised or organised along functional or geographical lines.
            </p>
            <p>
              &quot;Controller&quot; means the natural or legal person, public
              authority, agency or any other body which alone or jointly with others
              determines the purposes and means of the processing of personal data;
              where the purposes and means of such processing are determined by Union
              law or the law of the Member States, provision may be made for the
              controller or for the specific criteria for his or her designation under
              Union law or the law of the Member States.
            </p>
            <p>
              &quot;Processor&quot; means any natural or legal person, public authority,
              agency or other body which processes personal data on behalf of the
              controller.
            </p>
            <p>
              &quot;Recipient&quot; means any natural or legal person, public authority,
              agency or other body to whom personal data are disclosed, whether or not
              that person is a third party or not. However, authorities which may
              receive personal data in the course of a specific investigation carried
              out pursuant to Union law or the law of the Member States shall not be
              considered as recipients; the processing of such data by those authorities
              shall be carried out in accordance with the applicable data protection
              rules and in compliance with the purposes of the processing.
            </p>
            <p>
              “Third party” means any natural or legal person, public authority, agency
              or body other than the data subject, the controller, the processor and the
              persons who, under the direct authority of the controller or the
              processor, are authorised to process the personal data
            </p>
            <p>
              “Consent” of the data subject means any freely given, specific, informed
              and unambiguous indication of the data subject’s wishes by which he or
              she, by a statement or by a clear affirmative action, signifies agreement
              to the processing of personal data relating to him or her.
            </p>
            <p>&nbsp;</p>
            <h2>Processing operations</h2>
            <p>
              We collect and process the following personal data about you: name, DOB,
              country of your residence, contact and address information (if you send us
              your contact information), online identifiers (e.g. your IP address, phone
              number, browser type and version, mobile device model, IMEI (depends on
              used device and access method) , the operating system used, the referrer
              URL, the IP address, the file name, the access status, the amount of data
              transferred, the date and time of the server request) and social media
              identifiers and geolocation. If you contact us directly, we may receive
              additional information about you such as your name, email address,
              Relagram online ID, phone number, the contents of the message and/or
              attachments you may send us, and any other information you may choose to
              provide. When you register for an account, we may ask for your contact
              information, including items such as name, company name, address, email
              address, and telephone number.
            </p>
            <p>
              Certain third parties with whom we contract for the purposes of our
              services may ask you to submit data via our platform. These data may
              include your geolocation. Other than storing these data in an encrypted,
              secure location, Relagram does not make use of these data. In accepting
              the terms of this privacy policy, you agree that you are aware of the fact
              that we store these data, the reasons why and bases on which these data
              are processed (as set out below) and the restrictions on the processing of
              these data.
            </p>
            <h2>Processing purposes</h2>
            <p>We process your data for the following purposes:</p>
            <ul>
              <li>for the contact you have requested,</li>
              <li>for processing contracts with you,</li>
              <li>for advertising purposes</li>
              <li>for quality assurance and</li>
              <li>for our statistics.</li>
            </ul>
            <h2>Legal bases of data processing</h2>
            <p>The processing of your data takes place on the following legal bases:</p>
            <p>
              - Your consent according to Art. 6 para. 1 a) and Art. 9 para 2 (a) GDPR,
            </p>
            <p>
              - to perform a contract with you in accordance with Art. 6 para. 1 b) GDPR
            </p>
            <p>- to fulfil legal obligations under Art. 6 para. 1 c) GDPR or</p>
            <p>- because of a legitimate interest under Art. 6 para. 1 f) GDPR.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>Withdrawing your consent</h2>
            <p>
              Where we rely on your consent as the legal basis for processing your
              personal data, you may withdraw your consent at any time by contacting us
              using the contact details above. Our use of the information up to that
              point will be lawful. You may also be able to use the “Unsubscribe” option
              in any email from us.
            </p>
            <h2>California Consumer Privacy Act</h2>
            <p>
              Under the California Consumer Privacy Act (CCPA), residents of California
              have the right to opt out of the &quot;sale&quot; of their personal
              information. The meaning of “sale” under CCPA is very broad and includes
              certain types of sharing of information that may not be traditionally be
              thought of as a &quot;sale&quot;.
            </p>
            <p>
              Relagram participates in certain digital advertising networks that help
              us to deliver advertising that is tailored to your interests. This
              participation may constitute a &quot;sale&quot; of personal information
              under the CCPA. In order to opt out of this activity, please contact us at
              &nbsp;
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>{' '}
              or see our Cookies Policy for more information.
            </p>
            <p>
              Aside from the above, Relagram does not currently exchange personal
              information for money with any third party, and it does not otherwise
              share personal information in a way that would constitute a
              &quot;sale&quot; under the CCPA.
            </p>
            <p>
              However, if you would like to opt-out now of any possible future
              &quot;sale&quot; of your personal information in the event that our
              activities change, please contact us at&nbsp;
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>{' '}
              using the subject line “California Privacy Do Not Sell My Data”. Please
              provide us with your name and email address so that we can process the
              request.
            </p>
            <p>
              We do not permit children under the age of 18 to use our services. In the
              event that users under the age of 18 have nonetheless gained access to our
              services, if they are California residents and are under 18 years of age,
              they may request and obtain removal of user content they posted by
              emailing us at{' '}
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>
              . All requests must be labeled &quot;California Removal Request&quot; on
              the email subject line. All requests must provide a description of the
              User Content you want removed and information reasonably sufficient to
              permit us to locate that User Content. We do not accept California Removal
              Requests via postal mail, telephone or facsimile. We are not responsible
              for notices that are not labeled or sent properly, and we may not be able
              to respond if you do not provide adequate information.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>Legitimate interests</h2>
            <p>
              Insofar as we restrict the processing of your personal data to legitimate
              interests as defined by Art. 6 para. 1 f) GDPR, such interests include the
              improvement of our services, essential maintenance of our services,
              protection against abuse and the maintenance of our statistics.
            </p>
            <h2>Data sources</h2>
            <p>
              We receive the data from you (including about the equipment you use). If
              we do not collect the personal data directly from you, we will also inform
              you of the source of the personal data and, if applicable, whether it
              comes from publicly available sources.
            </p>
            <h2>Recipients or categories of recipients of personal data</h2>
            <p>
              When processing your data, we work together with the following service
              providers who have access to your personal data: providers of web analysis
              tools and social media platforms and third party contractors, our
              corporate affiliates and third party service providers (third parties that
              provide us with services, including but not limited to data hosting and/or
              other services that allow us to provide you with the products and services
              you request). Please note that this may include the provision of financial
              and/or transaction details from payment providers in order to process a
              transaction). Data is transferred to third countries outside the European
              Union. This takes place under contractual regulations provided for by law
              and the specific derogations set out above which are intended to ensure
              adequate protection of your data and which you can view on request.
            </p>
            <h2>Advertising Partners’ Privacy Policies</h2>
            <p>
              You should contact those Partners directly for details of their Privacy
              Policies.
            </p>
            <p>
              Third-party ad servers or ad networks uses technologies like cookies,
              JavaScript, or Web Beacons that are used in their respective
              advertisements and links that appear on Relagram, which are sent directly
              to users&apos; browser. They automatically receive your IP address when
              this occurs. These technologies are used to measure the effectiveness of
              their advertising campaigns and/or to personalize the advertising content
              that you see on websites that you visit.
            </p>
            <p>
              Please note that Relagram has no access to or control over these cookies
              that are used by third-party advertisers.
            </p>
            <h2>Advertising Guidelines</h2>
            <p>
              Please read our separate{' '}
              <u>
                <Link to={routes.ADD_GUIDE}>Advertising Guidelines</Link>
              </u>
            </p>
            <h2>Log files</h2>
            <p>
              Every time our website is accessed, usage data is transmitted by your
              internet browser and stored in log files (server log files). The data
              records stored in this process contain the following data: the domain from
              which the user accesses the app/website, date and time of access, IP
              address of the accessing device, website(s) that the user visits in the
              context of the offering, transferred data volume, browser type and
              version, operating system used, name of the internet service provider,
              message whether the access was successful. These log file data records are
              evaluated in anonymised form to improve the offering and make it more
              user-friendly, to find and eliminate errors and to control the utilisation
              of servers.
            </p>
            <h2>Google Analytics</h2>
            <p>
              We use the Google Analytics service of Google Inc. This service enables
              analysis of the use of our website and uses cookies for this purpose. For
              this purpose, the information generated by the cookie, such as your
              anonymised IP address, is transferred on our behalf to a Google Inc.
              server in the USA, where it is stored and evaluated. This ensures an
              anonymised recording of IP addresses. The anonymization of your IP address
              is usually done by shortening your IP address by Google Inc. within the
              European Union or in other signatory states of the European Economic Area
              (EEA). In exceptional cases, your IP address will be transferred to a
              Google Inc server in the USA and only there it will be anonymised. If your
              IP address is transmitted in this way, it is not merged with other data
              from Google Inc. As part of the Google Analytics advertising function,
              remarketing and reports on performance according to demographic
              characteristics and interests are used. The purpose of these procedures is
              to use the information on user behaviour to align the advertising measures
              more closely with the interests of the respective users. As part of
              remarketing, personalised advertising measures can be placed on other
              Internet sites based on the user&apos;s surfing behaviour on this website.
              If you have consented to Google linking your web and app browsing history
              to your Google Account and information from your Google Account being used
              to personalise ads, Google will use this data for cross-device
              remarketing. You may opt out of having your information collected by
              Google Analytics at any time. You may take the following steps:
            </p>
            <p>
              You can refuse to accept cookies. Please refer to Google Analytics Cookie
              Policy.
            </p>
            <p>
              It may be that some browsers continue automatically to accept cookies.
              However, you can prevent the use of cookies by adjusting your browser
              settings. If you do that, however, not all functions of the website may be
              available. You must configure the settings separately for each browser
              that you use.
            </p>
            <p>
              You can also prevent the collection and processing of this data by Google
              Inc. by downloading and installing Google’s browser add-on/
            </p>
            <h2>Children</h2>
            <p>
              We do not knowingly collect online contact information or personally
              identifiable offline contact information about children from children.
            </p>
            <p>
              We do not entice children by the prospect of a special game, prize or
              activity, to divulge any information about themselves for any reason. We
              do not permit children under the age of eighteen (18) to register with our
              site, as we do not knowingly accept or store personal data submitted by
              children younger than eighteen, nor do we respond to, promote to, market
              to, or communicate with children known to be younger than eighteen years
              old.
            </p>
            <h2>Changes to this Policy</h2>
            <p>
              Relagram reserves the right to amend this Privacy Policy at its
              discretion and at any time. When we make changes to this Policy, we will
              post the updated notice on this site and update the Policy’s effective
              date. Your continued use of our web site following the posting of changes
              constitutes your acceptance of such changes.
            </p>
            <p>
              <strong>Version 03 November 2020</strong>.
            </p></div>
        </div>
        <div className="mt-4">
          <h2>Relagram隐私政策</h2>
          <p>
            在Relagram，我们的主要优先事项之一是我们访问者的隐私。本隐私政策包含了Relagram收集、处理和存储的信息类型以及我们如何使用它。
          </p>
          <p>
            本隐私政策仅适用于我们的在线活动，并且适用于我们网站/平台的访问者所分享和/或收集的信息。本政策不适用于通过本网站/平台以外的渠道收集的任何信息。
          </p>
          <p>
            本隐私政策告诉您我们如何处理您的个人数据，并告知您的权利。我们了解作为数据主体的您对个人数据处理的重要性，因此我们遵守所有相关的法律要求。保护您的隐私对我们至关重要。我们对您的个人数据的处理符合《通用数据保护条例》（GDPR）和欧盟以外国家的国家法律和州法。
          </p>
          <h2>解释</h2>
          <p>
            本隐私政策使用欧盟通用数据保护条例（GDPR）中的定义。在GDPR意义上的个人数据的引用将被解释为涵盖加州消费者隐私法（CCPA）中的个人信息和个人数据的引用，以及这些数据的处理，以及根据GDPR所产生的权利和义务将被理解为涵盖在CCPA下的处理、权利和义务。
          </p>
          <p>
            “个人数据”指与确定的或可识别的个人（“数据主体”）相关的所有信息。可识别的意思是特指个人可以通过诸如姓名、识别号码、位置数据、在线标识符或与该个人的物理、生理、遗传、心理、经济、文化或社会身份相关的一个或多个特定因素的参照来确定。
          </p>
          <p>
            “处理”意味着对个人数据进行的任何操作或一系列操作，无论是否采用自动化手段，例如收集、记录、组织、归档、存储、适应或更改、检索、查询、使用、传输披露、传播或以其他方式提供、对齐或组合、限制、删除或销毁。
          </p>
          <p>
            “处理限制”意味着为限制将来对存储的个人数据的处理而对其进行标记。
          </p>
          <p>
            “个人数据分析”是指使用个人数据进行自动化处理，以评估与个人有关的某些个人方面，尤其是为了分析或预测与该个人的工作绩效、经济状况、健康、个人偏好、兴趣、可靠性、行为、位置或移动相关的方面。
          </p>
          <p>
            “文件系统”是指根据特定标准可访问的任何结构化个人数据集合，无论是集中式、分散式还是按功能或地理线路组织的。
          </p>
          <p>
            “控制者”是指个人或法人、公共机构、机构或其他单独或与他人共同确定个人数据处理目的和手段的任何主体；当个人数据处理的目的和手段由联盟法律或成员国法律确定时，可以规定控制者或其在联盟法律或成员国法律下指定的特定标准。
          </p>
          <p>
            “处理者”是指在控制者的名义下处理个人数据的任何自然人或法人、公共机构、机构或其他主体。
          </p>
          <p>
            “接收者”是指个人或法人、公共机构、机构或其他机构，其接收个人数据，无论该人是否是第三方。但是，根据联盟法律或成员国法律进行的特定调查过程中可能会收到个人数据的当局不应被视为接收者；这些当局对这些数据的处理应当符合适用的数据保护规定，并符合处理目的。
          </p>
          <p>
            “第三方”是指除数据主体、控制者、处理者之外的任何自然人或法人、公共机构、机构或机构，以及受控制者或处理者直接授权处理个人数据的人员。
          </p>
          <p>
            数据主体的“同意”意味着数据主体通过明确的、明确的、知情的和明确的表示他或她的意愿，无论是通过声明还是通过明确的肯定行动，表示同意与他或她有关的个人数据的处理。
          </p>
          <p>&nbsp;</p>
          <h2>处理操作</h2>
          <p>
            我们收集和处理有关您的以下个人数据：姓名、出生日期、您居住的国家、联系和地址信息（如果您发送给我们您的联系信息）、在线标识符（例如您的IP地址、电话号码、浏览器类型和版本、移动设备型号、IMEI（取决于使用的设备和访问方法）、使用的操作系统、引荐URL、IP地址、文件名、访问状态、传输的数据量、服务器请求的日期和时间）和社交媒体标识符和地理位置。如果您直接联系我们，我们可能会收到有关您的其他信息，例如您的姓名、电子邮件地址、Relagram在线ID、电话号码、消息内容和/或您可能选择提供的任何其他信息。当您注册账户时，我们可能会要求您提供联系信息，包括姓名、公司名称、地址、电子邮件地址和电话号码等项目。
          </p>
          <p>
            我们与之签订合同以提供我们服务目的的某些第三方可能会要求您通过我们的平台提交数据。这些数据可能包括您的地理位置信息。除了将这些数据存储在加密的安全位置外，Relagram不会使用这些数据。接受本隐私政策条款即表示您同意您已了解我们存储这些数据的事实、处理这些数据的原因和基础（如下所述），以及对这些数据处理的限制。
          </p>
          <h2>处理目的</h2>
          <p>我们为以下目的处理您的数据：</p>
          <ul>
            <li>用于您所请求的联系，</li>
            <li>与您签订合同，</li>
            <li>用于广告目的，</li>
            <li>用于质量保证和</li>
            <li>用于我们的统计。</li>
          </ul>
          <h2>数据处理的法律基础</h2>
          <p>您的数据处理基于以下法律基础：</p>
          <p>
            - 您的同意，根据《通用数据保护条例》（GDPR）第6条第1款第a款和第9条第2款（a）；
          </p>
          <p>
            - 根据GDPR第6条第1款第b款，与您执行合同；
          </p>
          <p>- 根据GDPR第6条第1款第c款履行法律义务或</p>
          <p>- 基于GDPR第6条第1款第f款的正当利益。</p>
          <p>&nbsp;</p>
          <h2>撤销您的同意</h2>
          <p>
            如果我们依赖您的同意作为处理您个人数据的法律基础，您可以随时通过使用上述联系方式联系我们撤销您的同意。在此之前我们对信息的使用将是合法的。您也可以在我们的任何电子邮件中使用“取消订阅”选项。
          </p>
          <h2>加州消费者隐私法</h2>
          <p>
            根据加州消费者隐私法（CCPA），加州居民有权选择退出其个人信息的“销售”。在CCPA下，“销售”的含义非常广泛，包括某些类型的信息共享，这些信息可能不被传统上认为是“销售”的。
          </p>
          <p>
            Relagram参与某些数字广告网络，帮助我们提供根据您的兴趣定制的广告。此参与可能构成根据CCPA下的个人信息的“销售”。为了选择退出此活动，请通过上述联系方式与我们联系或查看我们的Cookie政策以获取更多信息。
          </p>
          <p>
            除上述情况外，Relagram目前不会与任何第三方以金钱交换个人信息，也不以其他方式分享个人信息，这种分享方式可能构成CCPA下的“销售”。
          </p>
          <p>
            然而，如果您希望在我们的活动发生变化时立即选择退出可能的未来“销售”您个人信息的情况，请通过上述联系方式与我们联系，主题行为“加州隐私不出售我的数据”。请提供您的姓名和电子邮件地址，以便我们处理请求。
          </p>
          <p>
            我们不允许18岁以下的儿童使用我们的服务。如果18岁以下的用户未经许可获得了我们的服务，如果他们是加州居民并且未满18岁，则可以通过向我们发送电子邮件至上述联系方式要求删除他们发布的用户内容。所有请求必须在电子邮件主题行上标记“加州删除请求”。所有请求必须提供要删除的用户内容的描述以及合理充分的信息，以便我们能够找到该用户内容。我们不接受通过邮政邮件、电话或传真发送的加州删除请求。如果您的通知未经适当标记或发送，我们将不负责，如果您未提供充分的信息，我们可能无法回应。
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h2>合法利益</h2>
          <p>
            就我们将您的个人数据限制为《通用数据保护条例》第6条第1款第f款所定义的合法利益而言，此类利益包括改进我们的服务、必要维护我们的服务、防止滥用和维护我们的统计信息。
          </p>
          <h2>数据来源</h2>
          <p>
            我们从您那里收到数据（包括您使用的设备信息）。如果我们没有直接从您那里收集个人数据，我们还将告知您个人数据的来源，以及（如果适用）它是否来自公开可获取的来源。
          </p>
          <h2>个人数据的接收者或接收者类别</h2>
          <p>
            在处理您的数据时，我们与以下服务提供商合作，他们可以访问您的个人数据：网站分析工具和社交媒体平台的提供者以及第三方承包商、我们的企业关联公司和第三方服务提供商（为我们提供服务的第三方，包括但不限于数据托管和/或其他允许我们为您提供您请求的产品和服务的服务）。请注意，这可能包括从付款提供者那里提供财务和/或交易详细信息以处理交易）。数据将转移到欧洲联盟以外的第三国。这是根据法律规定的合同规定和上述特定的特权进行的，旨在确保您数据的充分保护，并在您的要求下可以查看。
          </p>
          <h2>广告合作伙伴的隐私政策</h2>
          <p>
            您应直接联系这些合作伙伴以了解其隐私政策的详细信息。
          </p>
          <p>
            第三方广告服务器或广告网络使用诸如Cookie、JavaScript或Web Beacon之类的技术，这些技术用于其各自的广告和链接，这些广告和链接直接发送到用户的浏览器。当这种情况发生时，他们会自动接收您的IP地址。这些技术用于衡量其广告活动的有效性和/或个性化您在访问的网站上看到的广告内容。
          </p>
          <p>
            请注意，Relagram无法访问或控制第三方广告商使用的这些Cookie。
          </p>
          <h2>广告指南</h2>
          <p>
            请阅读我们单独的
            <u>
              <Link to={routes.ADD_GUIDE}>广告指南</Link>
            </u>
          </p>
          <h2>日志文件</h2>
          <p>
            每当我们的网站被访问时，您的互联网浏览器会传输使用数据并存储在日志文件（服务器日志文件）中。在此过程中存储的数据记录包含以下数据：用户访问应用程序/网站的域名、访问的日期和时间、访问设备的IP地址、用户在提供范围内访问的网站、传输的数据量、浏览器类型和版本、使用的操作系统、互联网服务提供商的名称、访问是否成功的消息。这些日志文件数据记录以匿名形式进行评估，以改进和使其更加用户友好的提供服务，找出并消除错误并控制服务器的使用情况。
          </p>
          <h2>Google Analytics</h2>
          <p>
            我们使用Google Inc.的Google Analytics服务。此服务使我们能够分析我们网站的使用情况，并使用Cookie进行此目的。为此，由Cookie生成的信息（如您的匿名IP地址）将在我们的代表转移到美国的Google Inc.服务器上，存储和评估。这确保了IP地址的匿名记录。通常情况下，Google Inc.会在欧洲联盟或其他欧洲经济区（EEA）的签署国内缩短您的IP地址来对其进行匿名化。在个别情况下，您的IP地址将被转移到美国的Google Inc.服务器，并且只在那里将被匿名化。如果以这种方式传输您的IP地址，则不会与Google Inc.的其他数据合并。作为Google Analytics广告功能的一部分，使用重定向营销和根据人口特征和兴趣的性能报告。这些程序的目的是利用用户行为信息更精确地调整广告措施，以与相应用户的兴趣更紧密地对齐。作为重定向的一部分，可以根据用户在本网站上的浏览行为在其他互联网网站上放置个性化的广告。如果您不同意您在将来访问的任何网站上看到此类广告，您可以随时通过以下链接https://www.google.com/settings/ads/plugin在您的浏览器中禁用Cookie。您也可以通过http://www.aboutads.info/choices/上的关于网络广告倡议网站中的说明选择禁用Cookie。
          </p>
          <p>
            请注意，如果您清除浏览器的Cookie，则可能会删除以设置Cookie的拒绝Cookie的设置。在这种情况下，您需要重新设置这些Cookie。
          </p>
          <p>
            有关Google Inc.使用的Cookie的详细信息，请查看
            <u>
              <Link to={routes.GOOGLE_ANALYTICS}>Google Analytics的隐私政策</Link>
            </u>
            。
          </p>
          <p>
            有关如何处理用户数据的更多信息，请查看
            <u>
              <Link to={routes.GOOGLE_ANALYTICS_TERMS}>Google Analytics的服务条款</Link>
            </u>
            。
          </p>
          <h2>隐私权政策更新</h2>
          <p>
            我们保留在必要时更新我们的隐私政策的权利。因此，我们鼓励您定期查看此页面以了解任何变更。我们会通过在此页面上发布新的隐私政策来通知您任何更改。这些变更在发布后立即生效，除非另有规定。
          </p>
          <h2>联系我们</h2>
          <p>
            如果您对我们的隐私政策有任何疑问或建议，或者您想行使您的数据主体权利，请通过以下联系方式与我们联系：<u>contact@Relagram.com</u>或通过使用
            <u>
              <Link to={routes.CONTACT_FORM}>联系表格</Link>
            </u>
          </p>
          <p>&nbsp;</p>
          <p>
            最后更新日期：2023年1月1日
          </p>
        </div>
      </div>
    </div>
  )
}
