import { type } from "os"
import { Fields } from "../CreatePromo"

export type PeopleTarget = { ageFrom: number; ageTo: number; gender: 'MALE' | 'FEMALE' }

function showTo(fields: Fields) {
  return Object.keys(fields).reduce((acc, currentKey) => {
    if (currentKey.includes('Age') && fields[currentKey as keyof typeof fields]) {
      if (currentKey.includes('AgeOver61')) {
        acc.push({
          ageFrom: 61,
          ageTo: 100,
          gender: currentKey[0] === 'm' ? 'MALE' : 'FEMALE',
        })
      } else if (currentKey.includes('All')) {
        acc.push({
          ageFrom: 0,
          ageTo: 100,
          gender: currentKey[0] === 'm' ? 'MALE' : 'FEMALE',
        })
      } else {
        const values = currentKey.split('Age').pop()?.split('_') || [0, 0]

        acc.push({
          ageFrom: +values[0],
          ageTo: +values[1],
          gender: currentKey[0] === 'm' ? 'MALE' : 'FEMALE',
        })
      }
    }

    return acc
  }, [] as PeopleTarget[])
}

export default showTo
