import { FC } from 'react'

import { useModalContext } from '../contexts/ModalContext'
import okIcon from '../assets/svg/successPopupIcon.svg'
import errorIcon from '../assets/svg/errorPopupIcon.svg'
import closeIcon from '../assets/svg/cross.svg'

const Popup: FC = () => {
  const { popupConfig, closePopup } = useModalContext()
  const handleClosePopup = () => {
    popupConfig?.onClose?.()
    closePopup()
  }

  return popupConfig ? (
    <div className="fixed inset-0 flex items-center justify-center late-800 z-[999]">
      <div
        data-popup
        className="relative flex flex-col items-center text-center w-[100%] max-w-[386px] bg-white py-[30px] px-[20px] shadow-lg shadow-[#AF90FF3D] rounded-[16px]"
      >
        <div className="shrink-0">
          {popupConfig.type === 'error' && <img src={errorIcon} />}

          {popupConfig.type === 'ok' && <img src={okIcon} />}
        </div>

        {popupConfig.type === 'warning' && (
          <h2 className="text-yellow-500 mt-[10px]">{'Warning'}</h2>
        )}

        {popupConfig.type === 'error' && (
          <h2 className="text-red-700 mt-[10px]">{'Error'}</h2>
        )}

        <p className="mt-[30px] text-[13px]">{popupConfig.message}</p>

        {popupConfig.reason && (
          <p className="text-[9px] mt-[10px] text-slate-400">
            {popupConfig.reason}
          </p>
        )}

        <button
          onClick={handleClosePopup}
          type="button"
          className="
            absolute right-0 top-0 flex justify-center 
            shrink-0 items-center w-[40px] h-[40px] rounded-full
          "
        >
          <img src={closeIcon} />
        </button>
      </div>
    </div>
  ) : null
}

export default Popup
