async function isRightImageType(file: File) {
  return new Promise((res) => {
    const el = document.createElement('img')

    el.src = URL.createObjectURL(file)

    el.onload = () => {
      if (
        el.width === 512 &&
        el.height === 512 &&
        (file.type === 'image/webp' || file.type === 'image/png') && file.size < 3500000
        
      )
        return res(true)

      return res(false)
    }
  })
}

export default isRightImageType
