import React from 'react'
import { Link } from 'react-router-dom'

import { documentsRoutes } from '../../../constants/documentsRoutes'

import './styles.scss'

export const DocumentsPage = () => (
  <div className="documents-page">
    <h1 className="documents-page__header">Legal agreements and documents</h1>
    <ul>
      {documentsRoutes.map((route) => (
        <li className="documents-page__list-item" key={`footer${route.route}`}>
          <Link to={route.route}>{route.text}</Link>
        </li>
      ))}
    </ul>
  </div>
)
