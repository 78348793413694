import icon_dating from '../../../assets/svg/icon_dating.svg'
import icon_events from '../../../assets/svg/icon_events.svg'
import icon_chennels from '../../../assets/svg/icon_chennels.svg'
import icon_chats from '../../../assets/svg/icon_chats.svg'
import icon_business from '../../../assets/svg/icon_business.svg'
import icon_crypto from '../../../assets/svg/icon_crypto.svg'
import icon_fix from '../../../assets/svg/icon_fix.svg'
import icon_jobs from '../../../assets/svg/icon_jobs.svg'
import icon_maps from '../../../assets/svg/icon_maps.svg'
import icon_market from '../../../assets/svg/icon_market.svg'
import icon_metafek from '../../../assets/svg/icon_metafek.svg'
import icon_pets from '../../../assets/svg/icon_pets.svg'
import icon_places from '../../../assets/svg/icon_places.svg'
import icon_privacy from '../../../assets/svg/icon_privacy.svg'
import icon_protection from '../../../assets/svg/icon_protection.svg'
import icon_ref from '../../../assets/svg/icon_ref.svg'
import icon_stickers from '../../../assets/svg/icon_stickers.svg'
import icon_video from '../../../assets/svg/icon_video.svg'
import icon_avatar from '../../../assets/svg/icon_avatar.svg'
import icon_heart from '../../../assets/svg/icon_heart.svg'
import { routes } from '../../../constants/routes'

const path = (routes.FUNCTIONS_DETAILED.split(':').shift()) || 'no-func'

export const functions = [
  {
    icon: icon_dating,
    title: 'Dating',
    subtitle: 'Free dating service around the world',
    isHidden: false,
    link: `${path}dating`
  },
  {
    icon: icon_events,
    title: 'Events',
    subtitle: 'Create your own event or join an existing one',
    isHidden: false,
    link: `${path}events`
  },
  {
    icon: icon_chennels,
    title: 'Channels',
    subtitle: 'Become an influencer or follow channels that interest you',
    isHidden: false,
    link: `${path}chennels`
  },
  {
    icon: icon_chats,
    title: 'Chats',
    subtitle: 'Personal and group chats with individual privacy and appearance',
    isHidden: false,
    link: `${path}chats`
  },
  {
    icon: icon_business,
    title: 'Business Profile',
    subtitle:
      'Free tools to promote your business and communicate with customers',
    isHidden: false,
    link: `${path}business`
  },
  {
    icon: icon_jobs,
    title: 'Jobs',
    subtitle:
      'Offering and searching for a job with the possibility of using the geolocation function',
    isHidden: false,
    link: `${path}jobs`
  },
  {
    icon: icon_market,
    title: 'Market',
    subtitle: 'Buying, selling, renting. Goods, transport, real estate',
    isHidden: false,
    link: `${path}market`
  },
  {
    icon: icon_pets,
    title: 'Pets',
    subtitle:
      'Create a profile for your pet and communicate with other pet owners',
    isHidden: false,
    link: `${path}pets`
  },
  {
    icon: icon_places,
    title: 'Places',
    subtitle:
      'Travel tool. Share and discuss locations and photos of  beautiful places in the world',
    isHidden: false,
    link: `${path}places`
  },
  {
    icon: icon_fix,
    title: 'Fix',
    subtitle: 'Finding a local helper or adviser in fixing something',
    isHidden: true,
    link: `${path}fix`
  },
]

export const additionFuntions = [
  {
    icon: icon_maps,
    title: 'Maps and Geolocation.',
    subtitle:
      'Ability to customize the display of your location for each group separately',
    isHidden: false,
    link: `${path}maps`
  },
  {
    icon: icon_crypto,
    title: 'Crypto payments',
    subtitle:
      'Ability to pay for advanced services and advertising with cryptocurrency',
    isHidden: false,
    link: `${path}crypto`
  },
  {
    icon: icon_protection,
    title: 'Personal data protection',
    subtitle: 'We do not sell personal data of our users',
    isHidden: false,
    link: `${path}protection`
  },
  {
    icon: icon_metafek,
    title: 'MetaFek Coin (MFC)',
    subtitle: 'Using our own internal currency within the ecosystem',
    isHidden: false,
    link: `${path}metafek`
  },
  {
    icon: icon_ref,
    title: 'Referral system and rewards',
    subtitle:
      'Earn Coins for invited members, by mentioning us in other networks, watching advertisement, et',
    isHidden: false,
    link: `${path}ref`
  },
  {
    icon: icon_privacy,
    title: 'Privacy of the communication',
    subtitle:
      'Optional ability to communicate without saving correspondence on the server',
    isHidden: false,
    link: `${path}privacy`
  },
  {
    icon: icon_stickers,
    title: 'Custom Stickers',
    subtitle:
      'Ability to upload your own stickers. Let the world appreciate your talent',
    isHidden: false,
    link: `${path}stickers`
  },
  {
    icon: icon_avatar,
    title: 'Avatar Ticker/Reel',
    subtitle:
      'Using a ticker as a way of advertising to draw attention to yourself or any of your ads',
    isHidden: false,
    link: `${path}avatar`
  },
  {
    icon: icon_video,
    title: 'Video advertising',
    subtitle:
      'The ability to use your own videos to promote yourself and your products',
    isHidden: false,
    link: `${path}video`
  },
  {
    icon: icon_heart,
    title: 'Progressive subscription system',
    subtitle:
      'There are various advanced options not included in the regular free version.',
    isHidden: false,
    link: `${path}subscription`
  },
]
