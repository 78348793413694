import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { routes } from '../../constants/routes'
import './styles.scss'
import { LoginPage } from '../../pages/LoginPage'
import { ProfileRoutes } from '../ProfileRoutes'
import { LandingRoutes } from '../LandingRoutes'
import { thunkGetProfile } from '../../store/thunks/thunkGetProfile'
import { thunkGetUserAccount } from '../../store/thunks/thunkGetUserAccount'
import { authTokenStoreService } from '../../services/authTokenStoreService'
import { AuthProtect } from '../../components/Protect'
import { ResetPasswordPage } from '../../pages/ResetPasswordPage'
import { VideoPresentation } from '../../pages/VideoPresentation'
import Popup from '../../components/Popup'
import Modal from '../../components/Modal'

export const AppRouter = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { redirect } = useSelector((state) => state.commonUiReducer)
  const { pathname } = useLocation()
  const isProfileRoute = pathname.includes(routes.PROFILE.PROFILE)
  const isLoginRoute = pathname.includes(routes.LOGIN)
  const isLanding = !isProfileRoute
  const isLogged = authTokenStoreService.isLogged()

  useEffect(() => {
    if (isLogged) {
      dispatch(thunkGetProfile())
      dispatch(thunkGetUserAccount())
    }
  }, [])

  useEffect(() => {
    if (redirect.url) {
      history.push(redirect.url)
      if (redirect.force) {
        history.go(0)
      }
    }
  }, [redirect])

  return (
    <>
      <Header />
      <Popup />
      <Modal />
      <div className={`${isLanding ? '' : 'profile-container'}`}>
        <LandingRoutes />
        <Route path={routes.LOGIN}>
          <LoginPage />
        </Route>
        {/* <Route path={routes.VIDEO_PRESENTATION}>
          <VideoPresentation />
        </Route> */}
        <Route path={routes.RESET_PASSWORD}>
          <ResetPasswordPage />
        </Route>

        <Route path={routes.PROFILE.PROFILE}>
          <AuthProtect>
            <ProfileRoutes />
          </AuthProtect>
        </Route>

        <Switch>
          <Redirect path="/" exact to={routes.PRODUCT} />
        </Switch>
      </div>
      {isLanding && <Footer />}
    </>
  )
}
