import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { routes } from '../../constants/routes'
import { MainPage } from '../../pages/Product/MainPage'
import { FunctionsPage } from '../../pages/Product/FunctionsPage'
import { CurrencyPage } from '../../pages/Product/CurrencyPage'
import { BlogPage } from '../../pages/Product/BlogPage'
import { SecurePage } from '../../pages/Product/SecurePage'
import {
  AdvertisingGuidelines,
  CopyrightPolicy,
  PrivacyPolicy,
  TermsOfUse,
  VirtualItemsPolicy,
} from '../../components/documents'
import { DocumentsPage } from '../../pages/Product/DocumentsPage'
import { FaqPage } from '../../pages/Product/FaqPage'
import { AdMediaExamples } from '../../pages/Product/AdMediaExamples'
import { SourcesForAd } from '../../pages/Product/SourcesForAd'
import { UnsubscribePage } from '../../pages/Product/UnsubscribePage'
import { ReferralPage } from '../../pages/Product/ReferralPage'
import { AboutUs } from '../../components/documents/AboutUs'
import DonatePage from '../../pages/Product/DonatePage'
import { FunctionDetailedPage } from '../../pages/Product/FunctionDetailedPage'
import BlogItem from '../../pages/BlogItem/BlogItem'
import GetApp from '../../pages/GetApp/GetApp'

export const LandingRoutes = () => (
  <Switch>
    <Route path={routes.PRODUCT} component={MainPage} />
    <Route path={routes.FUNCTIONS} component={FunctionsPage} />
    <Route path={routes.FUNCTIONS_DETAILED} component={FunctionDetailedPage} />
    <Route path={routes.CURRENCY} component={CurrencyPage} />
    <Route path={routes.BLOG} component={BlogPage} />
    <Route path={routes.SECURE} component={SecurePage} />
    <Route path={routes.COPYRIGHT} component={CopyrightPolicy} />
    <Route path={routes.ADD_GUIDE} component={AdvertisingGuidelines} />
    <Route path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
    <Route path={routes.TERM_OF_USE} component={TermsOfUse} />
    <Route path={routes.ABOUT_US} component={AboutUs} />
    <Route path={routes.VIRTUAL_ITEMS_POLICY} component={VirtualItemsPolicy} />
    <Route path={routes.DOCUMENTS} component={DocumentsPage} />
    <Route path={routes.FAQ} component={FaqPage} />
    <Route path={routes.AD_MEDIA} component={AdMediaExamples} />
    <Route path={routes.SOURCES_FOR_AD} component={SourcesForAd} />
    <Route path={routes.UNSUBSCRIBE_PAGE} exact component={UnsubscribePage} />
    <Route path={routes.REFERENCE} component={ReferralPage} />
    <Route path={routes.SHARE} component={ReferralPage} />
    <Route path={routes.DONATE} component={DonatePage} />
    <Route path={routes.GET_APP} component={GetApp} />

    <Route path={routes.BLOG_ITEM} component={BlogItem} />
  </Switch>
)
