import React from 'react'

import { Button, TextInput } from '../../components/uiKit'

import ErrorMessage from './ErrorMessage'
import CodeStrengthIcon from './CodeStrengthIcon'

const FormPassword = ({ state, handleChangeValues }) => {
  const { password, repeatedPassword, loading, error, code } = state

  const isPasswordFilled =
    password !== undefined && password !== '' && password.length > 7

  const isRepeatedPasswordCorrect =
    isPasswordFilled && repeatedPassword === password

  const isSendButtonAvailable =
    isPasswordFilled && isRepeatedPasswordCorrect && code

  return (
    <>
      <div className="reset-form__item">
        <TextInput
          autoComplete="password"
          name="password"
          type="password"
          label="password"
          id="password"
          bordered={false}
          value={password}
          onChange={handleChangeValues}
          suffix={<CodeStrengthIcon password={password} />}
        />
      </div>
      <div className="reset-form__item">
        <TextInput
          name="repeatedPassword"
          type="password"
          label="repeat password"
          id="repeated-password"
          bordered={false}
          value={repeatedPassword}
          onChange={handleChangeValues}
          suffix={
            isRepeatedPasswordCorrect ? (
              <span className="success-text">ok</span>
            ) : null
          }
        />
      </div>
      <div className="reset-form__item">
        <TextInput
          name="code"
          type="text"
          label="confirmation code"
          id="code"
          bordered={false}
          value={code}
          onChange={handleChangeValues}
        />
      </div>
      {error && <ErrorMessage error={error} />}
      <div className="reset-form__item">
        <Button
          style={{ width: '100%' }}
          type="submit"
          loading={loading}
          disabled={!isSendButtonAvailable}
        >
          CHANGE PASSWORD
        </Button>
      </div>
    </>
  )
}

export default FormPassword
