const interests = [
  { name: 'All' },
  { name: 'All motors' },
  { name: 'Animals' },
  { name: 'Auction/Yard sale' },
  { name: 'Books and reading' },
  { name: 'Conference/Debates' },
  { name: 'Corporate off-sites & executive m...' },
  { name: 'Couchsurfing' },
  { name: 'Dance, ball or disco' },
  { name: 'Education' },
  { name: 'Festival' },
  { name: 'Flashmob' },
  { name: 'Food and Drink' },
  { name: 'For kids' },
  { name: 'Gaming' },
  { name: 'High Tech' },
  { name: 'Historical and cultural show' },
  { name: 'Hobby' },
  { name: 'Interview or fireside chat' },
  { name: 'Movie/Spectacular/Show' },
  { name: 'Music' },
  { name: 'Networking' },
  { name: 'Party' },
  { name: 'Performance art' },
  { name: 'Politics' },
  { name: 'Presentation' },
  { name: 'Product launch' },
  { name: 'Race and Rally' },
  { name: 'Religious' },
  { name: 'Seminar/Webinar' },
  { name: 'Sport' },
  { name: 'Standup or comedy show' },
  { name: 'Strike' },
  { name: 'Mll' },
  { name: 'Trade Show/Exhibition' },
  { name: 'Vip or invite-only' },
  { name: 'Workshop' },
]

export default interests.map((item, id) => ({ id, name: item.name }))
