import visa from '../assets/svg/icon_payment_method_visa.svg'
import amex from '../assets/svg/icon_payment_method_amex.svg'
import etherium from '../assets/svg/icon_payment_method_etherium.svg'
import unionpay from '../assets/svg/icon_payment_method_unionpay.svg'
import usdtcoin from '../assets/svg/icon_payment_method_usdtcoin.svg'
import discover from '../assets/svg/icon_payment_method_discover.svg'
import jcb from '../assets/svg/icon_payment_method_jcb.svg'
import lightcoin from '../assets/svg/icon_payment_method_lightcoin.svg'
import xrpcoin from '../assets/svg/icon_payment_method_xrpcoin.svg'
import cartesbancaires from '../assets/svg/icon_payment_method_cartesbancaires.svg'
import binance from '../assets/svg/icon_payment_method_binance.svg'
import bitcoin from '../assets/svg/icon_payment_method_bitcoin.svg'
import maestro from '../assets/svg/icon_payment_method_maestro.svg'
import mastercard from '../assets/svg/icon_payment_method_mastercard.svg'
import applePay from '../assets/svg/icon_payment_method_applepay.svg'
import googlePay from '../assets/svg/icon_payment_method_googlepay.svg'

export const wallets = [
  {
    name: 'USDT address Tether (ERC-20)',
    address: '0x7c7399FE85eD28f364B640e4d8E05a71bC723fbC',
    icon: usdtcoin,
  },
  {
    name: 'BTC address',
    address: '39UsGbgxrA2KZAxJydHL8PmAUAxrsUABGj',
    icon: bitcoin,
  },
  {
    name: 'ETH address',
    address: '0x8503fdc31bb8C62424f1F2E6C7b62B7170D13bcC',
    icon: etherium,
  },
  {
    name: 'XRP address',
    address: 'rw2ciyaNshpHe7bCHo4bRWq6pqqynnWKQg',
    icon: xrpcoin,
  },
  {
    name: 'LTC address',
    address: 'MRzJCWAFEAaZuUNwFdPKdYs9xKvjhGrmVf',
    icon: lightcoin,
  },
  {
    name: 'BNB address',
    address: '0x38c274bba925332355Cf1D23eE12f58E09a8A53A',
    icon: binance,
  },
]

export const paySystems = [
  {
    type: 'Card visa',
    icon: visa,
  },
  {
    type: 'Card maestro',
    icon: maestro,
  },
  {
    type: 'Card mastercard',
    icon: mastercard,
  },
  {
    type: 'Apple Pay',
    icon: applePay,
  },
  {
    type: 'Google Pay',
    icon: googlePay,
  },
]
