import React from 'react'

import './styles.scss'
import { wallets } from '../../../constants/paymentSystems'

import Wallet from './Wallet'
import CardPayments from './CardPayments'

const DonatePage = () => {
  return (
    <div className="donate-page">
      <div className="donate-page__wrapper">
        <div className="donate-page__header">
          <h3>Donate</h3>
        </div>
        <CardPayments />
        <div className="donate-page__payment-divider">
          <hr />
          <p>Or Cryptocurrency</p>
        </div>
        <ul className="donate-page__wallets-container">
          {wallets.map((wallet) => (
            <Wallet key={wallet.name} wallet={wallet} />
          ))}
        </ul>
        <div className="donate-page__footer">
          <span>
            {`Want to send other type of Cryptocurrency ? Send us your request to `}
            <a href="mailto:support@relagram.app">support@relagram.app</a> to get
            wallet address you need. Thank you for your help.
          </span>
        </div>
      </div>
    </div>
  )
}

export default DonatePage
