import { FC } from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../../constants/routes'
import logo from '../../../assets/svg/logo_yap_map_web.svg'
import whiteLogo from '../../../assets/svg/white_logo.svg'

// import './styles.scss'

interface LogoLinkProps {
  isWhiteLogo?: boolean
}

const LogoLink: FC<LogoLinkProps> = ({ isWhiteLogo }) => {
  return (
    <Link to={routes.PRODUCT}>
      {isWhiteLogo ? (
        <img src={whiteLogo} alt="logo" />
      ) : (
        <img src={logo} alt="logo" />
      )}
    </Link>
  )
}

export default LogoLink
