import React from 'react'

const NotificationCode = ({ email, onResendCode }) => (
  <div className="reset-form__item">
    <span className="reset-form__hint">
      {`Code has been sent to ${email}. `}
    </span>
    <button
      type="button"
      className="reset-form__repeat-button"
      onClick={onResendCode}
    >
      Send code again
    </button>
  </div>
)

export default NotificationCode
