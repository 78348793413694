const strongPassRegEx =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
const mediumPassRegEx =
  /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/

export const minifyName = (name) => (name?.length ? `${name[0]}.` : '')

export const separatedSum = (amount, separator) =>
  amount.replace(/\B(?=(\d{3})+(?!\d))/g, separator)

export const passwordStrengthValidator = (password) => {
  const strong = strongPassRegEx.test(password)
  const medium = !strong && mediumPassRegEx.test(password)
  const weak = !strong && !medium

  return {
    strong,
    medium,
    weak,
  }
}
