import { FC } from 'react'

import { AddStickerIcon } from '../icons'

import './styles.scss'

interface AddStickerInputProps {
  handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const AddStickerInput: FC<AddStickerInputProps> = ({
  handleFileSelect,
}) => {
  return (
    <label htmlFor="addSticker" className="adding-stickers-form__add-btn">
      <AddStickerIcon />
      <input
        type="file"
        name="add"
        hidden
        id="addSticker"
        onChange={handleFileSelect}
      />
    </label>
  )
}
