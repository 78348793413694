import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from '../../constants/routes'
import { AccountPage } from '../../pages/Profile/AccountPage'
import { PaymentPage } from '../../pages/Profile/PaymentPage'
import { ProfileMenu } from '../../pages/Profile/components/ProfileMenu'
import { Stickers } from '../../pages/Stickers'
import { AddStickers } from '../../pages/AddStickers'
import { AddAnimatedStickers } from '../../pages/AddAnimatedStickers'
import './styles.scss'
import { Ads } from '../../pages/Ads'
import { Adding } from '../../pages/Ads/Adding'
import AdsContext from '../../pages/Ads/AdsContexts'
import CreatePromo from '../../pages/Ads/CreatePromo'
import { tw } from '@twind/core'
import Reports from '../../pages/Reports'
import { StickerEditor } from '../../components/StickerEditor'
import DeleteAccount from '../../pages/DeleteAccount'

export const ProfileRoutes = () => {
  return (
    <div className="profile-page">
      <div className="profile-page__menu-container z-[9999]">
        <ProfileMenu />
      </div>
      <div className={tw('profile-page__nested-pages max-md:w-full')}>
        <Switch>
          <Route path={routes.PROFILE.ACCOUNT} component={AccountPage} />
          <Route path={routes.PROFILE.PAYMENT} component={PaymentPage} />
          <Route exact path={routes.PROFILE.STICKERS} component={Stickers} />
          <Route
            path={`${routes.PROFILE.ANIMATED_STICKERS}`}
            component={AddAnimatedStickers}
          />
          <Route
            path={`${routes.PROFILE.GRAPHIC_STICKERS}`}
            component={AddStickers}
          />
          <Route
            path={`${routes.PROFILE.GRAPHIC_STICKERS_EDITOR}`}
            component={StickerEditor}
          />
          <Route
            path={`${routes.PROFILE.DELETE_ACCOUNT}`}
            component={DeleteAccount}
          />
          <AdsContext>
            <Route exact path={routes.PROFILE.ADVERTISEMENT} component={Adding} />
            <Route exact path={routes.PROFILE.ADD} component={Ads} />
            <Route exact path={routes.PROFILE.REPORTS} component={Reports} />
            <Route exact path={`${routes.PROFILE.CREATE_PROMO}`} component={CreatePromo} />
          </AdsContext>
          <Redirect from={routes.PROFILE.PROFILE} to={routes.PROFILE.ACCOUNT} />
        </Switch>
      </div>
    </div>
  )
}
