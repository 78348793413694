import { getUserProfile } from '../../services/api/accountService'
import { actionSetUserProfile } from '../actions/commonNetActions'

export const thunkGetProfile = () => async (dispatch) => {
  try {
    const profile = await getUserProfile()
    dispatch(actionSetUserProfile(profile))
  } catch (err) {
    console.log('thunk error', err)
  }
}
