/* eslint-disable */
import React from 'react'

import './styles.scss'
import { Loader } from '../Loader'

export const Button = ({
  children,
  onClick,
  style,
  type,
  disabled,
  loading,
  className
}) => {
  return (
    <button
      onClick={onClick}
      type={type || 'button'}
      className={`ui-kit-button ${className ?? ''}`}
      style={style}
      disabled={disabled || loading}
    >
      {loading ? <Loader /> : children}
    </button>
  )
}
