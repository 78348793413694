import { FC, memo, useEffect, useMemo, useState } from 'react'
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import { routes } from '../../constants/routes'
import { useAdsContext } from './AdsContexts'
import { useHistory, useParams } from 'react-router-dom'
import { MultiSelect, Checkbox, SingleSelect } from '../../components/ui'

import ad1 from '../../assets/img/ad/1.jpg'
import ad2 from '../../assets/img/ad/2.jpg'
import ad3 from '../../assets/img/ad/3.jpg'
import ad4 from '../../assets/img/ad/4.jpg'
import ad5 from '../../assets/img/ad/5.jpg'
import { tw } from '@twind/core'
import countries from './allCountries'
import { createCampaign } from '../../services/api/addsService'
import { functions } from '../Product/FunctionsPage/items'
import { useModalContext } from '../../contexts/ModalContext'
import allBusinessItems from './allBusinessItems'
import interests from './interests'
import showTo from './heplers.ts/showTo'
import getFileUid from './heplers.ts/getFileUid'
import payWithOptions from './payWhithOptions'

export type Fields = {
  reuseEventButton: boolean
  renewBussinessButton: boolean
  renewYapMarketAdButton: boolean
  tickerBubble: boolean
  chatPlaceholder: boolean
  mAge18_30: boolean
  mAge31_40: boolean
  mAge41_50: boolean
  mAge51_60: boolean
  mAgeOver61: boolean
  mAgeAll: boolean
  fAge18_30: boolean
  fAge31_40: boolean
  fAge41_50: boolean
  fAge51_60: boolean
  fAgeOver61: boolean
  fAgeAll: boolean
  country: number[]
  showToBusinessOwnersOnly: boolean
  business: number[]
  timesToShow: string
  views: string
  payWith: number
  customizeInterests: boolean
  interest: number[]
  redirectLink: string
}

const fAgesSet = new Set([
  'fAge18_30',
  'fAge31_40',
  'fAge41_50',
  'fAge51_60',
  'fAgeOver61',
])
const mAgesSet = new Set([
  'mAge18_30',
  'mAge31_40',
  'mAge41_50',
  'mAge51_60',
  'mAgeOver61',
])

const CreatePromo: FC = () => {
  const { allAds } = useAdsContext()
  const { openModal, openPopup, closePopup } = useModalContext()
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()

  const [fields, setFields] = useState<Fields>({
    reuseEventButton: false,
    renewBussinessButton: false,
    renewYapMarketAdButton: false,
    tickerBubble: false,
    chatPlaceholder: false,
    mAge18_30: true,
    mAge31_40: false,
    mAge41_50: false,
    mAge51_60: false,
    mAgeOver61: false,
    mAgeAll: false,
    fAge18_30: true,
    fAge31_40: false,
    fAge41_50: false,
    fAge51_60: false,
    fAgeOver61: false,
    fAgeAll: false,
    country: [243],
    showToBusinessOwnersOnly: true,
    business: [0],
    timesToShow: '',
    views: '1',
    payWith: 0,
    customizeInterests: true,
    interest: [0],
    redirectLink: '',
  })

  const handleInputChange = (e: any) => {
    e.persist()

    let v = e.target.value

    setFields((s) => ({
      ...s,
      [e.target.name]: v < 0 ? 0 : v,
    }))
  }

  const handleChange = (event: unknown) => {
    const e = event as { name: string; [x: string]: unknown }

    if (fAgesSet.has(e.name)) {
      setFields((s) => ({
        ...s,
        fAgeAll: false,
      }))
    }

    if (mAgesSet.has(e.name)) {
      setFields((s) => ({
        ...s,
        mAgeAll: false,
      }))
    }

    if (e.name === 'fAgeAll') {
      setFields((s) => ({
        ...s,
        fAge18_30: true,
        fAge31_40: true,
        fAge41_50: true,
        fAge51_60: true,
        fAgeOver61: true,
        fAgeAll: true,
      }))

      return
    }

    if (e.name === 'mAgeAll') {
      setFields((s) => ({
        ...s,
        mAge18_30: true,
        mAge31_40: true,
        mAge41_50: true,
        mAge51_60: true,
        mAgeOver61: true,
        mAgeAll: true,
      }))
      return
    }

    if (e.name === 'business') {
      setFields((s) => ({
        ...s,
        business: (e.value as typeof allBusinessItems).map((int) => int.id),
      }))
      return
    }

    if (e.name === 'interest') {
      console.log('🚀 ~ e:', e)
      setFields((s) => ({
        ...s,
        interest: (e.value as typeof interests).map((int) => int.id),
      }))
      return
    }

    if (e.name === 'payWith') {
      setFields((s) => ({
        ...s,
        payWith: (e.value as (typeof payWithOptions)[number]).id,
      }))
      return
    }

    if (e.name === 'country') {
      setFields((s) => ({
        ...s,
        country: (e.value as typeof countries).map((int) => int.id),
      }))

      return
    }

    if (e.name === 'dontCustomizeInterests') {
      setFields((s) => {
        return {
          ...s,
          customizeInterests: !e.checked,
        }
      })
      return
    }

    if (e.name === 'dontShowToBusinessOwnersOnly') {
      setFields((s) => {
        return {
          ...s,
          showToBusinessOwnersOnly: !e.checked,
        }
      })
      return
    }

    setFields((s) => {
      return {
        ...s,
        [e.name]: e.checked,
      }
    })
  }

  const bc = useMemo<BreadcrumbItem[]>(
    () => [
      { title: 'Advertising campaign', path: routes.PROFILE.ADVERTISEMENT },
      { title: 'Create promo campaign' },
    ],
    [],
  )

  const promo = useMemo(() => allAds.find((ad) => ad.id === id), [])

  useEffect(() => {
    if (!promo?.url) push(routes.PROFILE.ADD)
  }, [promo])

  const handleCreateCampaign = () => {
    if (!+fields.timesToShow) {
      openPopup({
        type: 'warning',
        message: '`Amount of views I wish to purchase` should be filled and be more then 0',
      })

      return
    }
    
    if (!+fields.views) {
      openPopup({
        type: 'warning',
        message: '`Maximum amount of views for same person` should be more then 0',
      })

      return
    }
    const getDisplayingTo = () => {
      const genders = showTo(fields)
      const allM = genders.find(
        (item) =>
          item.ageFrom === 0 && item.ageTo === 100 && item.gender === 'MALE',
      )
      const allF = genders.find(
        (item) =>
          item.ageFrom === 0 && item.ageTo === 100 && item.gender === 'FEMALE',
      )
      if (allM && allF) {
        return 'Everyone one'
      }

      const res = genders.reduce((acc, current) => {
        if (!acc[current.gender]) acc[current.gender] = []

        acc[current.gender].push(current)

        return acc
      }, {} as { MALE: { ageFrom: number; ageTo: number }[]; FEMALE: { ageFrom: number; ageTo: number }[] })

      return (
        <div>
          {allM ? (
            'Every man'
          ) : (
            <div>
              Man:{' '}
              {res.MALE.map((item, i, arr) => {
                return (
                  <span key={i}>
                    {item.ageFrom} - {item.ageTo}
                    {i === arr.length - 1 ? ' years' : ', '}
                  </span>
                )
              })}
            </div>
          )}

          {allF ? (
            'Every woman'
          ) : (
            <div>
              Woman:{' '}
              {res.FEMALE.map((item, i, arr) => {
                return (
                  <span key={i}>
                    {item.ageFrom} - {item.ageTo}
                    {i === arr.length - 1 ? ' years' : ', '}
                  </span>
                )
              })}
            </div>
          )}
        </div>
      )
    }

    push(routes.PROFILE.ADD)

    openModal({
      message: (
        <div className="w-full text-left">
          <h2 className="text-[#4A4A4A] text-[19px] leading-[22.67px] mb-[25px]">
            Parameters
          </h2>
          <ul className="text-[13px] leading-[22px]">
            <li className="mb-[10px]">Show my ad to: {getDisplayingTo()}</li>
            <li className="mb-[10px]">
              Pick Counties:{' '}
              {countries
                .filter((item) => fields.country.some((int) => int === item.id))
                .map((item) => item.name)
                .join(', ')}
            </li>
            <li className="mb-[10px]">
              Business Ownership:{' '}
              {allBusinessItems
                .filter((item) =>
                  fields.business.some((int) => int === item.id),
                )
                .map((item) => item.name)
                .join(', ')}
            </li>
            <li className="mb-[10px]">
              Interests:{' '}
              {interests
                .filter((item) =>
                  fields.interest.some((int) => int === item.id),
                )
                .map((item) => item.name)
                .join(', ')}
            </li>
            <li className="mb-[10px]">
              Show no more than {fields.timesToShow} times to the same person
            </li>
            <li className="mb-[10px]">
              Pay:{' '}
              <span className="font-bold">
                {(
                  0.05 * +(fields.timesToShow ? fields.timesToShow : 0)
                ).toFixed(3)}{' '}
                {
                  payWithOptions.find((item) => item.id === fields.payWith)
                    ?.name
                }
              </span>
            </li>
          </ul>
        </div>
      ),
      onOk: () => {
        closePopup()

        createCampaign(fields, getFileUid(promo!.id) || '')
          .then(() => {
            openPopup({
              type: 'ok',
              message: (
                <span className="block">
                  Your payment was successfully processed.
                  <br />
                  Your advertising campaign is active now. Thank you.
                </span>
              ),
            })
          })
          .catch((error) => {
            openPopup({
              type: 'error',
              message: error?.data?.message || 'Something went wrong',
            })
          })
      },
      type: 'ok',
      onOkText: 'Purchase',
    })
  }

  const businessList = useMemo(
    () => [
      { id: 0, name: 'All' },
      ...functions.map((f, i) => {
        return {
          id: i + 1,
          name: f.title,
        }
      }),
    ],
    [],
  )

  return (
    <div className="max-md:w-full">
      <Breadcrumbs items={bc} />
      <div className={tw('w-full py-[30px] pl-[5px]')}>
        <video
          className={tw('rounded-[10px] w-[420px]')}
          src={promo?.url}
        ></video>
      </div>
      <div className={tw('grid grid-cols-2 gap-x-[20px] gap-y-[30px] w-full')}>
        <CheckboxWithImage
          img={ad1}
          checked={fields.reuseEventButton}
          text="Reuse Event Button"
          handleChange={handleChange}
          name="reuseEventButton"
        />
        <CheckboxWithImage
          img={ad2}
          checked={fields.renewBussinessButton}
          text="Renew Bussiness Button"
          handleChange={handleChange}
          name="renewBussinessButton"
        />
        <CheckboxWithImage
          img={ad3}
          checked={fields.renewYapMarketAdButton}
          text="Renew Market Ad Button"
          handleChange={handleChange}
          name="renewYapMarketAdButton"
        />
        <CheckboxWithImage
          img={ad4}
          checked={fields.tickerBubble}
          text="Ticker Bubble"
          handleChange={handleChange}
          name="tickerBubble"
        />
        <CheckboxWithImage
          img={ad5}
          checked={fields.chatPlaceholder}
          text="Chat Placeholder (5th place from the top)"
          handleChange={handleChange}
          name="chatPlaceholder"
        />
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px]  mb-[4px] font-[500]')}>
          Male
        </h4>
        {agesSelectSubtitle}
        <div className={tw('flex max-md:flex-col')}>
          <Checkbox
            content={'18-30'}
            name="mAge18_30"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.mAge18_30}
            onCheck={handleChange}
          />
          <Checkbox
            content={'31-40'}
            name="mAge31_40"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.mAge31_40}
            onCheck={handleChange}
          />
          <Checkbox
            content={'41-50'}
            name="mAge41_50"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.mAge41_50}
            onCheck={handleChange}
          />
          <Checkbox
            content={'51-60'}
            name="mAge51_60"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.mAge51_60}
            onCheck={handleChange}
          />
          <Checkbox
            content={'Orver 61'}
            name="mAgeOver61"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.mAgeOver61}
            onCheck={handleChange}
          />
          <Checkbox
            content={'All'}
            name="mAgeAll"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.mAgeAll}
            onCheck={handleChange}
          />
        </div>
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] mb-[4px] font-[500]')}>Female</h4>
        {agesSelectSubtitle}
        <div className={tw('flex max-md:flex-col')}>
          <Checkbox
            content={'18-30'}
            name="fAge18_30"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.fAge18_30}
            onCheck={handleChange}
          />
          <Checkbox
            content={'31-40'}
            name="fAge31_40"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.fAge31_40}
            onCheck={handleChange}
          />
          <Checkbox
            content={'41-50'}
            name="fAge41_50"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.fAge41_50}
            onCheck={handleChange}
          />
          <Checkbox
            content={'51-60'}
            name="fAge51_60"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.fAge51_60}
            onCheck={handleChange}
          />
          <Checkbox
            content={'Orver 61'}
            name="fAgeOver61"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.fAgeOver61}
            onCheck={handleChange}
          />
          <Checkbox
            content={'All'}
            name="fAgeAll"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.fAgeAll}
            onCheck={handleChange}
          />
        </div>
      </div>
      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px] mb-[20px] font-[500]')}>
          Pick Counties
        </h4>
        <MultiSelect
          items={countries}
          name="country"
          value={[countries[243]]}
          excludedItem={countries[243]}
          onChange={handleChange}
        />
      </div>
      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px] mb-[20px] font-[500]')}>
          Business Ownership
        </h4>

        <div className={tw('flex max-md:flex-col')}>
          <Checkbox
            content={'Show to business owners only'}
            name="showToBusinessOwnersOnly"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.showToBusinessOwnersOnly}
            onCheck={handleChange}
          />
          <Checkbox
            content={'Show to all. Business ownership not necessarily'}
            name="dontShowToBusinessOwnersOnly"
            className={tw('text-[10px] leading-[15px] flex items-center')}
            color="#AF90FF"
            checked={!fields.showToBusinessOwnersOnly}
            onCheck={handleChange}
          />
        </div>

        <MultiSelect
          items={allBusinessItems}
          name="business"
          value={[businessList[0]]}
          excludedItem={businessList[0]}
          disabled={!fields.showToBusinessOwnersOnly}
          onChange={handleChange}
        />
      </div>
      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px] mb-[20px] font-[500]')}>
          Interests
        </h4>
        <div className={tw('flex max-md:flex-col')}>
          <Checkbox
            content={'Customize'}
            name="customizeInterests"
            className={tw(
              'text-[10px] leading-[15px] flex items-center mr-[20px] max-md:mb-[12px]',
            )}
            color="#AF90FF"
            checked={fields.customizeInterests}
            onCheck={handleChange}
          />
          <Checkbox
            content={'Show to all. Having a hobby is not necessary'}
            name="dontCustomizeInterests"
            className={tw('text-[10px] leading-[15px] flex items-center')}
            color="#AF90FF"
            checked={!fields.customizeInterests}
            onCheck={handleChange}
          />
        </div>

        <MultiSelect
          items={interests}
          name="interest"
          value={[interests[0]]}
          excludedItem={interests[0]}
          disabled={!fields.customizeInterests}
          onChange={handleChange}
        />
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px]')}>
          When clicking on my ad users should be redirected to this link
        </h4>

        <input
          type="text"
          value={fields.redirectLink}
          onChange={handleInputChange}
          placeholder="For example www.relagram.com"
          className={tw('border-b-[1px] w-full py-[14px] outline-none block')}
          name="redirectLink"
        />
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px] mb-[20px] font-[500]')}>
          Promotion cost:
        </h4>
        <div
          className={tw(
            'text-[10px] leading-[15px] mb-[30px] font-[KohinoorBangla]',
          )}
        >
          One view cost is 0.05 MFC
        </div>

        <div className={tw('mb-[10px] text-[13px] leading-[22px]')}>
          Amount of views I wish to purchase
        </div>
        <input
          type="number"
          placeholder="Enter number here"
          value={fields.timesToShow}
          onChange={handleInputChange}
          className={tw('border-b-[1px] w-full py-[14px] outline-none block')}
          name="timesToShow"
        />
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px]')}>
          Maximum amount of views for same person (no more than entered below)
        </h4>

        <input
          type="number"
          value={fields.views}
          onChange={handleInputChange}
          placeholder="Enter number here"
          className={tw('border-b-[1px] w-full py-[14px] outline-none block')}
          name="views"
        />
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px]')}>I want to pay with</h4>

        <SingleSelect
          items={payWithOptions}
          name="payWith"
          value={payWithOptions[0]}
          onChange={handleChange}
        />
      </div>

      <div className={tw('mt-[30px]')}>
        <h4 className={tw('text-[13px] leading-[22px]')}>
          Total Amount Due, USD/USDT/MFC
        </h4>

        <div
          className={tw('border-b-[1px] w-full py-[14px] outline-none block')}
        >
          {(0.05 * +(fields.timesToShow ? fields.timesToShow : 0)).toFixed(3)}
        </div>
      </div>

      <div className={tw('flex justify-center')}>
        <button
          type="button"
          className={tw(
            `text-[17px] leading-[25px] uppercase px-[42px] py-[15px] max-md:px-[20px] max-md:py-[8px] text-[#AF90FF] bg-[#FFF] rounded-[8px]
             mr-[20px] hover:bg-[#AF90FF] hover:text-[#fff] border-[1px] border-[#AF90FF] mt-[20px]`,
          )}
        >
          Cancel{' '}
        </button>
        <button
          type="button"
          onClick={handleCreateCampaign}
          className={tw(
            `text-[17px] leading-[25px] uppercase px-[42px] py-[15px] max-md:px-[20px] max-md:py-[8px] text-white bg-[#AF90FF] rounded-[8px]
             hover:bg-white hover:text-[#AF90FF] border-[1px] border-[#AF90FF] mt-[20px]`,
          )}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

const CheckboxWithImage: FC<{
  checked: boolean
  name: string
  text: string
  img: string
  handleChange: (value: unknown) => void
}> = ({ checked, handleChange, name, text, img }) => {
  return (
    <div>
      <img
        className={tw('w-[200px] mb-[10px] max-md:w-[150px]')}
        src={img}
        alt=""
      />
      <Checkbox
        content={text}
        name={name}
        checked={checked}
        className={tw('text-[10px] leading-[15px] flex items-center')}
        color="#AF90FF"
        onCheck={handleChange}
      />
    </div>
  )
}

const agesSelectSubtitle = (
  <div className="text-[10px] font-[500] leading-[22px] text-[#8E8E93] mb-[24px]">
    You can select multiple options
  </div>
)

export default memo(CreatePromo)
