import React, { memo, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { routes } from '../../../../constants/routes'
import './styles.scss'
import { BurgerMenu } from '../../../../components/uiKit/BurgerMenu/BurgerMenu'
import { UserProfile } from '../../../../components/UserProfile'

const Component = () => {
  const [opened, setOpened] = useState(false)
  return (
    <div className="profile-menu">
      <ul className={`profile-menu__list ${opened ? 'mobile' : ''}`}>
        <li className="profile-menu__item">
          <NavLink
            to={routes.PROFILE.ACCOUNT}
            activeClassName="profile-menu__item-active-link"
          >
            Account
          </NavLink>
        </li>
        <li className="profile-menu__item">
          <NavLink
            to={routes.PROFILE.PAYMENT}
            activeClassName="profile-menu__item-active-link"
          >
            Payments
          </NavLink>
        </li>
        <li className="profile-menu__item">
          <NavLink
            to={routes.PROFILE.STICKERS}
            activeClassName="profile-menu__item-active-link"
          >
            Stickers
          </NavLink>
        </li>
        <li className="profile-menu__item">
          <NavLink
            to={routes.PROFILE.ADD}
            activeClassName="profile-menu__item-active-link"
          >
            Advertisement
          </NavLink>
        </li>
        <li className="profile-menu__item">
          <NavLink
            to={routes.PROFILE.REPORTS}
            activeClassName="profile-menu__item-active-link"
          >
            Reports
          </NavLink>
        </li>
      </ul>
      <div className="profile-menu__user-profile">
        {!opened && <UserProfile />}
      </div>
      <div className="profile-menu__burger-menu">
        <BurgerMenu opened={opened} setOpened={setOpened} />
      </div>
    </div>
  )
}

export const ProfileMenu = memo(Component)
