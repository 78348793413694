import { jwtDecode } from 'jwt-decode'
import { endpoints } from '../../constants/endpoints'
import { AdDto, MediaItem } from '../../pages/Ads/AdsContexts'
import { Fields } from '../../pages/Ads/CreatePromo'
import allBusinessItems from '../../pages/Ads/allBusinessItems'
import showTo from '../../pages/Ads/heplers.ts/showTo'
import interests from '../../pages/Ads/interests'
import payWithOptions from '../../pages/Ads/payWhithOptions'
import { StickerPackDto } from '../../store/thunks/thunkUploadStickers'
import { get, post, deleteReq } from '../authRequestsService'
import { PromoCampaign } from '../types/PromoCampaign'
import { authTokenStoreService } from '../authTokenStoreService'
import { statuses } from '../../pages/AddStickers'
import countries from '../../pages/Ads/allCountries'

type Location = {
  lat: number
  lon: number
  locationRandom: string
}

type PromoVideo = {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  url: string
  owner: {
    id: string
    blockedUsersIds: string[]
    email: string
    firstName: string
    lastName: string
  }
  status: string
  adminComment: string
}

type User = {
  id: string
  createdAt: string
  updatedAt: string
  instagramId: string
  facebookId: string
  twitterId: string
  isActive: boolean
  isDeleted: boolean
  blockedUsersIds: string[]
  email: string
  firstName: string
  lastName: string
  nickName: string
  middleName: string
  profileImageUrl: string
  location: Location
  orientation: string
  status: string
  birthDate: string
  student: boolean
  drinking: boolean
  smoking: boolean
  religion: string
  ethnos: string
  gender: string
  phone: string
  height: number
  weight: number
  lastSeen: string
  isBlocked: boolean
  private: boolean
  profile: string
  online: boolean
}

type AgeRange = {
  gender: string
  ageFrom: number
  ageTo: number
}

type UserView = {
  id: string
  createdAt: string
  user: {
    id: string
    email: string
    firstName: string
    lastName: string
  }
}

export type CampaignType = {
  id: string
  createdAt: string
  updatedAt: string
  promoVideo: PromoVideo
  owner: User
  cost: number
  total: number
  views: number
  showToOneUser: number
  redirectLink: string
  status: string
  interests: string[]
  countries: string[]
  businessTypes: string[]
  places: string[]
  languages: string[]
  age: AgeRange[]
  userViews: UserView[]
  businessOwnership: boolean
}

export const uploadImage = (formData: FormData) =>
  post(endpoints.UPLOAD_IMAGE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const uploadVideo = (formData: FormData) =>
  post(endpoints.UPLOAD_VIDEO, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }) as unknown as Promise<string>

export const deleteVideo = (fileName: string) =>
  deleteReq(
    `${endpoints.DELETE_VIDEO}?key=${fileName}`,
  ) as unknown as Promise<string>

export const saveMedia = async (file: MediaItem, name?: string) => {
  if (!file.fileUrl) {
    return
  }
  const dto = {
    name,
    url: file.fileUrl,
  }
  return post(endpoints.SAVE_PROMO_VIDEO, dto)
}

export const getCampaings = async () => {
  try {
    const res = await get(endpoints.GET_CAMPAIGNS)

    return res as unknown as PromoCampaign[]
  } catch (error) {
    console.log('🚀 ~ error:', error)
  }
}

export const getAdds = async () => {
  const { id: userId } = jwtDecode<{ id: string }>(
    authTokenStoreService.obtain() || '',
  )

  const res = (await get(`${endpoints.GET_PROMOS}/${userId}`, {
    params: {
      page: 0,
      size: 1000,
      sort: 'asc',
    },
  })) as unknown as AdDto[]

  

  return res.map((add) => {
    return {
      id: add.id,
      status: statuses[add.status],
      url: add.url,
      title: add.name,
    }
  })
}

const places = {
  reuseEventButton: 'REUSE_EVENT_BUTTON',
  renewBussinessButton: 'RENEW_BUSINESS_BUTTON',
  renewYapMarketAdButton: 'RENEW_YAPMARKET_AD_BUTTON',
  tickerBubble: 'TICKER_BUBBLE',
  chatPlaceholder: 'CHAT_PLACEHOLDER',
}

export const createCampaign = async (fields: Fields, promoVideoId: string) => {
  try {
    const ages = showTo(fields)

    const dto = {
      age: ages,
      businessOwnership: fields.showToBusinessOwnersOnly,
      showToOneUser: fields.views,
      views: fields.timesToShow,
      businessTypes:
        allBusinessItems.filter((item) => fields.business.some(int => item.id === int)).map(item => item.types).flat(),
      interests: interests.filter((item) => fields.interest.some(int => item.id === int)).map(item => item.name),
      countries: countries.filter((item) => fields.country.some(int => item.id === int)).map(item => item.name),
      languages: ['ENGLISH'],
      payWith: payWithOptions.find((o) => o.id === fields.payWith)?.types[0],
      places: Object.keys(fields)
        .map((key) => {
          if (
            Object.keys(places).some((pKey) => pKey === key) &&
            fields[key as keyof typeof fields]
          ) {
            return places[key as keyof typeof places]
          }
        })
        .filter((item) => !!item),
      promoVideo: {
        id: promoVideoId,
      },
    }
    
    return post(endpoints.CREATE_CAMPAIGN, dto) as unknown as Promise<string>
  } catch (error) {
    console.log('🚀 ~ error:', error)
    throw new Error(error as any)
  }
}

export const uploadStickerPack = (data: StickerPackDto) =>
  post(endpoints.UPLOAD_STICKER_PACK, data)
