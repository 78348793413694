import React from 'react'

const ErrorMessage = ({ error }) => {
  return (
    <div className="reset-form__item">
      <span className="reset-form__error">{error}</span>
    </div>
  )
}

export default ErrorMessage
