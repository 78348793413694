import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../../constants/routes'
import {
  CampaignType,
  deleteVideo,
  getAdds,
  uploadVideo,
} from '../../services/api/addsService'
import { toast } from 'react-toastify'
import getFileUid from './heplers.ts/getFileUid'

interface AdsContextData {
  file: MediaItem | null
  setImage: (file: File) => void
  resetImage: (src?: string) => void
  setFileTitle: (title: string) => void
  imageUploading: boolean
  loadedImageUrl: string
  fileBeingUploaded: boolean
  allAds: AllAds[]
}

export type AllAds = {
  id: string
  title: string
  url: string
  status: number
  data: CampaignType
}

export type Stickerpack = {
  id: string
  name: string
  icon: string
  status: string
}



export type MediaItem = {
  id: string
  image: File
  src: string
  error?: boolean
  fileUrl?: string
  title?: string
}

export type Owner = {
  id: string
  blockedUsersIds: string[]
  email: string
  firstName: string | null
  lastName: string | null
}

export type AdDto = {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  url: string
  owner: Owner
  status: string
  adminComment: string
}

const ALLOWED_WIDTH = 1280
const ALLOWED_HEIGHT = 720

const ALOWED_VIDEO_FORMATS = [
  'MOV',
  'MPEG-1',
  'MPEG-2',
  'MPEG4',
  'MP4',
  'MPG',
  'AVI',
  'WMV',
]

const Context = createContext<AdsContextData>(null!)

const AdsContext: FC<PropsWithChildren> = ({ children }) => {
  const { push, location } = useHistory()
  const [file, setFile] = useState<MediaItem | null>(null)
  const [fileBeingUploaded, setFileBeingUploaded] = useState<boolean>(false)
  const [imageUploading, setImageUploading] = useState(false)
  const [loadedImageUrl, setLoadedImageUrl] = useState('')
  const [allAds, setAllAds] = useState<AllAds[]>([])

  const setFileTitle = useCallback((title: string) => {
    setFile((file) => {
      if (!file) return file
      return {
        ...file,
        title,
      }
    })
  }, [])

  const setImage = useCallback((file: File) => {
    const isNotSupported = !ALOWED_VIDEO_FORMATS.some(
      (t) => t.toLowerCase() === file.name.split('.').pop()?.toLowerCase(),
    )

    if (isNotSupported) {
      alert('This file extension is not supported to be loaded')

      return
    }

    const image = document.createElement('video')
    const src = URL.createObjectURL(file)

    image.src = src

    image.onloadedmetadata = (e) => {
      const video = e.currentTarget as HTMLVideoElement

      if (
        video.videoWidth < ALLOWED_WIDTH ||
        video.videoHeight < ALLOWED_HEIGHT
      ) {
        setFile({ image: file, src, error: true, id: '' })
        push(routes.PROFILE.ADVERTISEMENT)
      } else {
        setFile({ image: file, src, id: '' })
        setFileBeingUploaded(true)
        push(routes.PROFILE.ADVERTISEMENT)

        const fd = new FormData()
        fd.append('video', file, file.name)

        uploadVideo(fd)
          .then((fileUrl) => {
            setFile((file) => {
              if (!file) return file

              return {
                ...file,
                fileUrl,
                id: getFileUid(fileUrl) || '',
              }
            })
            setFileBeingUploaded(false)
          })
          .catch((error) => {
            console.log('🚀 ~ error:', error)
            toast('Something went wrong during the image uploading', {
              className: 'notification--error',
            })

            push(routes.PROFILE.ADD)
          })

        // new Promise((r, rej) => {
        //   setTimeout(() => {
        //     setFile((file) => {
        //       if (!file) return file

        //       return {
        //         ...file,
        //         fileUrl: 'kek.lol',
        //       }
        //     })
        //     setTimeout(() => {
        //       setFileBeingUploaded(false)
        //     }, 1000)
        //     r('fileUrl')
        //   }, 1000)
        // })
      }
    }
  }, [])

  const resetImage = useCallback(
    (fileName?: string) => {
      setFile(null)

      if (fileName) {
        deleteVideo(fileName)
      }
    },
    [file?.id],
  )

  useEffect(() => {
    getAdds().then((r) => {
      setAllAds(r as any)
    })
  }, [])

  return (
    <Context.Provider
      value={{
        file,
        setImage,
        resetImage,
        imageUploading,
        loadedImageUrl,
        fileBeingUploaded,
        setFileTitle,
        allAds,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default AdsContext

export const useAdsContext = () => useContext(Context)
