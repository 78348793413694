import { defineConfig } from '@twind/core'
import presetAutoprefix from '@twind/preset-autoprefix'
import presetTailwind from '@twind/preset-tailwind'

export default defineConfig({
  presets: [presetAutoprefix(), presetTailwind()],
  ignorelist: [
    'container',
    'askForCookie',
    'askForCookie--header',
    'askForCookie--text',
    'askForCookie--subText',
    'uiBtn',
    'askForCookie--btn',
    'header',
    'header--block',
    'header__navigation',
    'header__navigation__item',
    'header__navigation__link',
    'active-link',
    'header--login-btn',
    'burger-menu',
    'start-screen',
    'section-block',
    'start-screen--texts',
    'start-screen__header',
    'start-screen__slogan',
    'start-screen--btns',
    'start-screen--btn',
    'mainScreenSection',
    'mainScreenSection--block',
    'mainScreenSection--subtitle',
    'mainScreenSection--qrs',
    'mainScreenSection--qr',
    'footer',
    'footer__navigation-item',
    'footer--logo',
    'footer__navigation-title',
    'footer__select',
    'footer--sub',
    'mob',
    'Toastify',
  ],
})
