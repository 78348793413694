import { getUserAccount } from '../../services/api/accountService'
import { actionSetUserAccount } from '../actions/commonNetActions'

export const thunkGetUserAccount = () => async (dispatch) => {
  try {
    const account = await getUserAccount()
    dispatch(actionSetUserAccount(account))
  } catch (err) {
    console.log('thunk error', err)
  }
}
