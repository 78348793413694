import React from 'react'
import RcInput, { InputRef } from 'rc-input'

import './styles.scss'
import ErrorMessage from './ErrorMessage'

interface TextInputProps {
  autoComplete?: string
  bordered?: boolean
  disabled?: boolean
  id?: string
  label?: string
  loading?: boolean
  name?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, name?: string) => void
  type?: string
  value?: string
  placeholder?: string
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  errorType?: 'error' | 'warning'
  errorMessage?: string
  inputClassName?: string
  className?: string
}

const TextInput = React.forwardRef(
  (
    {
      autoComplete,
      bordered,
      disabled,
      id,
      label,
      loading,
      onBlur,
      onChange,
      type,
      value,
      name,
      placeholder,
      suffix,
      prefix,
      addonBefore,
      addonAfter,
      errorType,
      errorMessage,
      inputClassName,
      className,
      ...rest
    }: TextInputProps,
    ref: any,
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist()
      onChange?.(e, name)
    }
    return (
      <div className={`ui-input ${className}`}>
        {label && id && <label htmlFor={id}>{label}</label>}
        <RcInput
          {...rest}
          autoComplete={autoComplete}
          // bordered={typeof bordered === 'boolean' && bordered.toString()}
          disabled={disabled}
          // loading={loading}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          type={type}
          value={value}
          placeholder={placeholder}
          suffix={suffix}
          prefix={prefix}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          className={inputClassName}
          ref={ref}
        />
        {errorMessage && (
          <ErrorMessage errorType={errorType} message={errorMessage} />
        )}
      </div>
    )
  },
)

TextInput.displayName = 'TextInput'

export default TextInput