import axios from 'axios'

import { API_DOMAIN, API_DOMAIN_PROD } from '../config'
import { routes } from '../constants/routes'

import { authTokenStoreService } from './authTokenStoreService'

const instance = axios.create({
  baseURL: API_DOMAIN_PROD,
    // process.env.NODE_ENV === 'development' ? API_DOMAIN : API_DOMAIN_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  const newConfig = { ...config }
  if (authTokenStoreService.obtain()) {
    newConfig.headers.common.Authorization = `Bearer ${authTokenStoreService.obtain()}`
  }
  return newConfig
})

instance.interceptors.response.use(
  (response) => response.data,
  (err) => {
    if (err.response.status === 401) {
      authTokenStoreService.remove()
      const { pathname, hash, search } = document.location
      const fromPath = `from=${pathname}`
      const query = search ? `${search}&${fromPath}` : `?${fromPath}`
      // TODO: uncomemt below
      // document.location.href = `${routes.LOGIN}${query}${hash}`
    }
    return Promise.reject(err.response)
  },
)

export const get = async (url, config) => instance.get(url, config)

export const post = async (url, data, config) =>
  instance.post(url, data, config)

export const put = async (url, data, config) => instance.put(url, data, config)

export const deleteReq = async (url, config) => instance.delete(url, config)
