import React from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player/youtube'

import Relagram_Cartoon_Bloggers_tool_Advertisiment_Vertical from '../../../assets/videos/Relagram_Cartoon_Bloggers_tool_Advertisiment_Vertical.mp4'
import Relagram_Cartoon_Bussines_tool_Advertisement_Vertical from '../../../assets/videos/Relagram_Cartoon_Bussines_tool_Advertisement_Vertical.mp4'
import Relagram_Cartoon_Dating_tool_Advertisement_Vertical from '../../../assets/videos/Relagram_Cartoon_Dating_tool_Advertisement_Vertical.mp4'
import Relagram_Cartoon_Events_tool_Advertisement_2_Vertical from '../../../assets/videos/Relagram_Cartoon_Events_tool_Advertisement_2_Vertical.mp4'
import Relagram_Cartoon_Events_tool_Advertisement_Vertical from '../../../assets/videos/Relagram_Cartoon_Events_tool_Advertisement_Vertical.mp4'
import Relagram_Channels_Tool_Advertisiment_Vertical from '../../../assets/videos/Relagram_Channels_Tool_Advertisiment_Vertical.mp4'
import Relagram_Dating_Tool_Advertisement_Vertical from '../../../assets/videos/Relagram_Dating_Tool_Advertisement_Vertical.mp4'
import Relagram_Events_Setup_Tool_Advertisement_Vertical from '../../../assets/videos/Relagram_Events_Setup_Tool_Advertisement_Vertical.mp4'
import Relagram_Free_Business_Advertisiment_Vertical from '../../../assets/videos/Relagram_Free_Business_Advertisiment_Vertical.mp4'
import Relagram_General_Promo_Vertical from '../../../assets/videos/Relagram_General_Promo_Vertical.mp4'
import Relagram_Market_Tool_Advertisement_Vertical from '../../../assets/videos/Relagram_Market_Tool_Advertisement_Vertical.mp4'
import Relagram_Proposal_to_Bloggers_Vertical from '../../../assets/videos/Relagram_Proposal_to_Bloggers_Vertical.mp4'
import Relagram_Cartoon_Dating_tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_Cartoon_Dating_tool_Advertisement_Horizontal.mp4'
import Relagram_Cartoon_Bussines_tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_Cartoon_Bussines_tool_Advertisement_Horizontal.mp4'
import Relagram_Cartoon_Events_tool_Advertisement_2_Horizontal from '../../../assets/videos/Relagram_Cartoon_Events_tool_Advertisement_2_Horizontal.mp4'
import Relagram_Dating_Tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_Dating_Tool_Advertisement_Horizontal.mp4'
import Relagram_Cartoon_Events_tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_Cartoon_Events_tool_Advertisement_Horizontal.mp4'
import Relagram_Events_Setup_Tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_Events_Setup_Tool_Advertisement_Horizontal.mp4'
import Relagram_Free_Business_Advertisiment_Horizontal from '../../../assets/videos/Relagram_Free_Business_Advertisiment_Horizontal.mp4'
import Relagram_Channels_Tool_Advertisiment_Horizontal from '../../../assets/videos/Relagram_Channels_Tool_Advertisiment_Horizontal.mp4'
import Relagram_General_Promo_Horizontal from '../../../assets/videos/Relagram_General_Promo_Horizontal.mp4'
import Relagram_Market_Tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_Market_Tool_Advertisement_Horizontal.mp4'
import Relagram_Proposal_to_Bloggers_Horizontal from '../../../assets/videos/Relagram_Proposal_to_Bloggers_Horizontal.mp4'
import Relagram_Cartoon_Bloggers_tool_Advertisiment_Horizontal from '../../../assets/videos/Relagram_Cartoon_Bloggers_tool_Advertisiment_Horizontal.mp4'
import Relagram_General_Promo_2_Horizontal from '../../../assets/videos/Relagram_General_Promo_2.mp4'
import Relagram_YapJobs_Tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_YapJobs_Tool_Advertisement_Horizontal.mp4'
import Relagram_YapJobs_Tool_Advertisement_Vertical from '../../../assets/videos/Relagram_YapJobs_Tool_Advertisement_Vertical.mp4'
import Relagram_Cartoon_YapJobs_Hiring_Tool_Horizontal from '../../../assets/videos/Relagram_Cartoon_YapJobs_Hiring_Tool_Horizontal.mp4'
import Relagram_Cartoon_YapJobs_Hiring_Tool_Vertical from '../../../assets/videos/Relagram_Cartoon_YapJobs_Hiring_Tool_Vertical.mp4'
import Relagram_YapPets_Tool_Advertisement_Horizontal from '../../../assets/videos/Relagram_YapPets_Tool_Advertisement_Horizontal.mp4'
import Relagram_YapPets_Tool_Advertisement_Vertical from '../../../assets/videos/Relagram_YapPets_Tool_Advertisement_Vertical.mp4'
import Relagram_Cartoon_YapPets_Tool_Horizontal from '../../../assets/videos/Relagram_Cartoon_YapPets_Tool_Horizontal.mp4'
import Relagram_Cartoon_YapPets_Tool_Vertical from '../../../assets/videos/Relagram_Cartoon_YapPets_Tool_Vertical.mp4'
import Relagram_Cartoon_YapJob_Search_Tool_Horizontal from '../../../assets/videos/Relagram_Cartoon_YapJob_Search_Tool_Horizontal.mp4'
import Relagram_Cartoon_YapJob_Search_Tool_Vertical from '../../../assets/videos/Relagram_Cartoon_YapJob_Search_Tool_Vertical.mp4'

import './styles.scss'

export const SourcesForAd = () => {
  const videos = [
    {
      url: 'https://youtu.be/UcZ_gcxUdJY',
      vertical: Relagram_Cartoon_Dating_tool_Advertisement_Vertical,
      horizontal: Relagram_Cartoon_Dating_tool_Advertisement_Horizontal,
      name: '',
      description:
        "Relagram presents: How to start dating. Princes' Warehouse discovered here.",
    },
    {
      url: 'https://youtu.be/SnFtZJ6CyY0',
      vertical: Relagram_Cartoon_Events_tool_Advertisement_Vertical,
      horizontal: Relagram_Cartoon_Events_tool_Advertisement_Horizontal,
      name: '',
      description: 'Relagram presents: Yard Sale Drama. Everything must go!',
    },
    {
      url: 'https://youtu.be/WVYs86RwXBk',
      vertical: Relagram_Cartoon_Events_tool_Advertisement_2_Vertical,
      horizontal: Relagram_Cartoon_Events_tool_Advertisement_2_Horizontal,
      name: '',
      description: 'Relagram presents: When your flash mob went wrong.',
    },
    {
      url: 'https://youtu.be/xg1wYMtszQ4',
      vertical: Relagram_Cartoon_Bussines_tool_Advertisement_Vertical,
      horizontal: Relagram_Cartoon_Bussines_tool_Advertisement_Horizontal,
      name: '',
      description: 'Relagram presents: Save The Spider! Make Jonathan busy!',
    },
    {
      url: 'https://youtu.be/JZD4bzm7v80',
      vertical: Relagram_Cartoon_Bloggers_tool_Advertisiment_Vertical,
      horizontal: Relagram_Cartoon_Bloggers_tool_Advertisiment_Horizontal,
      name: '',
      description:
        "Relagram presents: Blogger's Hard Life. Work Smarter Not Harder.",
    },
    {
      url: 'https://youtu.be/JAjig_eWomA',
      vertical: Relagram_General_Promo_Vertical,
      horizontal: Relagram_General_Promo_Horizontal,
      name: '',
      description: 'Relagram: General Promo Video',
    },
    {
      url: 'https://youtu.be/6iWAZ2tYwjw',
      vertical: Relagram_Market_Tool_Advertisement_Vertical,
      horizontal: Relagram_Market_Tool_Advertisement_Horizontal,
      name: '',
      description: 'Relagram: YapMarket Free AdsTool Promo Video.',
    },
    {
      url: 'https://youtu.be/GZ3JSiK467g',
      vertical: Relagram_Proposal_to_Bloggers_Vertical,
      horizontal: Relagram_Proposal_to_Bloggers_Horizontal,
      name: '',
      description: 'Relagram: Sync Your Subscribers Promo Video.',
    },
    {
      url: 'https://youtu.be/5PWlBjZSAx8',
      vertical: Relagram_Free_Business_Advertisiment_Vertical,
      horizontal: Relagram_Free_Business_Advertisiment_Horizontal,
      name: '',
      description: 'Relagram: Free Advertisement Tool for Business.',
    },
    {
      url: 'https://youtu.be/ni_3BUEvlAk',
      vertical: Relagram_Dating_Tool_Advertisement_Vertical,
      horizontal: Relagram_Dating_Tool_Advertisement_Horizontal,
      name: '',
      description: 'Relagram: FREE Dating Service Tool.',
    },
    {
      url: 'https://youtu.be/BqujRF5Y2FE',
      vertical: Relagram_Channels_Tool_Advertisiment_Vertical,
      horizontal: Relagram_Channels_Tool_Advertisiment_Horizontal,
      name: '',
      description: 'Relagram: YapChannels FREE Broadcast Tool.',
    },
    {
      url: 'https://youtu.be/mqLEJT6S8F4',
      vertical: null,
      horizontal: Relagram_General_Promo_2_Horizontal,
      name: '',
      description: 'Relagram: General promo video.',
    },
    {
      url: 'https://youtu.be/5rzgZK75cEo',
      vertical: Relagram_Events_Setup_Tool_Advertisement_Vertical,
      horizontal: Relagram_Events_Setup_Tool_Advertisement_Horizontal,
      name: '',
      description: 'Relagram: Free events setup tools.',
    },
    {
      url: 'https://youtu.be/z8zE-zddNVQ',
      vertical: Relagram_YapJobs_Tool_Advertisement_Vertical,
      horizontal: Relagram_YapJobs_Tool_Advertisement_Horizontal,
      name: '',
      description:
        'Relagram: Job or employees search tool. To the attention of bloggers and content creators!',
    },
    {
      url: 'https://youtu.be/bPgIgblYBf0',
      vertical: Relagram_YapPets_Tool_Advertisement_Vertical,
      horizontal: Relagram_YapPets_Tool_Advertisement_Horizontal,
      name: '',
      description:
        'Relagram: Find your Pet a friend or match. To the attention of bloggers and content creators!',
    },
    {
      url: 'https://youtu.be/ZyH5XgDAIDw',
      vertical: Relagram_Cartoon_YapJobs_Hiring_Tool_Vertical,
      horizontal: Relagram_Cartoon_YapJobs_Hiring_Tool_Horizontal,
      name: '',
      description: 'Relagram presents: How to find employees.',
    },
    {
      url: 'https://youtu.be/QNHrPolItlY',
      vertical: Relagram_Cartoon_YapPets_Tool_Vertical,
      horizontal: Relagram_Cartoon_YapPets_Tool_Horizontal,
      name: '',
      description: 'Relagram presents: How to find your pet a new friend.',
    },
    {
      url: 'https://youtu.be/ZARFL5Hr4Xk',
      vertical: Relagram_Cartoon_YapJob_Search_Tool_Vertical,
      horizontal: Relagram_Cartoon_YapJob_Search_Tool_Horizontal,
      name: '',
      description: 'Relagram presents: How to find a job.',
    },
  ]

  const renderVideosList = (list) =>
    list.map((video) => (
      <div className="sources__example" key={video.url}>
        <ReactPlayer url={video.url} width={294} height={180} controls />
        <div className="sources__links">
          {video.vertical && (
            <>
              <Link to={video.vertical} target="_blank" download>
                1080x1920
              </Link>
              <span>{' / '}</span>
            </>
          )}
          <Link to={video.horizontal} target="_blank" download>
            1920x1080
          </Link>
        </div>
        <div className="sources__description">
          <p className="sources__text">{video.description}</p>
        </div>
      </div>
    ))

  return (
    <section className="sources section-block">
      <div className="sources__header">Download templates:</div>
      <div className="sources__content">{renderVideosList(videos)}</div>
    </section>
  )
}
