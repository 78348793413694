import React, { useEffect, useRef } from 'react'
import QRcode from 'qrcode'

export const useQr = (link, title = null) => {
  const ref = useRef(null)

  useEffect(() => {
    if (link) {
      QRcode.toCanvas(ref.current, link, (error) => {
        if (error) console.error(error)
        console.log('success!')
      })
    }
  }, [link])

  return link ? <canvas ref={ref} title={title} /> : null
}
