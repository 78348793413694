import { FC, useMemo } from 'react'
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import { AddStickerIcon } from '../../components/icons'
import { tw, tx } from '@twind/core'
import { routes } from '../../constants/routes'
import { Link, useHistory } from 'react-router-dom'
import { useAdsContext } from './AdsContexts'
import trash from '../../assets/img/ad/trash.svg'
import trashRed from '../../assets/img/ad/trash-red.svg'
import Progress from './Progress'
import { Button } from '../../components/ui'
import LoadingImageSelector from './LoadingImageSelector'
import { saveMedia } from '../../services/api/addsService'

export const Adding: FC = () => {
  const { file, resetImage, setFileTitle, fileBeingUploaded } = useAdsContext()

  const { push } = useHistory()
  const bc = useMemo<BreadcrumbItem[]>(
    () => [
      { title: 'Advertising campaign', path: routes.PROFILE.ADVERTISEMENT },
      { title: 'Adding content' },
    ],
    [],
  )

  const hasFileError = file ? file.error : false

  const videoImage = useMemo(() => {
    if (!file) return
    return (
      <video
        src={URL.createObjectURL(file.image)}
        className={tx`w-full h-full object-cover rounded-[20px]`}
      />
    )
  }, [])

  return (
    <div className="sticker-requirements max-md:w-full">
      <Breadcrumbs items={bc} />

      <section
        className={tx`sticker-requirements__content w-[574px] max-md:w-auto`}
      >
        <div className={tx`flex flex-wrap items-center`}>
          <Link
            to={routes.PROFILE.ADD}
            className={tw(`w-[78px] h-[78px] mb-4 block fill-black`)}
          >
            <AddStickerIcon />
          </Link>

          <LoadingImageSelector file={file} />
        </div>

        {!!file && (
          <>
            <div className={tx`flex items-center pt-[10px] max-md:flex-col`}>
              <div
                className={tx`${
                  file.error ? 'bg-[#FB6F8033]' : ''
                } flex flex-wrap items-center border-[1px] border-dotted py-[30px] px-[43px] max-md:p-[20px] rounded-[20px] border-[#DEDEDE]`}
              >
                <div className={tx`relative`}>{videoImage}</div>
                {file.error && (
                  <div className="text-[#FB6F80] w-full text-center text-[13px] font-[KohinoorBangla] px-[43px] mt-[10px]">
                    <h3 className={tx`text-[18px]`}>Error</h3>
                    <p>
                      The size of your artwork must be at least 1280х720px This
                      photo will not be accepted.
                    </p>

                    <Link
                      className={tx`bg-[#FB6F80] inline-block mt-[30px] w-max text-white px-[20px] py-[11px] rounded-[6px]`}
                      to={routes.PROFILE.ADD}
                    >
                      Browse a new file
                    </Link>
                  </div>
                )}
              </div>
              <div
                className={tx`ml-[22px] max-md:ml-0 max-md:mt-[22px] w-[50px] shrink-0`}
              >
                {fileBeingUploaded ? (
                  <div className={tx`w-full`}>
                    <Progress isCompleted={!!file.fileUrl} />
                  </div>
                ) : (
                  <div className={tx`w-full shrink-0`}>
                    <button
                      type="button"
                      className={tx`rounded-full border-[1px] border-transparent hover:border-[#AF90FF]`}
                      onClick={() => {
                        resetImage(file.fileUrl?.split('/').pop())
                        push(routes.PROFILE.ADD)
                      }}
                    >
                      {' '}
                      <img src={file.error ? trashRed : trash} alt="trash" />
                    </button>
                  </div>
                )}
              </div>
            </div>

            <form
              action=""
              className={tx`pt-[35px]`}
              onSubmit={(e) => {
                e.preventDefault()
                saveMedia(file, e.currentTarget['option'].value).then(() => {
                  resetImage()
                })
              }}
            >
              {file && (
                <div
                  className={tx`flex flex-col font-[KohinoorBangla] border-b-[1px] bporder-[#E5E5E5]`}
                >
                  <label className={tx`text-[#9B9B9B] text-[13px]`}>
                    Title (optional)
                  </label>
                  <input
                    name={`option`}
                    className={tx`text-[#4A4A4A] text-[18px] leading-[27px] outline-none`}
                    type="text"
                    value={file.title}
                    onChange={(e) => {
                      setFileTitle(e.currentTarget.value)
                    }}
                  />
                </div>
              )}

              {!hasFileError && (
                <div className={tx`mt-[30px] max-w-[300px]`}>
                  <Button
                    type="submit"
                    disabled={!file.id}
                    content={
                      <div className={tx`uppercase text-[17px] px-[70px]`}>
                        submit content
                      </div>
                    }
                  />
                </div>
              )}
            </form>
          </>
        )}
      </section>
    </div>
  )
}
