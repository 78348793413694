import React from 'react'

export const En = () => (
  <>
    <p>
      <strong>Information displayed on the user’s profile: </strong>
    </p>
    <p>The user’s profile shows only the number of Followers and Following.</p>
    <p>
      According to our privacy policy, you will not be able to go to the
      profiles of Followers and Following of a user whose page you visited.
      These parameters are set to understand whether the user is active in the
      application.
    </p>
    <p>
      To find this user faster next time, mark him by clicking{' '}
      <strong>Follow</strong>. Afterwards, he will be displayed in your{' '}
      <strong>Following</strong> in your account.
    </p>
    <p>
      It is possible to request access to a user with a{' '}
      <em>
        <strong>private</strong>
      </em>{' '}
      profile. If the user decides to accept your request, he will confirm it
      and you will be able to send him a message.
    </p>
    <p>
      In the menu of the user with an{' '}
      <em>
        <strong>open </strong>
      </em>
      profile, one can:
    </p>
    <p>
      <strong>Invite to… </strong>- invite him to your group, channel, or event.
    </p>
    <p>
      <strong>Send message </strong>– send a private message and see the rest of
      your messages by opening the main search page in the Chats section and
      then the People subsection.
    </p>
    <p>
      <strong>Block </strong>– block a person, after this, he will not be able
      to see you in the application.
    </p>
    <p>
      <strong>Report </strong>– send a complaint to the administration, if the
      person has committed any violations.
    </p>
    <p>
      <strong>Copy URL </strong>– copy the URL address of the person’s profile
      and forward it in any way to anyone you want.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Features of the Dating system and Finding Friends (ping &amp;
        Hugging) in the application:{' '}
      </strong>
    </p>
    <p>The dating system is very simple.</p>
    <p>
      Any registered user can open the filters on the main search screen and
      turn the <strong>Show me on Dating (or ping &amp; Hugging)</strong>{' '}
      button to <strong>On</strong>. Now you are in the search of the dating
      service.
    </p>
    <p>
      On the search page, click the heart button <strong>Dating. </strong>Now
      you see all services of the section <strong>Dating</strong>.
    </p>
    <p>
      By activating the <strong>ping &amp; Hugging</strong> button, you can
      switch to the service for finding new friends, someone to hang out with,
      to meet, etc.
    </p>
    <p>
      When clicking <strong>Dating</strong> you deactivate{' '}
      <strong>ping &amp; Hugging</strong> and vice versa. This is for
      convenience, to visually separate people looking for romantic
      relationships from those looking to make new friends.
    </p>
    <p>
      By default (free), all people who want to meet are visible in the list
      (with the toggle for finding new friends turned on) within a radius of 10
      miles (16 km) from you. There is always an opportunity to purchase a
      subscription plan most suitable for you, by increasing the view radius on
      the map and by choosing for how many days you want to subscribe*. Only
      users who chose their location to be displayed can be seen on the map.
    </p>
    <p>
      To allow other dating members to see your location on the map, turn the{' '}
      <strong>Display me on a dating map </strong>button to on in filters (it is
      also duplicated in the <strong>Settings</strong> section in the account).
      To maintain confidentiality, your location is displayed within 2 miles (3
      km) from your real location.
    </p>
    <p>
      To indicate your current location, turn the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      In the filters section, you can choose gender, sexual orientation, and the
      age of people you want to find. We deliberately did not complicate the
      process of dating by adding additional filters such as hobbies, dating
      purposes, and others. We think that these filters are not objective and
      that people should get to know each other during the communication
      process.
    </p>
    <p>&nbsp;</p>
    <p>
      * <em>A subscription plan is purchased only with internal coins – </em>
      <em>
        <strong>MetaFek Coins</strong>
      </em>
      <em>
        {' '}
        (see the section “What are MetaFek Coins and Where To Get Them?”). Coins
        can be earned or purchased.{' '}
      </em>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Features of the Avatar feed in the Dating and ping &amp; Hugging
        sections:{' '}
      </strong>
    </p>
    <p>
      To be displayed in the <strong>Dating</strong> feed, set the{' '}
      <strong>Show me on Dating </strong>button to <strong>On</strong> in the
      filters, located near the search bar on the main search screen. Then go
      back to the search screen and click the <strong>Dating</strong> button to
      display the dating service on the map.
    </p>
    <p>
      To be displayed in the <strong>ping &amp; Hugging </strong>feed set the{' '}
      <strong>Show me on ping &amp; Hugging </strong>button to{' '}
      <strong>On</strong> in the filters, located near the search bar on the
      main search screen. Then go back to the search screen and click the{' '}
      <strong>ping &amp; Hugging</strong> button to display the dating
      service on the map.
    </p>
    <p>
      Click on + and choose a radius, in which you want to be visible to all
      users registered in the dating section. There, you also choose the number
      of rounds (how many times you want to be displayed in the feed). You can
      also select a limited display time (the number of minutes between rounds
      of your avatars) if you purchase 2 or more rounds<strong>*</strong>.
    </p>
    <p>
      You can add text of up to 150 characters, which will be visible to
      everyone in the selected radius. Shifts in the dating feed position occur
      due to the replacement of a previous avatar by the appearance of a new
      one. This moves the feed to the left by one position.
    </p>
    <p>&nbsp;</p>
    <p>
      * <em>A subscription plan is purchased only with internal coins – </em>
      <em>
        <strong>MetaFek Coins</strong>
      </em>
      <em>
        {' '}
        (see the section “What are MetaFek Coins and Where To Get Them?”). Coins
        can be earned or purchased.{' '}
      </em>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Features of the Avatar feed in the general search section:{' '}
      </strong>
    </p>
    <p>
      With <strong>Dating </strong>and <strong>ping &amp; Hugging </strong>
      being turned OFF, click on <strong>+</strong> and choose a radius, in
      which you want to be visible to all users on the general search screen.
    </p>
    <p>
      You can select exactly what you want to display in the news feed: your
      profile, a group, an event, a business, or a channel.
    </p>
    <p>
      There, you can also choose the number of rounds (how many times you want
      to appear in the feed).
    </p>
    <p>
      You can also select a limited display time (the number of minutes between
      rounds of your avatars) if you purchase 2 or more rounds*.
    </p>
    <p>
      You can add text of up to 150 characters, which will be visible to
      everyone in the selected radius.
    </p>
    <p>
      Shifts in the dating feed position occur due to the replacement of a
      previous avatar by the appearance of a new one. This moves the feed to the
      left by one position
    </p>
    <p>
      You can use the toggle buttons to adjust the appearance of the feed and
      also choose if you want to see only personal profiles or add new groups,
      events, channels, businesses, by activating the corresponding buttons on
      the main search screen.
    </p>
    <p>&nbsp;</p>
    <p>
      * <em>A subscription plan is purchased only with internal coins – </em>
      <em>
        <strong>MetaFek Coins</strong>
      </em>
      <em>
        {' '}
        (see the section “What are MetaFek Coins and Where To Get Them?”). Coins
        can be earned or purchased.{' '}
      </em>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Information displayed on the Events page of another user:{' '}
      </strong>
    </p>
    <p>Event Name.</p>
    <p>Event Description.</p>
    <p>Event Start Date and Time.</p>
    <p>Event End Date and Time.</p>
    <p>Event Administrator.</p>
    <p>Event Participants.</p>
    <p>Event Location.</p>
    <p>Event Photos.</p>
    <p>Limit Number of Participants, if applicable.</p>
    <p>
      An ability to pin a chat of the event, which will then be displayed first
      in the <strong>events </strong>chat section<strong>. </strong>
    </p>
    <p>An ability to turn new message notifications on or off.</p>
    <p>
      To allow other participants of this event to see your location on the map,
      set the <strong>Show me to this community </strong>button to on. To
      maintain confidentiality, your location is displayed within 2 miles (3 km)
      from your real location.
    </p>
    <p>
      To indicate your current location, set the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      To share the information about this event with other people through other
      messengers, SMS, or email, set the <strong>Share with friends </strong>
      button to on.
    </p>
    <p>
      To see other participants of the event who have agreed to show their
      geolocation within the framework of this event, click on the{' '}
      <strong>Maps </strong>icon.
    </p>
    <p>
      To join the event, click on the upper right corner in the menu (three
      dots) and select <strong>Join </strong>(send a request to the
      administrator to join the event). After the administrator has approved
      your request, you will be able to communicate in the event chat and the
      event will appear in your account, in the <strong>My Events</strong>{' '}
      section<strong>. </strong>After the event is over, the chat will disappear
      from the application automatically.
    </p>
    <p>
      To leave the event, click on the <strong>Leave </strong>button.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Information displayed on the Groups page of another user:{' '}
      </strong>
    </p>
    <p>Group Name.</p>
    <p>Group Description.</p>
    <p>Group Administrator.</p>
    <p>Group Participants.</p>
    <p>Group Photos.</p>
    <p>Limit Number of Participants, if applicable.</p>
    <p>An ability to turn new message notifications on or off.</p>
    <p>
      An ability to pin a chat of the group, which will then be displayed first
      in the G<strong>roups </strong>chat section<strong>. </strong>
    </p>
    <p>
      Information on whether the group is public and if you joined it by finding
      it in the search, or private, in which you were invited through the link.
      Private groups are not displayed in general search.
    </p>
    <p>
      To allow other participants of this group to see your location on the map,
      set the <strong>Show me to this community </strong>button to on. To
      maintain confidentiality, your location is displayed within 2 miles (3 km)
      from your real location.
    </p>
    <p>
      To indicate your current location, set the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      To share the information about this group with other people through other
      messengers, SMS, or email, set the <strong>Share with friends </strong>
      button to on.
    </p>
    <p>
      To see other participants of the group who have agreed to show their
      geolocation within the framework of this group, click on the{' '}
      <strong>Maps </strong>icon.
    </p>
    <p>
      To join the group, click on the upper right corner in the menu (three
      dots) and select <strong>Join </strong>(send a request to the
      administrator to join the group). After the administrator has approved
      your request, you will be able to communicate in the group chat and the
      group will appear in your account, in the <strong>My Groups</strong>{' '}
      section<strong>. </strong>
    </p>
    <p>
      To leave the group, click on the <strong>Leave </strong>button.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Information displayed on the Channel page of another user:{' '}
      </strong>
    </p>
    <p>Channel Name.</p>
    <p>Channel Description.</p>
    <p>Channel Administrator.</p>
    <p>Chat Participants.</p>
    <p>Channel Photos.</p>
    <p>Limit Number of Participants, if applicable.</p>
    <p>An ability to turn new message notifications on or off.</p>
    <p>
      An ability to pin a chat of the channel, which will then be displayed
      first in the <strong>chats </strong>category of{' '}
      <strong>Channels. </strong>
    </p>
    <p>
      Information on whether the channel is public and if you joined it by
      finding it in the search, or private, in which you were invited through
      the link. Private channels are not displayed in general search.
    </p>
    <p>
      To allow other participants of this channel to see your location on the
      map, set the <strong>Show me to this community </strong>button to on. To
      maintain confidentiality, your location is displayed within 2 miles (3 km)
      from your real location.
    </p>
    <p>
      To indicate your current location, set the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      To share the information about this channel with other people through
      other messengers, SMS, or email, set the{' '}
      <strong>Share with friends </strong>button to on.
    </p>
    <p>
      To see other participants of the channel who have agreed to show their
      geolocation within the framework of this channel, click on the{' '}
      <strong>Maps </strong>icon.
    </p>
    <p>
      To join the channel, click on the upper right corner in the menu (three
      dots) and select <strong>Join </strong>(send a request to the
      administrator to join the channel). After the administrator has approved
      your request, you will be able to comment on the news of the channel’s
      administrator and the channel will appear in your account, in the{' '}
      <strong>My Channels</strong> section<strong>. </strong>
    </p>
    <p>
      To leave the channel, click on the <strong>Leave </strong>button.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Settings when creating and editing your EVENT: </strong>
    </p>
    <p>Create and Edit Event Avatar.</p>
    <p>Create and Edit Event Name.</p>
    <p>Create and Edit Event Type.</p>
    <p>Create and Edit Event Description.</p>
    <p>Create and Delete Event Photos.</p>
    <p>Set and Change Event Start Date and Time.</p>
    <p>Set and Change Event End Date and Time.</p>
    <p>Set Event Place or Address.</p>
    <p>See Event Administrator.</p>
    <p>See Event Participants.</p>
    <p>Turn new message notifications on or off.</p>
    <p>Control the Limit Number of Participants, if applicable.</p>
    <p>
      Pin a chat of the event, which will then be displayed first in the{' '}
      <strong>chats </strong>category of <strong>Events </strong>(only for you)
      <strong>. </strong>
    </p>
    <p>
      Information on whether the event is public and if you joined it by finding
      it in the search, or private, in which you were invited through the link.{' '}
      <strong>Private events</strong> are not displayed in the general search.
      After creating an event, it is not possible to change its privacy status.
    </p>
    <p>
      To make an event public, available for the search for other users or
      private, in which it is possible to invite participants only through the
      link, set the <strong>Private </strong>button to on. Private events are
      not displayed in the general search. After creating an event, it is not
      possible to change its privacy status in the category{' '}
      <strong>Edit. </strong>
    </p>
    <p>
      To allow other participants of this event to see your location on the map,
      set the <strong>Show me to this community </strong>button to on. To
      maintain confidentiality, your location is displayed within 2 miles (3 km)
      from your real location.
    </p>
    <p>
      To indicate your current location, set the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      To share the information about this channel with other people through
      other messengers, SMS, or email, set the <strong>Invite people </strong>
      button to on.
    </p>
    <p>
      To see other participants of the event who have agreed to show their
      geolocation within the framework of this event, click on the{' '}
      <strong>Maps </strong>icon.
    </p>
    <p>
      To clear the chat completely, click on{' '}
      <strong>Clear chat history. </strong>
    </p>
    <p>
      <u>Be careful! Data will be lost forever! </u>
    </p>
    <p>
      To clear the chat completely and delete the event, click on{' '}
      <strong>Delete and Leave. </strong>
      <u>Data will be lost forever! </u>
    </p>
    <p>
      After the event is over, the community will disappear from the application
      automatically.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Settings when creating and editing your GROUP: </strong>
    </p>
    <p>Create and Edit Group Avatar.</p>
    <p>Create and Edit Group Name.</p>
    <p>Create and Edit Group Description.</p>
    <p>Create and Delete Group Photos.</p>
    <p>See Group Administrator.</p>
    <p>See Group Participants.</p>
    <p>Turn new message notifications on or off.</p>
    <p>Control the Limit Number of Participants, if applicable.</p>
    <p>
      Pin a chat of the group, which will then be displayed first in the{' '}
      <strong>Groups </strong>category of <strong>Groups </strong>(only for you)
      <strong>. </strong>
    </p>
    <p>
      To make a group public, available for the search for other users or
      private, in which it is possible to invite participants only through the
      link, set the <strong>Private </strong>button to on. Private groups are
      not displayed in general search. After creating a group, it is not
      possible to change its privacy status<strong>. </strong>
    </p>
    <p>
      To allow other participants of this group to see your location on the map,
      set the <strong>Show me to this community </strong>button to on. To
      maintain confidentiality, your location is displayed within 2 miles (3 km)
      from your real location.
    </p>
    <p>
      To indicate your current location, set the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      To share the information about this group with other people through other
      messengers, SMS, or email, set the <strong>Invite people </strong>button
      to on.
    </p>
    <p>
      To see other participants of the group who have agreed to show their
      geolocation within the framework of this group, click on the{' '}
      <strong>Maps </strong>icon.
    </p>
    <p>
      To clear the chat completely, click on{' '}
      <strong>Clear chat history. </strong>
    </p>
    <p>
      <u>Be careful! Data will be lost forever! </u>
    </p>
    <p>
      To clear the chat completely and delete the group, click on{' '}
      <strong>Delete and Leave. </strong>
      <u>Data will be lost forever! </u>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Settings when creating and editing your Channel: </strong>
    </p>
    <p>Create and Edit Channel Avatar.</p>
    <p>Create and Edit Channel Name.</p>
    <p>Create and Edit Channel Description.</p>
    <p>Create and Delete Channel Photos.</p>
    <p>Control the Limit Number of Participants, if applicable.</p>
    <p>See Channel Administrator.</p>
    <p>See Channel Participants.</p>
    <p>Turn new message notifications on or off.</p>
    <p>
      Pin a chat of the channel, which will then be displayed first in the{' '}
      <strong>chats </strong>category of <strong>Groups Channel </strong>
      (only for you)<strong>. </strong>
    </p>
    <p>
      To make a channel public, available for the search for other users or
      private, in which it is possible to invite participants only through the
      link, set the <strong>Private </strong>button to on. Private groups are
      not displayed in general search. After creating a channel, it is not
      possible to change its privacy status<strong>. </strong>
    </p>
    <p>
      To allow other participants of this channel to see your location on the
      map, set the <strong>Show me to this community </strong>button to on. To
      maintain confidentiality, your location is displayed within 2 miles (3 km)
      from your real location.
    </p>
    <p>
      To indicate your current location, set the{' '}
      <strong>Show my exact location </strong>button to on.
    </p>
    <p>
      To share the information about this channel with other people through
      other messengers, SMS, or email, set the <strong>Invite people </strong>
      button to on.
    </p>
    <p>
      To see other participants of the channel who have agreed to show their
      geolocation within the framework of this channel, click on the{' '}
      <strong>Maps </strong>icon.
    </p>
    <p>
      To clear the chat completely, click on{' '}
      <strong>Clear chat history. </strong>
    </p>
    <p>
      <u>Be careful! Data will be lost forever! </u>
    </p>
    <p>
      To clear the chat completely and delete the channel, click on{' '}
      <strong>Delete and Leave. </strong>
      <u>Data will be lost forever! </u>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Opportunities for advertising your BUSINESS in the application:{' '}
      </strong>
    </p>
    <p>
      In your account, click on the <strong>My Business </strong>section and
      click on + “Create A New Business”.
    </p>
    <p>Create Business Avatar.</p>
    <p>Create Business Name.</p>
    <p>Choose Business Category.</p>
    <p>Create Business Description.</p>
    <p>Create Business Photos.</p>
    <p>Set Business Address, Phone Number, and Website.</p>
    <p>
      Everyone located within the 48 km radius can see your business for free.
    </p>
    <p>
      To buy an additional coverage zone of visibility of your business and to
      select the number of days for which you want to purchase a subscription,
      go to the <strong>Advertisement plan</strong> section<strong>. </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      <em>
        Attention! The creation of additional businesses costs 50 MetaFek Coins
        per year. Coins can be earned or purchased. See the section “What are
        MetaFek Coins and Where To Get Them?”.{' '}
      </em>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>What are MetaFek Coins and Where To Get Them?</strong>
    </p>
    <p>
      We developed a coin in the application to pay for various internal
      services (the currency of this application). In the personal account in
      the right corner is your current balance, clicking on it brings you to the
      section where you can buy coins and learn how to earn them.
    </p>
    <p>
      If you invited a participant or promoted the Relagram application on
      social networks, you will earn free coins. See the Balance section to
      learn more about the rules for receiving coins.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Personal account</strong>
    </p>
    <p>The personal account contains:</p>
    <p>Name and Last Name used during registration.</p>
    <p>Avatar.</p>
    <p>Age (for the dating service).</p>
    <p>About You section (short description of yourself).</p>
    <p>MetaFek Coin Balance with the link to charge your card.</p>
    <p>Edit Profile.</p>
    <p>
      <strong>Share – </strong>share a link to your profile with the help of
      other messengers, SMS, or email.
    </p>
    <p>
      <strong>Search Friends</strong> – find people in the application through
      the main search.
    </p>
    <p>
      <strong>My Groups </strong>– groups created by you and groups that you
      participate in.
    </p>
    <p>
      <strong>My Events - </strong>events created by you and events that you
      participate in.
    </p>
    <p>
      <strong>My Business </strong>– a business profile that you created for
      advertising on the search map and in the search itself.
    </p>
    <p>
      <strong>Dating – </strong>a link to purchasing or checking your current
      dating plan.
    </p>
    <p>
      <strong>Purchase History – </strong>a display of all transactions related
      to purchasing or spending MetaFek Coins.
    </p>
    <p>
      <strong>Pending Requests </strong>– incoming requests from participants
      about joining a group or an event created by you. This section also has
      your requests about joining other groups or events. The requests to get
      access to your profile and to send you a message are also coming to this
      section. Check this section regularly.
    </p>
    <p>
      <strong>Settings</strong> – general settings (notifications, a display of
      your avatar on the general and dating searches maps. There, you can also
      RESET all settings into their initial state or delete your account in
      Relagram. Data will be lost forever.
    </p>
    <p>
      <strong>Help – </strong>legal documents and agreements accepted during the
      registration.
    </p>
    <p>
      <strong>Report a Bug – </strong>a message to customer support about a bug.
    </p>
    <p>
      <strong>Rate Us – </strong>rate the application.
    </p>
    <p>
      <strong>Log out – </strong>log out from the application.
    </p>
    <p>
      <strong>Delete Account – </strong>a complete deletion of all personal data
      and a closure of the profile in the Relagram application.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Features of the search screen: </strong>
    </p>
    <p>
      The top line of the search screen contains toggle buttons{' '}
      <strong>Dating, People, Event, Local Deals </strong>that are responsible
      for displaying objects on the map. When you type a text in the search bar,
      our algorithms show you matches in the search, which can be filtered by
      these filter-buttons –{' '}
      <strong>Dating, People, Event, Local Deals, </strong>and by{' '}
      <strong>Groups and Channels </strong>buttons, if talking about objects
      that cannot be displayed on a map due to their features.
    </p>
    <p>
      To work in more detail with the settings for displaying information on
      your map, go to the <strong>advanced filters</strong> section located to
      the right of the text input line.
    </p>
    <p>
      The<strong> Chat </strong>section has all your communication history. For
      convenience, chats are divided into personal chats and groups and events
      chats, in which you are participating.
    </p>
    <p>
      To go to your account, click the <strong>Profile</strong> button in the
      lower right corner.
    </p>
  </>
)
