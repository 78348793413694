import React, { FC, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'

interface ModalContextProps {
  popupConfig: PopupConfig | null
  modalConfig: ModalConfig | null
  openPopup: (config: PopupConfig) => void
  openModal: (config: ModalConfig) => void
  closePopup: () => void
}

type PopupConfig = {
  type: 'warning' | 'error' | 'ok'
  message: string | JSX.Element
  reason?: string
  onClose?: () => void
}
type ModalConfig = PopupConfig & {
  onOk: () => void | Promise<unknown>
  onOkText: React.ReactNode
}

const Context = createContext<ModalContextProps>(null!)

export const ModalContext: FC<PropsWithChildren> = ({ children }) => {
  const [popupConfig, setPopupConfig] = useState<PopupConfig | null>(null)
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null)

  const openPopup = useCallback((config: PopupConfig) => {
    setPopupConfig(config)
  }, [])

  const openModal = useCallback((config: ModalConfig) => {
    setModalConfig(config)
  }, [])

  const closePopup = useCallback(() => {
    setPopupConfig(null)
    setModalConfig(null)
  }, [])

  return (
    <Context.Provider
      value={{
        closePopup,
        openPopup,
        popupConfig,
        modalConfig,
        openModal,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useModalContext = () => useContext(Context)
