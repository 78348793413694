import { FC } from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

interface FunctionItemProps {
  icon: string
  title: string
  subtitle: string
  linkTo: string
}

const FunctionItem: FC<FunctionItemProps> = ({
  icon,
  title,
  subtitle,
  linkTo,
}) => {
  return (
    <li className="funcItem">
      <Link to={linkTo}>
        <img className="funcItem--img" src={icon} alt="icon" />

        <div className="funcItem--block">
          <div className="funcItem--title">{title}</div>
          <div className="funcItem--subtitle">{subtitle}</div>
        </div>
      </Link>
    </li>
  )
}

export default FunctionItem
