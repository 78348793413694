import { endpoints } from '../../constants/endpoints'
import { get, post, deleteReq } from '../authRequestsService'

export const signIn = (data) => post(endpoints.SIGN_IN, data)

export const sendCodeForPasswordResetByEmail = (data) =>
  post(endpoints.SEND_RESET_PASSWORD_CODE_TO_EMAIL, data)

export const confirmPasswordResetByEmail = (data) =>
  post(endpoints.CONFIRM_RESET_PASSWORD_CODE_BY_EMAIL, data)

export const getUserProfile = () => get(endpoints.PROFILE)

export const getUserAccount = () => get(endpoints.ACCOUNT)

export const deleteAccount = () => {
  return deleteReq(endpoints.DELETE_THIS_ACCOUNT)
}

export const createCryptoPayment = (data) =>
  post(endpoints.CRYPTO_PAYMENT, data)

export const checkCryptoPaymentStatus = (transactionId) =>
  get(endpoints.CHECK_CRYPTO_PAYMENT_STATUS(transactionId))
