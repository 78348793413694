import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

const logger = createLogger({
  collapsed: true,
})

const middlewares = [thunk, logger]

export default function configureStore() {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )
}
