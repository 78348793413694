import { FC, ReactNode } from 'react'
import { User, UserViews } from '../../services/types/PromoCampaign'

interface OverviewProps {
  videoUrl: string
  date: string
  pay: string | number
  showTo: string
  showForMale: string
  showForFemale: string
  country: string[]
  business: string[] | null
  interests: string[] | null
  redirectUrl: string
  amountOfViewsToPurchase: number
  amountOfViewsToPerson: number
  userViews: UserViews[]
}

const Overview: FC<OverviewProps> = (props) => {
  return (
    <>
      <li
        data-overview
        className="p-[24px] rounded-2xl font-[KohinoorBangla] text-[13px] leading-[22px] shadow-xl max-w-[600px]"
        style={{ boxShadow: '0px 8px 16px 0px #0000001A' }}
      >
        <video
          controls={false}
          src={props.videoUrl}
          className="rounded-md mb-[24px] min-w-[526px] w-full"
        />

        <div className="flex w-full justify-between mb-[24px]">
          <Title<string> title="Date" value={props.date} />
          <Title<string | number> title="Pay" value={`${props.pay} MFC`} />
        </div>

        <Title<string> title="Show my ad to" value={props.showTo} />
        {props.showForMale && (
          <Title<string> title="Male" value={`${props.showForMale} years`} />
        )}
        {props.showForFemale && (
          <Title<string>
            title="Female"
            value={`${props.showForFemale} years`}
          />
        )}
        <Title<string>
          title="Pick Countres"
          value={props.country.join(', ') || 'not specified'}
        />
        <Title<string>
          title="Business Ownership"
          value={props.business?.join(', ') || 'not specified'}
        />
        <Title<string>
          title="Interests"
          value={props.interests?.join(', ') || 'not specified'}
        />
        <Title<string>
          title="When clicking on my ad users should be redirected  to this link"
          value={props.redirectUrl || 'not specified'}
        />
        <Title<string>
          title="Amount of views I wish to purchase"
          value={props.amountOfViewsToPurchase?.toString()}
        />
        <Title<string>
          title="Maximum amount of views for same person (no more than entered below)"
          value={props.amountOfViewsToPerson?.toString()}
        />
      </li>
      {!!props.userViews.length && (
        <li className="mt-[10px]">
          <ul
            style={{ boxShadow: '0px 8px 16px 0px #0000001A' }}
            className="bg-white overflow-hidden rounded-2xl w-full max-w-[600px]"
          >
            {props.userViews.map((uv) => (
              <li
                key={uv.id}
                className="justify-between grid grid-cols-3 border-b-[1px] font-[Poppins] text-[15px] leading-[22px] text-[#4A4A4A]"
              >
                <div className="p-[20px]">
                  {uv.user.firstName} {uv.user.lastName}
                </div>
                <div className="p-[20px]">
                  {new Date(uv.createdAt).toLocaleDateString()}
                </div>
                <div className="p-[20px]">
                  {new Date(uv.createdAt).toLocaleTimeString(undefined, {
                    timeStyle: 'short',
                    hourCycle: 'h12',
                  })}
                </div>
              </li>
            ))}
          </ul>
        </li>
      )}
    </>
  )
}

function Title<T extends ReactNode>({
  title,
  value,
}: {
  title: string
  value: T
}) {
  return (
    <div className="text-[#2A2E3C] my-[5px]">
      <span className="text-[#919EAB]">{title}:</span> {value}
    </div>
  )
}

const mockUserViews = (
  [
    {
      id: '23',
      createdAt: new Date().toISOString(),
      user: {
        firstName: 'kek',
        lastName: 'lol',
      },
    },
    {
      id: '231',
      createdAt: new Date().toISOString(),
      user: {
        firstName: 'kek',
        lastName: 'lol',
      },
    },
  ] as UserViews[]
)

export default Overview
