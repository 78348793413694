const allBusinessItems = [
  {
    name: 'All',
    types: [
      'STORE',
      'BANKING',
      'EDUCATION',
      'AGRICULTUAL',
      'ESTATE',
      'REPAIR',
      'CULTURE',
      'SPORT',
      'ACCOUNTING',
      'LEGAL',
      'COMPUTER',
      'CAFE',
      'VEGAN',
      'TOURRISM',
      'UTILITIES',
      'TRANSPORTATION',
      'OTHER',
      'RELIGION',
      'BAR',
      'WEDDING',
      'AUTOE',
      'GOVERMENT',
      'MEDICINE',
      'GASSTATION',
      'BEAUTY',
      'INDUSTIAL',
      'PET',
      'IMPROVMENTS',
      'COMUNITY',
      'PUB',
      'NIGHTLIFE',
      'RESTAURANT',
    ],
  },
  {
    name: 'Accounting & Business Services',
    types: ['ACCOUNTING'],
  },
  {
    name: 'Agricultural',
    types: ['AGRICULTUAL'],
  },
  {
    name: 'Art, Culture & Entertainment',
    types: ['CULTURE'],
  },
  {
    name: 'Auto Sales & Service',
    types: ['AUTOE'],
  },
  {
    name: 'Banking, Finance & Insurance',
    types: ['BANKING'],
  },
  {
    name: 'Bar',
    types: ['BAR'],
  },
  {
    name: 'Beauty & Personal Care',
    types: ['BEAUTY'],
  },
  {
    name: 'Coffe, Tea & Bakery',
    types: ['CAFE'],
  },
  {
    name: 'Community Organisation',
    types: ['COMUNITY'],
  },
  {
    name: 'Education',
    types: ['EDUCATION'],
  },
  {
    name: 'Gas station',
    types: ['GASSTATION'],
  },
  {
    name: 'Government',
    types: ['GOVERMENT'],
  },
  {
    name: 'Home Improvement',
    types: ['IMPROVMENTS'],
  },
  {
    name: 'Industrial and Manufacturing',
    types: ['INDUSTIAL'],
  },
  {
    name: 'Internet, Computers & Services',
    types: ['COMPUTER'],
  },
  { name: 'Legal Services', types: ['LEGAL'] },
  { name: 'Medicine & Health Care', types: ['MEDICINE'] },
  { name: 'Nightlife', types: ['NIGHTLIFE'] },
  { name: 'Pet Services', types: ['PET'] },
  { name: 'Pub', types: ['PUB'] },
  { name: 'Real Estate', types: ['ESTATE'] },
  { name: 'Religion and Spirituality', types: ['RELIGION'] },
  { name: 'Restaurant', types: ['RESTAURANT'] },
  { name: 'Service & Repair', types: ['REPAIR'] },
  { name: 'Sport & Recreation', types: ['SPORT'] },
  { name: 'Store', types: ['STORE'] },
  { name: 'Tourism', types: ['TOURRISM'] },
  { name: 'Transportation', types: ['TRANSPORTATION'] },
  { name: 'Utilities', types: ['UTILITIES'] },
  { name: 'Vegan lifestyle', types: ['VEGAN'] },
  { name: 'Wedding, Events & Meetings', types: ['WEDDING'] },
  { name: 'Other', types: ['OTHER'] },
]

export default allBusinessItems.map((item, id) => ({ id, ...item, }))
