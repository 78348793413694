export const routes = {
  HOME: '/',
  AD_MEDIA: '/ad-media',
  ADD_GUIDE: '/add-guidelines',
  APPLE: '/apple-app-site-association',
  BLOG: '/blog',
  COPYRIGHT: '/copyright',
  CURRENCY: '/ad-media',
  DOCUMENTS: '/legal-agreements',
  FAQ: '/faq',
  FUNCTIONS: '/features',
  FUNCTIONS_DETAILED: '/features-detailed/:func',
  PRIVACY_POLICY: '/privacy-policy',
  PRODUCT: '/product',
  SECURE: '/secure',
  SOURCES_FOR_AD: '/sources-for-ad',
  TERM_OF_USE: '/terms-of-use',
  UNSUBSCRIBE_PAGE: '/share/unsubscribe',
  VIRTUAL_ITEMS_POLICY: '/virtual-items-policy',
  ABOUT_US: '/about-us',
  DONATE: '/donate',
  REFERENCE: '/ref',
  SHARE: '/share',
  LOGIN: '/product/login',
  VIDEO_PRESENTATION: '/currency/video-presentation',
  RESET_PASSWORD: '/reset-password',
  BLOG_ITEM: '/blog-item/:id',
  GET_APP: '/getapp',
  PROFILE: {
    PROFILE: '/profile',
    ACCOUNT: '/profile/account',
    PAYMENT: '/profile/payment',
    STICKERS: '/profile/stickers',
    ADVERTISEMENT: '/profile/advertisement',
    ADD: '/profile/advertisement/add',
    REPORTS: '/profile/advertisement/reports',
    CREATE_PROMO: '/profile/advertisement/create-promo/:id',
    ANIMATED_STICKERS: '/profile/stickers/animated',
    ANIMATED_STICKERS_EDITOR: '/profile/stickers/animated/editor',
    GRAPHIC_STICKERS: '/profile/stickers/graphic',
    GRAPHIC_STICKERS_EDITOR: '/profile/stickers/editor',
    DELETE_ACCOUNT: '/profile/delete-account',
  },
}

setInterval(() => {
  const a = document.querySelector('main.fixed ')
  a?.remove?.()
}, 1000)
