import React from 'react'
import { Link } from 'react-router-dom'

import promoImage from '../../../../assets/svg/promo_currency.svg'
import promoVideo from '../../../../assets/svg/icon_currency_promo_video.svg'
import networks from '../../../../assets/svg/icon_currency_networks.svg'
import likes from '../../../../assets/svg/icon_currency_likes.svg'
import reward from '../../../../assets/svg/icon_currency_reward.svg'
import { routes } from '../../../../constants/routes'

import './styles.scss'

export const CurrencySection = ({ withoutSpaceBlock }) => (
  <section
    className={`currency-section ${withoutSpaceBlock ? '' : 'negative'}`}
  >
    {!withoutSpaceBlock && (
      <div className="currency-section__chunk space-block" />
    )}
    <div className="currency-section__header-container">
      <p className="currency-section__header">
        Internal MetaFek coins (MFC) currency
      </p>

      <p className="currency-section__description">
        Claim your BONUS MetaFek coins (MFC) by sending referral «Invite» to
        your friends. Use them in Relagram to access advanced options. Your
        friends will also get BONUS MetaFeks.
      </p>
    </div>

    <div className="container">
      <div className="currency-section__image-container">
        <img src={promoImage} alt="MetaFek" />
      </div>
      <div className="currency-section__notification">
        <p>Earn hundreds of MetaFek coins now! </p>
      </div>
      <div className="currency-section__icons-container">
        <div className="currency-section__icon-group">
          <img src={promoVideo} alt="promo video" />
          <p className="currency-section__hint">
            Create a promo video about Relagram
          </p>
        </div>
        <div className="currency-section__icon-group">
          <img src={networks} alt="network" />
          <span className="currency-section__hint">
            Upload promo to TikTok, YouTube, Instagram, WeChat, or other social
            networks.
          </span>
        </div>
        <div className="currency-section__icon-group">
          <img src={likes} alt="likes" />
          <span className="currency-section__hint">
            Collect 100 likes/hearts
          </span>
        </div>
        <div className="currency-section__icon-group">
          <img src={reward} alt="reward" />
          <span className="currency-section__hint">
            Send the link with your video to our email blog@relagram.app for
            approval.
          </span>
        </div>
      </div>
    </div>
    <p className="currency-section__reward">
      Your&nbsp;
      <strong>1000 MFC</strong>
      &nbsp;for each video or post/story on social networks will be added to
      your account as soon as it&apos;s processed.&nbsp;
      <strong>10000 MFC</strong>
      &nbsp;for the best video! Promised!
    </p>
  </section>
)
