/* eslint-disable max-lines */
import { Link } from 'react-router-dom'
import { routes } from '../../../constants/routes'
import { contactMailLink } from '../../../constants/links'
import './styles.scss'

export const TermsOfUse = () => {
  return (
    <div className="documents">
      <div className="container">
        <div className="mt-4">
          <div>
            <h1>TERMS OF USE</h1>
            <p>&nbsp;</p>
            <p>
              <strong>
                Smart Modern Solutions LLC DBA hereinafter «Relagram»{' '}
              </strong>
            </p>
            <p>
              <strong>registered in New Jersey, USA</strong>
            </p>
            <p>&nbsp;</p>
            <h1>Definitions</h1>
            <p>The following terms shall have the meanings specified below:</p>
            <p>
              “Services” means the services and products supplied by «Relagram»
              Relagram
            </p>
            <p>to the Customer under the Agreement specified below.</p>
            <p>
              “Customer”, “you” (or “Client”) means any user, person or entity who
              creates an account and uses App Services supplied by Relagram under
              the Agreement.
            </p>
            <p>
              “Visitor” means any person who is browsing any website where{' '}
              <u>Relagram</u> Services are installed.
            </p>
            <p>
              “Agent” means any person who is employed by Customer to operate the
              Service.
            </p>
            <p>
              “End-user” means any person who uses App Services to communicate with
              the Customer.
            </p>
            <p>
              “Personal Data/Personal Information” means personal data within the
              meaning of the Regulation (EU) 2016⁄679 of the European Parliament and
              of the Council of 27 April 2016 on the protection of natural persons
              with regard to the processing of personal data and on the free
              movement of such data and repealing Directive 95/46/EC (The General
              Data Protection Regulation – “GDPR”), processed or controlled by{' '}
              <u>Relagram</u> (or a Sub-processor) on behalf of the Customer
              pursuant to or in connection with the Agreement.
            </p>
            <p>
              “Confidential Information” means all information, whether written or
              oral, provided by the disclosing party to the receiving party and
              which (i) is known by the receiving party to be confidential; (ii) is
              marked as or stated to be confidential; or (iii) ought reasonably to
              be considered by the receiving party to be confidential.
            </p>
            <p>
              “Third Party Service Provider” means any third party that collects,
              process and/or uses Personal Information under the instructions of
              Relagram including any consultant, representative, advisor, or
              independent contractor (including Sub-processors) who renders services
              to Relagram, a subsidiary, or an affiliate.
            </p>
            <p>
              “Sub-processor” means entities whose software, goods or services are
              used by Relagram in order to run a business, in particular while
              providing the Services.
            </p>
            <p>
              All terms derived from General Data Protection Regulation such as
              “Commission”, “Controller”, “Data Subject”, “Member State”, “Personal
              Data”, “Personal Data Breach”, “Processing”, “Processor” should be
              understood in line with their regular meaning coming from the
              Regulation.
            </p>
            <p>&nbsp;</p>
            <h1>1. Acceptance of Terms of Use Agreement.</h1>
            <p>
              By creating an account in Relagram, whether through a mobile device,
              mobile application or computer (collectively, the “Service”) you agree
              to be bound by (i) these Terms of Use, (ii) our{' '}
              <span>
                <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>
              </span>
              , which is incorporated by reference into this Agreement, and (iii)
              any terms disclosed and agreed to by you if you purchase additional
              features, products or services we offer on the Service (collectively,
              this “Agreement”). If you do not accept and agree to be bound by all
              of the terms of this Agreement, please do not use the Service.
            </p>
            <p>
              We may make changes to this Agreement and to the Service from time to
              time. We may do this for a variety of reasons including to reflect
              changes in or requirements of the law, new features, or changes in
              business practices. The most recent version of this Agreement will be
              posted on the Service under Terms and Conditions and on
              <u> Relagram</u>, and you should regularly check for the most recent
              version. The most recent version is the version that applies. If the
              changes include material changes that affect your rights or
              obligations, we will notify you in advance of the changes by
              reasonable means, which could include notification through the Service
              or via email. If you continue to use the Service after the changes
              become effective, then you agree to the revised Agreement.
            </p>
            <h1>2. Eligibility.</h1>
            <p>
              You must be at least 18 years of age to create an account on Relagram
              and use the Service. By creating an account and using the Service, you
              represent and warrant that:
            </p>
            <p>
              <ul>
                <li>
                  you can form a binding contract with <u>Inc</u>,
                </li>
                <li>
                  you are not a person who is barred from using the Service under
                  the // eslint-disable-next-line max-lines laws of{' '}
                  <u>your country</u> or any other applicable jurisdiction–meaning
                  that you do not appear on the <u>your country.</u>
                </li>
                <li>
                  you will comply with this Agreement and all applicable local,
                  state, national and international laws, rules and regulations, and
                </li>
                <li>
                  you have never been convicted of a felony or indictable offense
                  (or crime of similar severity), a sex crime, or any crime
                  involving violence, and that you are not required to register as a
                  sex offender with any state, federal or local sex offender
                  registry.
                </li>
              </ul>
            </p>
            <h1>3. Your Account.</h1>
            <p>
              In order to use <u>Relagram</u>, you may sign in using your account
              on Facebook, Google or other social media.&nbsp;
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your login
              credentials you use to sign up for App and you are solely responsible
              for all activities that occur under those credentials. If you think
              someone has gained access to your account, please immediately contact
              us.
            </p>
            <h1>4. Modifying the Service and Termination.</h1>
            <p>
              <u>Relagram</u> is always striving to improve the Service and bring
              you additional functionality that you will find engaging and useful.
              This means we may add new product features or enhancements from time
              to time as well as remove some features, and if these actions do not
              materially affect your rights or obligations, we may not provide you
              with notice before taking them. We may even suspend the Service
              entirely, in which event we will notify you in advance unless
              extenuating circumstances, such as safety or security concerns,
              prevent us from doing so.
            </p>
            <h1>5. Safety; Your Interactions with Other Users.</h1>
            <p>
              Though <u>Relagram</u> strives to encourage a respectful user
              experience through features like the ability to make your profile
              private it is not responsible for the conduct of any user on or off
              the Service. You agree to use caution in all interactions with other
              users, particularly if you decide to communicate off the Service or
              meet in person. You agree that you will not provide your financial
              information (for example, your credit card or bank account
              information), or wire or otherwise send money, to other users.
            </p>
            <p>
              YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU
              UNDERSTAND THAT <u>Relagram</u> DOES NOT CONDUCT CRIMINAL BACKGROUND
              CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS
              USERS. <u>Relagram</u> MAKES NO REPRESENTATIONS OR WARRANTIES AS TO
              THE CONDUCT OF USERS.
            </p>
            <h1>
              6. Rights <u>Relagram</u>. <u>Relagram</u> Grants You.
            </h1>
            <p>
              <u>Relagram</u> grants you a personal, worldwide, royalty-free,
              non-assignable, non-exclusive, revocable, and non-sublicensable
              license to access and use the Service. This license is for the sole
              purpose of letting you use and enjoy the Service’s benefits as
              intended by App and permitted by this Agreement. Therefore, you agree
              not to:
            </p>
            <ul>
              <li>
                use the Service or any content contained in the Service for any
                commercial purposes without our written consent.
              </li>
              <li>
                copy, modify, transmit, create any derivative works from, make use
                of, or reproduce in any way any copyrighted material, images,
                trademarks, trade names, service marks, or other intellectual
                property, content or proprietary information accessible through the
                Service without <u>Inc’s</u> prior written consent. Please read our
                separate{' '}
                <u>
                  <Link to={routes.COPYRIGHT}>
                    <strong>Copyright policy</strong>
                  </Link>
                </u>
                .
              </li>
              <li>
                express or imply that any statements you make are endorsed by{' '}
                <u>Relagram</u>
              </li>
              <li>
                use any robot, bot, spider, crawler, scraper, site search/retrieval
                application, proxy or other manual or automatic device, method or
                process to access, retrieve, index, “data mine,” or in any way
                reproduce or circumvent the navigational structure or presentation
                of the Service or its contents.
              </li>
              <li>
                use the Service in any way that could interfere with, disrupt or
                negatively affect the Service or the servers or networks connected
                to the Service.
              </li>
              <li>
                upload viruses or other malicious code or otherwise compromise the
                security of the Service.
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any information transmitted to or through the
                Service.
              </li>
              <li>
                “frame” or “mirror” any part of the Service without <u>Inc’s</u>{' '}
                prior written authorization.
              </li>
              <li>
                use meta tags or code or other devices containing any reference to{' '}
                <u>Relagram</u> or the Service (or any trademark, trade name,
                service mark, logo or slogan of <u>Relagram</u>) to direct any
                person to any other website for any purpose.
              </li>
              <li>
                modify, adapt, sublicense, translate, sell, reverse engineer,
                decipher, decompile or otherwise disassemble any portion of the
                Service, or cause others to do so.
              </li>
              <li>
                use or develop any third-party applications that interact with the
                Service or other users’ Content or information without our written
                consent.
              </li>
              <li>
                use, access, or publish the <u>Relagram</u> application programming
                interface without our written consent.
              </li>
              <li>
                probe, scan or test the vulnerability of our Service or any system
                or network.
              </li>
              <li>
                encourage or promote any activity that violates this Agreement.
              </li>
            </ul>
            <p>
              The Company may investigate and take any available legal action in
              response to illegal and/ or unauthorized uses of the Service,
              including termination of your account.
            </p>
            <p>
              Any software that we provide you may automatically download and
              install upgrades, updates, or other new features. You may be able to
              adjust these automatic downloads through your device’s settings.
            </p>
            <h1>
              7. Rights you Grant <u>Relagram.</u>
            </h1>
            <p>
              You agree that all information that you submit upon creation of your
              account, including information submitted from your Social media
              account, is accurate and truthful and you have the right to post the
              Content on the Service and grant the license to <u>Relagram</u>{' '}
              above.
            </p>
            <p>
              You understand and agree that we may monitor or review any Content you
              post as part of a Service. We may delete any Content, in whole or in
              part, that in our sole judgment violates this Agreement or may harm
              the reputation of the Service.
            </p>
            <p>
              When communicating with our customer care representatives, you agree
              to be respectful and kind. If we feel that your behavior towards any
              of our customer care representatives or other employees is at any time
              threatening or offensive, we reserve the right to immediately
              terminate your account.
            </p>
            <p>
              In consideration for Relagram allowing you to use the Service, you
              agree that we, our affiliates, and our third-party partners may place
              advertising on the Service. By submitting suggestions or feedback to{' '}
              <u>Relagram</u> regarding our Services, you agree that{' '}
              <u>Relagram</u> may use and share such feedback for any purpose
              without compensating you.
            </p>
            <p>
              Please be informed that <u>Relagram</u> may access, store and
              disclose your account information and Content if required to do so by
              law, by performing its agreement with you, or in a good faith belief
              that such access, storage or disclosure satisfies a legitimate
              interest, including to: (i) comply with legal process; (ii) enforce
              the Agreement; (iii) respond to claims that any Content violates the
              rights of third parties; (iv) respond to your requests for customer
              service; or (v) protect the rights, property or personal safety of the
              Company or any other person. Please check our Privacy Policy.
            </p>
            <h1>
              8. Community Rules.&nbsp;
              <br /> By using the Service, you agree that you will not:
            </h1>
            <ul>
              <li>
                use the Service for any purpose that is illegal or prohibited by
                this Agreement.
              </li>
              <li>use the Service for any harmful or nefarious purpose</li>
              <li>
                use the Service in order to damage <u>Relagram</u>
              </li>
              <li>
                violate our Community Guidelines, as updated from time to time.
              </li>
              <li>spam, solicit money from or defraud any users.</li>
              <li>
                impersonate any person or entity or post any images of another
                person without his or her permission.
              </li>
              <li>
                bully, “stalk,” intimidate, assault, harass, mistreat or defame any
                person.
              </li>
              <li>
                post any Content that violates or infringes anyone’s rights,
                including rights of publicity, privacy, copyright, trademark or
                other intellectual property or contract right. Please read our
                separate{' '}
                <u>
                  <Link to={routes.COPYRIGHT}>
                    <strong>Copyright policy</strong>
                  </Link>
                </u>
                .
              </li>
              <li>
                post any Content that is hate speech, threatening, sexually explicit
                or pornographic; incites violence; or contains nudity or graphic or
                gratuitous violence.
              </li>
              <li>
                post any Content that promotes racism, bigotry, hatred or physical
                harm of any kind against any group or individual.
              </li>
              <li>
                solicit passwords for any purpose, or personal identifying
                information for commercial or unlawful purposes from other users or
                disseminate another person’s personal information without his or her
                permission.
              </li>
              <li>
                use another user’s account, share an account with another user, or
                maintain more than one account.
              </li>
              <li>
                create another account if we have already terminated your account,
                unless you have our permission.
              </li>
            </ul>
            <p>
              <u>Relagram</u> reserves the right to investigate and/ or terminate
              your account without a refund of any purchases if you have violated
              this Agreement, misused the Service or behaved in a way that{' '}
              <u>Relagram</u> regards as inappropriate or unlawful, including
              actions or communications that occur on or off the Service.
            </p>
            <h1>9. Other Users’ Content.</h1>
            <p>
              Although <u>Relagram</u> reserves the right to review and remove
              Content that violates this Agreement, such Content is the sole
              responsibility of the user who posts it, and <u>Relagram</u> cannot
              guarantee that all Content will comply with this Agreement. If you see
              Content on the Service that violates this Agreement, please report it
              within the Service or via our contact page.
            </p>
            <h1>10. Purchases.</h1>
            <p>
              <strong>Generally. </strong>From time to time, <u>Relagram</u> may
              offer products and services for purchase (“in app purchases”) through
              iTunes, Google Play, billing or other payment platforms authorized by{' '}
              <u>Relagram</u> If you choose to make an in app purchase, you will be
              prompted to confirm your purchase with the applicable payment
              provider, and your method of payment (be it your card or a third party
              account such as Google Play or iTunes) (your “Payment Method”) will be
              charged for the in app purchase at the prices displayed to you for the
              service(s) you&apos;ve selected as well as any sales or similar taxes
              that may be imposed on your payments, and you authorize{' '}
              <u>Relagram</u> or the third party account, as applicable, to charge
              you. If you purchase an auto-recurring periodic subscription through
              an in app purchase, your IAP Account will continue to be billed for
              the subscription until you cancel. After your initial subscription
              commitment period, and again after any subsequent subscription period,
              your subscription will automatically continue for an additional
              equivalent period, at the price you agreed to when subscribing.
            </p>
            <p>Auto-Renewal; Automatic Card Payment</p>
            <p>
              Subscriptions are automatically renewed, until you terminate or cancel
              the subscription. When you purchase a subscription, your Payment
              Method will continue to be billed monthly in advance within 24 hours
              of the date of the initial purchase at the price you agreed to when
              initially subscribing. Your card payment information will be stored
              and subsequently used for the automatic card payments in accordance
              with the Agreement.
            </p>
            <p>
              After each payment you will receive a receipt by email. Objections to
              a payment already made, should be directed to Customer support if you
              were billed directly by <u>Relagram</u> or the relevant third party
              account such as iTunes. You are also be able to object by contacting
              your bank or payment provider, who can provide further information on
              your rights as well as applicable time limits.
            </p>
            <p>
              You may unconditionally withdraw your consent to automatic card
              payments at any time by going to settings on <u>Relagram</u> or the
              relevant third party account, but be advised that you are still
              obligated to pay any outstanding amounts.
            </p>
            <p>
              If you want to change or terminate your subscription, you will need to
              log in to your third party account (or Account Settings) and follow
              instructions to terminate or cancel your subscription, even if you
              have otherwise deleted your account with us or if you have deleted the
              application from your device. Deleting your account or deleting the
              application from your device does not terminate or cancel your
              subscription; <u>Relagram</u> will retain all funds charged to your
              Payment Method until you terminate or cancel your subscription on{' '}
              <u>Relagram</u> or the third party account, as applicable. If you
              terminate or cancel your subscription, you may use your subscription
              until the end of your then-current subscription term, and your
              subscription will not be renewed after your then-current term expires.
            </p>
            <p>
              <strong>
                Additional Terms that apply if you pay directly with your Payment
                Method. In the future Relagram may offer you the option of paying
                Relagram directly.{' '}
              </strong>
              If you pay <u>Relagram</u> directly, <u>Relagram</u> may correct any
              billing errors or mistakes that it makes even if it has already
              requested or received payment. If you initiate a chargeback or
              otherwise reverse a payment made with your Payment Method, The app may
              terminate your account immediately in its sole discretion.
            </p>
            <p>
              You may edit your Payment Method information by visiting{' '}
              <u>Relagram</u> and going to “My Profile.” If a payment is not
              successfully settled, due to expiration, insufficient funds, or
              otherwise, and you do not edit your Payment Method information,
              terminate or cancel your subscription, you remain responsible for any
              uncollected amounts and authorize us to continue billing the Payment
              Method, as it may be updated. This may result in a change to your
              payment billing dates. In addition, you authorize us to obtain updated
              or replacement expiration dates and card numbers for your credit or
              debit card as provided by your credit or debit card issuer. The terms
              of your payment will be based on your Payment Method and may be
              determined by agreements between you and the financial institution,
              credit card issuer or other provider of your chosen Payment Method.
            </p>
            <p>
              <strong>
                Internal virtual MetaFek Coins (MFC), Emoji, Stickers and Other
                Virtual Items (here and after Product).{' '}
              </strong>
              From time to time, you may be able to purchase a limited, personal,
              non-transferable, non-sublicensable, revocable license to use “virtual
              items,” including but not limited to Product. Any Product balance
              shown in your account does not constitute a real-world balance or
              reflect any stored value, but instead constitutes a measurement of the
              extent of your license. Products do not incur fees for non-use,
              however, the license granted to you in Products will terminate in
              accordance with the terms of this Agreement, when <u>Relagram</u>{' '}
              ceases providing the Service or your account is otherwise closed or
              terminated. <u>Inc</u>, in its sole discretion, reserves the right to
              charge fees for the right to access or use Product and/ or may
              distribute Virtual Items with or without charge. <u>Relagram</u> may
              manage, regulate, control, modify or eliminate Products at any time.{' '}
              <u>Relagram</u> shall have no liability to you or any third party in
              the event that <u>Relagram</u> exercises any such rights. Virtual
              Items may only be redeemed through the Service. ALL PURCHASES AND
              REDEMPTIONS OF PRODUCTS MADE THROUGH THE SERVICE ARE FINAL AND
              NON-REFUNDABLE. The provision of Virtual Items for use in the Service
              is a service that commences immediately upon the acceptance of your
              purchase of such Product. YOU ACKNOWLEDGE THAT <u>Relagram</u> IS NOT
              REQUIRED TO PROVIDE A REFUND IN RESPECT OF VIRTUAL ITEMS FOR ANY
              REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR
              UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT CLOSED, WHETHER SUCH CLOSURE WAS
              VOLUNTARY OR INVOLUNTARY.
            </p>
            <p>
              <strong>Refunds. </strong>Generally, all charges for purchases are
              nonrefundable, and there are no refunds or credits for partially used
              periods. We may make an exception if a refund for a subscription
              offering is requested within fourteen days of the transaction date, or
              if the laws applicable in your jurisdiction provide for refunds.
            </p>
            <p>
              For subscribers residing in the EU or European Economic Area, in
              accordance with local law, you are entitled to a full refund without
              stating the reason during the 14 days after the subscription begins.
              Please note that this 14-day period commences when the subscription
              starts.
            </p>
            <p>
              Purchases of Virtual Items and Products are FINAL AND NON-REFUNDABLE.
            </p>
            <p>
              To request are fund: If you subscribed using your Apple ID, refunds
              are handled by Apple. To request a refund, go to iTunes, click on your
              Apple ID, select “Purchase history,” find the transaction and hit
              “Report Problem”. You can also submit a request at
              https://getsupport.apple.com.
            </p>
            <p>
              If you subscribed using your Google Play Store account or through{' '}
              <u>Relagram</u> directly: please contact customer support with your
              order number for the Google Play Store (you can find the order number
              in the order confirmation email or by logging in to Google Wallet) or{' '}
              <u>Relagram</u> (you can find this on your confirmation email). You
              may also mail or deliver a signed and dated notice, which states that
              you, the buyer, are canceling this agreement, or words of similar
              effect. Please also include the email address or telephone number
              associated with your account along with your order number.
            </p>
            <p>
              <strong>Consequences of Cancellation: </strong>You cannot cancel an
              order for delivery of digital content that is not delivered on a
              physical medium if order processing has begun with your explicit prior
              consent and acknowledgement that you will thereby lose your right of
              cancellation. This applies, e.g., to purchases of Virtual Items. That
              means that such purchases are FINAL AND NON-REFUNDABLE. Also read our
              separate{' '}
              <u>
                <Link to={routes.VIRTUAL_ITEMS_POLICY}>Virtual Items Policy</Link>
              </u>{' '}
              document
            </p>
            <h1>11. Disclaimers.</h1>
            <p>
              <u>Relagram</u> PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE”
              BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO
              WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
              OTHERWISE WITH RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED
              THEREIN), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
              SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE OR NON-INFRINGEMENT. <u>Relagram</u> DOES NOT REPRESENT OR
              WARRANT THAT (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR
              FREE, (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR
              (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE
              SERVICE WILL BE ACCURATE.
            </p>
            <p>
              <u>Relagram</u> TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR
              ANOTHER USER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE
              SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
              OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.
            </p>
            <h1>12. Third Party Services.</h1>
            <p>
              The Service may contain advertisements and promotions offered by third
              parties and links to other web sites or resources. <u>Relagram</u> is
              not responsible for the availability (or lack of availability) of such
              external websites or resources. If you choose to interact with the
              third parties made available through our Service, such party’s terms
              will govern their relationship with you. <u>Relagram</u> is not
              responsible or liable for such third parties’ terms or actions.
            </p>
            <h1>13. Limitation of Liability.</h1>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
              COMPANY, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE
              LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL
              OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS,
              WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
              GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS
              TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE, (II) THE
              CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON, THROUGH, OR
              FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR
              ALTERATION OF YOUR CONTENT, EVEN IF <u>Relagram</u> HAS BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL <u>INC’S</u>{' '}
              AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE
              EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO <u>Relagram</u> FOR THE
              SERVICE WHILE YOU HAVE AN ACCOUNT.
            </p>
            <h1>14. Venue.</h1>
            <p>
              Except for users residing in the EU or European Economic Area, who may
              bring claims in their country of residence in accordance with
              applicable law, and except for claims that may be properly brought in
              a small claims court of competent jurisdiction in the county in which
              you reside or in State of New Jersey, all claims arising out of or
              relating to this Agreement, to the Service, or to your relationship
              with <u>Relagram </u>that for whatever reason are not submitted to
              arbitration will be litigated exclusively in the federal or state
              courts of New Jersey, U.S.A. You and <u>Relagram</u> consent to the
              exercise of personal jurisdiction of courts in the State of New Jersey
              and waive any claim that such courts constitute an inconvenient forum.
            </p>
            <h1>15. Indemnity by You.</h1>
            <p>
              You agree, to the extent permitted under applicable law, to indemnify,
              defend and hold harmless <u>Relagram</u>, our affiliates, and their
              and our respective officers, directors, agents, and employees from and
              against any and all complaints, demands, claims, damages, losses,
              costs, liabilities and expenses, including attorney’s fees, due to,
              arising out of, or relating in any way to your access to or use of the
              Service, your Content, or your breach of this Agreement.
            </p>
            <h1>16. Entire Agreement; Other.</h1>
            <p>
              This Agreement, with the Privacy Policy, the Safety Tips and any terms
              disclosed and agreed to by you if you purchase additional features,
              products or services we offer on the Service, contains the entire
              agreement between you and <u>Relagram</u> regarding the use of the
              Service. If any provision of this Agreement is held invalid, the
              remainder of this Agreement shall continue in full force and effect.
              The failure of Relagram to exercise or enforce any right or provision
              of this Agreement shall not constitute a waiver of such right or
              provision. You agree that your <u>Relagram</u> account is
              non-transferable and all of your rights to your account and its
              Content terminate upon your death. No agency, partnership, joint
              venture, fiduciary or other special relationship or employment is
              created as a result of this Agreement and you may not make any
              representations on behalf of or bind in any manner.
            </p>
            <h1>17. Other Terms</h1>
            <p>
              <strong>Open Source. </strong>The Platform contains certain open
              source software. Each item of open source software is subject to its
              own applicable license terms. Please check our{' '}
              <u>
                <a href="https://www.tiktok.com/legal/open-source?lang=en">
                  Open Source Policy
                </a>
              </u>
              .
            </p>
            <h1>
              18. <strong>Entire Agreement.</strong>
            </h1>
            <p>
              These Terms constitute the whole legal agreement between you and
              Relagram? and govern your use of the Services and completely replace
              any prior agreements between you and Relagram? in relation to the
              Services.
            </p>
            <h1>
              19. <strong>Links.</strong>
            </h1>
            <p>
              You may link to our home page, provided you do so in a way that is
              fair and legal and does not damage our reputation or take advantage of
              it. You must not establish a link in such a way as to suggest any form
              of association, approval or endorsement on our part where none exists.
              You must not establish a link to our Services in any website that is
              not owned by you. The website in which you are linking must comply in
              all respects with the content standards set out at “Your Access to and
              Use of Our Services” above. We reserve the right to withdraw linking
              permission without notice.
            </p>
            <h1>
              20. <strong>No Waiver.</strong>
            </h1>
            <p>
              Our failure to insist upon or enforce any provision of these Terms
              shall not be construed as a waiver of any provision or right.
            </p>
            <h1>
              21. <strong>Security.</strong>
            </h1>
            <p>
              We do not guarantee that our Services will be secure or free from bugs
              or viruses. You are responsible for configuring your information
              technology, computer programs and platform to access our Services. You
              should use your own virus protection software.
            </p>
            <h1>
              22. <strong>Severability.</strong>
            </h1>
            <p>
              If any court of law, having jurisdiction to decide on this matter,
              rules that any provision of these Terms is invalid, then that
              provision will be removed from the Terms without affecting the rest of
              the Terms, and the remaining provisions of the Terms will continue to
              be valid and enforceable.
            </p>
            <p>&nbsp;</p>
            <h1>23. Governing Law</h1>
            <p>
              <u>
                <a href="https://www.lawinsider.com/clause/governing-law-jurisdiction">
                  <strong>This Agreement</strong>
                </a>
              </u>
              , and all claims or causes of action (whether at law, in contract or
              in tort) that may be based upon, arise out of or relate to this
              Agreement or the negotiation, execution or performance hereof, shall
              be governed by and construed in accordance with the laws of the State
              of New Jersey, without giving effect to any choice or conflict of law
              provision or rule (whether of the State of New Jersey or any other
              jurisdiction) that would cause the application of the laws of any
              jurisdiction other than the State of New Jersey.
            </p>
            <p>&nbsp;</p>
            <h1>
              24. <strong>ARBITRATION AND CLASS ACTION WAIVER.</strong>
            </h1>
            <p>
              This Section includes an arbitration agreement and an agreement that
              all claims will be brought only in an individual capacity (and not as
              a class action or other representative proceeding). Please read it
              carefully. You may opt out of the arbitration agreement by following
              the opt out procedure described below.
            </p>
            <p>
              Informal Process First. You agree that in the event of any dispute
              between you and Relagram you will first contact Relagram and make a
              good faith sustained effort to resolve the dispute before resorting to
              more formal means of resolution, including without limitation any
              court action.
            </p>
            <p>
              Arbitration Agreement. After the informal dispute resolution process
              any remaining dispute, controversy, or claim (collectively, “Claim”)
              relating in any way to your use of Relagram’s services and/or
              products, including the Services, or relating in any way to the
              communications between you and Relagram or any other user of the
              Services, will be finally resolved by binding arbitration. This
              mandatory arbitration agreement applies equally to you and Relagram.
              However, this arbitration agreement does not (a) govern any Claim by
              Relagram for infringement of its intellectual property or access to
              the Services that is unauthorized or exceeds authorization granted in
              these Terms or (b) bar you from making use of applicable small claims
              court procedures in appropriate cases. If you are an individual, you
              may opt out of this arbitration agreement within thirty (30) days of
              the first of the date you access or use this Services by following the
              procedure described below.
            </p>
            <p>
              You agree that the U.S. Federal Arbitration Act governs the
              interpretation and enforcement of this provision, and that you and
              Relagram are each waiving the right to a trial by jury or to
              participate in a class action. This arbitration provision will survive
              any termination of these Terms.
            </p>
            <p>
              If you wish to begin an arbitration proceeding, after following the
              informal dispute resolution procedure, you must send a letter
              requesting arbitration and describing your claim to:
            </p>
            <p>
              Email Address:{' '}
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>
            </p>
            <p>
              The arbitration will be administered by the American Arbitration
              Association (AAA) under its rules including, if you are an individual,
              the AAA&apos;s Supplementary Procedures for Consumer-Related Disputes.
              If you are not an individual or have used the Services on behalf of an
              entity, the AAA&apos;s Supplementary Procedures for Consumer-Related
              Disputes will not be used. The AAA&apos;s rules are available at
              www.adr.org or by calling 1-800-778-7879.
            </p>
            <p>
              Payment of all filing, administration and arbitrator fees will be
              governed by the AAA&apos;s rules. If you are an individual and have
              not accessed or used the Services on behalf of an entity, we will
              reimburse those fees for claims where the amount in dispute is less
              than $10,000, unless the arbitrator determines the claims are
              frivolous, and we will not seek attorneys’ fees and costs in
              arbitration unless the arbitrator determines the claims are frivolous.
            </p>
            <p>
              The arbitrator, and not any federal, state, or local court, will have
              exclusive authority to resolve any dispute relating to the
              interpretation, applicability, unconscionability, arbitrability,
              enforceability, or formation of this arbitration agreement, including
              any claim that all or any part of this arbitration agreement is void
              or voidable. However, the preceding sentence will not apply to the
              “Class Action Waiver” section below.
            </p>
            <p>
              If you do not want to arbitrate disputes with Relagram and you are an
              individual, you may opt out of this arbitration agreement by sending
              an email to{' '}
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>{' '}
              within thirty (30) days of the first of the date you access or use the
              Services.
            </p>
            <p>
              <strong>Class Action Waiver. </strong>Any Claim must be brought in the
              respective party’s individual capacity, and not as a plaintiff or
              class member in any purported class, collective, representative,
              multiple plaintiff, or similar proceeding (“Class Action”). The
              parties expressly waive any ability to maintain any Class Action in
              any forum. If the Claim is subject to arbitration, the arbitrator will
              not have authority to combine or aggregate similar claims or conduct
              any Class Action nor make an award to any person or entity not a party
              to the arbitration. Any claim that all or part of this Class Action
              Waiver is unenforceable, unconscionable, void, or voidable may be
              determined only by a court of competent jurisdiction and not by an
              arbitrator. The parties understand that any right to litigate in
              court, to have a judge or jury decide their case, or to be a party to
              a class or representative action, is waived, and that any claims must
              be decided individually, through arbitration.
            </p>
            <p>
              If this class action waiver is found to be unenforceable, then the
              entirety of the Arbitration Agreement, if otherwise effective, will be
              null and void. The arbitrator may award declaratory or injunctive
              relief only in favor of the individual party seeking relief and only
              to the extent necessary to provide relief warranted by that
              party&apos;s individual claim. If for any reason a claim proceeds in
              court rather than in arbitration, you and Relagram each waive any
              right to a jury trial.
            </p>
            <p>
              If a counter-notice is received by Relagram’s we may send a copy of
              the counter-notice to the original complaining party informing that
              person that we may replace the removed content or cease disabling it.
              Unless the original complaining party files an action seeking a court
              order against the Content Provider, member or user, the removed
              content may be replaced, or access to it restored, in ten business
              days or more after receipt of the counter-notice, at Relagram’s’s
              sole discretion.
            </p>
            <p>
              Please understand that filing a counter-notification may lead to legal
              proceedings between you and the complaining party to determine
              ownership. Be aware that there may be adverse legal consequences in
              your country if you make a false or bad faith allegation by using this
              process.
            </p>
            <h1>
              25. <strong>California Consumer Rights Notice.</strong>
            </h1>
            <p>
              Under California Civil Code Section 1789.3, California users of the
              Services receive the following specific consumer rights notice: The
              Complaint Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs may be contacted in writing
              at the contact information set forth at&nbsp;
              <u>
                <a href="https://www.dca.ca.gov/about_us/contactus.shtml">
                  https://www.dca.ca.gov/about_us/contactus.shtml
                </a>
              </u>
            </p>
            <p>
              You should not be using the Services if you are under 18 years of age.
              Users of the Services who are California residents and are under 18
              years of age who have nonetheless used the Services may request and
              obtain removal of User Content they posted by emailing us at{' '}
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>
              . All requests must be labeled &quot;California Removal Request&quot;
              on the email subject line. All requests must provide a description of
              the User Content you want removed and information reasonably
              sufficient to permit us to locate that User Content. We do not accept
              California Removal Requests via postal mail, telephone or facsimile.
              We are not responsible for notices that are not labeled or sent
              properly, and we may not be able to respond if you do not provide
              adequate information.
            </p>
            <h1>
              26. Exports<strong>. </strong>
            </h1>
            <p>
              You agree that you will not export or re-export, directly or
              indirectly the Services and/or other information or materials provided
              by Relagram hereunder, to any country for which the United States or
              any other relevant jurisdiction requires any export license or other
              governmental approval at the time of export without first obtaining
              such license or approval. In particular, but without limitation, the
              Services may not be exported or re-exported (a) into any U.S.
              embargoed countries or any or any country that has been designated by
              the U.S. Government as a “terrorist supporting” country, or (b) to
              anyone listed on any U.S. Government list of prohibited or restricted
              parties, including the U.S. Treasury Department&apos;s list of
              Specially Designated Nationals or the U.S. Department of Commerce
              Denied Person’s List or Entity List.
            </p>
            <h1>
              27. U.S. Government Restricted Rights<strong>. </strong>
            </h1>
            <p>
              The Services and related documentation are &quot;Commercial
              Items&quot;, as that term is defined at 48 C.F.R. §2.101, consisting
              of &quot;Commercial Computer Software&quot; and &quot;Commercial
              Computer Software Documentation&quot;, as such terms are used in 48
              C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with
              48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as
              applicable, the Commercial Computer Software and Commercial Computer
              Software Documentation are being licensed to U.S. Government end users
              (a) only as Commercial Items and (b) with only those rights as are
              granted to all other end users pursuant to the terms and conditions
              herein.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>App Stores</strong>
            </p>
            <p>
              To the extent permitted by applicable law, the following supplemental
              terms shall apply when accessing Relagram hrough specific devices:
            </p>
            <p>
              <strong>Notice regarding Apple.</strong>
            </p>
            <p>
              By downloading the Platform from a device made by Apple, Relagram
              (“Apple”) or from Apple’s App Store, you specifically acknowledge and
              agree that:
            </p>
            <p>
              These Terms operate between Relagram and you; Apple is not a party to
              these Terms.
            </p>
            <p>
              The license granted to you hereunder is limited to a personal,
              limited, non-exclusive, non-transferable right to install the Platform
              on the Apple device(s) authorized by Apple that you own or control for
              personal, non-commercial use, subject to the Usage Rules set forth in
              Apple’s App Store Terms of Services.
            </p>
            <p>
              Apple is not responsible for the Platform or the content thereof and
              has no obligation whatsoever to furnish any maintenance or support
              services with respect to the Platform.
            </p>
            <p>
              In the event of any failure of the Platform to conform to any
              applicable warranty, you may notify Apple, and Apple will refund the
              purchase price for the Platform, if any, to you. To the maximum extent
              permitted by applicable law, Apple will have no other warranty
              obligation whatsoever with respect to the Platform.
            </p>
            <p>
              Apple is not responsible for addressing any claims by you or a third
              party relating to the Platform or your possession or use of the
              Platform, including without limitation (a) product liability claims;
              (b) any claim that the Platform fails to conform to any applicable
              legal or regulatory requirement; and (c) claims arising under consumer
              protection or similar legislation.
            </p>
            <p>
              In the event of any third party claim that the Platform or your
              possession and use of the Platform infringes such third party’s
              intellectual property rights, Apple is not responsible for the
              investigation, defense, settlement or discharge of such intellectual
              property infringement claim.
            </p>
            <p>
              You represent and warrant that (a) you are not located in a country
              that is subject to a U.S. Government embargo, or that has been
              designated by the U.S. Government as a “terrorist supporting” country;
              and (b) you are not listed on any U.S. Government list of prohibited
              or restricted parties.
            </p>
            <p>
              Apple and its subsidiaries are third party beneficiaries of these
              Terms and upon your acceptance of the terms and conditions of these
              Terms, Apple will have the right (and will be deemed to have accepted
              the right) to enforce these Terms against you as a third party
              beneficiary hereof.
            </p>
            <p>
              Relagram expressly authorizes use of the Platform by multiple users
              through the Family Sharing or any similar functionality provided by
              Apple.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Windows Phone Store.</strong>
            </p>
            <p>
              By downloading the Platform from the Windows Phone Store (or its
              successors) operated by Microsoft, Relagram or its affiliates, you
              specifically acknowledge and agree that:
            </p>
            <p>
              You may install and use one copy of the Platform on up to five (5)
              Windows Phone enabled devices that are affiliated with the Microsoft
              account you use to access the Windows Phone Store. Beyond that, we
              reserve the right to apply additional conditions or charge additional
              fees.
            </p>
            <p>
              You acknowledge that Microsoft Corporation, your phone manufacturer
              and network operator have no obligation whatsoever to furnish any
              maintenance and support services with respect to the Platform.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Amazon Appstore.</strong>
            </p>
            <p>
              By downloading the Platform from the Amazon Appstore (or its
              successors) operated by Amazon Digital Services, Relagram or
              affiliates (“Amazon”), you specifically acknowledge and agree that:
            </p>
            <p>
              to the extent of any conflict between (a) the Amazon Appstore Terms of
              Use or such other terms which Amazon designates as default end user
              license terms for the Amazon Appstore (“Amazon Appstore EULA Terms”),
              and (b) the other terms and conditions in these Terms, the Amazon
              Appstore EULA Terms shall apply with respect to your use of the
              Platform that you download from the Amazon Appstore, and
            </p>
            <p>
              Amazon does not have any responsibility or liability related to
              compliance or non-compliance by Relagram or you (or any other user)
              under these Terms or the Amazon Appstore EULA Terms.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Google Play.</strong>
            </p>
            <p>
              By downloading the Platform from Google Play (or its successors)
              operated by Google, Relagram or one of its affiliates (“Google”), you
              specifically acknowledge and agree that:
            </p>
            <p>
              to the extent of any conflict between (a) the Google Play Terms of
              Services and the Google Play Business and Program Policies or such
              other terms which Google designates as default end user license terms
              for Google Play (all of which together are referred to as the “Google
              Play Terms”), and (b) the other terms and conditions in these Terms,
              the Google Play Terms shall apply with respect to your use of the
              Platform that you download from Google Play, and
            </p>
            <p>
              you hereby acknowledge that Google does not have any responsibility or
              liability related to compliance or non-compliance by Relagram or you
              (or any other user) under these Terms or the Google Play Terms.
            </p>
            <p>&nbsp;</p>
            <h1>28. Your Access to and Use of Our Services</h1>
            <p>
              Your access to and use of the Services is subject to these Terms and
              all applicable laws and regulations. You may not:
            </p>
            <p>
              -access or use the Services if you are not fully able and legally
              competent to agree to these Terms or are authorized to use the
              Services by your parent or legal guardian;
            </p>
            <p>
              -make unauthorized copies, modify, adapt, translate, reverse engineer,
              disassemble, decompile or create any derivative works of the Services
              or any content included therein, including any files, tables or
              documentation (or any portion thereof) or determine or attempt to
              determine any source code, algorithms, methods or techniques embodied
              by the Services or any derivative works thereof;
            </p>
            <p>
              -distribute, license, transfer, or sell, in whole or in part, any of
              the Services or any derivative works thereof
            </p>
            <p>
              market, rent or lease the Services for a fee or charge, or use the
              Services to advertise or perform any commercial solicitation;
            </p>
            <p>
              -use the Services, without our express written consent, for any
              commercial or unauthorized purpose, including communicating or
              facilitating any commercial advertisement or solicitation or spamming;
            </p>
            <p>
              -interfere with or attempt to interfere with the proper working of the
              Services, disrupt our website or any networks connected to the
              Services, or bypass any measures we may use to prevent or restrict
              access to the Services;
            </p>
            <p>
              -incorporate the Services or any portion thereof into any other
              program or product. In such case, we reserve the right to refuse
              service, terminate accounts or limit access to the Services in our
              sole discretion;
            </p>
            <p>
              -use automated scripts to collect information from or otherwise
              interact with the Services;
            </p>
            <p>
              -impersonate any person or entity, or falsely state or otherwise
              misrepresent you or your affiliation with any person or entity,
              including giving the impression that any content you upload, post,
              transmit, distribute or otherwise make available emanates from the
              Services;
            </p>
            <p>
              -intimidate or harass another, or promote sexually explicit material,
              violence or discrimination based on race, -sex, religion, nationality,
              disability, sexual orientation or age;
            </p>
            <p>
              -use or attempt to use another’s account, service or system without
              authorization from Relagram , or create a false identity on the
              Services;
            </p>
            <p>
              -use the Services in a manner that may create a conflict of interest
              or undermine the purposes of the Services, such as trading reviews
              with other users or writing or soliciting fake reviews;
            </p>
            <p>
              -use the Services to upload, transmit, distribute, store or otherwise
              make available in any way: files that contain viruses, trojans, worms,
              logic bombs or other material that is malicious or technologically
              harmful;
            </p>
            <p>
              -use, post or repost any unsolicited or unauthorized advertising,
              solicitations, promotional materials, “junk mail,” “spam,” “chain
              letters,” “pyramid schemes,” or any other prohibited form of
              solicitation;
            </p>
            <p>
              -use, post or repost any private information of any third party,
              including addresses, phone numbers, email addresses, number and
              feature in the personal identity document (e.g., National Insurance
              numbers, passport numbers) or credit card numbers;
            </p>
            <p>
              -use, post or repost any material which does or may infringe any
              copyright, trademark or other intellectual property or privacy rights
              of any other person. Please read our separate{' '}
              <u>
                <Link to={routes.COPYRIGHT}>
                  <strong>Copyright policy</strong>
                </Link>
              </u>
              ;
            </p>
            <p>
              -use, post or repost any material which is defamatory of any person,
              obscene, offensive, pornographic, hateful or inflammatory;
            </p>
            <p>
              -use, post or repost any material that would constitute, encourage or
              provide instructions for a criminal offence, dangerous activities or
              self-harm;
            </p>
            <p>
              -use, post or repost any material that is deliberately designed to
              provoke or antagonize people, especially trolling and bullying, or is
              intended to harass, harm, hurt, scare, distress, embarrass or upset
              people;
            </p>
            <p>
              -use, post or repost any material that contains a threat of any kind,
              including threats of physical violence;
            </p>
            <p>
              -use, post or repost any material that is racist or discriminatory,
              including discrimination on the basis of someone’s race, religion,
              age, gender, disability or sexuality;
            </p>
            <p>
              -give, post or repost any answers, responses, comments, opinions,
              analysis or recommendations that you are not properly licensed or
              otherwise qualified to provide; or
            </p>
            <p>
              material that, in the sole judgment of Relagram, is objectionable or
              which restricts or inhibits any other person from using the Services,
              or which may expose Relagram, the Services or its users to any harm
              or or liability of any type.
            </p>
            <p>&nbsp;</p>
            <p>Contact Us.</p>
            <p>
              You can reach us at{' '}
              <u>
                <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
              </u>
            </p>
          </div>

        </div>

        <div className="mt-4">
          <h1>使用条款</h1>
          <p>&nbsp;</p>
          <p><strong>Smart Modern Solutions LLC DBA以下简称“Relagram”</strong></p>
          <p><strong>注册地点：美国新泽西州</strong></p>
          <p>&nbsp;</p>
          <h1>定义</h1>
          <p>以下术语的含义如下所示：</p>
          <p>“服务”指由Relagram向客户提供的服务和产品。</p>
          <p>根据以下协议，提供给客户。</p>
          <p>“客户”，“您”（或“客户”）是指创建帐户并在协议下使用由Relagram提供的App服务的任何用户、人或实体。</p>
          <p>“访客”指浏览安装了Relagram服务的任何网站的任何人。</p>
          <p>“代理”指由客户雇用的操作服务的任何人。</p>
          <p>“最终用户”指使用App服务与客户进行沟通的任何人。</p>
          <p>“个人数据/个人信息”指根据或与协议相关的Relagram（或子处理者）在客户的控制或处理下根据欧盟法规（EU）2016⁄679（通用数据保护条例-“GDPR”）的规定或定义的个人数据。</p>
          <p>“机密信息”指向接收方提供的所有信息，无论是书面还是口头，并且（i）被接收方认识为机密的；（ii）标记为或声明为机密；或（iii）合理地应被接收方视为机密。</p>
          <p>“第三方服务提供商”指根据Relagram的指示收集、处理和/或使用个人信息的任何第三方，包括向Relagram、子公司或关联公司提供服务的任何顾问、代表、顾问或独立承包商（包括子处理者）。</p>
          <p>“子处理者”指在提供服务的过程中由Relagram使用其软件、商品或服务的实体。</p>
          <p>所有源自《通用数据保护条例》的术语，如“委员会”、“控制者”、“数据主体”、“成员国”、“个人数据”、“个人数据违规”、“处理”、“处理器”应理解为符合其来自该法规的常规含义。</p>
          <p>&nbsp;</p>
          <h1>1. 接受使用条款协议。</h1>
          <p>通过在Relagram中创建帐户，无论是通过移动设备、移动应用程序还是计算机（统称为“服务”），您同意受以下条款的约束：</p>
          <p>(i) 这些使用条款，(ii) 我们的<u><Link to={routes.PRIVACY_POLICY}>隐私政策</Link></u>，该隐私政策已纳入本协议，并且(iii) 如果您购买我们在服务上提供的其他功能、产品或服务，则您同意并接受您披露并同意的任何条款（统称为“协议”）。如果您不接受并同意遵守本协议的所有条款，请不要使用该服务。</p>
          <p>我们可能会不时地对本协议和服务进行更改。我们可能出于各种原因进行更改，包括反映法律的变化或要求、新功能或业务实践的变化。最新版本的本协议将发布在服务条款和条件下，并且您应定期查看最新版本。最新版本是适用的版本。如果更改包括影响您的权利或义务的重大更改，我们将通过合理方式提前通知您，这可能包括通过服务或通过电子邮件通知。如果您在更改生效后继续使用服务，则表示您同意修改后的协议。</p>
          <h1>2. 合格性。</h1>
          <p>您必须至少年满18岁才能在Relagram上创建帐户并使用该服务。通过创建帐户并使用该服务，您声明并保证：</p>
          <ul>
            <li>您可以与<u>Inc</u>形成具有约束力的合同。</li>
            <li>您不是根据<u>您所在国家</u>或任何其他适用司法管辖区的法律被禁止使用该服务的人-这意味着您不会出现在<u>您所在国家</u>的法律中。</li>
            <li>您将遵守本协议和所有适用的地方、州、国家和国际法律、规则和法规，以及</li>
            <li>您从未因重罪或可起诉的罪行（或类似严重性的犯罪）、性犯罪或涉及暴力的任何犯罪而被定罪，并且您无需向任何州、联邦或地方性别罪犯注册。</li>
          </ul>
          <h1>3. 您的帐户。</h1>
          <p>为了使用Relagram，您可以使用Facebook、Google或其他社交媒体的帐户登录。</p>
          <p>您负责保护您用于注册应用程序的登录凭据的保密性，您将独自对发生在这些凭据下的所有活动负责。如果您认为某人已经访问了您的帐户，请立即与我们联系。</p>
          <h1>4. 修改服务和终止。</h1>
          <p>Relagram始终致力于改进服务，并为您带来您发现有吸引力和有用的额外功能。这意味着我们可能不时地添加新的产品功能或增强功能，以及删除一些功能，如果这些操作不会重大影响您的权利或义务，我们可能不会在采取这些操作之前通知您。我们甚至可能完全暂停服务，在这种情况下，除非出现严重情况（例如安全或安全问题）阻止我们这样做，否则我们将提前通知您。</p>
          <h1>5. 安全性；您与其他用户的互动。</h1>
          <p>尽管Relagram努力通过功能鼓励尊重用户体验，例如使您的个人资料私密化，但对服务内或外任何用户的行为Relagram概不负责。您同意在与其他用户的所有互动中都要谨慎，特别是如果您决定在服务外沟通或亲自会面。您同意不提供您的财务信息（例如您的信用卡或银行帐户信息），或通过电汇或其他方式向其他用户发送资金。</p>
          <p>您独自对您与其他用户的互动负责。您了解Relagram不对其用户的行为进行犯罪背景调查或以其他方式调查其用户的背景。Relagram对用户的行为不作任何声明或保证。</p>
          <h1>6. Relagram的权利。 Relagram授予您的权利。</h1>
          <p>Relagram授予您个人、全球、免费、不可转让、非专有、可撤销和非许可的许可，以访问和使用该服务。此许可的唯一目的是让您按照App的意图和本协议的许可使用和享受服务的好处。因此，您同意不会：</p>
          <ul>
            <li>未经我们的书面许可，将服务或服务中包含的任何内容用于任何商业目的。</li>
            <li>未经Inc事先书面同意，不得复制、修改、传输、创建任何衍生作品、利用或以任何方式复制任何通过服务可访问的受版权保护的材料、图片、商标、商号、服务标记或其他知识产权、内容或专有信息。请阅读我们的单独的<u><Link to={routes.COPYRIGHT}><strong>版权政策</strong></Link></u>。</li>
            <li>表达或暗示您发表的任何声明均得到Relagram的认可</li>
            <li>使用任何机器人、机器人、蜘蛛、爬虫、网站搜索/检索应用程序、代理或其他手动或自动设备、方法或过程访问、检索、索引、“数据挖掘”或以任何方式复制或规避服务或其内容的导航结构或呈现方式。</li>
            <li>以任何方式使用可能干扰、破坏或对服务或与服务连接的服务器或网络产生负面影响的方式使用该服务。</li>
            <li>上传病毒或其他恶意代码或以其他方式破坏服务的安全性。</li>
            <li>伪造标题或以其他方式操纵标识符，以掩盖向服务传输的任何信息的来源。</li>
            <li>未经Inc的事先书面授权，“框架”或“镜像”服务的任何部分。</li>
            <li>使用包含对Relagram或服务的引用（或Relagram的商标、商号、服务标记、标志或口号）的任何元标记或代码或其他设备将任何人引导到任何其他网站，目的是为了任何目的。</li>
            <li>修改、适应、转让、翻译、出售、反向工程、解密、反编译或以其他方式拆分服务的任何部分，或导致他人这样做。</li>
            <li>未经我们的书面同意，使用或开发与服务或其他用户的内容或信息进行互动的任何第三方应用程序。</li>
            <li>未经我们的书面同意，使用、访问或发布Relagram应用程序编程界面。</li>
            <li>探测、扫描或测试我们的服务或任何系统或网络的漏洞。</li>
            <li>鼓励或促进违反本协议的任何活动。</li>
          </ul>
          <p>公司可能会对违反使用条款的非法和/或未经授权的使用采取任何可用的法律行动，包括终止您的帐户。</p>
          <p>我们向您提供的任何软件可能会自动下载并安装升级、更新或其他新功能。您可能可以通过设备的设置调整这些自动下载。</p>
          <h1>7. 您授予Relagram的权利。</h1>
          <p>您同意在创建帐户时提交的所有信息，包括从您的社交媒体帐户提交的信息，都是准确和真实的，并且您有权将内容发布到服务上并授予Relagram上述许可。</p>
          <p>
            您理解并同意，我们可能会监视或审查您作为服务的一部分发布的任何内容。我们可能会删除任何完整或部分违反本协议或可能损害服务声誉的内容，根据我们独立的判断。
          </p>
          <p>
            在与我们的客户服务代表沟通时，您同意尊重和友善。如果我们认为您对任何客户服务代表或其他员工的行为在任何时候构成威胁或冒犯，我们保留立即终止您的帐户的权利。
          </p>
          <p>
            考虑到Relagram允许您使用该服务，您同意我们、我们的关联公司和第三方合作伙伴可以在服务上放置广告。通过向Relagram提供关于我们服务的建议或反馈，您同意Relagram可以使用并分享此类反馈，而不需对您进行补偿。
          </p>
          <p>
            请注意，如果法律要求如此，或者根据与您的协议执行的需要，或者基于一种善意相信认为这样的访问、存储或披露满足了正当利益，包括：(i) 遵守法律程序；(ii) 强制执行本协议；(iii) 回应有关任何内容侵犯第三方权利的索赔；(iv) 回应您的客户服务请求；或(v) 保护公司或任何其他人的权利、财产或个人安全，Relagram可能会访问、存储和披露您的帐户信息和内容。请查阅我们的隐私政策。
          </p>
          <h1>8. 社区规则。</h1>
          <ul>
            <li>不得将服务用于任何违法或被本协议禁止的目的。</li>
            <li>不得将服务用于任何有害或恶意的目的。</li>
            <li>不得使用服务以损害 Relagram。</li>
            <li>不得违反我们的社区准则，如不时更新。</li>
            <li>不得向任何用户发送垃圾邮件，向其索要钱财或欺诈。</li>
            <li>不得冒充任何个人或实体，或发布任何未经他人许可的其它人的图片。</li>
            <li>不得欺负、跟踪、恐吓、攻击、骚扰、虐待或诽谤任何个人。</li>
            <li>不得发布任何侵犯或违反他人权利的内容，包括肖像权、隐私权、版权、商标或其他知识产权或合同权利。请阅读我们单独的《版权政策》。</li>
            <li>不得发布任何仇恨言论、威胁性的、色情的或淫秽的内容；煽动暴力；或包含裸露或图像暴力的内容。</li>
            <li>不得发布任何宣传种族主义、偏见、仇恨或任何形式的对任何群体或个人的身体伤害的内容。</li>
            <li>不得为任何目的索取密码，或者从其他用户那里非法获取商业或非法目的的个人身份信息，或未经他人许可传播他人的个人信息。</li>
            <li>不得使用另一个用户的帐户，与另一个用户共享帐户，或保留多个帐户。</li>
            <li>如果我们已经终止了您的帐户，则不得创建另一个帐户，除非您获得我们的许可。</li>
          </ul>
          <p>Relagram 保留根据自行判断视为不适当或非法的行为或沟通，包括在服务内外发生的行为或沟通，调查和/或终止您的帐户而不退还任何购买款项的权利，如果您违反了本协议或滥用了服务。</p>
          <h1>9. 其他用户的内容。</h1>
          <p>虽然 Relagram 保留审查和删除违反本协议的内容的权利，但此类内容是发布它的用户的唯一责任，Relagram 不能保证所有内容都符合本协议。如果您在服务中看到违反本协议的内容，请在服务内或通过我们的联系页面报告。</p>
          <h1>10. 购买。</h1>
          <p><strong>通常情况下。</strong>不时，Relagram 可能通过 iTunes、Google Play、计费或其他由 Relagram 授权的付款平台提供购买产品和服务（“应用内购买”）。如果您选择进行应用内购买，您将被提示使用适用的付款提供商确认您的购买，并且您的付款方式（无论是您的卡还是第三方帐户，如 Google Play 或 iTunes）将按照显示给您的服务价格进行收费，以及任何可能对您的付款施加的销售税或类似税款，并且您授权 Relagram 或适用的第三方帐户向您收费。如果您通过应用内购买购买了自动续订的周期性订阅，您的 IAP 帐户将继续对订阅进行结算，直到您取消为止。在您的初始订阅承诺期之后，以及在任何后续订阅期之后，您的订阅将自动继续，价格将为您订阅时同意的价格，而且在您取消订阅之前，您的付款方式将持续扣款。</p>
          <p>自动续订；自动卡付款</p>
          <p>订阅将自动续订，直到您终止或取消订阅。当您购买订阅时，您的付款方式将继续在初始购买日期的 24 小时内提前每月按照您最初订阅时同意的价格收费。您的卡付款信息将被存储，并随后根据协议用于自动卡付款。</p>
          <p>每次付款后，您将通过电子邮件收到收据。如果您已经直接由 Relagram 或 iTunes 收取了费用，请向客户支持部门提出已经付款的异议。您也可以通过与您的银行或付款提供者联系来提出异议，他们可以提供有关您的权利以及适用的时间限制的进一步信息。</p>
          <p>您可以随时在 Relagram 的设置或相关的第三方帐户中取消对自动卡付款的同意，但请注意，您仍然有责任支付任何未支付的金额。</p>
          <p>如果您想更改或终止您的订阅，您需要登录到您的第三方帐户（或帐户设置）并按照说明终止或取消您的订阅，即使您已经从我们这里删除了您的帐户，或者您已经从您的设备中删除了应用程序。删除您的帐户或从您的设备中删除应用程序不会终止或取消您的订阅；Relagram 将保留对您的付款方式收取的所有资金，直到您在 Relagram 或适用的第三方帐户上终止或取消您的订阅。如果您终止或取消您的订阅，则您可以在当前订阅期结束之前使用您的订阅，您的订阅将在当前期限到期后不会续订。</p>
          <p><strong>如果您直接使用您的付款方式付款，则适用的其他条款。</strong>将来，Relagram 可能会为您提供直接向 Relagram 付款的选项。如果您直接向 Relagram 付款，则 Relagram 可能会纠正任何错误或失误，即使它已经要求或收到了付款。如果您启动了退款请求或以其他方式撤消了使用您的付款方式进行的付款，应用程序可能会自行决定立即终止您的帐户。</p>
          <p>您可以通过访问 Relagram 并转到“我的个人资料”来编辑您的付款方式信息。如果付款未成功结算，因为到期、资金不足或其他原因，并且您没有编辑您的付款方式信息、终止或取消您的订阅，则您仍然对任何未收回的金额负责，并授权我们继续向付款方式收费，因为它可能已更新。这可能会导致您的付款结算日期发生变化。此外，您授权我们获取您的信用卡或借记卡的更新或替换到期日期和卡号，由您的信用卡或借记卡发行机构提供。您的付款条款将根据您的付款方式确定，并可能由您与金融机构、信用卡发行机构或其他您选择的付款方式提供者之间的协议确定。</p>
          <p><strong>内部虚拟 MetaFek 币（MFC）、表情符号、贴纸和其他虚拟物品（以下简称为产品）。</strong>不时，您可能可以购买有限的、个人的、不可转让的、不可再许可的、可撤销的使用“虚拟物品”的许可，包括但不限于产品。您帐户中显示的任何产品余额都不构成真实世界的余额或反映任何储值，而是您许可的范围的衡量标准。产品不会因不使用而产生费用，然而，在本协议的条款下授予您的许可将在以下情况终止：Relagram 停止提供服务、您的帐户被关闭或终止。Inc 将自行决定收取访问或使用产品的费用，或者可能免费分发虚拟物品。Relagram 可以随时管理、监管、控制、修改或删除产品。在 Relagram 行使任何此类权利时，Relagram 对您或任何第三方均不承担责任。虚拟物品只能通过服务兑换。通过服务购买和兑换的所有产品都是最终的，不可退款。为在服务中使用的虚拟物品提供服务是在接受您购买此类产品后立即开始的服务。您确认 Relagram 不需要出于任何原因提供虚拟物品的退款，并且在关闭帐户时，无论关闭是自愿的还是非自愿的，您将不会收到未使用的虚拟物品的货币或其他补偿。</p>
          <p><strong>退款。</strong>通常情况下，所有购买的费用均不可退款，并且对于部分使用的期间也不提供退款或信用。如果在交易日期之后的十四天内请求退款，或者您所在司法管辖区的法律规定退款，则我们可能会例外退还订阅服务的费用。对于居住在欧盟或欧洲经济区的订阅用户，根据当地法律规定，您有权在订阅开始后的 14 天内无需说明理由获得全额退款。请注意，此 14 天期限是从订阅开始时开始计算的。</p>
          <p>虚拟物品和产品的购买是最终的且不可退款。</p>
          <p>要求退款：如果您使用 Apple ID 订阅，请由 Apple 处理退款。要请求退款，请转到 iTunes，点击您的 Apple ID，选择“购买记录”，找到交易并点击“报告问题”。您也可以在 https://getsupport.apple.com 提交请求。</p>
          <p>如果您使用 Google Play Store 帐户或通过 Relagram 直接订阅：请与您的 Google Play Store 的订单号联系客户支持（您可以在订单确认电子邮件中或通过登录 Google 钱包找到订单号）或 Relagram（您可以在确认电子邮件中找到此信息）。您还可以邮寄或递交一份签署日期的通知，说明您作为买方正在取消本协议，或类似效力的用语。请同时提供与您的帐户关联的电子邮件地址或电话号码以及您的订单号。</p>
          <p><strong>取消的后果：</strong>如果您已明确同意并确认在开始订单处理后即会失去取消权，您不能取消对未交付实体介质的数字内容的订单。例如，对于虚拟物品的购买适用此规定。这意味着此类购买是最终的且不可退款。还请阅读我们单独的《虚拟物品政策》文件。</p>
          <h1>11. 免责声明。</h1>
          <p>Relagram 根据适用法律“按原样”和“按可用性”提供服务，不提供任何种类的明示、暗示、法定或其他方面的保证，包括但不限于对服务（包括其中包含的所有内容）的任何暗示的满意质量、适销性、特定用途的适用性或不侵权的任何保证。Relagram 不保证或声明（A）服务将不间断、安全或无错误，（B）服务中的任何缺陷或错误将得到纠正，或者（C）您通过服务获得的任何内容或信息将是准确的。</p>
          <p>Relagram 不对您或其他用户或第三方发布、发送或接收的任何内容负责。通过使用服务下载或以其他方式获取的任何材料都是根据您自己的决定和风险来进行的。</p>
          <h1>12. 第三方服务。</h1>
          <p>服务可能包含由第三方提供的广告和促销活动，以及到其他网站或资源的链接。Relagram 不对此类外部网站或资源的可用性（或缺乏可用性）负责。如果您选择与我们的服务提供的第三方互动，该方的条款将管理其与您的关系。Relagram 对此类第三方的条款或行为不承担责任或义务。</p>
          <h1>13. 责任限制。</h1>
          <p>根据适用法律的规定，公司、其关联公司、雇员、许可人或服务提供商不对任何间接、后果性、示例性、偶然性、特殊或惩罚性的损害承担责任，包括但不限于利润损失，无论直接或间接产生，或任何数据、使用、信誉或其他无形损失的损失，结果是：（I）您访问或使用服务，（II）其他用户或第三方在使用服务之后或通过服务之后的行为或内容；或（III）您的内容被未经授权的访问、使用或更改，即使 Relagram 已被告知可能发生此类损害。在任何情况下，Relagram 对您因使用服务而产生的所有索赔的总责任不得超过您在您拥有帐户期间向 Relagram 支付的金额（如果有的话）。</p>
          <h1>14. 场所。</h1>
          <p>除了居住在欧盟或欧洲经济区的用户可以根据适用法律在其居住国提交索赔外，并且除了可能适用于您所居住县的小额索赔法院可以适当提交的索赔之外，所有因本协议、服务或您与 Relagram 的关系而产生的索赔都将专属地诉讼在美国新泽西州的联邦或州法院中进行。您和 Relagram 同意新泽西州法院行使个人管辖权，并放弃任何这样的法院构成不便的论点。</p>
          <h1>15. 你的赔偿责任。</h1>
          <p>您同意，在适用法律允许的范围内，赔偿、捍卫并使 Relagram、我们的关联公司以及我们和您的相关官员、董事、代理人和员工免受任何和所有投诉、要求、索赔、损害、损失、成本、责任和费用（包括律师费），由于您对服务的访问或使用，您的内容或您违反本协议而产生、引起或以任何方式与之相关。</p>
          <h1>16. 整个协议；其他。</h1>
          <p>本协议，连同隐私政策、安全提示和您购买服务中提供的任何条款（如果您购买了我们在服务中提供的额外功能、产品或服务并披露并同意了这些条款），包含您与 Relagram 关于使用服务的全部协议。如果本协议的任何条款无效，则本协议的其余部分仍然有效。Relagram 未能行使或执行本协议的任何权利或条款不构成对该等权利或条款的放弃。您同意您的 Relagram 帐户不可转让，并且您对您的帐户及其内容的所有权利在您去世后终止。本协议并不构成任何代理、合伙、合资、受信托或其他特殊关系或雇佣的结果，您不得以任何方式代表或捆绑。</p>
          <h1>17. 其他条款</h1>
          <p><strong>开放源码。</strong>平台包含某些开放源码软件。每个开放源码软件项目均适用其自己的适用许可条款。请查阅我们的<a href="https://www.tiktok.com/legal/open-source?lang=en">开源政策</a>。</p>
          <h1>18. 整个协议。</h1>
          <p>这些条款构成您与 Relagram 之间的完整法律协议，并管理您对服务的使用，并完全取代了您与 Relagram 之间关于服务的任何先前协议。</p>
          <h1>19. 链接。</h1>
          <p>您可以链接到我们的主页，前提是您以公平合法的方式进行链接，不损害我们的声誉或利用它。您不能建立这样一种链接，以暗示我们在不存在的情况下与您有任何形式的关联、认可或支持。您不能在您不拥有的任何网站中建立到我们服务的链接。您链接的网站必须在所有方面符合上述“您对我们服务的访问和使用”中规定的内容标准。我们保留撤销链接权限的权利，无需事先通知。</p>
          <h1>20. 不放弃。</h1>
          <p>我们未坚持或执行这些条款的任何规定，不得被解释为对任何规定或权利的放弃。</p>
          <h1>21. 安全性。</h1>
          <p>我们不能保证我们的服务将安全无虞或没有错误或病毒。您有责任配置您的信息技术、计算机程序和平台以访问我们的服务。您应使用自己的防病毒软件。</p>
          <h1>22. 可分割性。</h1>
          <p>如果任何有管辖权的法院裁定这些条款的任何规定无效，则该规定将在不影响其余规定的情况下从这些条款中删除，并且这些条款的其余规定将继续有效且可执行。</p>
          <p>&nbsp;</p>
          <h1>23. 适用法律</h1>
          <p>《本协议》以及可能基于、起因于或与《本协议》或此处的谈判、签署或履行有关的任何索赔或诉因（无论是依法律、合同或侵权诉讼）都应受新泽西州法律的管辖和解释，不考虑任何选择或法律冲突规定或规则（无论是新泽西州的还是其他任何司法管辖区的），这些规定会导致适用除新泽西州法律之外的任何司法管辖区的法律。</p>
          <p>&nbsp;</p>
          <h1>24. 仲裁和阶级行动弃权。</h1>
          <p>本部分包括仲裁协议和所有索赔仅以个人身份提出的协议（不得作为集体诉讼或其他代表性诉讼或程序）。请仔细阅读。您可以通过以下方式选择退出仲裁协议。</p>
          <p>首先进行非正式程序。您同意，在您与 Relagram 之间发生任何纠纷时，您将首先联系 Relagram，并尽一切诚意努力解决纠纷，然后再诉诸更正式的解决方式，包括但不限于任何法院诉讼。</p>
          <p>仲裁协议。在非正式争议解决程序之后，任何余下的与您对 Relagram 的服务和/或产品（包括服务）的使用以及与您和 Relagram 或服务的任何其他用户之间的通信在任何方面相关的争议、争议或索赔（统称为“索赔”）都将最终通过仲裁来解决。这项强制性的仲裁协议同样适用于您和 Relagram。然而，这项仲裁协议不适用于（a）Relagram 对其知识产权侵权或未经授权或超出本条款授权的服务访问的任何索赔，也不排除您在适当情况下使用适用的小额索赔法院程序的权利。如果您是个人，则可以在首次访问或使用本服务之日起三十（30）天内选择退出这项仲裁协议，方法请参阅下面的描述。</p>
          <p>您同意美国《联邦仲裁法》对本规定的解释和执行，并且您和 Relagram 都放弃了通过陪审团审判或参加集体诉讼的权利。本仲裁条款将在本条款终止后继续有效。</p>
          <p>如果您希望开始仲裁程序，请在遵循非正式争议解决程序后，向以下地址发送一封信请求仲裁并描述您的索赔：</p>
          <p>电子邮件地址： <a href="mailto:contact@relagram.com">contact@relagram.com</a></p>
          <p>仲裁将由美国仲裁协会 (AAA) 根据其规则进行，包括如果您是个人，则根据 AAA 的《消费者相关争议补充规程》。如果您不是个人或代表实体使用服务，则不会使用 AAA 的《消费者相关争议补充规程》。 AAA 的规则可通过访问 www.adr.org 或致电 1-800-778-7879 获取。</p>
          <p>所有文件、管理和仲裁员费用将受 AAA 规则的管辖。如果您是个人，且未代表实体访问或使用服务，我们将对争议金额低于 10000 美元的索赔退还这些费用，除非仲裁员裁定该索赔是莫须有的，并且我们不会在仲裁中寻求律师费和费用，除非仲裁员裁定该索赔是莫须有的。</p>
          <p>仲裁员，而不是任何联邦、州或地方法院，将有权决定解释、适用、不合理性、可仲裁性、可执行性或形成本仲裁协议的任何争议，包括任何本仲裁协议的全部或任何部分是无效或可撤销的任何主张。然而，前一句不适用于下面的“阶级行动弃权”部分。</p>
          <p>如果您不想与 Relagram 仲裁争议，且您是个人，您可以在首次访问或使用服务之日起三十（30）天内通过发送电子邮件至 <a href="mailto:contact@relagram.com">contact@relagram.com</a> 选择退出此仲裁协议。</p>
          <p><strong>阶级行动弃权。</strong>任何索赔必须由各方以各自的个人身份提出，而不是以某种形式的原告或集体成员在任何所谓的集体、集体、代表、多个原告或类似诉讼中（“集体诉讼”）。各方明确放弃在任何论坛中维护任何集体诉讼的能力。如果索赔受到仲裁管辖，仲裁员将不具备合并或汇总类似索赔或进行任何集体诉讼，也不会向仲裁员授予不是仲裁参与方的人或实体的奖励。任何声明本阶级行动弃权的全部或部分无法执行、不合理、无效或可撤销的主张只能由具有管辖权的法院确定，而不是由仲裁员确定。各方理解，在法庭上诉权、由法官或陪审团决定其案件的权利或成为集体或代表性诉讼的一方的任何权利均已被放弃，而且任何索赔必须通过仲裁个别决定。</p>
          <p>如果发现此类阶级行动弃权无法执行，则整个仲裁协议（如果其他方面有效）将无效。仲裁员只能根据个别当事方的要求授予宣告性或禁令性救济，且仅在必要时授予宣告性或禁令性救济，该当事方的个别索赔保证了该救济。如果任何原因导致索赔在法院而不是仲裁中进行，您和 Relagram 各自放弃要求陪审团审理的任何权利。</p>
          <p>如果 Relagram 收到反通知，我们可能会将反通知副本发送给最初投诉的一方，通知该方我们可能替换被移除的内容或停止禁用它。除非最初的投诉方提起诉讼要求法院对内容提供者、成员或用户做出命令，否则在收到反通知后的十个工作日或更长时间内，我们可以替换被移除的内容或恢复对其的访问，由 Relagram 自行决定。</p>
          <p>请了解，提交反通知可能会导致您和投诉方之间的法律程序来确定所有权。请注意，如果您使用此流程进行虚假或恶意指控，则可能会在您的国家受到不利的法律后果。</p>
          <h1>25. <strong>加利福尼亚消费者权利通知。</strong></h1>
          <p>根据加利福尼亚州民法第 1789.3 条，服务的加利福尼亚用户收到以下特定的消费者权利通知：加利福尼亚消费者事务部的投诉协助单位可以通过书面信函联系，联系信息如下：<a href="https://www.dca.ca.gov/about_us/contactus.shtml">https://www.dca.ca.gov/about_us/contactus.shtml</a></p>
          <p>如果您未满 18 岁，则不应使用服务。仍然使用服务的加利福尼亚州居民且未满 18 岁的用户可以通过发送电子邮件至 <a href="mailto:contact@relagram.com">contact@relagram.com</a> 请求并获得删除其张贴的用户内容。所有请求必须在电子邮件主题行上标注“加利福尼亚删除请求”。所有请求必须提供您要删除的用户内容的描述和合理信息，以便我们定位该用户内容。我们不接受通过邮政、电话或传真发送的加利福尼亚删除请求。如果通知未正确标记或发送，我们不负责，如果您未提供充分信息，我们可能无法做出回应。</p>
          <h1>26. <strong>出口</strong><strong>。</strong></h1>
          <p>您同意，您不会直接或间接出口或再出口 Relagram 在此处提供的服务和/或其他信息或材料到美国或任何其他相关司法管辖区在出口时需要任何出口许可证或其他政府批准的国家。具体来说，但不限于，服务可能不会出口或重新出口（a）到任何美国禁运国家或任何被美国政府指定为“支持恐怖主义”的国家，或者（b）到任何列入美国政府禁止或限制方当事人名单的人员，包括美国财政部的特别指定国民名单或美国商务部拒绝人员名单或实体名单。</p>
          <h1>27. <strong>美国政府受限权利</strong><strong>。</strong></h1>
          <p>服务及相关文件均为“商业项目”，根据 48 C.F.R. §2.101 的定义，在这里，它们由“商业计算机软件”和“商业计算机软件文档”组成，这些术语在 48 C.F.R. §12.212 或 48 C.F.R. §227.7202 中使用，视情况而定。根据 48 C.F.R. §12.212 或 48 C.F.R. §227.7202-1 至 227.7202-4，根据适用，商业计算机软件和商业计算机软件文档被授予美国政府最终用户的许可（a）仅作为商业项目和（b）根据本条款授予所有其他最终用户的权利和条件。</p>
          <p>&nbsp;</p>
          <p><strong>应用商店</strong></p>
          <p>根据适用法律的规定，使用特定设备访问 Relagram 时，以下补充条款适用：</p>
          <p><strong>有关 Apple 的通知。</strong></p>
          <p>通过从 Apple 制造的设备或从 Apple 的应用商店下载平台，您明确承认并同意：</p>
          <p>这些条款适用于 Relagram 和您之间的关系；Apple 不是这些条款的一方。</p>
          <p>您在此获得的许可仅限于在由 Apple 授权的您拥有或控制的个人、有限、非独家、不可转让的权利，在个人、非商业用途中安装平台，但受到 Apple 的应用商店服务条款中规定的使用规则的约束。</p>
          <p>Apple 对平台或其内容不负责，也无义务提供任何与平台有关的维护或支持服务。</p>
          <p>如果平台未能符合任何适用的保修，您可以通知 Apple，并且 Apple 将向您退还平台的购买价格（如果有）。在适用法律允许的最大范围内，Apple 对平台不承担任何其他保修义务。</p>
          <p>Apple 对于您或第三方就平台或您对平台的拥有或使用（包括但不限于）的任何索赔不负责任，（a）产品责任索赔；（b）任何声称平台未能符合任何适用法律或法规要求的任何索赔；和（c）根据消费者保护或类似立法的索赔。</p>
          <p>在任何第三方声称平台或您对平台的拥有和使用侵犯其知识产权权利时，Apple 不负责调查、辩护、解决或解除此类知识产权侵权索赔。</p>
          <p>您声明并保证（a）您不位于美国政府禁运国家，或已被美国政府指定为“支持恐怖主义”的国家；以及（b）您未列入任何美国政府的禁止或限制方当事人名单。</p>
          <p>Apple 及其子公司是这些条款的第三方受益人，而且在您接受这些条款后，如果您违反了这些条款，则 Apple 有权（在您违反这些条款后向您发出通知后）根据这些条款单方面主张对您的权利（在 Apple 不需遵循任何法院程序的情况下，无论是否提起诉讼或其他司法程序）。</p>
          <p><strong>有关谷歌的通知。</strong></p>
          <p>通过从 Google 制造的设备或从 Google 的应用商店下载平台，您明确承认并同意：</p>
          <p>这些条款适用于 Relagram 和您之间的关系；谷歌不是这些条款的一方。</p>
          <p>您在此获得的许可仅限于在由谷歌授权的您拥有或控制的个人、有限、非独家、不可转让的权利，在个人、非商业用途中安装平台，但受到 Google 的 Google Play 商店服务条款中规定的使用规则的约束。</p>
          <p>Google 对平台或其内容不负责，也无义务提供任何与平台有关的维护或支持服务。</p>
          <p>如果平台未能符合任何适用的保修，您可以通知谷歌，并且谷歌将向您退还平台的购买价格（如果有）。在适用法律允许的最大范围内，Google 对平台不承担任何其他保修义务。</p>
          <p>Google 对于您或第三方就平台或您对平台的拥有或使用（包括但不限于）的任何索赔不负责任，（a）产品责任索赔；（b）任何声称平台未能符合任何适用法律或法规要求的任何索赔；和（c）根据消费者保护或类似立法的索赔。</p>
          <p>在任何第三方声称平台或您对平台的拥有和使用侵犯其知识产权权利时，Google 不负责调查、辩护、解决或解除此类知识产权侵权索赔。</p>
          <p>您声明并保证（a）您不位于美国政府禁运国家，或已被美国政府指定为“支持恐怖主义”的国家；以及（b）您未列入任何美国政府的禁止或限制方当事人名单。</p>
          <p>谷歌及其子公司是这些条款的第三方受益人，而且在您接受这些条款后，如果您违反了这些条款，则谷歌有权（在您违反这些条款后向您发出通知后）根据这些条款单方面主张对您的权利（在谷歌不需遵循任何法院程序的情况下，无论是否提起诉讼或其他司法程序）。</p>
          <h1>28. <strong>联系我们。</strong></h1>
          <p>如果您对此《协议》有任何疑问，或需要了解有关我们的服务的更多信息，请通过以下方式联系我们：</p>
          <p>电子邮件地址： <a href="mailto:contact@relagram.com">contact@relagram.com</a></p>

          <h1>28. 您对我们服务的访问和使用</h1>
          <p>您对服务的访问和使用受这些条款和所有适用的法律和法规的约束。您不得：</p>
          <ul>
            <li>如果您没有完全能力并且在法律上有权同意这些条款或者是通过您的父母或法定监护人授权使用服务，则无权访问或使用服务；</li>
            <li>制作未经授权的副本，修改，适应，翻译，反向工程，拆卸，反编译或创建服务或其中包含的任何内容的任何衍生作品，包括其中的任何文件，表格或文档（或其中的任何部分）或确定或试图确定任何源代码，算法，方法或技术，或者确定服务或其任何衍生作品所体现的源代码，算法，方法或技术；</li>
            <li>全或部分分发，许可，转让或出售任何服务或其任何衍生作品；</li>
            <li>以费用或收费方式推广，出租或出租服务，或使用服务进行广告或进行任何商业招揽；</li>
            <li>未经我们明确书面同意，以任何商业或未经授权的目的使用服务，包括传播或促进任何商业广告或招揽或垃圾邮件；</li>
            <li>干扰或试图干扰服务的正常运作，破坏我们的网站或连接到服务的任何网络，或绕过我们可能使用的任何防止或限制访问服务的措施；</li>
            <li>将服务或其中的任何部分合并到任何其他程序或产品中。在这种情况下，我们保留拒绝服务，终止帐户或限制访问服务的权利；</li>
            <li>使用自动化脚本从服务收集信息或以其他方式与服务进行交互；</li>
            <li>冒充任何人或实体，或虚假陈述或以其他方式歪曲您或您与任何人或实体的关联，包括给人上传，发布，传输，分发或以其他方式提供的任何内容都是从服务发出的印象；</li>
            <li>恐吓或骚扰他人，或宣传基于种族，性别，宗教，国籍，残疾，性取向或年龄的性暗示材料，暴力或歧视行为；</li>
            <li>未经Relagram的明确授权，使用或试图使用他人的帐户，服务或系统，或在服务上创建虚假身份；</li>
            <li>以可能引起利益冲突或损害服务目的的方式使用服务，例如与其他用户交易评论或撰写或请求虚假评论；</li>
            <li>使用服务上传，传输，分发，存储或以任何方式提供：包含病毒，特洛伊木马，蠕虫，逻辑炸弹或其他恶意或技术有害材料的文件；</li>
            <li>使用，发布或转载任何未经请求或未经授权的广告，招揽，促销材料，“垃圾邮件”，“垃圾邮件”，“连锁信件”，“金字塔计划”或任何其他禁止形式的招揽；</li>
            <li>使用，发布或转载任何第三方的私人信息，包括地址，电话号码，电子邮件地址，个人身份证明文件中的数字和特征（例如，国民保险号码，护照号码）或信用卡号码；</li>
            <li>使用，发布或转载可能侵犯任何其他人的任何版权，商标或其他知识产权或隐私权的材料。请阅读我们单独的<a href="{routes.COPYRIGHT}"><strong>版权政策</strong></a>；</li>
            <li>使用，发布或转载任何诽谤任何人，淫秽，冒犯，色情，令人讨厌或煽动性的材料；</li>
            <li>使用，发布或转载可能构成，鼓励或提供指导犯罪行为，危险活动或自残的材料；</li>
            <li>使用，发布或转载故意设计用于挑衅或激怒他人的任何材料，尤其是恶搞和欺负，或者意图骚扰，伤害，伤害，吓唬，使人困扰，尴尬或难过的人；</li>
            <li>使用，发布或转载包含任何形式威胁的材料，包括身体暴力威胁；</li>
            <li>使用，发布或转载种族主义或歧视性的材料，包括基于某人的种族，宗教，年龄，性别，残疾或性取向的歧视；</li>
            <li>发布或转载任何您没有合格或其他合格提供的答案，回复，评论，意见，分析或建议；或</li>
            <li>根据Relagram的单独判断，可能被认为是令人反感的材料，或限制或阻止任何其他人使用服务的材料，或可能使Relagram，服务或其用户遭受任何类型的伤害或责任。</li>
          </ul>
          <p>联系我们。</p>
          <p>您可以通过<a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>联系我们</p>
        </div>
      </div>
    </div>
  )
}
