import React from 'react'

import copy from '../../../assets/svg/icon_copy.svg'
import Tooltip from '../../../components/uiKit/Tooltip'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'

const Wallet = ({ wallet }) => {
  const { name, address, icon } = wallet

  const [write, read] = useCopyToClipboard()

  const handleCopyClick = () => {
    write(address)
  }

  return (
    <li className="donate-page__wallet">
      <div className="donate-page__wallet-name">
        <img src={icon} alt={name} />
        <span>{`${name}: `}</span>
      </div>
      <Tooltip
        overlay={<span>copied</span>}
        trigger={['click']}
        placement="top"
      >
        <div className="donate-page__wallet-address" onClick={handleCopyClick}>
          <span className="donate-page__wallet-address-input">{address}</span>
          <img
            className="donate-page__wallet-address-icon"
            src={copy}
            alt="copy"
          />
        </div>
      </Tooltip>
    </li>
  )
}

export default Wallet
