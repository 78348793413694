import { tw } from '@twind/core'
import { StartScreenSection } from '../sections'

export const MainPage = () => {
  return (
    <div>
      <StartScreenSection />
      <div className="container">
        <div className="twr">
          <h2>About Us</h2>
          <h3>What is Relagram?</h3>
          <p>Is it a messenger? Is it a marketplace? Is it a digital dating space? Is it a job portal? </p>
          <p>It’s all these and even more!</p>
          <p>Relagram is a revolutionary new platform that combines a messenger's convenience with a social network's connectivity. The app is designed to connect people and facilitate communication for personal or professional purposes.</p>

          <h2 style={{ marginTop: '50px' }}>Connect With Friends</h2>
          <p>Relagram you like to keep in touch with the world around you. To make this possible, one of the app's major features is the instant messenger. This feature allows you to skip the FOMO and always be in touch with friends, family, and colleagues, no matter where you are.</p>
          <p>Get real-time updates, share photos, and swap stories with one easy-to-use app.</p>
          <p>For those who can’t live without their fur babies, the app also includes a feature called Pets, which allows you to create and maintain a profile for your pet. This is a great way to connect with other pet owners and share experiences and tips that will make you an even better pet parent.</p>
          <p>Want to add more people to a conversation? The app’s group chat feature makes it easy to find your tribe and connect with them. It is perfect for organizing events, team projects, or your next weekend party.</p>

          <h3>Find Your Forever After</h3>
          <p>Want to turn an online connection into your very own IRL love story? Relagram could be the setting for your own digital romance. The platform’s dating feature makes it easy to meet new people, with advanced search filters that help you zero in on potential matches based on your location and preferences.</p>

          <h3>Discover a New World</h3>
          <p>Places is a feature that allows travelers to share recommendations for unusual and offbeat places they’ve visited. This is an excellent resource for discovering new and exciting destinations.</p>

          <h3>Build Your Audience</h3>
          <p>Do you have some significant ideas to tell the world? Find followers and start your text-based thematic channel on the Relagram app. Go on, share your thoughts and ideas with and build a community based on your interests on the app.</p>
          <p>Relagram’s events tab lets you create and promote events, such as parties, concerts, and other gatherings. Why worry about finding the right promoter for your event, ever.? Relagram is all you need.</p>

          <h3>Publicize Your Online Presence</h3>
          <p>Relagram values the contributions of bloggers and influencers and allows you to link to your existing channels on other social networks. This is our way of thanking you for helping us advertise and promote our product.</p>

          <h3>Buy, Sell & Promote Your Brand</h3>
          <p>To empower business owners, the app also offers a host of features that can help them leverage the power of social commerce. For starters, users who own a business there is a possibility to create and promote a business profile on the platform.</p>
          <p>Whether you’re looking for potential customers or leads to promote your product or service, they’re all on the Relagram app. In addition, there’s also the Market, an in-app marketplace where you can buy and sell goods. Whether it's something you no longer need or if you want to sell the product you have innovated, it is easy to find an audience of receptive buyers on the Relagram app.</p>

          <h3>Find Your Dream Job or the Perfect Employee</h3>
          <p>Are you looking for your dream job? Or are you scouting for the right talent to fill an existing position?</p>
          <p>For job seekers and employers - there’s Jobs, a job portal that’s part of the Relagram ecosystem. Finding and filling opportunities near you has never been easier!</p>

          <h3> What makes the Relagram app unique?</h3>
          <h4>You Won’t Spend a Dime</h4>
          <p>One of the unique features of the app is the use of its internal currency, MetaFek Coin. This currency can be earned within the app by undertaking micro-actions, earning referral coins, or via product advertising awards within the ecosystem. MetaFek can be used to purchase advanced features and subscription plans without spending any real money.</p>
          <h4>Real-Time Navigation</h4>
          <p>A geolocation map also helps you navigate events, businesses, goods, people, and pets in your area in real-time. You can also display your exact or approximate location on the map or just turn it off.</p>
          <h4>Compatible Across Devices</h4>
          <p>Phone or tablet, iOS or Android, the app is compatible across devices and operating systems. Whether you upgrade or crossover, the app, and all your data, are always there waiting for you.</p>
          <p>Overall, Relagram is a comprehensive platform that offers a wide range of features for communication, connection, and commerce.</p>
          <p>Break through the clutter of using multiple apps for specific purposes and get this all-in-one app with the best of everything the digital world offers. Connections or romance, DMs or group chats, Android or iOS, business or leisure, work or play, whatever you seek, there’s one app for it all.</p>
          <p>What are you waiting for? It’s time to Relagram!</p>
        </div>
      </div>
    </div>
  )
}
