import React, { useState } from 'react'

import './styles.scss'

export const SelectComponent = ({ options, label, onChange, defaultValue }) => {
  const [state, setState] = useState(defaultValue || undefined)
  const handleChange = (e) => {
    setState(e.target.value)
    onChange(e.target.value)
  }

  const renderOptions = () =>
    options.map((option) => (
      <option value={option.value} key={option.value}>
        {option.title}
      </option>
    ))

  return (
    <div className="select-component">
      <label htmlFor="select">{label}</label>
      <select
        className="select-component__select"
        id="select"
        onChange={handleChange}
      >
        {renderOptions()}
      </select>
    </div>
  )
}
