import { appStoreLink, googlePlayLink } from "../constants/links"

function getAppLink() {
  const ug = navigator.userAgent.toLowerCase()

  if (ug.includes('iphone') || ug.includes('macintosh')) {
    return appStoreLink
  }

  return googlePlayLink
}

export default getAppLink