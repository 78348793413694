import { FC, useMemo } from 'react'
import { MediaItem } from './AdsContexts'
import { tx } from '@twind/core'
import frameGray from '../../assets/img/ad/ring.svg'
import frameGreen from '../../assets/img/ad/ring-green.svg'
import frameRed from '../../assets/img/ad/ring-red.svg'

interface LoadingImageSelectorProps {
  file: MediaItem | null
}

const LoadingImageSelector: FC<LoadingImageSelectorProps> = ({ file }) => {
  const video = useMemo(() => {
    if (!file) return null
    return (
      <video
        src={URL.createObjectURL(file.image)}
        className={tx`w-full h-full object-cover`}
      />
    )
  }, [])
  return !!file ? (
    <>
      <button
        type="button"
        className={tx`relative ml-[20px] mb-4 w-[98px] h-[98px]`}
      >
        <img
          className={tx`absolute`}
          src={file.error ? frameRed : file.fileUrl ? frameGreen : frameGray}
          alt="frame"
        />
        <div className={tx`p-[10px] w-full h-full`}>{video}</div>
      </button>
    </>
  ) : null
}

export default LoadingImageSelector
