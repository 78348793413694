import React, { useState } from 'react'

import { FaqDocuments } from '../../../components/documents'
import { SelectComponent } from '../../../components/SelectComponent'

import './styles.scss'

const { En, Ru } = FaqDocuments

const dictionary = {
  Ru: {
    page: <Ru />,
    label: 'Выберите язык:',
  },
  En: {
    page: <En />,
    label: 'Choose your language:',
  },
}

export const FaqPage = () => {
  const [currentLang, setCurrentLang] = useState('En')

  const options = [
    {
      value: 'En',
      title: 'English',
    },
    {
      value: 'Ru',
      title: 'Русский',
    },
  ]

  const handleLangChange = (value) => {
    setCurrentLang(value)
  }

  return (
    <div className="documents">
      <div className="faq-page__select-wrapper">
        <SelectComponent
          options={options}
          defaultValue="En"
          onChange={handleLangChange}
          label={dictionary[currentLang].label}
        />
      </div>
      {dictionary[currentLang].page}
    </div>
  )
}
