import icon_dating from '../../../assets/svg/icon_dating.svg'
import icon_events from '../../../assets/svg/icon_events.svg'
import icon_chennels from '../../../assets/svg/icon_chennels.svg'
import icon_chats from '../../../assets/svg/icon_chats.svg'
import icon_business from '../../../assets/svg/icon_business.svg'
import icon_crypto from '../../../assets/svg/icon_crypto.svg'
import icon_fix from '../../../assets/svg/icon_fix.svg'
import icon_jobs from '../../../assets/svg/icon_jobs.svg'
import icon_maps from '../../../assets/svg/icon_maps.svg'
import icon_market from '../../../assets/svg/icon_market.svg'
import icon_metafek from '../../../assets/svg/icon_metafek.svg'
import icon_pets from '../../../assets/svg/icon_pets.svg'
import icon_places from '../../../assets/svg/icon_places.svg'
import icon_privacy from '../../../assets/svg/icon_privacy.svg'
import icon_protection from '../../../assets/svg/icon_protection.svg'
import icon_ref from '../../../assets/svg/icon_ref.svg'
import icon_stickers from '../../../assets/svg/icon_stickers.svg'
import icon_video from '../../../assets/svg/icon_video.svg'
import icon_avatar from '../../../assets/svg/icon_avatar.svg'
import icon_heart from '../../../assets/svg/icon_heart.svg'

import dating from '../../../assets/img/features/dating.jpg'
import datingPhone from '../../../assets/img/phone_dating_service.png'
import events from '../../../assets/img/features/events.jpg'
import eventsPhone from '../../../assets/img/phone_events.png'
import channels from '../../../assets/img/features/channels.jpg'
import channelsPhone from '../../../assets/img/phone_channels.png'
import chats from '../../../assets/img/features/chats.jpg'
import chatsPhone from '../../../assets/img/phone_chats.png'
import business from '../../../assets/img/features/business.jpg'
import businessPhone from '../../../assets/img/phone_business.png'
import jobs from '../../../assets/img/features/jobs.jpg'
import jobsPhone from '../../../assets/img/phone_jobs.png'
import marcket from '../../../assets/img/features/marcket.jpg'
import marcketPhone from '../../../assets/img/phone_marcket.png'
import pets from '../../../assets/img/features/pets.jpg'
import petsPhone from '../../../assets/img/phone_pets.png'
import places from '../../../assets/img/features/places.jpg'
import placesPhone from '../../../assets/img/phone_places.png'
import map from '../../../assets/img/features/map.jpg'
import crypto from '../../../assets/img/features/crypto.jpg'
import cryptoPhone from '../../../assets/img/phone_crypto.png'
import protection from '../../../assets/img/features/protection.jpg'
import protectionPhone from '../../../assets/img/phone_protection.png'
import metafek from '../../../assets/img/features/metafek.jpg'
import metafekPhone from '../../../assets/img/phone_metafek.png'
import ref from '../../../assets/img/features/ref.jpg'
import refPhone from '../../../assets/img/phone_coins.png'
import privacy from '../../../assets/img/features/privacy.jpg'
import privacyPhone from '../../../assets/img/phone_privacy.png'
import stickers from '../../../assets/img/features/stickers.jpg'
import stickersPhone from '../../../assets/img/phone_stickers.png'
import avatar from '../../../assets/img/features/avatar.jpg'
import avatarPhone from '../../../assets/img/phone_location.png'
import video from '../../../assets/img/features/video.jpg'
import videoPhone from '../../../assets/img/phone_video.png'

import subscription from '../../../assets/img/features/subscription.jpg'
import subscriptionPhone from '../../../assets/img/phone_subscr.png'

import locationPhone from '../../../assets/img/phone_location.png'
import { routes } from '../../../constants/routes'

export const deatiledFuncData = {
  dating: {
    icon: icon_dating,
    bgImage: dating,
    phoneImage: datingPhone,
    title: 'Free dating service',
    description: () => {
      return (
        <>
          <p>
            Looking for love? You're at the right place! With unlimited
            messaging, you can talk to that special someone from dawn to dusk
            since the platform has no communication restrictions. Signing up for
            the dating service is completely free too.
          </p>
          <p>
            They say communication is key to healthy relationships. At
            Relagram, you can do just that. You’ll get to meet tons of people
            with similar interests and make new connections, whether you’re
            looking for new friends or that one special spark.
          </p>
          <p>
            The app’s free dating service also lets you filter through searches
            to find possible matches based on your geographic location or
            preferences. Try it now — your forever after might just be waiting
            on the other end!
          </p>
        </>
      )
    },
  },
  events: {
    icon: icon_events,
    bgImage: events,
    phoneImage: eventsPhone,
    title: 'Events',
    description: () => (
      <>
        <p>
          Create your own event and promote it across your network in a few
          minutes with the “Events” tab. It doesn’t need to be a niche event,
          either! You can promote anything from a weekend yard sale on your
          property, or a music gig. What’s more, you can also join an existing
          event if you see something that interests you.
        </p>
        <p>
          With Relagram, there’s no limit to what you can promote and who you
          can reach. To create an event, simply click on the “Profile” icon in
          the lower right-hand corner, then go to “My Events.” Click on the “New
          Event” button. Upload an image, add the time, place, venue, and other
          important details, and you’re good to go!
        </p>
      </>
    ),
  },
  chennels: {
    icon: icon_chennels,
    bgImage: channels,
    phoneImage: channelsPhone,
    title: 'Channels',
    description: () => (
      <>
        <p>
          Got something to say? Relagram allows you to create your own
          text-based broadcast channel based on a range of exciting topics and
          themes. You can join an existing channel, too, and share your views
          with others.
        </p>
        <p>
          From your dashboard, simply click the “Channels” icon on the lower
          portion of the screen. If you’d like to join an existing channel,
          search for a topic or scroll through the list of available channels.
        </p>
        <p>
          To create a new broadcast channel, click the “+” button on the upper
          right-hand side of the screen. Fill out pertinent details, such as
          your channel name, channel photos, and description. Next, choose a
          chat background from a selection of aesthetic backgrounds and
          landscapes to match your channel’s vibe. You can also set your chat to
          private, manage notifications, and adjust location settings.
        </p>
      </>
    ),
  },
  chats: {
    icon: icon_chats,
    bgImage: chats,
    phoneImage: chatsPhone,
    title: 'Chats',
    description: () => (
      <>
        <p>
          Relagram away — that’s what Relagram is made for! Participate in
          one-on-one chats or group chats with customizable privacy settings.
          You can easily create group chats for events, teams, projects, and
          more.
        </p>
        <p>
          o get started, click “Chat” from your dashboard. It’s on the lower
          part of the screen, so you won’t miss it. If you have no contacts yet,
          click “Start Now” and search for people based on location, deals,
          jobs, or events. For example, create a Garage Sale Group within your
          area easily on the app. You can also select an event with a group chat
          to participate in the conversation.
        </p>
      </>
    ),
  },
  business: {
    icon: icon_business,
    bgImage: business,
    phoneImage: businessPhone,
    title: 'Business Profile',
    description: () => (
      <>
        <p>
          This one’s for all business owners. Use our free tools to build and
          promote your business. Reach potential customers and suppliers by
          creating a business profile on the platform. Link your existing social
          media channels for better visibility!
        </p>
        <p>
          To start, click “Profile” on the lower right-hand part of your
          dashboard, then select “My Business.” Click “Start Now” to complete
          your free business profile and put your name on the map. Then, fill
          out the required details, such as your business name, type,
          description, address, phone number, and website, and start getting
          noticed.
        </p>
        <p>
          Feel free to add a profile photo and other related photos as well! You
          can also select an advertisement plan based on your location. Your
          plan can be tweaked by changing your desired ad coverage in terms of
          distance as well as the running time of the ads.
        </p>
      </>
    ),
  },
  jobs: {
    icon: icon_jobs,
    bgImage: jobs,
    phoneImage: jobsPhone,
    title: 'Jobs',
    description: () => (
      <>
        <p>
          Need to hire people for your business or a project? Jobs is your
          go-to solution. If you’re a job seeker, use Jobs to look for jobs
          that meet your search criteria. If you’re a business owner looking to
          fill a vacancy in your company, click “Profile” on your dashboard.
          Then, scroll to “MyJobs” and click “Hire Now.” You can then fill
          out the required information and put your job posting out there to
          attract potential applicants.
        </p>
        <p>
          For job seekers, navigate to “Jobs” on your dashboard by swiping left
          across the icons below the map. Depending on your location, you’ll see
          the number of available job postings in the area. Click through a
          purple circle to view the listings in that area.
        </p>
      </>
    ),
  },
  market: {
    icon: icon_market,
    bgImage: marcket,
    phoneImage: marcketPhone,
    title: 'Market',
    description: () => (
      <>
        <p>
          Market is an all-in-one marketplace where you can rent, buy, and sell
          goods and services with fellow Mappers! Here, you can find potential
          customers within your area or good deals from neighborhood sellers.
          With market, selling new and used items or offering equipment for rent
          is easier than ever.
        </p>
        <p>
          To sell something, go to your profile and select “My Market.” Then,
          select “Create” to post your item. You can choose from three options:
          Transportation, Stuff, or Housing. Then, select whether you’re putting
          your item or service for sale or rent. Click “Create” and fill out the
          basic information required.
        </p>
        <p>
          If, for example, you’re listing a house for sale, you’ll need to add
          property details such as your property type, the year it was built,
          number of bedrooms, features, and the like. You can add up to 10
          photos. Select the desired coverage and time for your ad, then click
          “Post.”
        </p>
      </>
    ),
  },
  pets: {
    icon: icon_pets,
    bgImage: pets,
    phoneImage: petsPhone,
    title: 'Pets',
    description: () => (
      <>
        <p>
          Pets is where you can create a profile for your pets and communicate
          with other pet owners to exchange tips, product recommendations, or
          even funny stories! Whether you own a dog or something more exotic
          (like a turtle), you’re sure to find fellow pet lovers here.
        </p>
        <p>
          From your profile, scroll to “My Friends” and select “Add a Pet.” Want
          to have more fun? Simply create a separate profile for each pet! Add
          three photos of your pet from different angles, key in their name, and
          write a description to let friends know about your pet’s likes and
          dislikes.
        </p>
        <p>
          Then, add basic information like the species, breed, sex, date of
          birth, pedigree, color, etc. You can also let others know if you’re
          looking for a potential mate for your pet and where you’re based.
        </p>
      </>
    ),
  },
  places: {
    icon: icon_places,
    bgImage: places,
    phoneImage: placesPhone,
    title: 'Places',
    description: () => (
      <>
        <p>
          Share and view hi-res photos and discover new destinations for your
          next adventure! With Places, you can travel around the world virtually
          or share tips with locals and fellow travelers on the best spots to
          dine, stay in, shop, and more.
        </p>
        <p>
          To get started, click your desired location on the map and create a
          post. Feel free to add photos and a short description, too. You can
          also create a separate broadcast channel dedicated to travel tips,
          such as “Travel to Asia: Top Tips” and the like!
        </p>
      </>
    ),
  },
  fix: {
    icon: icon_fix,
    bgImage: dating,
    phoneImage: 999,
    title: 'Fix',
    description: 'Finding a local helper or adviser in fixing something',
    isHidden: true,
  },
  maps: {
    icon: icon_maps,
    bgImage: map,
    phoneImage: locationPhone,
    title: 'Maps and Geolocation.',
    description: () => (
      <>
        <p>
          Relagram is location-smart, which means you can customize your
          location display for individual groups. Chat with friends from around
          the world and instantly see events and offers happening around them,
          so you’ll never miss a beat even when you’re miles apart!
        </p>
        <p>
          Keep in touch with family, colleagues, and friends whether they’re
          traveling, working, or living elsewhere. With Map, you can simply
          click on a location on the world map or key in your desired location,
          and you’ll be shown a list of events within the radius of your
          selected location.
        </p>
        <p>
          You can also view local deals, ads, and groups within that location
          and stay connected wherever you may be.
        </p>
      </>
    ),
  },
  crypto: {
    icon: icon_crypto,
    bgImage: crypto,
    phoneImage: cryptoPhone,
    title: 'Crypto payments',
    description: () => (
      <>
        <p>
          Relagram allows you to pay for advertising and other advanced features
          with its native internal coin, MetaFek Coin (MFC). MFC can be
          purchased through payment channels authorized by Apple and Google,
          depending on the app version you’re using (iOS or Android).
        </p>
        <p>
          You can purchase MFC by paying in fiat currency or cryptocurrency, and
          it can then be used for many functions on the platform. Relagram also
          accepts donations in other cryptocurrencies, including Tether (USDT),
          Bitcoin (BTC), Ethereum (ETH), Ripple (XRP), Litecoin (LTC), and
          Binance Coin (BNB).
        </p>
      </>
    ),
  },
  protection: {
    icon: icon_protection,
    bgImage: protection,
    phoneImage: protectionPhone,
    title: 'Personal data protection',
    description: () => (
      <>
        <p>
          Relagram values the privacy of its users. You can rest assured that we
          do not sell our users’ personal data. We strictly adhere to the
          regulations outlined by the General Data Protection Regulation (GDPR)
          of the European Union and its Member States, as well as the California
          Consumer Privacy Act (CCPA).
        </p>
        <p>
          We collect and process your personal data based on the requirements of
          the app to provide the best user experience. This includes online
          identifiers like your IP address, browser version, etc. We only gather
          the data needed to provide you with adequate information for your
          needs within the app and for processing contracts and ads with your
          user account.
        </p>
        <p>
          For more information, view our detailed{' '}
          <a href={routes.PRIVACY_POLICY}>Privacy Policy here.</a>
        </p>
      </>
    ),
  },
  metafek: {
    icon: icon_metafek,
    bgImage: metafek,
    phoneImage: metafekPhone,
    title: 'MetaFek Coin (MFC)',
    description: () => (
      <>
        <p>
          MetaFek is our native currency within the Relagram ecosystem. You can
          purchase it through Apple or Google, and its price at the time will be
          displayed at the point of purchase.
        </p>
        <p>
          Your user account will be credited with the MFC you have purchased in
          real time. MFC is not meant to be exchanged for legal tender, cash, or
          any other form of fiat currency or credit. MFC can be earned by
          carrying out certain tasks on the app, like sharing the app with
          friends.
        </p>
        <p>
          MFC is also the sole mode of currency on the platform. It cannot be
          used in combination with coupons, discounts, and promos unless
          otherwise indicated by the Relagram team through official channels.
        </p>
      </>
    ),
  },
  ref: {
    icon: icon_ref,
    bgImage: ref,
    phoneImage: refPhone,
    title: 'Referral system and rewards',
    description: () => (
      <>
        <p>
          Get rewarded for being an active member in the Relagram ecosystem.
          MetaFek Coins can be earned by performing certain tasks on the app,
          such as inviting friends using your unique QR code, which earns you 50
          MFC per share.
        </p>
        <p>
          When you rate the app on your corresponding app store, you get up to
          10 MFC. These coins can then be used to run ads on the platform and
          promote your business pages and offerings.
        </p>
        <p>
          You’ll also find other ways to earn more MFC as the platform grows,
          such as by mentioning Relagram in other social networks, watching
          ads, creating videos, and interacting with other users or contributing
          to groups. <a href={routes.AD_MEDIA}>Stay tuned for more!</a>
        </p>
      </>
    ),
  },
  privacy: {
    icon: icon_privacy,
    bgImage: privacy,
    phoneImage: privacyPhone,
    title: 'Privacy of the communication',
    description: () => (
      <>
        <p>
          On Relagram, you can communicate privately within groups and
          one-on-one chats. Your secured secret chats or chat histories will not
          be saved on our servers, and you can rest assured that any
          confidential or sensitive information you share with someone on the
          platform will be safe.
        </p>
        <p>
          We also have an extensive Privacy Policy in accordance with global
          regulatory standards on consumer rights and privacy. As such, even
          though we collect your personal information to serve you better and
          provide you with the best user experience, you can be assured of full
          control over your data under the GDPR and CCPA.
        </p>
        <p>
          {`This means we do not sell your data to anyone. You can also opt to
          have your data removed from the platform by contacting us at `}{' '}
          <a href="mailto:contact@relagram.app">contact@relagram.app</a>.
        </p>
      </>
    ),
  },
  stickers: {
    icon: icon_stickers,
    bgImage: stickers,
    phoneImage: stickersPhone,
    title: 'Custom Stickers',
    description: () => (
      <>
        <p>
          Have a talent for drawing and creating stuff? Find a spot to thrive
          and showcase your work on our platform. Users can upload custom
          stickers though our web landing page. <a href={routes.LOGIN}>Login</a>
        </p>
        <p>
          You can create sticker sets based on various themes, such as TV shows,
          popular travel destinations, books, music, sports, movies, and the
          like. You can also create generic illustrations in your own creative
          style.
        </p>
      </>
    ),
  },
  avatar: {
    icon: icon_avatar,
    bgImage: avatar,
    phoneImage: avatarPhone,
    title: 'Avatar Ticker/Reel',
    description: () => (
      <>
        <p>
          Use our unique ticker feature to set up ads and feature your profile.
          The reel allows you to set your desired parameters for sharing your ad
          or post with ease and gives you control with regard to its reach.
        </p>
        <p>
          To quickly create a post you want to promote, simply navigate to your
          dashboard and click the circle with three dots on the upper right-hand
          portion of the screen. You’ll see a pop-up that says, “I want to
          promote,” from which you can select the type of content you wish to
          advertise.
        </p>
        <p>
          Say you’re an influencer looking to promote your profile. Click
          “Profile” below “I want to promote.” You’ll also see a set of sliders
          indicating the amount of coins in MFC you’ll need to reach a certain
          distance or run your ad multiple times.
        </p>
      </>
    ),
  },
  video: {
    icon: icon_video,
    bgImage: video,
    phoneImage: videoPhone,
    title: 'Video advertising',
    description: () => (
      <>
        <p>
          If you’re a vlogger or social media influencer, business owner,
          Relagram is the place to be for uploading short videos that target
          your desired audiences based on geolocation and customer's
          preferences. This can be very helpful if you’re promoting a local
          service or product, as it can increase product and brand visibility.
        </p>
        <p>
          Create your own promo compagn under your account at landing page by
          following simple steps:{' '}
          <a href={routes.LOGIN}>https://relagram.com/product/login</a>
        </p>
      </>
    ),
  },
  subscription: {
    icon: icon_heart,
    bgImage: subscription,
    phoneImage: subscriptionPhone,
    title: 'Progressive subscription system',
    description: () => (
      <>
        <p>
          As a content creator, you can create subscriptions and provide upgrade
          options to your followers and community participants as needed. Use
          your MetaFek coins to adjust the location coverage as well as the
          length of your subscriptions, as needed.
        </p>
        <p>
          You can create regular, free content available to your general
          community, and a tiered/progressive subscription system with content
          that increases in value, depending on your package options.
        </p>
      </>
    ),
  },
}
