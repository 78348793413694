import { FC } from 'react'
import './styles.scss'

interface ButtonProps {
  type?: 'submit' | 'reset' | 'button' | undefined
  isObtn?: boolean
  onCLick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  content?: string | JSX.Element
  wrapperClassName?: string
  inline?: boolean
  disabled?: boolean
}

const Button: FC<ButtonProps> = ({
  type = 'button',
  onCLick,
  disabled,
  content,
  isObtn = false,
  wrapperClassName = '',
  inline = false,
}) => {
  return (
    <div
      className={`uiBtn ${isObtn ? 'uiBtn--o' : ''} ${
        inline ? 'uiBtn--inline' : ''
      } ${wrapperClassName} ${disabled ? 'pointer-events-none opacity-60' : ''}`}
    >
      <button onClick={onCLick} type={type} disabled={disabled}>
        {content}
      </button>
    </div>
  )
}

export default Button
