import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './App'

import './styles/fonts.scss'
import './index.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import install from '@twind/with-react'
import config from './twind.config'
import "@lottiefiles/lottie-player/dist/tgs-player";
// activate twind - must be called at least once
install(config)

ReactDOM.render(<App />, document.getElementById('root'))
