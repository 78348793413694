import React from 'react'

const ErrorMessage = ({ message, errorType }) => {
  return (
    <div className="ui-kit-input__hint-wrapper">
      <span className={errorType === 'error' ? 'error-text' : 'warning-text'}>
        {message}
      </span>
    </div>
  )
}

export default ErrorMessage
