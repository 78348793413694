import { AnyAction } from 'redux'

import { StickerFile } from '../../pages/AddAnimatedStickers'
import {
  uploadImage,
  uploadStickerPack,
  uploadVideo,
} from '../../services/api/stickerManagingService'

type UploadedSticker = { emoji: StickerFile['emoji']; link: string }

type Sticker = {
  name: string
  url: string
  tag: string
}

export type StickerPackDto = {
  name: string
  icon: string
  category: 'GRAPHIC' | 'ANIMATION'
  price: number
  stickers: Sticker[]
}

export const thunkUploadStickers = (
  stickerFiles: StickerFile[],
  packName: string,
  packPrice: number = 0,
  onSuccess: () => void,
  onLoading: () => void,
  onError: () => void,
  type: 'GRAPHIC' | 'ANIMATION',
) =>
  (async () => {
    onLoading()

    const isGraphic = type === 'GRAPHIC'
    const upload = isGraphic ? uploadImage : uploadVideo

    try {
      const requests: Promise<UploadedSticker>[] = []

      stickerFiles.forEach((sf) => {
        const fd = new FormData()

        fd.append(isGraphic ? 'image' : 'video', sf.file, sf.file.name)

        const promise = upload(fd).then((r) => {
          return { emoji: sf.emoji, link: r as unknown as string }
        })

        requests.push(promise)
      })

      const uploadedStickersData: UploadedSticker[] = await Promise.all(requests)

      const stickerDto: StickerPackDto = {
        name: packName,
        price: packPrice,
        category: type,
        icon: uploadedStickersData[0].link,
        stickers: uploadedStickersData.map((item, i) => ({
          url: item.link,
          name: `sticker-${i}`,
          tag: item.emoji.emoji,
        })),
      }

      await uploadStickerPack(stickerDto)

      onSuccess()
    } catch (error) {
      onError()
      console.log('🚀 ~ error:', error)
    }
  }) as unknown as AnyAction
