import React from 'react'

import { Button } from '../../../components/uiKit'
import { paySystems } from '../../../constants/paymentSystems'
import { useQr } from '../../../hooks/useQr'
import { stripeDonationLink } from '../../../constants/links'

const CardPayments = () => {
  const [isQrOpen, setIsQrOpen] = React.useState(false)
  const stripeQR = useQr(isQrOpen ? stripeDonationLink : null)

  const toggleQr = () => setIsQrOpen((prev) => !prev)

  const onCardPayClick = () => {
    window.location = stripeDonationLink
  }

  return (
    <div className="donate-page__card-payments">
      <Button
        onClick={onCardPayClick}
        className="donate-page__card-payments-systems-button"
      >
        <span>by</span>
        <div className="donate-page__card-payments-systems-icons-container">
          {paySystems.map((sys) => (
            <img
              key={sys.type}
              title={sys.type}
              src={sys.icon}
              alt={sys.type}
            />
          ))}
        </div>
      </Button>
      <Button onClick={toggleQr} className="donate-page__qr-button">
        {isQrOpen ? 'hide QR code' : 'show QR code'}
      </Button>
      {stripeQR}
    </div>
  )
}

export default CardPayments
