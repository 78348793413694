import { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

export default () => {
  const [from, setFrom] = useQueryParam('from', StringParam)

  useEffect(() => {
    return () => setFrom(null)
  }, [])

  return from
}
