const AUTH_KEY = 'auth'

export const authTokenStoreService = {
  obtain() {
    return localStorage.getItem(AUTH_KEY)
  },

  store(value) {
    localStorage.setItem(AUTH_KEY, value)
  },

  remove() {
    localStorage.removeItem(AUTH_KEY)
  },

  isLogged() {
    return Boolean(this.obtain())
  },
}
