import { FC } from 'react'
import { tx } from '@twind/core'

const AddStickerIcon: FC = () => {
  return (
    <svg className={tx('group')} viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="addStickerInputBg"
        className={tx('group-hover:!fill-[#AF90FF]')}
        d="M0 49C0 8.6485 8.6485 0 49 0C89.3515 0 98 8.6485 98 49C98 89.3515 89.3515 98 49 98C8.6485 98 0 89.3515 0 49Z"
        fill="#F4F6FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={tx('group-hover:!fill-white')}
        d="M48 58C48 58.5523 48.4477 59 49 59C49.5523 59 50 58.5523 50 58V50H58C58.5523 50 59 49.5523 59 49C59 48.4477 58.5523 48 58 48H50V40C50 39.4477 49.5523 39 49 39C48.4477 39 48 39.4477 48 40V48H40C39.4477 48 39 48.4477 39 49C39 49.5523 39.4477 50 40 50H48V58Z"
        fill="#3C3E4B"
      />
    </svg>
  )
}

export default AddStickerIcon
