import { FC, useEffect, useMemo, useState } from 'react'
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import { Tab } from '@headlessui/react'
import Overview from './Overview'
import { CampaignType, getCampaings } from '../../services/api/addsService'
import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { useAdsContext } from '../Ads/AdsContexts'
import { PromoCampaign } from '../../services/types/PromoCampaign'
import allBusinessItems from '../Ads/allBusinessItems'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Reports: FC = () => {
  const bc = useMemo<BreadcrumbItem[]>(
    () => [{ title: 'Advertising campaign' }],
    [],
  )

  const places = useMemo(
    () =>
      ({
        REUSE_EVENT_BUTTON: 'Reuse Event Button',
        RENEW_BUSINESS_BUTTON: 'Renew Bussiness Button',
        RENEW_YAPMARKET_AD_BUTTON: 'Renew YapMarket Ad Button',
        TICKER_BUBBLE: 'Ticker Bubble',
        CHAT_PLACEHOLDER: 'Chat Placeholder (5th place from the top)',
      } as Record<string, string>),
    [],
  )
  const [campaines, setCampaines] = useState<PromoCampaign[]>([])

  const categories = useMemo(() => {
    return campaines.reduce(
      (acc, current) => {
        if (current.status === 'IN_PROGRESS') {
          acc['In Process'].push(current)
          return acc
        }
        acc.Completed.push({ ...current })
        return acc
      },
      { 'In Process': [], Completed: [] } as Record<
        'In Process' | 'Completed',
        PromoCampaign[]
      >,
    )
  }, [campaines])

  console.log('🚀 ~ categories:', categories)

  useEffect(() => {
    getCampaings().then((r) => {
      if (r) setCampaines(r)
    })
  }, [])

  return (
    <div className="sticker-requirements max-md:w-full">
      <Breadcrumbs items={bc} />
      <div className="w-full mt-8">
        <Tab.Group>
          <Tab.List className="flex rounded-xl ">
            {Object.keys(categories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    'rounded-lg px-[20px] py-[8px] text-[15px] font-medium leading-[25px] mr-3 outline-none font-[KohinoorBangla]',
                    selected
                      ? 'bg-[#AF90FF] text-white shadow-sm'
                      : 'text-[#4A4A4A] hover:bg-[#AF90FF] hover:text-white',
                  )
                }
              >
                {category}
              </Tab>
            ))}
            <Link
              className="rounded-lg px-[20px] text-[#4A4A4A] py-[8px] text-[15px] font-medium leading-[25px] mr-3 outline-none font-[KohinoorBangla] hover:bg-[#AF90FF] hover:text-white"
              to={routes.PROFILE.ADD}
            >
              Start new Start new campaign
            </Link>
          </Tab.List>
          <Tab.Panels className="mt-2">
            {Object.values(categories).map((posts, idx) => (
              <Tab.Panel key={idx}>
                <ul>
                  {posts.map((post) => {
                    const male = post.age
                      .filter((p) => p.gender === 'MALE')
                      .map((p) => `${p.ageFrom} - ${p.ageTo}`)
                      .join(', ')
                    const female = post.age
                      .filter((p) => p.gender === 'FEMALE')
                      .map((p) => `${p.ageFrom} - ${p.ageTo}`)
                      .join(', ')

                    return (
                      <li
                        key={post.id}
                        className="relative rounded-md p-3 outline-none"
                      >
                        <Overview
                          date={new Date(post.createdAt).toLocaleDateString()}
                          showTo={post.places.map((p) => places[p]).join(', ')}
                          showForMale={male}
                          showForFemale={female}
                          country={post.countries}
                          interests={post.interests}
                          redirectUrl={post.redirectLink || ''}
                          business={allBusinessItems
                            .filter((bt) =>
                              bt.types.every((t) =>
                                post.businessTypes.some(
                                  (busines) => t === busines,
                                ),
                              ),
                            )
                            .map((b) => b.name)}
                          pay={post.total}
                          videoUrl={post.promoVideo?.url || ''}
                          amountOfViewsToPurchase={post.views}
                          amountOfViewsToPerson={post.showToOneUser}
                          userViews={[]}
                        />
                      </li>
                    )
                  })}
                </ul>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}

export default Reports
