import { sendCodeForPasswordResetByEmail } from '../../services/api/accountService'

export const thunkSendCodeForResetPasswordByEmail =
  (data, setLoading, onSuccess, onError) => async (dispatch) => {
    setLoading(true)
    try {
      const resp = await sendCodeForPasswordResetByEmail(data)
      if (resp?.result === 'ok') {
        onSuccess()
      }
    } catch (error) {
      onError(error.data.message)
    }
    setLoading(false)
  }
