import { Link } from 'react-router-dom'

import { routes } from '../../../constants/routes'

import promoImage from '../../../assets/svg/promo_currency.svg'
import i1 from '../../../assets/img/mfc/1.jpg'
import i2 from '../../../assets/img/mfc/2.jpg'
import i3 from '../../../assets/img/mfc/3.png'
import i4 from '../../../assets/img/mfc/4.png'
import i5 from '../../../assets/img/mfc/5.png'
import i6 from '../../../assets/img/mfc/6.png'

import './styles.scss'

const items = [
  {
    link: `${routes.BLOG_ITEM.split(':').shift()}0`,
    image: i1,
    title: 'Progressive subscription system.',
    descr:
      'There are various advanced options not included in the regular free v...',
  },
  {
    link: `${routes.BLOG_ITEM.split(':').shift()}0`,
    image: i2,
    title: 'Progressive subscription system.',
    descr:
      'There are various advanced options not included in the regular free v...',
  },
  {
    link: `${routes.BLOG_ITEM.split(':').shift()}0`,
    image: i3,
    title: 'Progressive subscription system.',
    descr:
      'There are various advanced options not included in the regular free v...',
  },
  {
    link: `${routes.BLOG_ITEM.split(':').shift()}0`,
    image: i4,
    title: 'Progressive subscription system.',
    descr:
      'There are various advanced options not included in the regular free v...',
  },
  {
    link: `${routes.BLOG_ITEM.split(':').shift()}0`,
    image: i5,
    title: 'Progressive subscription system.',
    descr:
      'There are various advanced options not included in the regular free v...',
  },
  {
    link: `${routes.BLOG_ITEM.split(':').shift()}0`,
    image: i6,
    title: 'Progressive subscription system.',
    descr:
      'There are various advanced options not included in the regular free v...',
  },
]

export const BlogPage = () => (
  <div>
    <section className={`blogPage`}>
      <div className="container blogPage--container">
        <div className="blogPage--main">
          <p className="blogPage--header">
            Internal MetaFek coins (MFC) currency
          </p>

          <img src={promoImage} alt="MetaFek" />
        </div>
      </div>
    </section>

    <section className={`blogPage`}>
      <div className="container blogPage--container list">
        <ul className="blogPage--list">
          {items.map((item, i) => (
            <Link to={item.link} key={i}>
              <li>
                <img src={item.image} alt="item" />
                <div>{item.title}</div>
                <p>
                  {item.descr.length > 68
                    ? `${item.descr.slice(0, 68)}...`
                    : item.descr}
                </p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </section>
  </div>
)
