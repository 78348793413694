export const endpoints = {
  UNSUBSCRIBE: (userId, template) => `user/${userId}/${template}/unsubscribe`,
  PROFILE: '/profile',
  ACCOUNT: '/account',
  SIGN_IN: '/signin',
  DELETE_THIS_ACCOUNT: '/user',
  SEND_RESET_PASSWORD_CODE_TO_EMAIL: '/resetpassword/email/sendcode',
  CONFIRM_RESET_PASSWORD_CODE_BY_EMAIL: '/resetpassword/email/confirm',
  GET_APP: '/getapp',
  CRYPTO_PAYMENT: '/account/crypto-payments',
  CHECK_CRYPTO_PAYMENT_STATUS: (transactionId) =>
    `/account/crypto-payments/${transactionId}`,
    UPLOAD_IMAGE: '/upload/image',
    UPLOAD_VIDEO: '/upload/video',
    DELETE_VIDEO: '/video',
    SAVE_PROMO_VIDEO: '/promo/video',
    CREATE_CAMPAIGN: '/promo/campaign',
    GET_PROMOS: '/promo/video/user',
    GET_CAMPAIGNS: '/promo/campaign',
    UPLOAD_STICKER_PACK: '/sticker-pack',
    DELETE_STICKER_PACK: '/sticker-pack',
    GET_STICKER_PACK: '/sticker-pack/owner',
    UPLOAD_ADS_IMAGE: '/advertisement/photo',
}
