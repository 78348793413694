import { authTokenStoreService } from '../../services/authTokenStoreService'
import { actionSetRedirect } from '../actions/commonUiActions'
import { signIn } from '../../services/api/accountService'
import { routes } from '../../constants/routes'

export const thunkSignIn =
  (data, from, setError, setLoading, redirectToDeleteAccountFlag) =>
  async (dispatch) => {
    setLoading(true)
    try {
      const resp = await signIn(data)
      const { access_token: token } = resp
      authTokenStoreService.store(token)

      dispatch(
        actionSetRedirect(
          from ||
            (redirectToDeleteAccountFlag
              ? routes.PROFILE.DELETE_ACCOUNT
              : routes.PROFILE.ACCOUNT),
          true,
        ),
      )
    } catch (error) {
      const { status } = error
      if (status === 401) {
        setError('Wrong email or password')
        alert('Please try again or reset your password')
      } else if (status === 403) {
        setError(
          'Your account has been permanently locked, you can reset password',
        )
        alert(
          'Your account has been permanently locked, you can reset password',
        )
      } else {
        setError(error.data?.message || 'error')
      }
    }
    setLoading(false)
  }
