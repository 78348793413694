import React from 'react'

import { passwordStrengthValidator } from '../../utils/stringProcessors'

const CodeStrengthIcon = ({ password }) => {
  const isPasswordFilled = password !== undefined && password !== ''
  if (isPasswordFilled && password?.length < 8) {
    return <span className="error-text">short</span>
  }
  const strength = passwordStrengthValidator(password)
  if (strength.weak && isPasswordFilled) {
    return <span className="error-text">weak</span>
  }
  if (strength.medium) {
    return <span className="warning-text">medium</span>
  }
  if (strength.strong) {
    return <span className="success-text">strong</span>
  }

  return null
}

export default CodeStrengthIcon
