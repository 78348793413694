import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import { routes } from '../../constants/routes'

import './style.scss'
import Stickerpacks from '../AddStickers/Stickerpacks'

export const Stickers = () => {
  const bc = useMemo<BreadcrumbItem[]>(() => [{ title: 'Stickers' }], [])

  return (
    <div className="sticker-requirements">
      <Breadcrumbs items={bc} />
  
      <section className="sticker-requirements__content">
      <Stickerpacks />
        <div className="sticker-requirements__block">
          <h3 className="sticker-requirements__title">
            Animation Requirements:
          </h3>
          <div className="sticker-requirements__description">
            The canvas size must be 512х512 pixels.
            <br />
            Objects must not leave the canvas.
            <br />
            Animation length must not exceed 3 seconds.
            <br />
            All animations must be looped.
            <br />
            Final file size must not exceed 64 KB after rendering in Bodymovin.
            <br />
            All animations must run at 60 Frames Per Second.
            <br />
            You must not use the following Adobe After Effects functionality
            when animating your artwork: Auto-bezier keys, Expressions, Masks,
            Layer Effects, Images, Solids, Texts, 3D Layers, Merge Paths, Star
            Shapes, Gradient Strokes, Repeaters, Time Stretching, Time
            Remapping, Auto-Oriented Layers.
            <br />
            The icon could be added. The icon size could be 100х100 pixels with
            looped animation no more than 3 seconds.
            <br />
            Pay attention! The sticker pack will be rejected if at least one
            condition is not followed your sticker pack will be rejected.
          </div>
          <div className="sticker-requirements__button">
            <Link to={`${routes.PROFILE.ANIMATED_STICKERS}`}>
              Animated stickers
            </Link>
          </div>
        </div>
        <div className="sticker-requirements__block">
          <h3 className="sticker-requirements__title">Image Requirements:</h3>
          <div className="sticker-requirements__description">
            For stickers, one side must be exactly 512 pixels in size – the
            other side can be 512 pixels or less.
            <br />
            For emoji, images must be exactly 100x100 pixels in size.
            <br />
            The image file must be in either .PNG or .WEBP format.
            <br />
            Tip: a transparent background, white stroke and black shadow effect
            will make your sticker stand out.
          </div>
          <div className="sticker-requirements__button">
            <Link to={`${routes.PROFILE.GRAPHIC_STICKERS}`}>
              Graphic stickers
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}
