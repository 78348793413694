import { FC, useEffect, useRef, useState } from 'react'

interface ProgressProps {
  isCompleted: boolean
}

const Progress: FC<ProgressProps> = ({ isCompleted }) => {
  const [percentage, setPercentage] = useState(0)
  const circumference = 22 * Math.PI * 2
  const dash = (percentage * circumference) / 100
  const tid = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    if (isCompleted) setPercentage(100)
  }, [isCompleted])

  useEffect(() => {
    tid.current = setInterval(() => {
      setPercentage((s) => {
        if (s > 85) {
          clearInterval(tid.current as any)

          tid.current = setInterval(() => {
            setPercentage((s) => {
              if (s >= 99) {
                clearInterval(tid.current as any)

                return 99
              }
              return s + 1
            })
          }, 500)
        }
        if (s >= 100) {
          clearInterval(tid.current as any)
          return 100
        }

        if (s === 5) {
          return s + 30
        }
        if (s === 20) {
          return s + 40
        }

        return s + 1
      })
    }, 100)
    return () => {
      clearInterval(tid.current as any)
    }
  }, [isCompleted])

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="22" stroke="#9B9B9B" strokeWidth="2" />

      <circle
        fill="none"
        stroke="#AF90FF"
        cx={0}
        cy={24}
        r={22}
        strokeWidth={`4`}
        transform={`rotate(-90 ${24 / 2} ${24 / 2})`}
        // @ts-ignore
        strokeDasharray={[dash, circumference - dash]}
        strokeLinecap="round"
        style={{ transition: 'all 0.5s' }}
      />
      <text
        fill="#AF90FF"
        fontSize="14px"
        x="50%"
        y="50%"
        dy="5px"
        textAnchor="middle"
      >
        {`${percentage}%`}
      </text>
    </svg>
  )
}

export default Progress
