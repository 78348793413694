import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import { AnimatedStickerEditor } from '../../components/AnimatedStickerEditor'
import { PreparedSticker } from '../../components/PreparedSticker'
import { StickerAddingForm } from '../../components/StickerAddingForm'
import { routes } from '../../constants/routes'
import { AddStickerInput } from '../../components/AddStickerInput'
import { thunkUploadStickers } from '../../store/thunks/thunkUploadStickers'
import isRightAnimationType from '../../utils/isRightAnimationType'

import './styles.scss'
import { useModalContext } from '../../contexts/ModalContext'
import {
  FreeStickerPackInfo,
  MAX_STICKERS_NUMBER,
  approveText,
  loadStickersMessage,
} from '../../constants'

export type EmojiData = { emojiName: string; emoji: string }

export type StickerFile = {
  id: number
  emoji: EmojiData
  file: File
  sticker: string
  isAnimated?: boolean
}

const MAX_STICKER_SIZE = 64000

const warnMessage = `
File format is not supported. 
Only .webp, 
duration less than 3 sec,
size less than 64 Kb
and dimensions must be 512x512 px`

export const AddAnimatedStickers = () => {
  const { push } = useHistory()
  const [stickerFiles, setStickerFiles] = useState<StickerFile[]>([])
  const [editableSticker, setEditableSticker] = useState<File | null>(null)
  const [isStickersBeingLoaded, setIsStickersBeingLoaded] = useState(false)
  const { openModal, openPopup } = useModalContext()

  const dispatch = useDispatch()

  const bc = useMemo<BreadcrumbItem[]>(
    () => [
      { title: 'StickerFiles', path: routes.PROFILE.STICKERS },
      { title: 'Add Animated Stickers' },
    ],
    [],
  )

  const onSuccess = () => {
    setIsStickersBeingLoaded(false)
    toast('Your Stickerpack has been loaded!')

    push(routes.PROFILE.STICKERS)
  }

  const onError = () => {
    setIsStickersBeingLoaded(false)
    toast('Something went wrong during the stickerpack uploading', {
      className: 'notification--error',
    })
  }

  const onLoading = () => {
    setIsStickersBeingLoaded(true)
  }

  const sendStickers = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget
    const { packName, packPrice } = form

    openModal({
      type: 'ok',
      message: loadStickersMessage,
      onOkText: approveText,
      onOk() {
        dispatch(
          thunkUploadStickers(
            stickerFiles,
            packName.value,
            +packPrice.value,
            onSuccess,
            onLoading,
            onError,
            'ANIMATION',
          ),
        )
      },
    })
  }

  const handleEditingDone = (sticker: StickerFile) => {
    setStickerFiles((state) => [...state, sticker])
    setEditableSticker(null)
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()

    if (!e.target.files?.length) return

    if (
      e.target.files[0].type.includes('image') ||
      e.target.files[0].type.includes('video') ||
      !e.target.files[0].name.endsWith('.tgs')
    ) {
      toast('File format is not supported. Only .tgs allowed')
      e.target.value = ''

      return
    }

    setEditableSticker(e.target.files[0])
    e.target.value = ''
  }

  const handleEditorClose = useCallback(() => {
    setEditableSticker(null)
  }, [])

  const handleRemoveSticker = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const sId = e.currentTarget.dataset.stickerId

    setStickerFiles((files) => {
      return files.filter(({ id }) => id.toString() !== sId)
    })
  }

  return (
    <div className="adding-stickers">
      <Breadcrumbs items={bc} />
      {FreeStickerPackInfo}
      <div className="relative">
        <div className="text-violet-400">{FreeStickerPackInfo}</div>
        <form className="adding-stickers-form" onSubmit={sendStickers}>
          <StickerAddingForm
            stickerFiles={stickerFiles}
            isDataSending={isStickersBeingLoaded}
          />

          <div className="sticker-monitor">
            {stickerFiles.map(({ id, sticker, emoji, isAnimated }) => (
              <PreparedSticker
                handleRemoveSticker={handleRemoveSticker}
                key={id}
                id={id}
                isAnimated={isAnimated}
                sticker={sticker}
                emoji={emoji}
              />
            ))}

            <AddStickerInput handleFileSelect={handleFileSelect} />
          </div>

          {editableSticker && (
            <div className="absolute inset-0 bg-white z-10">
              <AnimatedStickerEditor
                onDone={handleEditingDone}
                onClose={handleEditorClose}
                editableSticker={editableSticker}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
