import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { Button, Checkbox, LogoLink, TextInput } from '../../components/ui'
import { thunkSignIn } from '../../store/thunks/thunkSingIn'
import useQueryFrom from '../../hooks/useQueryFrom'
// import { TextInput } from '../../components/uiKit'
import { routes } from '../../constants/routes'
import bgBehind from '../../assets/svg/login-form-behind-bg.svg'
import bgInFront from '../../assets/svg/login-form-bg.svg'
import bgBehindMob from '../../assets/svg/login-form-behind-bg-back.svg'
import bgInFrontMob from '../../assets/svg/login-form-behind-bg-front.svg'
import closeIcon from '../../assets/svg/icon_close_cross.svg'

import './styles.scss'

export const LoginPage = () => {
  const [state, setState] = useState({
    password: undefined,
    email: undefined,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const from = useQueryFrom()
  const params = useParams()
  const redirectToDeleteAccountFlag = (new URLSearchParams(location.search)).get('redirect-to-delete-account')

  const dispatch = useDispatch()
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setError(undefined)
    // @ts-ignore
    dispatch(thunkSignIn(state, from, setError, setLoading, redirectToDeleteAccountFlag))
  }

  const handleChangePassword = (e: any) => {
    setState((prev) => ({
      ...prev,
      password: e.target.value,
    }))
  }

  const handleChangeEmail = (e: any) => {
    setState((prev) => ({
      ...prev,
      email: e.target.value,
    }))
  }

  return (
    <div className="login-page">
      <div className="container">
        <div className="login-page__form-container relative">
          <Link to={routes.HOME} className="absolute flex items-center justify-center right-3 top-3 w-5 h-5">
            <img src={closeIcon} alt="close icon" />
          </Link>
          <div>
            <div className="login-page--bg">
              <img src={bgBehind} alt="" />
            </div>
            <div className="login-page--bg">
              <img src={bgInFront} alt="" />
            </div>
          </div>
          <div>
            <div className="login-page--bg mob">
              <img src={bgBehindMob} alt="" />
            </div>
            <div className="login-page--bg mob">
              <img src={bgInFrontMob} alt="" />
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            autoComplete="on"
            className="login-form"
          >
            <div className="login-page--logo">
              <LogoLink />
            </div>

            <h2>Welcome to Relagram! 👋🏻</h2>
            <p>Please sign-in to your account and start the adventure</p>

            <div className="login-form__item">
              <TextInput
                label="Email"
                id="email"
                name="email"
                autoComplete="email"
                value={state.email}
                onChange={handleChangeEmail}
              />
            </div>
            <div className="login-form__item">
              <TextInput
                autoComplete="password"
                name="password"
                type="password"
                label="password"
                id="password"
                bordered={false}
                value={state.password}
                onChange={handleChangePassword}
              />
            </div>

            <Checkbox
              name="remember-me"
              content={<span className="login-form--label-checkbox">Remember me</span>}
              className="login-form--checkbox"
            />

            {error && (
              <div className="login-form__item">
                <span className="login-form__error">{error}</span>
              </div>
            )}

            <div className="login-form__item">
              <Button type="submit" content="sign in" />
            </div>
            <div className="login-form__item">
              <div className="login-form__link">
                <Link to={routes.RESET_PASSWORD}>Forgot Password?</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
