import { FC, useEffect, useState } from 'react'
import { Button } from '../ui'

import './styles.scss'

const AskForCookie: FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    ;(window as any).removeCookie = () => eraseCookie('cookieAcceptance')
    setTimeout(() => {
      const cookie = getCookie('cookieAcceptance')
      if (!cookie) setIsOpen(true)
    }, 1000)
  }, [])

  const handleClick = () => {
    setTimeout(() => {
      setCookie('cookieAcceptance', 'Yes')
      setIsOpen(false)
    }, 500)
  }

  return (
    <div className={`askForCookie ${isOpen ? 'askForCookie--opened' : ''}`}>
      <h2 className="askForCookie--header">We use Cookies</h2>
      <p className="askForCookie--text">
        We use cookies to collect information about you. We use this
        information:
        <br />
        1. to give you a better experience of our website (functional)
        <br />
        2. to count the pages you visit (statistics)
        <br />
        3. to serve you relevant promotions (marketing)
      </p>

      <h3 className="askForCookie--subText">
        Click “OK” to give us your consent to use cookies for all these
        purposes.
      </h3>

      <Button
        wrapperClassName="askForCookie--btn"
        content="OK"
        onCLick={handleClick}
      />
    </div>
  )
}

function setCookie(name: string, value: string, days: number = 100) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

function getCookie(name: string) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;'
}

export default AskForCookie
