import types from '../actions/types'

const { ui } = types

const initialState = {
  redirect: {
    url: '',
    blank: false,
    force: false,
  },
  isModalOpen: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ui.SET_REDIRECT_URL: {
      return {
        ...state,
        redirect: {
          ...state.redirect,
          ...payload,
        },
      }
    }
    case ui.OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
        componentType: payload,
      }
    }
    case ui.CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
        componentType: '',
      }
    }
    default:
      return state
  }
}
