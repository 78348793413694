import { Axios } from 'axios'

import { endpoints } from '../../constants/endpoints'
import { StickerPackDto } from '../../store/thunks/thunkUploadStickers'
import { get, post, deleteReq } from '../authRequestsService'
import { authTokenStoreService } from '../authTokenStoreService'
import { jwtDecode } from 'jwt-decode'

export const uploadImage = (formData: FormData) => {
  // return Promise.resolve({data: 'qwe'})
  return post(endpoints.UPLOAD_IMAGE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// export const deleteImage = (formData: FormData) =>
//   deleteReq(endpoints.UPLOAD_IMAGE, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })

export const uploadVideo = (formData: FormData) =>
  post(endpoints.UPLOAD_VIDEO, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const uploadStickerPack = (data: StickerPackDto) =>
  post(endpoints.UPLOAD_STICKER_PACK, data)

export const getStickerPack = () => {
  const { id: ownerId } = jwtDecode<{ id: string }>(
    authTokenStoreService.obtain() || '',
  )

  return get(`${endpoints.GET_STICKER_PACK}/${ownerId}`)
}

export const deleteStickerPack = (stickerpackId: string) => {
  return deleteReq(`${endpoints.DELETE_STICKER_PACK}/${stickerpackId}`)
}
