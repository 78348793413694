import { createCryptoPayment } from '../../services/api/accountService'

export const thunkCreateCryptoPayment =
  (paymentDetails) => async (dispatch) => {
    try {
      const url = await createCryptoPayment(paymentDetails)
      window.open(url, '_parent').focus()
    } catch (err) {
      console.log('thunk error', err)
    }
    return null
  }
