import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { authTokenStoreService } from '../../services/authTokenStoreService'
import { routes } from '../../constants/routes'

export const AuthProtect = ({ children }) => {
  const { pathname } = useLocation()
  const isLogged = authTokenStoreService.isLogged()

  const isAccountDeletingPath = pathname === routes.PROFILE.DELETE_ACCOUNT

  return isLogged ? children : <Redirect to={`${routes.LOGIN}?${isAccountDeletingPath ? 'redirect-to-delete-account=true' : ''}`} />
}
