import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'

import { put } from '../../../services/apiService'
import { endpoints } from '../../../constants/endpoints'
import { routes } from '../../../constants/routes'

import './styles.scss'

export const UnsubscribePage = () => {
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(false)
  const { search } = useLocation()

  const { id, template } = qs.parse(search, { ignoreQueryPrefix: true })

  const unsubscribe = async () => {
    setPending(true)
    try {
      await put(endpoints.UNSUBSCRIBE(id, template))
      setUnsubscribed(true)
    } catch (error) {
      setError(true)
    }
    setPending(false)
  }

  return (
    <div className="unsubscribe-page">
      {!error && unsubscribed && (
        <div className="unsubscribe-page__message">
          Your subscription has been successfully canceled
        </div>
      )}
      {error ? (
        <div className="unsubscribe-page__message">
          something goes wrong, try again from app
        </div>
      ) : (
        <button
          onClick={unsubscribe}
          className="unsubscribe-page__button"
          disabled={pending}
          type="button"
        >
          {pending ? 'waiting...' : 'unsubscribe'}
        </button>
      )}
      <div className="unsubscribe-page__link">
        <Link to={routes.PRODUCT}>go to homepage</Link>
      </div>
    </div>
  )
}
