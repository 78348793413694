import { FC, useEffect, useState } from 'react'
import { gemoji } from 'gemoji'
import { toast } from 'react-toastify'

import { Button as btn } from '../uiKit'
import closeIcon from '../../assets/svg/icon_close_cross.svg'
import { EmojiData, StickerFile } from '../../pages/AddAnimatedStickers'

import './styles.scss'

interface AnimatedStickerEditorProps {
  editableSticker: File
  onDone: (sticker: StickerFile) => void
  onClose: () => void
}

const Button = btn as any

export const AnimatedStickerEditor: FC<AnimatedStickerEditorProps> = ({
  editableSticker,
  onDone,
  onClose,
}) => {
  const [imageScr, setImageScr] = useState<string>('')
  const [correspondingEmoji, setCorrespondingEmoji] =
    useState<EmojiData | null>(null)

  const done = () => {
    if (!correspondingEmoji) {
      toast('Please select an emoji to continue adding this sticker')
      return
    }

    if (correspondingEmoji)
      onDone({
        id: new Date().getTime(),
        file: editableSticker,
        isAnimated: true,
        emoji: {
          emojiName: correspondingEmoji.emojiName,
          emoji: correspondingEmoji.emoji,
        },
        sticker: imageScr,
      })
  }

  useEffect(() => {
    const videoUrl = URL.createObjectURL(editableSticker)

    setImageScr(videoUrl)
  }, [])

  return (
    <div className="">
      <div>
        <div className="flex items-center flex-col mb-[32px]">
          <div className="flex justify-between w-[300px]">
            <tgs-player
              style={{ height: '100px', width: '100px' }}
              autoplay
              loop
              mode="normal"
              src={URL.createObjectURL(editableSticker)}
            ></tgs-player>

            {correspondingEmoji ? (
              <div className="text-[98px] leading-[98px]">
                {correspondingEmoji.emoji}
              </div>
            ) : (
              <div>{noEmogiPlaceholder}</div>
            )}
          </div>
        </div>

        <div className="text-[#8D9298] font-[KohinoorBangla] text-[12px] leading-[18px] text-center">
          What emotion corresponds to the sticker?
        </div>

        <div className="">
          <div className="flex flex-wrap max-h-[100px] overflow-auto">
            {gemoji.map((e) => {
              const name = e.names[0]
              return (
                <button
                  onClick={() =>
                    setCorrespondingEmoji({ emoji: e.emoji, emojiName: name })
                  }
                  type="button"
                  key={name}
                  className={`emoji-matcher--btn ${
                    name === correspondingEmoji?.emojiName
                      ? 'emoji-matcher--btn-active'
                      : ''
                  }`}
                >
                  {e.emoji}
                </button>
              )
            })}
          </div>

          <div className="flex justify-center mt-[32px]">
            <div className="mr-[16px]">
              <button
                className="bg-white rounded-[4px] border-[1px] border-[#AF90FF]
                 text-[#AF90FF] text-[13px] leading-[19.5px] font-[Poppins] px-[14px] py-[9px]
                 hover:bg-[#AF90FF]
                 hover:text-white
                 "
                type="button"
                onClick={onClose}
              >
                CANCEL
              </button>
            </div>
            <button
              className="bg-[#AF90FF] border-[1px] border-transparent rounded-[4px]
                 text-white text-[13px] leading-[19.5px] font-[Poppins] px-[14px] py-[9px]
                 hover:bg-white
                 hover:text-[#AF90FF]
                 hover:border-[#AF90FF]

                 "
              type="button"
              onClick={done}
            >
              ADD
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const noEmogiPlaceholder = (
  <svg
    width="98"
    height="98"
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 49C0 8.6485 8.6485 0 49 0C89.3515 0 98 8.6485 98 49C98 89.3515 89.3515 98 49 98C8.6485 98 0 89.3515 0 49Z"
      fill="#FBF9FF"
    />
    <path
      d="M43.1577 44.2717H45.4811C45.6305 42.3288 46.9349 41.1196 48.9865 41.1196C51.011 41.1196 52.3153 42.356 52.3153 44.0408C52.3153 45.4946 51.7039 46.3505 50.1278 47.3152C48.2664 48.4293 47.3968 49.6522 47.4104 51.4457V52.6957H49.7882V51.8125C49.7882 50.3859 50.2773 49.625 52.0028 48.606C53.742 47.5598 54.8425 46.0516 54.8425 43.9185C54.8425 41.1196 52.5055 39 49.0816 39C45.2637 39 43.3072 41.337 43.1577 44.2717ZM48.7827 59C49.8833 59 50.6577 58.212 50.6577 57.1114C50.6577 55.9973 49.8833 55.2092 48.7827 55.2092C47.6822 55.2092 46.8941 55.9973 46.8941 57.1114C46.8941 58.212 47.6822 59 48.7827 59Z"
      fill="#292D32"
    />
  </svg>
)
