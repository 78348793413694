import React from 'react'

import './styles.scss'
import { contactMailLink } from '../../../constants/links'

export const CopyrightPolicy = () => (
  <div className="documents">
    <p>
      Relagram respects the intellectual property rights of others, and we
      expect you to do the same. Relagram Terms of Service and Private Policy
      do not allow posting, sharing, or sending any content that violates or
      infringes someone else’s copyrights, trademarks or other intellectual
      property rights.
    </p>
    <h1>Copyright</h1>
    <p>
      Copyright is a legal right that protects original works of authorship
      (e.g., music, videos, etc.). Generally, copyright protects an original
      expression of an idea but does not protect underlying ideas or facts.
    </p>
    <h1>Copyright Infringement</h1>
    <p>
      We do not allow any content that infringes copyright. Any use of
      copyrighted content of others without proper authorization or legally
      valid reason may lead to a violation. In some cases, you may be required
      to provide evidence to prove that you are entitled to use copyrighted
      content of others.
    </p>
    <h1>Removal of Content; Suspension or Termination of Account</h1>
    <p>
      Any user content that infringes other person’s copyright may be removed.
      Your account may be suspended or terminated for multiple copyright
      violations in connection with your use of the Relagram site or app, or
      other violations of the Terms of Service and Private Policy.
    </p>
    <h1>Copyright Infringement Notification</h1>
    <p>
      If you believe that your copyright-protected work was posted on Relagram
      site or app, or otherwise hosted by Relagram without authorization, you
      may submit a copyright infringement notification.
    </p>
    <p>The notification must include the following:</p>
    <p>
      An email address, physical address and a phone number so that we and the
      uploader of your work can contact you.
    </p>
    <p>
      A clear and complete description of your work that you believe has been
      infringed.
    </p>
    <p>
      The URL or other identifying location of the allegedly infringing work.
    </p>
    <p>
      A statement that you have a good faith belief that use of the material in
      the manner complained of is not authorized by the copyright owner, its
      agent, or law.
    </p>
    <p>
      A statement, made under penalty of perjury, that the above information is
      accurate, and that you are the copyright owner or are authorized to act on
      behalf of the copyright owner.
    </p>
    <p>
      The physical or electronic signature of the copyright owner or an
      authorized representative of the copyright owner. To satisfy this
      requirement, you may type your full legal name (not that of a company) at
      the bottom of your notification.
    </p>
    <p>Submit the above information to us:</p>
    <p>
      By Email:{' '}
      <u>
        <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
      </u>
    </p>
    <p>&nbsp;</p>
    <p>
      All information submitted in your copyright infringement notification may
      be forwarded to the uploader of the content, to{' '}
      <u>
        <a href="https://www.lumendatabase.org/">lumendatabase.org</a>
      </u>
      , or otherwise made public in any way by Relagram or a third party.
    </p>
    <p>
      Before submitting a notification, please be aware of that intentionally
      submitting a misleading or fraudulent report may lead to liability for
      damages under section 512(f) of the United States Digital Millennium
      Copyright Act (DMCA) or similar laws in other countries.
    </p>
    <h1>Copyright Infringement Counter-Notification</h1>
    <p>
      If you receive a copyright infringement notification that you believe to
      be in error, you may provide us with a counter-notification.
    </p>
    <p>The counter-notification must include the following:</p>
    <p>
      Your contact information, including your full legal name (not that of a
      company), an email address, a physical address, and a phone number. If you
      are an authorized representative of the uploader, such as an attorney,
      please be sure to specify your relationship to the uploader.
    </p>
    <p>
      Identify the location, including any URL, of the work that has been
      removed or disabled.
    </p>
    <p>
      You must agree to and include the following statement: &quot;I consent to
      the jurisdiction of the Federal District Court for the district in which
      my address is located, or if my address is outside of the United States,
      the judicial district in which Relagram is located, and will accept
      service of process from the claimant.&quot;
    </p>
    <p>
      And the following statement: &quot;I swear, under penalty of perjury, that
      I have a good faith belief that the material was removed or disabled as a
      result of a mistake or misidentification of the material to be removed or
      disabled.&quot;
    </p>
    <p>
      Your physical or electronic signature. To satisfy this requirement, you
      may type your full legal name (not that of a company) at the bottom of
      your electronic counter-notification.
    </p>
    <p>Submit the above information to us:</p>
    <p>
      By Email:{' '}
      <u>
        <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
      </u>
    </p>
    <p>&nbsp;</p>
    <p>
      The counter-notification process may take no less than 10 business days to
      complete, please be patient. During this time, the copyright claimant may
      file an action seeking a court order to keep the content down. Please note
      that we will forward the entire counter-notification to the copyright
      claimant, including any personal information you provide, and the
      counter-notification may be made public. The claimant may use this
      information to file a lawsuit against you.
    </p>
    <p>
      If we do not receive notice within 10 business days that the original
      reporter is seeking a court order to prevent further infringement of the
      material at issue, we may replace or cease disabling access to the
      material that was removed. The decision to re-post any material is at
      Relagram sole discretion.
    </p>
    <p>&nbsp;</p>
    <h1>Trademark</h1>
    <p>
      A trademark is a word, symbol, slogan, design, or combination of any of
      the foregoing that identifies the source of a product or service and
      distinguishes it from other products or services.
    </p>
    <p>
      Relagram’s policies prohibit any content that infringes on another
      person’s trademark. Using another person’s trademark in a way that may
      mislead or confuse people to believe that you are affiliated with the
      trademark owner may be a violation of our trademark policy.
    </p>
    <p>
      However, using another person’s trademark for the purpose of accurately
      referencing the trademark owner’s products or services, or to compare them
      to other products or services, is generally not considered a violation of
      our trademark policy.
    </p>
    <h1>Removal of Content; Suspension or Termination of Account</h1>
    <p>
      Any user content that infringes other person’s trademark may be removed.
      Your account may be suspended or terminated for multiple trademark
      violations in connection with your use of the Relagram site or app, or
      other violations of the Terms of Service and{' '}
      <a href="https://support.tiktok.com/en/my-account-settings/community-policy-default">
        Private
      </a>{' '}
      Policy.
    </p>
    <h1>Trademark Infringement Notification</h1>
    <p>
      Relagram may investigate reports that are submitted by the trademark holder
      or their authorized representative. You can submit a trademark report to
      us via email{' '}
      <u>
        <a href={`mailto:${contactMailLink}`}>{contactMailLink}</a>
      </u>
      , by providing the following information:
    </p>
    <p>
      Your contact information, including your full name, physical address,
      email address and a phone number. Please note that we may provide your
      information and details of your report to the person who posted the
      content or owns the account you are reporting. This person may contact you
      with the information you provide.
    </p>
    <p>Your relationship to the trademark owner.</p>
    <p>
      A clear and complete description of your trademark that you believe has
      been infringed, including the jurisdiction of registration, registration
      number, trademarked goods and services class.
    </p>
    <p>
      A scan of trademark registration certificate or a direct link (URL) to
      your trademark record.
    </p>
    <p>
      Information about the allegedly infringing content, including the URL or
      other identifying location of the allegedly infringing content, and a
      description of how the content has been infringing your trademark.
    </p>
    <p>
      A statement that you have a good faith belief that use of the trademark
      described above, in the manner complained of, is not authorized by the
      trademark owner, its agent, or the law.
    </p>
    <p>
      A statement, made under penalty of perjury, that the above information is
      accurate, and that you are the trademark owner or are authorized to act on
      behalf of the owner.
    </p>
    <p>
      The physical or electronic signature of the trademark owner or an
      authorized representative of the trademark owner.
    </p>
    <p>
      If we remove content in response to a report of trademark infringement, we
      may notify the person you reported to inform them the content was removed.
      We may also provide them with your contact information, including your
      email address and the name of the trademark owner, and/or details of your
      report.
    </p>
    <p>&nbsp;</p>
    <h1>General Note:</h1>
    <p>
      As a Relagram user, you are responsible for the content you post. If you
      have questions about copyright law or trademark law, such as questions
      about whether your content or your use of another person’s name or brand
      infringes or otherwise violates another person’s rights, you may want to
      contact an attorney. If you are unsure whether the material you plan to
      report to us is infringing or otherwise violating another person’s right,
      you may also want to first seek legal advice before reporting such content
      to us.
    </p>
    <p>
      If you submit a report or infringement notification to us, we may contact
      you if we have additional questions about your report or notification.
      Please note that Relagram is not in a position to adjudicate disputes
      between third parties, and may not be able to remove the content or
      suspend the account you reported for infringement or violation. As an
      alternative, you may want to contact the person who posted the content or
      owns the account to try to resolve your issue directly.
    </p>
  </div>
)
