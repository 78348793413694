import { FC, useState } from 'react'
import { useModalContext } from '../contexts/ModalContext'
import closeIcon from '../assets/svg/cross.svg'

const Modal: FC = () => {
  const { modalConfig, closePopup } = useModalContext()
  const [processing, setProcessing] = useState(false)
  const handleClosePopup = () => {
    modalConfig?.onClose?.()
    closePopup()
  }
  return modalConfig ? (
    <div className="fixed inset-0 flex items-center justify-center late-800 z-[999]">
      <div
        data-popup
        className="relative flex flex-col items-center text-center w-[100%] max-w-[386px]
         bg-white py-[30px] px-[20px] shadow-lg shadow-[#AF90FF3D] rounded-[16px]
         max-md:ml-[8px] max-md:mr-[8px] border-[1px] border-[#AF90FF]
         "
      >
        {/* <div className="shrink-0">
          {modalConfig.type === 'warning' && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-[10vh] text-yellow-500" />
          )}

          {modalConfig.type === 'error' && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-[10vh] text-red-700" />
          )}

          {modalConfig.type === 'ok' && <FontAwesomeIcon icon={faCheckCircle} className="w-[10vh] text-lime-500" />}
        </div> */}

        {/* {modalConfig.type === 'warning' && (
          <h2 className="text-yellow-500 mt-[10px]">{t('sidebarPopupWarn', 'Warning')}</h2>
        )} */}

        {/* {modalConfig.type === 'error' && <h2 className="text-red-700 mt-[10px]">{t('sidebarPopupError', 'Error')}</h2>} */}

        {modalConfig.message}

        {modalConfig.reason && (
          <p className="text-[12px] mt-[10px] text-slate-400">
            {modalConfig.reason}
          </p>
        )}

        <button
          onClick={handleClosePopup}
          type="button"
          className="
            absolute right-0 top-0 flex justify-center 
            shrink-0 items-center w-[40px] h-[40px] rounded-full
          "
        >
          <img src={closeIcon} />
        </button>

        <button
          onClick={async () => {
            setProcessing(true)
            await modalConfig.onOk()
            setProcessing(false)
            closePopup()
          }}
          type="button"
          className={`${
            processing ? '!opacity-50' : ''
          } mt-[25px] bg-[#AF90FF] px-[30px] py-[15px] rounded-[8px] text-[17px] leading-[25.5px] text-white uppercase`}
        >
          {modalConfig.onOkText}
        </button>
      </div>
    </div>
  ) : null
}

export default Modal
