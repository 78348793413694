import React, { useEffect } from 'react'

import { appStoreLink, googlePlayLink } from '../../../constants/links'
import checkUserAgent from '../../../utils/checkUserAgent'
import AppStoreLogo from '../../../assets/svg/logo_app_store.svg'
import googlePlayLogo from '../../../assets/svg/logo_google_play.svg'
import { useQr } from '../../../hooks/useQr'
import './styles.scss'

const QrsSection = ({ iosLink, androidLink }) => {
  const appStoreQR = useQr(iosLink)
  const googlePlayQR = useQr(androidLink)
  return (
    <div className="referral-page">
      <div className="referral-page__header">
        <h3>Install Relagram application</h3>
      </div>
      <div className="referral-page__qrs">
        <section className="referral-page__logos-container">
          <p>For iOS</p>
          {appStoreQR}
          <a href={iosLink}>
            <img src={AppStoreLogo} alt="app store logo" />
          </a>
        </section>
        <section className="referral-page__logos-container">
          <p>For Android</p>
          {googlePlayQR}
          <a href={androidLink}>
            <img src={googlePlayLogo} alt="google play logo" />
          </a>
        </section>
      </div>
    </div>
  )
}

export const ReferralPage = () => {
  const { userAgent } = window.navigator
  const agent = userAgent.toLowerCase()
  const { isAndroid, isIOS, isPc } = checkUserAgent(agent)
  const androidLink = `${googlePlayLink}&referrer=%22${encodeURIComponent(
    window.location.href,
  )}`

  useEffect(() => {
    const openLink = (link) => window.open(link, '_self')
    if (isAndroid) openLink(androidLink)
    if (isIOS) openLink(appStoreLink)
  }, [])

  return isPc ? (
    <QrsSection iosLink={appStoreLink} androidLink={androidLink} />
  ) : null
}
