import { FC, useEffect, useMemo } from 'react'
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs'
import checkedicon from '../../assets/img/ad/green_checked.svg'
import { tw } from '@twind/core'
import DragDrop from './DragDrop'
import { AllAds, useAdsContext } from './AdsContexts'
import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'
import Progress from './Progress'

export const Ads = () => {
  const { setImage, allAds } = useAdsContext()

  const bc = useMemo<BreadcrumbItem[]>(
    () => [{ title: 'Advertising campaign' }],
    [],
  )

  return (
    <div className="sticker-requirements max-md:w-full">
      <Breadcrumbs items={bc} />

      <section className="sticker-requirements__content">
        <div className={tw('flex flex-col mb-[26px] max-md:w-full')}>
          <div
            className={tw(
              `flex flex-wrap max-md:flex-nowrap max-md:w-full overflow-auto font-[KohinoorBangla]`,
            )}
          >
            <VideoRow allAds={allAds} />
          </div>

          <DragDrop onChange={setImage} />
        </div>
        <div
          className={tw(
            'grid grid-cols-2 gap-y-[10px] gap-x-[45px] max-md:grid-cols-1',
          )}
        >
          <div
            className={tw(
              'flex items-center text-[12px] text-[#4A4A4A] col-span-2 max-md:col-span-1',
            )}
          >
            <img
              className={tw('mr-[10px]')}
              src={checkedicon}
              alt="checkedicon"
            />
            <span>Upload a video without copyright violations ONLY</span>
          </div>
          <div
            className={tw(
              'flex items-center text-[12px] text-[#4A4A4A] max-md:col-span-1',
            )}
          >
            <img
              className={tw('mr-[10px]')}
              src={checkedicon}
              alt="checkedicon"
            />
            <span>Excludes graphic nudity, violence, or hatev</span>
          </div>
          <div
            className={tw(
              'flex items-center text-[12px] text-[#4A4A4A] max-md:col-span-1',
            )}
          >
            <img
              className={tw('mr-[10px]')}
              src={checkedicon}
              alt="checkedicon"
            />
            <span>High quality videos</span>
          </div>
          <div
            className={tw(
              'flex items-center text-[12px] text-[#4A4A4A] max-md:col-span-1',
            )}
          >
            <img
              className={tw('mr-[10px]')}
              src={checkedicon}
              alt="checkedicon"
            />
            <span>Mindful of the rights of others</span>
          </div>
        </div>
      </section>
    </div>
  )
}

const VideoRow: FC<{ allAds: AllAds[] }> = ({ allAds }) => {
  const statuses = useMemo(() => {
    return [
      <div className={tw('text-[#9B9B9B]')}>Moderation in process</div>,
      <div className={tw('text-[#7ED321]')}>Approved</div>,
      <div className={tw('text-[#FB6F80]')}>Rejected</div>,
    ]
  }, [])

  return (
    <>
      {allAds.map((add) => {
        return (
          <div
            key={add.id}
            className={tw(
              'flex shrink-0 flex-col w-[150px] mr-[30px] mb-[30px]',
            )}
          >
            <div
              className={tw(`h-[86px] w-full flex items-center justify-center rounded-[12px] mb-[20px] overflow-hidden`)}
            >
              <video
                onLoadedData={(e) => {
                  ;(e.target as any).style.display = 'block'
                }}
                className={tw(
                  'relative z-[99] w-full h-full hidden object-cover',
                )}
                src={add.url}
                controls={false}
              ></video>

              <div className={tw('absolute')}>
                <Progress isCompleted={false} />
              </div>
            </div>
            <div
              className={tw(
                `text-[16px] leading-[24px] font-[500] ${
                  add.status === 2 ? 'text-[#9B9B9B]' : 'text-[#4A4A4A]'
                } mb-[4px]`,
              )}
            >
              {add.title}
            </div>
            <div className={tw('text-[12px] leading-[18px]')}>
              {statuses[add.status]}
            </div>
            {add.status === 1 && (
              <Link
                to={`${routes.PROFILE.CREATE_PROMO.split('/:')[0]}/${add.id}`}
                className={tw(
                  `text-[13px] leading-[19.5px] uppercase px-[14px] py-[7px] text-white bg-[#AF90FF] rounded-[4px]
              hover:bg-white hover:text-[#AF90FF] border-[1px] border-[#AF90FF] mt-[20px]`,
                )}
              >
                USE THIS ARTWORK
              </Link>
            )}
          </div>
        )
      })}
    </>
  )
}
